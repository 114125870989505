import React, { useState } from 'react';
import './PdfContent.css';
import { saveAs } from 'file-saver';
import { renderToString } from 'react-dom/server';
import { NumberOfWords } from './Constants';
import { useSelector } from 'react-redux';
import { UserSessionSelector } from '../store/UserSession';
import htmlDocx from 'html-docx-js/dist/html-docx';
import axios from 'axios';
import axiosPrivate from 'config/axiosPrivate';
import parse from 'html-react-parser';
import { Button } from '@mui/material';

const styles = {
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    textAlign: 'center',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  underlined: {
    fontSize: 14.1,
    fontWeight: 'bold',
    fontStyle: 'normal',
    textDecoration: 'underline',
  },
  boldText: {
    fontSize: 14.1,
    fontWeight: 'bold',
    fontStyle: 'normal',
    marginTop: 20,
    marginBottom: 20,
  },
  normalText: {
    fontSize: 14.1,
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 15,
    marginTop: 15,
  },
  image: {
    height: 200,
    width: 200,
  },
};

export const PdfView = (props) => {
  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="theme-color" content="#000000" />
      </head>
      <body>
        <div style={{ marginBottom: 25 }}>
          <span style={styles.title}>Comparaison &nbsp; hors &nbsp; ligne</span>
        </div>

        <div>
          <span style={styles.underlined}>Mail</span>
          <span style={styles.normalText}>: {props.user.mail}</span>
        </div>
        <div>
          <span style={styles.underlined}>Date</span>
          <span style={styles.normalText}>
            : {new Date().getDate()}/{new Date().getMonth() + 1}/
            {new Date().getFullYear()}
          </span>
          <div style={{ marginLeft: 25 }} />
          <span style={styles.underlined}>Heures &nbsp;</span>
          <span style={styles.normalText}>
            : {new Date().getHours()}:{new Date().getMinutes()}
          </span>
        </div>
        {props.url && props.url.length > 0 && (
          <div>
            <span style={styles.underlined}>Url</span>
            <span style={styles.normalText}>: {props.url}</span>
          </div>
        )}
        <div>
          <span style={styles.boldText}>
            Mots &nbsp; - &nbsp; clés &nbsp; avec &nbsp; fréquence:
          </span>
        </div>
        <div>
          <span style={styles.normalText}>{props.keywords}</span>
        </div>
        {props.text && (
          <div>
            <span style={styles.underlined}>Nombre de mots</span>
            <span style={styles.normalText}>: {NumberOfWords(props.text)}</span>
          </div>
        )}

        <div>
          <span style={styles.boldText}>Texte: &nbsp;</span>
        </div>
        {parse(props.content)}

        <div
          style={{
            marginTop: 25,
            marginBottom: 25,
          }}
        >
          <span>
            --------------------------------------------------------------------------
          </span>
        </div>
        {props.result && (
          <div>
            <span style={styles.boldText}>Résultats</span>
            {props.result.semanticEnrichment !== null && (
              <div style={styles.row}>
                <span style={styles.normalText}>
                  Enrichissement sémantique :{' '}
                </span>
                <span style={{ color: props.color }}>
                  {parseInt(props.result.semanticEnrichment)}%
                </span>
              </div>
            )}
          </div>
        )}
        {props.result &&
          props.result.greenWordsByFreq.greenWordsWithRemainingFreq &&
          Object.keys(props.result.greenWordsByFreq.greenWordsWithRemainingFreq)
            .length > 0 && (
            <div>
              <div>
                <span
                  style={
                    styles.boldText && {
                      marginBottom: 20,
                      marginTop: 20,
                    }
                  }
                >
                  {'Ajout de mots-clés avec la fréquence restante :'}
                </span>
              </div>
              <div>
                <span>
                  {Object.keys(
                    props.result.greenWordsByFreq.greenWordsWithRemainingFreq
                  ).map((item, key) => {
                    return (
                      <span style={styles.normalText} key={key}>
                        {item}(
                        <span style={{ textDecoration: 'line-through' }}>
                          {props.convertedText[item]},
                        </span>
                        <span>
                          {
                            props.result.greenWordsByFreq
                              .greenWordsWithRemainingFreq[item]
                          }
                          ){' '}
                        </span>
                      </span>
                    );
                  })}
                </span>
              </div>
            </div>
          )}
        {props.result &&
          props.result.remainingKeywords &&
          Object.keys(props.result.remainingKeywords).length > 0 && (
            <div>
              <div>
                <span
                  style={
                    styles.boldText && {
                      marginBottom: 20,
                      marginTop: 20,
                    }
                  }
                >
                  {'Mots clés restants:'}
                </span>
              </div>
              <div>
                <span style={styles.normalText}>
                  {Object.keys(props.result.remainingKeywords).map(
                    (item, key) => {
                      let word =
                        item +
                        '(' +
                        props.result.remainingKeywords[item] +
                        ') ';
                      return word;
                    }
                  )}
                </span>
              </div>
            </div>
          )}
      </body>
    </html>
  );
};

export function isValidFormat(text) {
  console.log('text', text);
  if (!text || text === '') return false;
  // Normalize the text by replacing new lines with commas and trim extra spaces
  text = text.replace(/\r?\n/g, ',').trim();

  // Remove extra commas resulting from consecutive new lines or leading/trailing commas
  text = text.replace(/,+/g, ',').replace(/^,|,$/g, '');

  // Split the text into segments by commas
  const segments = text.split(/\s*,\s*/);

  // Filter out empty segments
  const nonEmptySegments = segments.filter((segment) => segment.trim() !== '');

  // Function to check if a keyword is valid
  const isValidSegment = (segment) => {
    const trimmedSegment = segment.trim();

    // Check if the segment contains a frequency
    const hasFrequency =
      trimmedSegment.endsWith(')') && trimmedSegment.includes('(');

    if (hasFrequency) {
      // Split into keyword and frequency parts
      const openParenIndex = trimmedSegment.lastIndexOf('(');
      const keywordPart = trimmedSegment.slice(0, openParenIndex).trim();
      const frequencyPart = trimmedSegment.slice(openParenIndex + 1, -1); // Extracts content inside parentheses

      // Check if the frequency is a valid number and keyword part is non-empty
      if (keywordPart && !isNaN(frequencyPart) && frequencyPart.trim() !== '') {
        return true;
      }
      return false;
    } else {
      // Allow plain keywords with letters, spaces, and apostrophes
      return /^[\p{L}\s'-]+$/u.test(trimmedSegment); // \p{L} matches any kind of letter from any language
    }
  };

  // Check each non-empty segment
  for (let segment of nonEmptySegments) {
    if (!isValidSegment(segment)) {
      return false; // Return false if any segment is invalid
    }
  }

  return true; // All segments are valid
}

function checkBrackets(str) {
  // depth of the parenthesis
  var depth = 0;
  // for each char in the string : 2 cases
  for (var i in str) {
    if (str[i] === '(') {
      // if the char is an opening parenthesis then we increase the depth
      depth++;
    } else if (str[i] === ')') {
      if (str[parseInt(i) + 1] !== '\n' && str.length !== parseInt(i) + 1)
        return false;
      // if the char is an closing parenthesis then we decrease the depth
      depth--;
    }
    //  if the depth is negative we have a closing parenthesis
    //  before any matching opening parenthesis
    if (depth < 0) return false;
  }
  // If the depth is not null then a closing parenthesis is missing
  if (depth > 0) return false;
  // OK !
  return true;
}

// function checkFormat(str){
//   forEach(elm in str){

//   }
// }

function extractNumber(str) {
  const regex = /\((\d*\.?\d*)\)/g;
  let m;

  let val = 0;
  while ((m = regex.exec(str)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    val = parseInt(m[1]);
  }
  return val;
}

export const setKeyWordList = (input) => {
  let list = null;
  const regex = /,\s?/g;
  let keywords = input.replace(regex, '\n');

  let isValid = checkBrackets(keywords);
  if (isValid) {
    const lines = keywords.trim().split('\n');
    for (let i = 0; i < lines.length; i++) {
      const number = extractNumber(lines[i]);
      if (number === 0) return null;

      const value = lines[i].substring(0, lines[i].indexOf('('));
      list = {
        ...list,
        [value.trim()]: number,
      };
    }
  }
  return list;
};

export const FetchDataForPdq = (props) => {
  const { user } = useSelector(UserSessionSelector);
  const [loading, setLoading] = useState(false);
  try {
    return (
      <Button
        variant="contained"
        disabled={loading}
        color="primary"
        onClick={() => {
          setLoading(true);
          (async () => {
            const response = await axiosPrivate
              .get('/texts/' + props.id + '/getFullDetails')
              .then((res) => res.data);
            const text = response;
            const keywords = response.semanticGap
              .map((x) => x.word + '(' + x.frequency + ')')
              .join(',\n');
            const convertedText = setKeyWordList(keywords);
            let result = response.result && JSON.parse(response.result);
            let color = '#ffffff';
            if (result) {
              if (result.semanticEnrichment <= 29) {
                color = '#FF595D';
              }
              if (
                result.semanticEnrichment <= 69 &&
                29 < result.semanticEnrichment
              ) {
                color = '#FFBE64';
              }
              if (result.semanticEnrichment > 69) {
                color = '#3ED774';
              }
            }
            const fileBuffer = htmlDocx.asBlob(
              renderToString(
                <PdfView
                  user={user}
                  keywords={keywords}
                  url={text.url}
                  content={text.content}
                  text={text.textValue}
                  result={result}
                  color={color}
                  convertedText={convertedText}
                />
              )
            );
            saveAs(fileBuffer, text.textName + '.docx');
          })().then(() => {
            setLoading(false);
          });
        }}
      >
        Télécharger{' '}
      </Button>
    );
  } catch {
    return (
      <Button disabled variant={'contained'} color={'secondary'}>
        Erreur
      </Button>
    );
  }
};

export const DownloadAll = (props) => {
  const { user } = useSelector(UserSessionSelector);
  const fetchData = async () => {
    const data = props.data;
    const zip = require('jszip')();
    let files = await data
      .filter((x) => x.validateAt)
      .map(async (item) => {
        const response = await axios
          .get(props.url + '/GetTextDetailsForDownload', {
            params: {
              id: item.id,
            },
          })
          .then((res) => res.data);
        const keywords = response.gapSemantique
          .map((x) => x.mot + '(' + x.nbrColumn + ')')
          .join(',\n');
        const convertedText = setKeyWordList(keywords);
        let color = '#ffffff';
        const result = response.result && JSON.parse(response.result);
        if (result) {
          if (result.semanticEnrichment <= 29) {
            color = '#FF595D';
          }
          if (
            result.semanticEnrichment <= 69 &&
            29 < result.semanticEnrichment
          ) {
            color = '#FFBE64';
          }
          if (result.semanticEnrichment > 69) {
            color = '#3ED774';
          }
        }
        const fileBuffer = htmlDocx.asBlob(
          renderToString(
            <PdfView
              user={user}
              keywords={keywords}
              content={response.content}
              url={response.url}
              text={response.text1}
              result={result}
              color={color}
              convertedText={convertedText}
            />
          )
        );
        return {
          name: response.nomText + '.docx',
          data: fileBuffer,
        };
      });
    const result = await Promise.all(files);
    result.map((x) => zip.file(x.name, x.data));
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'allFiles.zip');
    });
  };
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={fetchData}
      disabled={props.disabled}
    >
      Télécharger tout
    </Button>
  );
};
