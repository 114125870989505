import React, { useState } from 'react';
import SearchIcon from 'icons/SearchIcon';

const SearchComponent = ({ searchValue, placeholder, onSearchValueChange }) => {
  return (
    <div className="w-100">
      <div className="input-group input-cs w-100">
        <div className="input-group-text">
          <SearchIcon />
        </div>
        <input
          type="text"
          className="form-control ps-0"
          placeholder={placeholder}
          value={searchValue}
          onChange={(e) => onSearchValueChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default SearchComponent;
