import React, { useState } from 'react';
import he from 'he';
import { diff_match_patch } from 'diff-match-patch';
import { diffWords } from 'diff';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  updateUserAnalysisCount,
  UserSessionSelector,
} from 'store/UserSession';

import axiosOfflineComparison from 'config/axiosOfflineComparison';
import axiosPrivate from 'config/axiosPrivate';

import { setKeyWordList } from 'helpers/PdfFunction';
import {
  removeWhitespace,
  replaceTextInBold,
  entitiesToSpecialChars,
  stripHTML,
} from 'utils/StringUtils';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';

import { ROLES } from 'utils/Constants';

export const AnalyseText = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector(UserSessionSelector);
  const { t } = useTranslation();

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const FetchResult = () => {
    const list = setKeyWordList(props.keywords);

    let textInput = '';

    if (list === null) {
      setErrorSuccessMsg(
        t(
          `Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.`
        )
      );
      setIsSuccess(false);
      setErrorSuccess(true);
      props.setLoading(false);
      props.setResult(null);
      return;
    }

    try {
      if (props.initialText && props.text) {
        let newContent = removeWhitespace(props.text);
        let oldContent = removeWhitespace(props.initialText);

        const diff = diffWords(oldContent, newContent);

        const addedWords = diff
          .filter((part) => part.added)
          .map((part) => part.value)
          .join(' ');

        textInput = addedWords;
      } else {
        textInput = props.text;
      }
    } catch (e) {
      textInput = props.text;
      console.log('Error in text processing', e);
    }
    const content = stripHTML(he.decode(textInput.trim().normalize('NFC')));

    axiosOfflineComparison
      .post(
        '/offline-comparison/analyzeKeywordsWithFreqJSON',
        { content: content, keywords: list },
        { params: { language: props.language.id } }
      )
      .then((res) => {
        if (res.status === 200 && res.data.status === 'ok') {
          props.setLoading(false);
          props.setList(list);
          props.setResult(res.data);

          let processedContent = '';
          if (props.orderType === 'PREMIUM') {
            if (/<\/?[a-z][\s\S]*>/i.test(props.text)) {
              processedContent = entitiesToSpecialChars(props.content);
            } else {
              processedContent = he.decode(props.content);
            }
          } else {
            processedContent = he.decode(props.content);
          }

          let filteredKeywords = res.data.wordsInBold.filter((keyword) => {
            return keyword.length > 0 && keyword !== '';
          });
          console.log(
            '🚀 ~ filteredKeywords ~ filteredKeywords:',
            filteredKeywords
          );

          const highlightedContent = replaceTextInBold(
            processedContent,
            filteredKeywords
          );

          props.setContent(highlightedContent);

          if (user && user.role === ROLES.WRITER) {
            axiosPrivate
              .put('/user/increaseAnalysis')
              .then((res) => {
                dispatch(updateUserAnalysisCount(res.data));
              })
              .catch((e) => console.log('text analysis error', e));
          }
        } else {
          props.setLoading(false);
          props.setResult(null);
        }
      })
      .catch((e) => {
        console.log('error', e);
        if (e?.response?.status) {
          setErrorSuccessMsg(
            t(
              `Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.`
            )
          );
          setIsSuccess(false);
          setErrorSuccess(true);
        }
      });
  };

  if (
    props.disabled ||
    props.keywords === '' ||
    props.content === '' ||
    (props.analysis && props.limit ? props.analysis === props.limit : false)
  )
    return <button className="bt-submit-disabled-y"> {t('Analyser')} </button>;

  return (
    <div>
      <button
        className="bt-submit-y"
        onClick={() => {
          props.setResult(null);
          props.setLoading(true);
          FetchResult();
        }}
      >
        {t('Analyser')}
      </button>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
};

export default AnalyseText;
