import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

export const UpDownInputArrows = (props) => {
  if (props.disabled === true)
    return (
      <>
        <div
          style={{ backgroundColor: 'rgb(224,224,224)', color: 'grey' }}
          id="inc-button"
          className="spinner-button"
        >
          <ChevronUpIcon />
        </div>
        <div
          style={{ backgroundColor: 'rgb(224,224,224)', color: 'grey' }}
          id="dec-button"
          className="spinner-button"
        >
          <ChevronDownIcon />
        </div>
      </>
    );

  return (
    <>
      <div id="inc-button" className="spinner-button">
        <ChevronUpIcon style={{ cursor: 'pointer' }} />
      </div>
      <div id="dec-button" className="spinner-button">
        <ChevronDownIcon style={{ cursor: 'pointer' }} />
      </div>
    </>
  );
};
