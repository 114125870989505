import React from 'react';

import './shared.css';

import { PAGENUMBERSLARGELIMIT } from 'utils/Constants';

export const ShortPaginator = (props) => {
  return (
    <div className="row row-page">
      <div>
        <div className="box-pagination">
          <div className="list-page-number">
            {props.pageNumbers
              .slice(
                Math.max(0, props.currentPage - PAGENUMBERSLARGELIMIT),
                Math.min(
                  props.currentPage + PAGENUMBERSLARGELIMIT,
                  props.pageNumbers.length
                )
              )
              .map((pageNum, index) => (
                <span
                  key={index}
                  className={pageNum === props.currentPage ? 'active' : ''}
                  onClick={() => props.goToPage(pageNum)}
                >
                  {pageNum}
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
