import {
  Button,
  IconButton,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { classes } from '../modal/modalClasses';
import { Close } from '@mui/icons-material';
import ReceiptIcon from '@mui/icons-material/Receipt';
const theme = createTheme({
  palette: {
    primary: {
      main: '#757575',
    },
  },
});
const CustomDropzone = ({
  message,
  getRootProps,
  getInputProps,
  isDragActive,
  onRemoveFile,
  t,
  file,
  visualize,
}) => {
  const modifMessage = message ? JSON.parse(message) : null;
  const handleRemoveFile = () => {
    visualize !== true && onRemoveFile();
  };

  return (
    <div>
      {file ? (
        <>
          <div style={{ display: 'flex', alignItems: 'left' }}>
            <h5 style={classes.FactureInserted} className="modal-title">
              {t('Votre facture')}
            </h5>

            <ThemeProvider theme={theme}>
              <Button
                disabled={false}
                size="small"
                variant="outlined"
                startIcon={<ReceiptIcon />}
                sx={{ marginBottom: theme.spacing(2) }}
              >
                {' '}
                <Typography>{file.name}</Typography>
                {visualize !== true && (
                  <IconButton
                    aria-label="Remove file"
                    onClick={handleRemoveFile}
                    sx={{ marginLeft: theme.spacing(1) }}
                  >
                    <Close />
                  </IconButton>
                )}
              </Button>
            </ThemeProvider>
          </div>{' '}
          {message && (
            <div
              style={{
                display: 'flex',
              }}
            >
              <img
                style={classes.IconAlertModif}
                src={'/Images/icons/Icon Alert Modif.png'}
                alt="alert icon"
              />
              <span style={classes.ModificationDemande}>
                <span style={classes.ModificationDemandeTitle}>
                  {t('Modification demandée')} :
                </span>
                <span class="text-style-2"></span>
                {modifMessage.message}
              </span>
            </div>
          )}
          <div>
            <iframe
              src={URL.createObjectURL(file)}
              width="100%"
              height="500px"
              title="PDF Viewer"
            />
          </div>
        </>
      ) : (
        <>
          {' '}
          <h5 style={classes.RcapitulatifFacturation} className="modal-title">
            {t('Importer votre facture')}
            <br />
          </h5>
          <div {...getRootProps()} style={classes.Dropzone}>
            <input {...getInputProps()} name="file" multiple={false} />
            {isDragActive ? (
              <div>
                <p style={classes.DropzoneText}>
                  {t('Glissez votre fichier ici')}...
                </p>
              </div>
            ) : (
              <div style={classes.Frame8}>
                <img
                  style={classes.CTATelecharger}
                  src="/Images/telecharger.png"
                  alt="icon-edit"
                />
                <p style={classes.DropzoneText}>
                  {t('Glissez votre fichier ou importer')}
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CustomDropzone;
