import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../../config/axios';

import { Modal } from 'reactstrap';
import { EMAIL_REGEX } from '../../../utils/Regex';

import { classes } from './modalClasses';
import { Alert, CircularProgress } from '@mui/material';

const FORGOT_PASSWORD_URL = '/user/otp';

export const ForgotPasswordModal = (props) => {
  const { t } = useTranslation();

  const [mail, setMail] = useState('');
  const [disabled, setDisabledForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');

  useEffect(() => {
    mail.length > 0 && mail.match(EMAIL_REGEX)
      ? setDisabledForm(false)
      : setDisabledForm(true);
  }, [mail]);

  const handleSubmit = () => {
    axios
      .get(FORGOT_PASSWORD_URL, { params: { userMail: mail } })
      .then(() => {
        setLoading(false);
        setError(false);
        setSuccessMsg(
          `${t(
            'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email'
          )} ${mail}. 
          ${t('vérifiez votre email et cliquez sur le lien pour continuer !')}`
        );
        setSuccess(true);
      })
      .catch((e) => {
        setLoading(false);

        e.response && e.response.status === 404
          ? setErrorMsg(t("cet email n'existe pas"))
          : setErrorMsg(t("Oups....! Une erreur s'est produite"));
        setError(true);
      });
  };

  return (
    <Modal style={classes.modalCustom} isOpen={props.open}>
      <div style={classes.modalHeader} className="modal-header">
        <h5 style={classes.title} className="modal-title">
          {t('Réinitialiser votre mot de passe')}
        </h5>
        <button
          type="button"
          onClick={() => {
            setMail('');
            props.handleClose();
          }}
          style={classes.btnClose}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div style={classes.modalPad} className="modal-body">
        <div className="row row-mb-lg">
          <div className="col-12 col-md-12">
            <div className="label-title">{'Email*'}</div>
            <div>
              <input
                type="text"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                className="form-control form-ct"
              />
            </div>
          </div>
        </div>
        {error ? (
          <Alert severity="error">
            <strong>{errorMsg}</strong>
          </Alert>
        ) : null}

        {success ? (
          <Alert severity="success">
            <strong>{successMsg}</strong>
          </Alert>
        ) : null}

        <div>
          {disabled ? (
            <button
              style={{ marginTop: '20px' }}
              className="bt-submit-disabled-y float-end"
            >
              {' '}
              {t('Réinitialiser')}{' '}
            </button>
          ) : (
            <button
              style={{ marginTop: '20px' }}
              className="bt-submit-y float-end"
              onClick={() => {
                handleSubmit();
                setLoading(true);
              }}
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: 'white' }} />
              ) : (
                t('Réinitialiser')
              )}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
