import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ErrorComponent from 'components/shared/ErrorComponent';
import { Paginator } from 'components/shared/Paginator';
import { Loader } from 'components/shared/Loader';
import { ProgressBar } from 'components/shared/ProgressBar';
import { CircularBar } from 'components/shared/CircularBar';
import { CustomDropdownReadOnly } from 'components/shared/DropDown/CustomDropdown';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

import OrderTypeFilter from 'components/shared/Filter/OrderTypeFilter';

import {
  ORDERSTATUS,
  FILTER_ORDER_TYPE_LIST,
  ORDER_TYPE,
} from 'utils/Constants';

import { handleSorting } from 'store/Admin/OrderList';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { formatDate } from 'utils/DateUtils';
import { adminOrderListSelector } from 'store/Admin/OrderList';
import {
  fetchAdminOrderDisplay,
  fetchAdminTextOrderDisplay,
  fetchAdminOrderLineDisplay,
} from 'store/Admin/OrderDisplay/features';

import { setStatus } from 'store/Admin/OrderDisplay';
import { setOrderId, setOrderType } from 'store/Admin/Analyser';
import { filterOrders } from 'helpers/FilterOrders';
import SearchIcon from 'icons/SearchIcon';

import './order.css';
import { Button } from '@mui/material';

const pageNbOptions = [10, 15, 20, 50, 100];

export default function InProgressOrders() {
  let { inProgressOrders, isLoading, isError } = useSelector(
    adminOrderListSelector
  );
  const { t } = useTranslation();

  const columns = [
    { label: t('Titre de la commande'), field: 'title', sortable: true },
    { label: t('Type'), field: 'type', sortable: false },
    { label: t('Deadline'), field: 'deadline', sortable: true, sort: 'asc' },
    { label: t('Rédacteurs'), field: 'writers', sortable: false },
    {
      label: t('Textes livrés'),
      field: 'deliveredTextsAmount',
      sortable: true,
      sort: 'asc',
    },
    {
      label: t('Textes validés'),
      field: 'validatedTextsAmount',
      sortable: true,
      sort: 'asc',
    },
    {
      label: t('Textes en ligne'),
      field: 'deployedTextsAmount',
      sortable: true,
      sort: 'asc',
    },
    {
      label: t('Temps restant'),
      field: 'finishedAt',
      sortable: true,
      sort: 'asc',
    },
  ];

  const fieldWidths = {
    title: '35%',
    deadline: '15%',
    type: '10%',
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedType, setSelectedType] = useState(FILTER_ORDER_TYPE_LIST[0]);

  const [pageNumbers, setPageNumbers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [titleSearch, setTitleSearch] = useHistoryState('titleSearch', '');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  useEffect(() => {
    if (!inProgressOrders) return;

    const filtered = filterOrders(inProgressOrders, selectedType, titleSearch);
    if (titleSearch && titleSearch.length > 0) {
      setCurrentPage(1);
    }
    setFilteredOrders(filtered);
  }, [selectedType, titleSearch, inProgressOrders]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortingChange = (field) => {
    const direction =
      field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
    dispatch(
      handleSorting({
        table: ORDERSTATUS.INPROGRESS,
        sortField: field,
        sortOrder: direction,
      })
    );
    setSortField(field);
    setSortOrder(direction);
  };

  return (
    <div className="content-center">
      <div className="box-info-header">
        <div className="b-child-1">
          <h1>{t('Commandes en cours')}</h1>
        </div>
        <div className="b-child-2">
          <div className="d-flex justify-content-end">
            <div className="box-input-text pe-0">
              <div className="input-group input-cs w-100 pe-0">
                <div className="input-group-text">
                  <SearchIcon />
                </div>
                <input
                  type="text"
                  className="form-control ps-0"
                  value={titleSearch}
                  onChange={(event) => setTitleSearch(event.target.value)}
                  placeholder={t('Rechercher')}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className="me-2">{t('Afficher les résultats')}</span>

              <div className="dropdown" style={{ width: '70px' }}>
                <button
                  className="btn dropdown-toggle dropdown-custom w-100"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {ordersPerPage}
                  <span>
                    <ChevronDownIcon />
                  </span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {pageNbOptions
                    .filter((nb) => nb !== ordersPerPage)
                    .map((item, index) => (
                      <li key={index}>
                        <button
                          className="dropdown-item"
                          onClick={() => setOrdersPerPage(item)}
                        >
                          {item}
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <ErrorComponent />
      ) : (
        <div className="box-wrapper">
          <div className="table-responsive">
            <div>
              <table className="table-lists table-commandes">
                <thead>
                  <tr>
                    {columns.map(({ label, field, sortable }) => (
                      <th
                        style={{
                          cursor: sortable ? 'pointer' : 'default',
                          width: fieldWidths[field] || '',
                        }}
                        onClick={
                          sortable ? () => handleSortingChange(field) : null
                        }
                        key={field}
                      >
                        {label}
                        {!sortable ? null : field === sortField &&
                          sortOrder === 'asc' ? (
                          <span>
                            <ChevronUpIcon style={{ marginLeft: '3px' }} />
                          </span>
                        ) : (
                          <span>
                            <ChevronDownIcon style={{ marginLeft: '3px' }} />
                          </span>
                        )}{' '}
                        {field === 'type' ? (
                          <OrderTypeFilter
                            orderTypeList={FILTER_ORDER_TYPE_LIST}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                            handleClick={handleClick}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                          />
                        ) : null}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order, index) => (
                    <tr key={index}>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          dispatch(setStatus(ORDERSTATUS.INPROGRESS));
                          dispatch(setOrderId(order.id));
                          dispatch(setOrderType(order.type));
                          dispatch(fetchAdminOrderDisplay(order.id));
                          dispatch(fetchAdminTextOrderDisplay(order.id));
                          dispatch(fetchAdminOrderLineDisplay(order.id));
                          history.push('/Admin/DetailsDeLaCommande');
                        }}
                      >
                        <span>{order?.title}</span>
                      </td>
                      <td>
                        {
                          ORDER_TYPE.find((elem) => elem.type === order.type)
                            .name
                        }
                      </td>
                      <td>
                        <span>
                          {order.deadline ? formatDate(order.deadline) : '-'}
                        </span>
                      </td>
                      <td>
                        <div>
                          {order.writers.length === 0 ? (
                            <Button disabled>{t('0 rédacteurs')} </Button>
                          ) : (
                            <>
                              <Button
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                onClick={(e) => handleClick(index, e)}
                              >
                                <img
                                  src="/Images/icon-users-down.png"
                                  alt="icon-users-down"
                                />
                              </Button>
                              <CustomDropdownReadOnly
                                handleClose={handleClose}
                                anchorEl={anchorEl && anchorEl[index]}
                                list={order.writers}
                                getOptionLabel={(option) => {
                                  return option.mail;
                                }}
                              />
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.deliveredTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.validatedTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.deployedTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <CircularBar
                          finishedAt={order.finishedAt}
                          orderId={order.id}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <Paginator
        isLoading={isLoading}
        listLength={filteredOrders.length}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        nbElementsPerPage={ordersPerPage}
        goToPage={(page) => setCurrentPage(page)}
      />
    </div>
  );
}
