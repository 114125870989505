import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosPrivate from 'config/axiosPrivate';

import { useHistory } from 'react-router-dom';
import { ROLES } from 'utils/Constants';
import { WritersList } from 'components/Admin/WritersList';
import { WritersSearch } from 'components/Admin/WritersSearch';
import { WritersSearchResult } from 'components/Admin/WritersSearchResult';

import { lower } from 'utils/StringUtils';

import { CSVLink } from 'react-csv';

const API = {
  getAllWriters: '/user/all',
  getAvailableThematics: '/thematic/all',
  getAvailableLanguages: '/language/all',
};

export default function WritersManagement() {
  const { t } = useTranslation();
  const CSVFileHeaders = [
    { label: t('Nom/prénom'), key: 'name' },
    { label: t('Mail'), key: 'mail' },
    { label: t('Téléphone'), key: 'phoneNumber' },
  ];

  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [writersList, setWritersList] = useState([]);
  const [rating, setRating] = useState(0);

  const [requiredThematics, setRequiredThematics] = useState([]);
  const [mainRequiredLanguages, setMainRequiredLanguages] = useState([]);
  const [minorRequiredLanguages, setMinorRequiredLanguages] = useState([]);
  const [available, setAvailable] = useState(true);

  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [availableLanguagesList, setAvailableLanguagesList] = useState([]);

  const history = useHistory();
  const [searchResult, setSearchResult] = useState(false);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));

    axiosPrivate
      .get(API.getAvailableLanguages)
      .then((res) => setAvailableLanguagesList(res.data));

    axiosPrivate
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));

    axiosPrivate
      .get(API.getAllWriters, { params: { role: ROLES.WRITER } })
      .then((res) => {
        setLoading(false);
        setWritersList(res.data);
      });
  }, []);

  const findLanguageIndex = (language) => {
    return mainRequiredLanguages.findIndex((element) =>
      lower(element.language).includes(lower(language))
    );
  };

  const checkLanguage = (language) => {
    let index = mainRequiredLanguages.findIndex((element) =>
      lower(element.language).includes(lower(language))
    );
    let array = [...mainRequiredLanguages];
    if (index > -1) {
      array.splice(index, 1);
    } else {
      let item = availableLanguagesList.find((element) =>
        lower(element.language).includes(lower(language))
      );
      array.push(item);
    }

    setMainRequiredLanguages(array);
  };

  const search = () => {
    let writersWithRequiredLanguages = writersList.filter((user) => {
      return (
        user.isAvailable === available &&
        requiredThematics.every((inputThematic) => {
          return (
            user.thematics.length > 0 &&
            user.thematics.some(
              (userThematic) => inputThematic.id === userThematic.id
            )
          );
        }) &&
        mainRequiredLanguages
          .concat(minorRequiredLanguages)
          .every((inputLangue) => {
            return (
              user.languages.length > 0 &&
              user.languages.some(
                (userLanguage) => inputLangue.id === userLanguage.id
              )
            );
          }) &&
        user.note >= rating
      );
    });

    setResult(writersWithRequiredLanguages);
    setSearchResult(true);
  };

  const initialize = () => {
    setRequiredThematics([]);
    setMainRequiredLanguages([]);
    setMinorRequiredLanguages([]);
    setAvailable(true);
    setRating(null);
  };

  return (
    <div>
      <div className="content-center">
        <div className="box-ui-top">
          <div className="txt-title-top">
            <span onClick={history.goBack} className="bt-circle me-3" />
            <h1>{t('Rédacteurs')}</h1>
          </div>
          <div>
            {searchResult ? (
              <CSVLink
                filename={
                  t('listeRedacteur-') +
                  new Date().getDate() +
                  '-' +
                  (new Date().getMonth() + 1) +
                  '-' +
                  new Date().getFullYear() +
                  '.csv'
                }
                headers={CSVFileHeaders}
                data={
                  result.length > 0
                    ? result.map((x) => ({
                        name: x.name,
                        mail: x.mail,
                        phoneNumber: x.phoneNumber,
                      }))
                    : []
                }
              >
                <div className="bt-csv">
                  <img src="/Images/icon-csv.png" alt="icon-csv" />{' '}
                  {t('Exporter .csv')}
                </div>
              </CSVLink>
            ) : null}
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4">
            <WritersList
              writersList={writersList}
              loading={loading}
            ></WritersList>
          </div>
          <div className="col-12 col-md-8">
            {searchResult ? (
              <WritersSearchResult
                result={result}
                return={() => setSearchResult(false)}
              />
            ) : (
              <WritersSearch
                loading={loading}
                findLanguageIndex={findLanguageIndex}
                requiredThematics={requiredThematics}
                setRequiredThematics={setRequiredThematics}
                availableThematicsList={availableThematicsList}
                availableLanguagesList={availableLanguagesList}
                mainRequiredLanguages={mainRequiredLanguages}
                setMainRequiredLanguages={setMainRequiredLanguages}
                minorRequiredLanguages={minorRequiredLanguages}
                setMinorRequiredLanguages={setMinorRequiredLanguages}
                available={available}
                setAvailable={setAvailable}
                rating={rating}
                setRating={setRating}
                checkLanguage={checkLanguage}
                searchWriters={search}
                initialize={initialize}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
