import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'reactstrap';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import axiosPrivate from 'config/axiosPrivate';

import { classes } from './modalClasses';
import { InputField } from 'components/shared/InputField';

import { UrlSchema } from 'utils/schemas';
import { updateDeployedTexts } from 'store/Client/OrderDisplay';

export const URLInputModal = ({
  currentTexts,
  setCurrentTexts,
  sortedText,
  setOrdredText,
  open,
  handleClose,
  initialDeploymentQueue,
  updateDeploymentQueue,
}) => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [deploymentQueue, setDeploymentQueue] = useState([
    ...initialDeploymentQueue,
  ]);

  const modelMessageForOneText =
    deploymentQueue.length === 1 && deploymentQueue[0].url
      ? t('Voulez-vous confirmer l’URL actuelle ou la modifier ?')
      : t(
          'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:'
        );

  useEffect(() => {
    setDeploymentQueue([...initialDeploymentQueue]);
  }, [initialDeploymentQueue]);

  const handleUrlChange = (id, newUrl) => {
    setDeploymentQueue((prevQueue) =>
      prevQueue.map((text) =>
        text.id === id ? { ...text, url: newUrl } : text
      )
    );
  };

  const handleRemoveText = (id) => {
    formRef.current.reset();
    setDeploymentQueue((prevQueue) =>
      prevQueue.filter((text) => text.id !== id)
    );
  };

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});
      await UrlSchema.validate(data, {
        abortEarly: false,
      });
      const deployData = deploymentQueue.map((text) => ({
        textId: text.id,
        url: text.url,
        deployed: true,
      }));

      axiosPrivate
        .post('texts/deploy', deployData)
        .then((res) => {
          dispatch(updateDeployedTexts(res.data));

          updateDeploymentQueue([]);
          handleClose();
        })
        .catch((err) => {
          console.log('err', err);
        });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error, index) => {
          validationErrors[error.path] = t(error.message);
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Modal Modal style={classes.modalCustom} isOpen={open}>
      <div style={classes.modalHeader} className="modal-header">
        <h6 style={classes.title} className="modal-title">
          {t('Information')}:
        </h6>

        <button
          type="button"
          onClick={() => {
            handleClose();
            updateDeploymentQueue([]);
          }}
          style={classes.btnClose}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div style={classes.modalPad} className="modal-body">
        <div className="row row-mb-lg">
          {deploymentQueue.length > 1 ? (
            <h6>
              {t(
                `Merci de bien vouloir indiquer ci-dessous l'url de la page où a été intégré le contenu:`
              )}
            </h6>
          ) : (
            <h6>{modelMessageForOneText}</h6>
          )}
        </div>
        <Form ref={formRef} onSubmit={handleSubmit}>
          {deploymentQueue.length >= 1 && (
            <div className="row row-mb-lg">
              <div className="row row-mb-lg">
                <div className="col-12 col-md-6">
                  <div className="label-title">{'Nom du Texte'}</div>
                </div>{' '}
                <div className="col-12 col-md-6">
                  <div className="label-title">{'URL'}</div>
                </div>
              </div>
              {deploymentQueue.map((text, index) => (
                <div className="row row-mb-lg" key={text.id}>
                  <div className="col-12 col-md-6 d-flex  align-items-center">
                    {deploymentQueue.length > 1 && (
                      <span
                        className="bt-minus-circle me-3 "
                        style={{ width: '25px', height: '25px' }}
                        onClick={() => handleRemoveText(text.id)}
                      />
                    )}

                    <div style={{ width: '80%' }}>{text.title}</div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div>
                      <InputField
                        name={`url-${index}`}
                        type="text"
                        className="form-control form-ct"
                        placeholder="https://www."
                        value={text.url || ''}
                        onChange={(e) =>
                          handleUrlChange(text.id, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div>
                <button
                  style={{ marginTop: '20px' }}
                  className="bt-submit-y float-end"
                >
                  {t('Enregistrer')}
                </button>
              </div>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
};
