import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'reactstrap';

import { classes } from './modalClasses';

export const KeywordStuffingModal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      style={classes.modalCustom}
      className="modal-stuffing-wdith"
      isOpen={props.open}
    >
      <div style={classes.modalHeader} className="modal-header">
        <h5 style={classes.title} className="modal-title">
          {t(props.title)}
        </h5>
        <button
          type="button"
          onClick={props.handleClose}
          style={classes.btnClose}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div style={classes.modalPad} className="modal-body modal-inner-margin">
        <div className="row">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={classes.imgConfirm}
              src={'/Images/modal/img-confirm.png'}
              alt="confirm"
            />
          </div>
          <div>
            <div style={classes.textConfirmStuffing}>
              <p>
                {props.stuffedKeywords.map((item) => {
                  const tfPercentage = item['tfPercentage'] * 100;
                  let colorClass;

                  if (tfPercentage > 2) {
                    colorClass = 'keywordStuffedRed';
                  }

                  return (
                    <li key={item['word']} className={colorClass}>
                      <span> {item['word']} </span> (
                      <span>{tfPercentage.toFixed(2)}% </span>)
                    </li>
                  );
                })}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <div
              style={classes.textConfirm}
              dangerouslySetInnerHTML={{
                __html: props.message.replace(/\n/g, '<br>'),
              }}
            ></div>
          </div>
        </div>
        <div style={classes.boxBtConfirm}>
          {props.showButtonClose && (
            <button
              style={{ backgroundColor: '#ffe426', color: 'black' }}
              onClick={props.handleClose}
              className="confirm-keyword-stuffing me-2"
            >
              {t('Modifier le texte')}
            </button>
          )}
          <button
            style={{ backgroundColor: '#ffe426', color: 'black' }}
            onClick={props.executeAction}
            className="confirm-keyword-stuffing"
          >
            {t('Demande examen')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default KeywordStuffingModal;
