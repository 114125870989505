import { Loader } from 'components/shared/Loader';
import axiosFacturation from 'config/axiosFacturation';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import ErrorSuccesModal from '../ErrorSuccesModal';
import { classes } from '../modalClasses';
import GenerateBillFormModal from './GenerateBillFormModal';

export const GenerateBillModal = ({
  isOpen,
  handleClose,
  commandID,
  billStatus,
  billRef,
  file,
  message,
  visualize,
}) => {
  const [status, setStatus] = useState('idle');
  const [bill, setBill] = useState(null);
  const [filePdf, setFilePdf] = useState(null);
  // const [error, setError] = useState(null);
  const [allPrice, setAllPrice] = useState(null);

  const { t } = useTranslation();

  const generateBill = useCallback(async () => {
    setStatus('loading');
    try {
      const response = await axiosFacturation.get(
        `/writer/GenerateBill?command%20id=${commandID}`
      );
      // byteArrayToPDFFile();
      setStatus('succeeded');
      setBill(response.data);
      setAllPrice(response.data.totalPrice);
    } catch (error) {
      setStatus('failed');
      // setError(error.response.data);
    }
  }, [commandID]);
  const byteArrayToPDFFile = useCallback(async () => {
    const fileBytes = file.content;
    const fileName = file.name;
    const base64String = window.atob(fileBytes);
    const decodedBytes = new Uint8Array(base64String.length);
    for (let i = 0; i < base64String.length; i++) {
      decodedBytes[i] = base64String.charCodeAt(i);
    }
    const pdfBlob = new Blob([new Uint8Array(decodedBytes)], {
      type: 'application/pdf',
    });
    const pdfFile = new File([pdfBlob], fileName, { type: 'application/pdf' });
    setFilePdf(pdfFile);
  }, [file]);

  useEffect(() => {
    generateBill();
    if (file != null) {
      byteArrayToPDFFile();
    }
  }, [byteArrayToPDFFile, file, generateBill]);

  const [errorSuccessMsg] = useState(
    t('Impossible de générer la facture ! Veuillez réessayer')
  );

  return (
    <div>
      <Modal
        style={classes.largeModalCustomForBill}
        isOpen={isOpen && status !== 'failed'}
        toggle={handleClose}
        backdrop={true} // Enable clicking outside to close the modal
        keyboard={false}
      >
        {status === 'loading' ? (
          <Loader />
        ) : status === 'succeeded' && bill != null ? (
          <GenerateBillFormModal
            bill={bill}
            commandID={commandID}
            priceWord={bill.price}
            allPrice={allPrice}
            setAllPrice={setAllPrice}
            message={message}
            handleClose={handleClose}
            billStatus={billStatus}
            billRef={billRef}
            file={filePdf}
            visualize={visualize}
          />
        ) : (
          ''
        )}
      </Modal>
      {status === 'failed' ? (
        <ErrorSuccesModal
          open={isOpen}
          message={errorSuccessMsg}
          handleClose={handleClose}
          success={false}
        ></ErrorSuccesModal>
      ) : (
        ''
      )}
    </div>
  );
};
