export function filterOrders(orders, selectedType, titleSearch) {
  let arr = [...orders];

  if (selectedType && selectedType.type.length > 0) {
    arr = arr.filter((x) => {
      return x.type === selectedType.type;
    });
  }

  if (titleSearch && titleSearch.length > 0) {
    arr = arr.filter((x) => {
      return (
        x.title.toLowerCase().includes(titleSearch.toLowerCase()) ||
        (x.writers &&
          x.writers.some(
            (w) =>
              w.name.toLowerCase().includes(titleSearch.toLowerCase()) ||
              w.mail.toLowerCase().includes(titleSearch.toLowerCase())
          ))
      );
    });
  }

  return arr;
}

export function filterBills(orders, titleSearch, writerAndPriceSearch) {
  let arr = [...orders];
  if (titleSearch && titleSearch.length > 0) {
    arr = arr.filter((x) => {
      return (
        x.commandTitle.toLowerCase().includes(titleSearch.toLowerCase()) ||
        (writerAndPriceSearch &&
          (x.writerName.toLowerCase().includes(titleSearch.toLowerCase()) ||
            x.totalPrice <= parseFloat(titleSearch)))
      );
    });
  }
  if (
    titleSearch &&
    titleSearch.length > 0 &&
    !isNaN(parseFloat(titleSearch))
  ) {
    arr.sort((a, b) => b.totalPrice - a.totalPrice);
  }
  return arr;
}

export function textFilterByStatus(textList, selectedStatus) {
  if (selectedStatus.field.length > 0) {
    return textList.filter((x) => {
      switch (selectedStatus.field) {
        case 'revisionSent':
          return (
            (x['globalRevisionSent'] === true || x['revisionSent'] === true) &&
            x['revisionDone'] === false &&
            x['validated'] === false
          );
        case 'revisionDone':
          return (
            (x['globalRevisionSent'] === true || x['revisionSent'] === true) &&
            x['revisionDone'] === true &&
            x['validated'] === false
          );
        case 'delivered':
          return (
            x['globalRevisionSent'] === false &&
            x['revisionSent'] === false &&
            x['revisionDone'] === false &&
            x['validated'] === false &&
            x['delivered'] === true
          );
        case 'brouillon':
          return (
            x['globalRevisionSent'] === false &&
            x['revisionSent'] === false &&
            x['revisionDone'] === false &&
            x['validated'] === false &&
            x['delivered'] === false
          );
        default:
          return x[selectedStatus.field] === selectedStatus.value;
      }
    });
  } else {
    return textList;
  }
}

export function textFilterByType(
  texts,
  selectedType,
  isCombo,
  isTranslate,
  isText
) {
  return texts.filter((text) => {
    if (selectedType.field.length === 0) {
      return true; // No filtering needed
    }

    if (isCombo && text.type === 'COMBINED') {
      return true;
    }

    if (isTranslate && text.type === 'TRANSLATED') {
      return true;
    }

    if (isText && text.type === 'NON_TRANSLATED') {
      return true;
    }

    return !isCombo && !isTranslate && !isText;
  });
}
