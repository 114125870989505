import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';

import axiosEvaluation from 'config/axiosEvaluation';
import { TextField } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { classes } from 'components/shared/modal/modalClasses';
import { Loader } from 'components/shared/Loader';

function CommentEvaluationModal({
  orderId,
  writerCommandId,
  isOpen,
  setEvaluationModalToShow,
  t,
  toggle,
  handleClose,
  setEvaluationStatus,
  setErrorSuccessMsg,
}) {
  const [status, setStatus] = useState('idle');
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    setStatus('loading');

    try {
      const response = await axiosEvaluation.post(
        `/client/evaluations/comment?comment=${message}&writerCommand=${writerCommandId}`
      );

      setEvaluationModalToShow('ERRORSUCCESS');
      setEvaluationStatus('succeeded');
      setErrorSuccessMsg(
        t('Votre évaluation est précieuse pour nous ! Merci !')
      );
      setStatus('succeded');
    } catch (error) {
      setErrorSuccessMsg(
        t(
          "Une erreur s'est produite lors de la soumission de votre évaluation."
        )
      );

      setEvaluationStatus('error');
      setEvaluationModalToShow('ERRORSUCCESS');
    }
  };

  return (
    <div>
      <Modal
        style={classes.modalCustom}
        toggle={toggle}
        isOpen={isOpen && status !== 'failed'}
      >
        {status !== 'loading' && (
          <>
            <div style={classes.modalHeader} className="modal-header">
              <h5 style={classes.title} className="modal-title">
                {t('Rédiger un commentaire')}
              </h5>
              <button
                type="button"
                onClick={handleClose}
                style={classes.btnClose}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div style={classes.modalPad} className="modal-body">
              <TextField
                id="outlined-multiline-flexible"
                placeholder={
                  t('Votre commentaire...') +
                  '\n \n' +
                  t('Ex : "Contenus de très bonne qualité') +
                  ' : ' +
                  t(
                    "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?"
                  ) +
                  '"'
                }
                multiline
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message}
                maxRows={6}
                minRows={4}
                // onChange={(e) => setMessage(e.target.value)}
                style={{ width: '100%', marginBottom: '20px' }}
              />{' '}
              <div style={classes.boxBtConfirm}>
                <button className="bt-submit-y" onClick={handleSubmit}>
                  {t('Valider')}
                </button>
              </div>
            </div>
          </>
        )}
        {status === 'loading' && <Loader />}
      </Modal>
    </div>
  );
}

export default CommentEvaluationModal;
