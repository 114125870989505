import { InputField } from 'components/shared/InputField';
import InputFieldWithArrow from 'components/shared/InputField/InputFieldWithArrow';
import { useTranslation } from 'react-i18next';
import { BILL_TRANSFERRED } from 'utils/Constants';
import { classes } from '../../modalClasses';

const FormFields = ({
  bill,
  fieldValues,
  validationErrors,
  handleFieldChange,
  handleFieldClick,
  visualize,
}) => {
  const { t } = useTranslation();
  const [number, unit] = bill.deadline.split(' ');

  return (
    <div className="row row-mb-lg">
      <div style={classes.facturationTitle}>
        <span style={classes.facturationTitleSpan}>
          {t('Titre de la commande')} :{' '}
        </span>{' '}
        {bill.commandTitle}
      </div>
      <div style={classes.line1}></div>
      <div className="col-12 col-md-6">
        <p style={classes.formLabels}>
          <b>{t('Langue')}:</b> {bill.langue}
        </p>
        <p style={classes.formLabels}>
          <b>{t('Nombre de texte')} :</b> {bill.nb_text}
        </p>
        <p style={classes.formLabels}>
          <b>{t('Nombre de mot par texte :')} </b>{' '}
          {bill.actualBilling === true ? t('Au réel') : bill.nb_mots}
        </p>
        <p style={classes.formLabels}>
          <b>{t('Paiement')} : </b>{' '}
          {bill.payment === BILL_TRANSFERRED ? t('Virement') : t('Virement')}
        </p>
        <p style={classes.formLabels}>
          <b>{t('Délai')} :</b> {number + ' ' + t(unit)}
        </p>
      </div>
      <div className="col-12 col-md-6">
        <div style={classes.formLabelsRight}>
          <b>{t('Référence de votre facture')} : </b>{' '}
          <InputField
            style={{ width: '340px', marginLeft: '5px' }}
            type="text"
            name="bill reference"
            error={validationErrors['billRef']}
            value={fieldValues.billRef}
            onChange={(e) => {
              handleFieldChange('billRef', e.target.value);
              handleFieldClick('billRef');
            }}
            onClick={() => {
              handleFieldClick('billRef');
            }}
            disabled={visualize === true}
          />
        </div>
        <div style={classes.formLabelsRight}>
          <b>{t('Coût au mot')} : </b>{' '}
          <InputFieldWithArrow
            style={{ width: '164px', marginLeft: '5px' }}
            name="price"
            type="number"
            className="form-control form-ct"
            error={validationErrors['price']}
            value={fieldValues.price}
            onChange={(e) => {
              handleFieldChange('price', e.target.value);
              handleFieldClick('price');
            }}
            onClick={() => handleFieldClick('price')}
            greyButton={visualize}
            readOnly={visualize}
          />
        </div>
        <div style={classes.formLabelsRight}>
          <b>{t('Montant global de la commande')} : </b>{' '}
          <InputFieldWithArrow
            style={{ width: '164px', marginLeft: '5px' }}
            name="price"
            type="number"
            error={validationErrors['totalPrice']}
            className="form-control form-ct"
            value={Math.round(fieldValues.totalPrice * 100) / 100}
            onChange={(e) => {
              handleFieldChange('totalPrice', e.target.value);
              handleFieldClick('totalPrice');
            }}
            onClick={() => handleFieldClick('totalPrice')}
            greyButton={visualize}
            readOnly={visualize}
          />
        </div>
      </div>
    </div>
  );
};
export default FormFields;
