import React from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

const styles = {
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    textAlign: 'center',
  },
  underlined: {
    fontSize: 14.1,
    fontWeight: 'bold',
    fontStyle: 'normal',
    textDecoration: 'underline',
  },
  boldText: {
    fontSize: 14.1,
    fontWeight: 'bold',
    fontStyle: 'normal',
    marginTop: 20,
    marginBottom: 20,
  },
  normalText: {
    fontSize: 14.1,
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 15,
    marginTop: 15,
  },
};

export const WordFileStructure = (props) => {
  const { t } = useTranslation();
  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="theme-color" content="#000000" />
      </head>
      <body>
        <div style={styles.row}>
          <span style={styles.title}>{t('Comparaison hors ligne')}</span>
        </div>

        <div>
          <span style={styles.underlined}>{t('Mail')} : </span>
          <span style={styles.normalText}>{props.user.mail}</span>
        </div>
        <div>
          <span style={styles.underlined}>{t('Date')} : </span>
          <span style={styles.normalText}>
            {new Date().getDate()}/{new Date().getMonth() + 1}/
            {new Date().getFullYear()}
          </span>
          <div style={{ marginLeft: 25 }} />
          <span style={styles.underlined}>{t('Heures')} &nbsp; : </span>
          <span style={styles.normalText}>
            {new Date().getHours()}:{new Date().getMinutes()}
          </span>
        </div>
        <div>
          <span style={styles.underlined}>{t('Titre')} : </span>
          <span style={styles.normalText}>{props.title}</span>
        </div>
        <div>
          <span style={styles.underlined}>{t('Balise titre')} : </span>
          <span style={styles.normalText}>{props.titleTag}</span>
        </div>
        <div>
          <span style={styles.underlined}>{t('Meta-description')} : </span>
          <span style={styles.normalText}>{props.metaDescriptionTag}</span>
        </div>
        {props.url && props.url.length > 0 && (
          <div>
            <span style={styles.underlined}>{t('Url')} : </span>
            <span style={styles.normalText}>{props.url}</span>
          </div>
        )}
        <div>
          <span style={styles.boldText}>{t('mot-clé (fréquence)')}:</span>
        </div>
        <div>
          <span style={styles.normalText}>{props.keywords}</span>
        </div>
        <div>
          <span style={styles.underlined}>{t('Nombre de mots')} : </span>
          <span style={styles.normalText}>{props.numberOfWords}</span>
        </div>

        <div>
          <span style={styles.boldText}>{t('Texte')} : &nbsp;</span>
        </div>
        {parse(props.content)}

        <div
          style={{
            marginTop: 25,
            marginBottom: 25,
          }}
        >
          <span>
            --------------------------------------------------------------------------
          </span>
        </div>
        {props.result && (
          <div>
            <span style={styles.boldText}>{t('Résultats')}</span>
            {props.result.semanticEnrichment !== null && (
              <div style={styles.row}>
                <span style={styles.normalText}>
                  {t('Enrichissement sémantique')} :{' '}
                </span>
                <span style={{ color: props.color }}>
                  {parseInt(props.result.semanticEnrichment)}%
                </span>
              </div>
            )}
          </div>
        )}
        {props.result &&
          props.result.greenWordsByFreq.greenWordsWithRemainingFreq &&
          Object.keys(props.result.greenWordsByFreq.greenWordsWithRemainingFreq)
            .length > 0 && (
            <div>
              <div>
                <span
                  style={
                    styles.boldText && {
                      marginBottom: 20,
                      marginTop: 20,
                    }
                  }
                >
                  {t('Ajout de mots-clés avec la fréquence restante')}:
                </span>
              </div>
              <div>
                <span>
                  {Object.keys(
                    props.result.greenWordsByFreq.greenWordsWithRemainingFreq
                  ).map((item, key) => {
                    return (
                      <span style={styles.normalText} key={key}>
                        {item}(
                        <span style={{ textDecoration: 'line-through' }}>
                          {props.convertedText[item]},
                        </span>
                        <span>
                          {
                            props.result.greenWordsByFreq
                              .greenWordsWithRemainingFreq[item]
                          }
                          ){' '}
                        </span>
                      </span>
                    );
                  })}
                </span>
              </div>
            </div>
          )}
        {props.result &&
          props.result.remainingKeywords &&
          Object.keys(props.result.remainingKeywords).length > 0 && (
            <div>
              <div>
                <span
                  style={
                    styles.boldText && {
                      marginBottom: 20,
                      marginTop: 20,
                    }
                  }
                >
                  {t('Mots clés restants')}:
                </span>
              </div>
              <div>
                <span style={styles.normalText}>
                  {Object.keys(props.result.remainingKeywords).map(
                    (item, key) => {
                      let word =
                        item +
                        '(' +
                        props.result.remainingKeywords[item] +
                        ') ';
                      return word;
                    }
                  )}
                </span>
              </div>
            </div>
          )}
      </body>
    </html>
  );
};

export default WordFileStructure;
