import React from 'react';

import { Loader } from 'components/shared/Loader';
import StarIcon from '@mui/icons-material/Star';

import { CustomSWitch } from 'components/shared/CustomSwitch';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';
import { CustomAutocompleteMultipleChoice } from 'components/shared/DropDown/CustomAutocompleteMultipleChoice';

import './writers.css';

import { lower } from 'utils/StringUtils';
import { MAIN_LANGUAGES } from 'utils/Constants';
import { useTranslation } from 'react-i18next';
import { Box, Rating } from '@mui/material';

export const WritersSearch = (props) => {
  const { t } = useTranslation();

  const callbackFromThematicMultiSelect = (newList) => {
    props.setRequiredThematics(newList);
  };

  const callbackFromLanguagesMultiSelect = (newList) => {
    props.setMinorRequiredLanguages(newList);
  };

  if (props.loading) return <Loader />;

  return (
    <div style={{ position: 'relative' }}>
      <div className="box-wrapper" style={{ height: '535px' }}>
        <div className="txt-title">{t('Chercher un rédacteur')}</div>
        <div className="row row-mb" style={{ marginTop: '35px' }}>
          <div className="col-12 col-md-12">
            <div className="label-title">{t('Thématiques d’expertise')}</div>
            <CustomChipsAutocomplete
              callbackFromMultiSelect={callbackFromThematicMultiSelect}
              value={props.requiredThematics}
              disabled={!props.availableThematicsList}
              list={props.availableThematicsList}
              setValue={(option) => props.setRequiredThematics(option)}
              getOptionLabel={(option) => {
                return t(option.thematic);
              }}
            />
          </div>
        </div>
        <div className="row row-mb mt-4">
          <div className="col-12 col-md-12">
            <div className="label-title mb-2">{t('Langues')} :</div>
            <div className="box-wrapper-cols">
              {MAIN_LANGUAGES.slice(0, 3).map((language, key) => (
                <div key={key} className="cols">
                  <div className="box-checkbox box-list-check">
                    <input
                      checked={props.findLanguageIndex(language) > -1}
                      onChange={() => props.checkLanguage(language)}
                      className="form-check-input"
                      type="checkbox"
                    />

                    <div> {t(language)}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="box-wrapper-cols mt-4">
              {MAIN_LANGUAGES.slice(3).map((language, key) => (
                <div key={key} className="cols">
                  <div className="box-checkbox box-list-check">
                    <input
                      checked={props.findLanguageIndex(language) > -1}
                      onChange={() => props.checkLanguage(language)}
                      className="form-check-input"
                      type="checkbox"
                    />
                    <div> {t(language)}</div>
                  </div>
                </div>
              ))}
              <div className="cols">
                <CustomAutocompleteMultipleChoice
                  callbackFromMultiSelect={callbackFromLanguagesMultiSelect}
                  value={props.minorRequiredLanguages}
                  disabled={!props.availableLanguagesList}
                  list={props.availableLanguagesList.filter(
                    (x) =>
                      !MAIN_LANGUAGES.map((lan) => lower(lan)).includes(
                        lower(x.language)
                      )
                  )}
                  setValue={(option) => props.setMinorRequiredLanguages(option)}
                  getOptionLabel={(option) => {
                    return t(option.language);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row row-mb">
          <div className="col-12 col-md-12">
            <div className="label-title mb-2">{t('Disponibilité')} : </div>

            <div className="box-list-check">
              <CustomSWitch
                value={props.available}
                toggleValue={() => props.setAvailable(!props.available)}
              />
              <div>
                {props.available
                  ? `${t('disponible')}`
                  : `${t('indisponible')}`}
              </div>
            </div>
          </div>
        </div>
        <div className="row row-mb">
          <div className="col-12 col-md-12">
            <div className="label-title mb-2">{t('Note rédacteur')} : </div>
            <div className="box-line-star">
              <span className="txt-dark">Global :</span>
            </div>
            <Box sx={{ marginLeft: 1, marginRight: 1, fontSize: 16 }}>
              <Rating
                name={`overall rating`}
                value={props.rating}
                onChange={(event, value) => props.setRating(value)}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.3 }} fontSize="inherit" />
                }
                icon={<StarIcon style={{ fontSize: 'inherit' }} />}
              />
            </Box>{' '}
          </div>
        </div>
        <div className="row row-mb" style={{ position: 'absolute', bottom: 0 }}>
          <div className="col-12 col-md-12">
            <span
              style={{
                display: 'inline-block',
                padding: '2px 10px',
                borderRadius: '6px',
                cursor: 'pointer',
                backgroundColor: '#FFE426',
              }}
              onClick={props.initialize}
            >
              {t('Réinitialiser')}
            </span>
          </div>
        </div>
      </div>

      <div className="fa-pull-right">
        <button className="bt-submit-y" onClick={() => props.searchWriters()}>
          {t('Rechercher')}
        </button>
      </div>
    </div>
  );
};
