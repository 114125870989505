import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { classes } from 'components/shared/modal/modalClasses';
import { Modal } from 'reactstrap';
import { DownloadRevisionDocument } from 'components/shared/DownloadFile/DownloadRevisionFile';

function RevisionDocumentModal({
  open,
  handleClose,
  fileName,
  textId,
  textTitle,
  revisionText,
  date,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      style={classes.modalCustom}
      isOpen={open}
      toggle={handleClose} // Close the modal when toggle is called
      backdrop={true} // Enable clicking outside to close the modal
      keyboard={false} // Disable closing the modal when the Esc key is pressed
    >
      <div style={classes.modalHeader} className="modal-header">
        <h2>{t('Révision demandée')}</h2>
        <button
          type="button"
          style={classes.btnClose}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        />
      </div>

      <div style={classes.modalPad} className="modal-body">
        <>
          <>
            <div class="review">
              <label for="review-title">{t('Titre du texte')}</label>
              <input
                value={textTitle}
                type="text"
                id="review-title"
                readOnly={true}
              />
              <label for="review-date">{t('Date d’envoi')}</label>
              <input
                value={date}
                type="text"
                id="review-date"
                readOnly={true}
              />
              <label for="review-body">{t('Mail envoyé')}</label>
              <textarea
                rows="10"
                value={revisionText}
                id="review-body"
                readOnly={true}
              ></textarea>
            </div>

            {fileName && (
              <DownloadRevisionDocument fileName={fileName} textId={textId} />
            )}
          </>
        </>
      </div>
    </Modal>
  );
}

export default RevisionDocumentModal;
