import axiosPrivate from 'config/axiosPrivate';
import { utils as xlsxUtils, writeFile as xlsxWriteFile } from 'xlsx';

export const exportRoadMap = (selectedOrders, orderId, account, t) => {
  let commandsId;
  if (orderId) {
    commandsId = orderId;
  } else {
    commandsId = [...selectedOrders].join(',');
  }

  axiosPrivate
    .get('/commands/' + commandsId + '/getStatsByCommands')
    .then((res) => {
      const workBook = xlsxUtils.book_new();
      const formattedData = res.data.map((order) => {
        return {
          [t('Mot clé')]: order.keyword,
          [t('Intitulé commande')]: order.titled,
          [t('Titre')]: order.titleTag,
          [t('Meta-description')]: order.metaDescriptionTag,
          [t('URL')]: order.url,
          [t('Nombre de mots gérés')]: order.wordsManagedNumber,
          [t('Min/Max')]: `${order.minWords}/${order.maxWords}`,
          [t('Nombre de mots rédigés')]: order.totalNumberOfTextAdded,
          [t('Status texte')]: order.status,
          [t('Date de livraison')]: order.deliveredAt,
          [t('Score')]: order.analyseResult,
          [t('Date deadline validation client')]: order.deadline,
          [t('Date de validation')]: order.validateAt,
          [t('ONLINE')]: order.deployed === true ? t('OUI') : t('NON'),
          [t('Texte')]: order.text,
        };
      });
      const xlsx = xlsxUtils.json_to_sheet(formattedData);
      xlsxUtils.book_append_sheet(workBook, xlsx, 'Orders');
      xlsxWriteFile(workBook, `Export roadmap - ${account}.xlsx`);
    });
};
