import axios from 'axios';
import { axiosOfflineComparisonAPI } from './endpoints';

const BASE_URL = axiosOfflineComparisonAPI;

const axiosOfflineComparison = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: 'Bearer c19b42aa-8a99-300d-8fa6-1faeaa3be506',
    'Content-Type': 'application/json',
    accept: 'application/json;charset=UTF-8',
  },
});

export default axiosOfflineComparison;
