import { createSlice } from '@reduxjs/toolkit';
import { fetchAllOrders } from './features';

import { ORDERSTATUS } from 'utils/Constants';

export const adminOrderList = createSlice({
  name: 'adminOrderList',
  initialState: {
    title: null,
    url: null,
    delayedOrders: [],
    inProgressOrders: [],
    nonAttributedOrders: [],
    smartOrders: [],
    smartWithReviewOrders: [],
    writersOffers: [],
    validatedOrders: [],

    isLoading: true,
    isError: true,
  },
  reducers: {
    addOrder: (state, action) => {
      if (action.payload.status === ORDERSTATUS.INPROGRESS)
        state.inProgressOrders.push(action.payload.order);

      if (action.payload.status === ORDERSTATUS.NONATTRIBUTED)
        state.nonAttributedOrders.push(action.payload.order);
    },
    updateOrder: (state, action) => {
      let array = [];
      switch (action.payload.status) {
        case ORDERSTATUS.VALID:
          array = [...state.validatedOrders];
          break;
        case ORDERSTATUS.DELAYED:
          array = [...state.delayedOrders];
          break;
        case ORDERSTATUS.INPROGRESS:
          array = [...state.inProgressOrders];
          break;
        default:
          array = [...state.nonAttributedOrders];
          break;
      }
      let index = array.findIndex(
        (order) => order.id === action.payload.orderId
      );
      array[index].textAmount = action.payload.textAmount;
      array[index].title = action.payload.title;
      switch (action.payload.status) {
        case ORDERSTATUS.VALID:
          state.validatedOrders = [...array];
          break;
        case ORDERSTATUS.DELAYED:
          state.delayedOrders = [...array];
          break;
        case ORDERSTATUS.INPROGRESS:
          state.inProgressOrders = [...array];
          break;
        default:
          state.nonAttributedOrders = [...array];
          break;
      }
    },
    removeOrder: (state, action) => {
      let array = [];
      switch (action.payload.status) {
        case ORDERSTATUS.INPROGRESS:
          array = [...state.inProgressOrders];
          break;
        case ORDERSTATUS.DELAYED:
          array = [...state.delayedOrders];
          break;
        default:
          array = [...state.nonAttributedOrders];
          break;
      }
      array = array.filter((item) => item.id !== action.payload.id);

      switch (action.payload.status) {
        case ORDERSTATUS.INPROGRESS:
          state.inProgressOrders = [...array];
          break;
        case ORDERSTATUS.DELAYED:
          state.delayedOrders = [...array];
          break;
        default:
          state.nonAttributedOrders = [...array];
          break;
      }
    },
    updateOrderDeliveredTextAmount: (state, { payload }) => {
      let index = state.validatedOrders.findIndex(
        (order) => order.id === payload.orderId
      );
      if (index > -1) {
        state.validatedOrders[index].deliveredTextsAmount =
          state.validatedOrders[index].deliveredTextsAmount + payload.amount;
        return;
      }

      index = state.delayedOrders.findIndex(
        (order) => order.id === payload.orderId
      );
      if (index > -1) {
        state.delayedOrders[index].deliveredTextsAmount =
          state.delayedOrders[index].deliveredTextsAmount + payload.amount;
        return;
      }

      index = state.inProgressOrders.findIndex(
        (order) => order.id === payload.orderId
      );
      if (index > -1)
        state.inProgressOrders[index].deliveredTextsAmount =
          state.inProgressOrders[index].deliveredTextsAmount + payload.amount;
    },
    removeNonAttributedOrder: (state, action) => {
      let array = [...state.nonAttributedOrders];
      let index = array.findIndex(
        (order) => order.id === action.payload.orderId
      );
      let updatedOrder = { ...array[index] };
      updatedOrder.writerPropositions = updatedOrder.writerPropositions.filter(
        (writer) => writer.id !== action.payload.writerId
      );
      array[index] = updatedOrder;
      state.nonAttributedOrders = [...array];
    },
    updateWriterOffer: (state, { payload }) => {
      let array = [...state.writersOffers];

      let index = array.findIndex(
        (writerOffer) =>
          writerOffer.orderId === payload.orderId &&
          writerOffer.id === payload.writerId
      );
      if (index > -1) {
        array[index][payload.option] = payload.value;
        state.writersOffers = array;
      }
    },
    handleSorting: (state, { payload }) => {
      let array = [];
      switch (payload.table) {
        case ORDERSTATUS.VALID:
          array = [...state.validatedOrders];
          break;
        case ORDERSTATUS.DELAYED:
          array = [...state.delayedOrders];
          break;
        case ORDERSTATUS.INPROGRESS:
          array = [...state.inProgressOrders];
          break;

        case ORDERSTATUS.SMART_WITH_REVIEW:
          array = [...state.smartWithReviewOrders];
          break;

        case ORDERSTATUS.SMART:
          array = [...state.smartWithReviewOrders];
          break;
        default:
          array = [...state.nonAttributedOrders];
          break;
      }

      if (payload.sortField) {
        const sorted = [...array].sort((a, b) => {
          if (a[payload.sortField] === null) return 1;
          if (b[payload.sortField] === null) return -1;
          if (
            payload.sortField === 'deliveredTextsAmount' ||
            payload.sortField === 'validatedTextsAmount'
          ) {
            const pourcentageA = (a[payload.sortField] * 100) / a['textAmount'];
            const pourcentageB = (b[payload.sortField] * 100) / b['textAmount'];

            return payload.sortOrder === 'asc'
              ? pourcentageA - pourcentageB
              : pourcentageB - pourcentageA;
          }

          return (
            a[payload.sortField]
              .toString()
              .localeCompare(b[payload.sortField].toString(), 'en', {
                numeric: true,
              }) * (payload.sortOrder === 'asc' ? 1 : -1)
          );
        });
        switch (payload.table) {
          case ORDERSTATUS.VALID:
            state.validatedOrders = [...sorted];
            break;
          case ORDERSTATUS.DELAYED:
            state.delayedOrders = [...sorted];
            break;
          case ORDERSTATUS.INPROGRESS:
            state.inProgressOrders = [...sorted];
            break;
          case ORDERSTATUS.SMART_WITH_REVIEW:
            state.smartWithReviewOrders = [...sorted];
            break;

          case ORDERSTATUS.SMART:
            state.smartOrders = [...sorted];
            break;
          default:
            state.nonAttributedOrders = [...sorted];
            break;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrders.fulfilled, (state, { payload }) => {
        state.delayedOrders = payload.delayedCommands;
        state.inProgressOrders = payload.inProgressCommands;
        state.validatedOrders = payload.validatedCommands;
        state.nonAttributedOrders = payload.nonAttributedCommands.map(
          (order) => ({
            ...order,
            expanded: false,
          })
        );
        state.smartOrders = payload.smartCommands;

        state.smartWithReviewOrders = payload.smartWithReviewCommands;

        state.writersOffers = payload.nonAttributedCommands
          .filter((order) => order.writerPropositions.length > 0)
          .map((order) =>
            order.writerPropositions.map((writer) => ({
              orderId: order.id,
              actualBilling: false,
              wordsManagedNumber: 0,
              isAccepted: 2,
              ...writer,
            }))
          )
          .flat();

        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchAllOrders.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAllOrders.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default adminOrderList.reducer;

export const adminOrderListSelector = (state) => state.adminOrderList;

export const {
  addOrder,
  updateOrder,
  removeOrder,
  removeNonAttributedOrder,
  updateWriterOffer,
  handleSorting,
  updateOrderDeliveredTextAmount,
  updateCommande,
} = adminOrderList.actions;
