import { createSlice } from '@reduxjs/toolkit';
import { fetchAllOrders } from './features';

import { ORDERSTATUS } from 'utils/Constants';

export const writerOrderList = createSlice({
  name: 'writerOrderList',
  initialState: {
    title: null,
    url: null,
    delayedOrders: [],
    inProgressOrders: [],
    nonAttributedCommands: [],
    validatedOrders: [],
    smartWithReviewOrders: [],
    isLoading: true,
    isError: true,
  },
  reducers: {
    updateOrderDeliveredTextAmount: (state, { payload }) => {
      let index = state.validatedOrders.findIndex(
        (order) => order.id === payload.orderId
      );
      if (index > -1) {
        state.validatedOrders[index].deliveredTextsAmount =
          state.validatedOrders[index].deliveredTextsAmount + payload.amount;
        return;
      }

      index = state.delayedOrders.findIndex(
        (order) => order.id === payload.orderId
      );
      if (index > -1) {
        state.delayedOrders[index].deliveredTextsAmount =
          state.delayedOrders[index].deliveredTextsAmount + payload.amount;
        return;
      }

      index = state.inProgressOrders.findIndex(
        (order) => order.id === payload.orderId
      );
      if (index > -1)
        state.inProgressOrders[index].deliveredTextsAmount =
          state.inProgressOrders[index].deliveredTextsAmount + payload.amount;
    },
    removeNonAttributedOrder: (state, action) => {
      state.nonAttributedCommands = state.nonAttributedCommands.filter(
        (item) => item.id !== action.payload
      );
    },
    handleSorting: (state, { payload }) => {
      let array = [];
      switch (payload.table) {
        case ORDERSTATUS.VALID:
          array = [...state.validatedOrders];
          break;
        case ORDERSTATUS.DELAYED:
          array = [...state.delayedOrders];
          break;
        case ORDERSTATUS.INPROGRESS:
          array = [...state.inProgressOrders];
          break;
        default:
          array = [...state.nonAttributedOrders];
          break;
      }

      if (payload.sortField) {
        const sorted = [...array].sort((a, b) => {
          if (a[payload.sortField] === null) return 1;
          if (b[payload.sortField] === null) return -1;
          if (
            payload.sortField === 'deliveredTextsAmount' ||
            payload.sortField === 'validatedTextsAmount'
          ) {
            const pourcentageA = (a[payload.sortField] * 100) / a['textAmount'];
            const pourcentageB = (b[payload.sortField] * 100) / b['textAmount'];

            return payload.sortOrder === 'asc'
              ? pourcentageA - pourcentageB
              : pourcentageB - pourcentageA;
          }

          return (
            a[payload.sortField]
              .toString()
              .localeCompare(b[payload.sortField].toString(), 'en', {
                numeric: true,
              }) * (payload.sortOrder === 'asc' ? 1 : -1)
          );
        });
        switch (payload.table) {
          case ORDERSTATUS.VALID:
            state.validatedOrders = [...sorted];
            break;
          case ORDERSTATUS.DELAYED:
            state.delayedOrders = [...sorted];
            break;
          case ORDERSTATUS.INPROGRESS:
            state.inProgressOrders = [...sorted];
            break;
          default:
            state.nonAttributedOrders = [...sorted];
            break;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrders.fulfilled, (state, { payload }) => {
        state.delayedOrders = payload.delayedCommands;
        state.inProgressOrders = payload.inProgressCommands;
        state.nonAttributedCommands = payload.nonAttributedCommands;
        state.validatedOrders = payload.validatedCommands;
        state.smartWithReviewOrders = payload.smartWithReviewCommands;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchAllOrders.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAllOrders.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default writerOrderList.reducer;

export const {
  handleSorting,
  removeNonAttributedOrder,
  updateOrderDeliveredTextAmount,
} = writerOrderList.actions;

export const writerOrderListSelector = (state) => state.writerOrderList;
