import React from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setStatus } from 'store/Admin/OrderDisplay';
import { setOrderId, setOrderType } from 'store/Admin/Analyser';
import {
  fetchAdminOrderDisplay,
  fetchAdminTextOrderDisplay,
  fetchAdminOrderLineDisplay,
} from 'store/Admin/OrderDisplay/features';

import { ORDERSTATUS } from 'utils/Constants';
import { CustomSlider } from 'components/shared/CustomSlider';

export const RenderNoWriterPropositionTableRow = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <tr key={props.index}>
      <td
        style={{ cursor: 'pointer' }}
        onClick={() => {
          dispatch(setStatus(ORDERSTATUS.NONATTRIBUTED));
          dispatch(fetchAdminOrderDisplay(props.order.id));
          dispatch(setOrderId(props.order.id));
          dispatch(setOrderType(props.order.type));
          dispatch(fetchAdminTextOrderDisplay(props.order.id));
          dispatch(fetchAdminOrderLineDisplay(props.order.id));
          history.push('/Admin/DetailsDeLaCommande');
        }}
      >
        <div className="d-flex">
          <div style={{ width: '270px' }}>{props.order.title}</div>
          {props.order.type === 'SMART_WITH_REVIEW' ? (
            <div className="smart_order_icon ">
              <img src="Images/icon-eye.jpg" alt="eye" />
            </div>
          ) : (
            ''
          )}
        </div>
      </td>
      {[...Array(8)].map((e, i) => (
        <td key={i}>
          <span className="text-gray">-</span>
        </td>
      ))}
      <td>
        <CustomSlider order={props.order} disabled={true}></CustomSlider>
      </td>
    </tr>
  );
};
