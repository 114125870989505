import { classes } from 'components/shared/modal/modalClasses';
import React from 'react';

const ModalHeader = ({ handleClose, title }) => {
  return (
    <div style={classes.modalHeader} className="modal-header">
      <h5 style={classes.RcapitulatifFacturation} className="modal-title">
        {title}
        <br />
      </h5>
      <button
        type="button"
        style={classes.btnClose}
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={handleClose}
      />
    </div>
  );
};
export default ModalHeader;
