import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosPrivate from 'config/axiosPrivate';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { updateUser, UserSessionSelector } from 'store/UserSession';
import { clientWebsiteDetailsSelector } from 'store/Client/ClientWebsiteDisplay';
import { fetchWebsiteDisplay } from 'store/Client/ClientWebsiteDisplay/features';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { CustomAutocompleteDisabled } from 'components/shared/DropDown/CustomAutocomplete';
import { InputField } from 'components/shared/InputField';
import { profileSchema } from 'utils/schemas';
import { ROLES } from 'utils/Constants';

const UPDATE_PROFILE_URL = '/user/updateProfile';

function ClientProfile() {
  const { user } = useSelector(UserSessionSelector);
  const { webSitesList, isLoading } = useSelector(clientWebsiteDetailsSelector);
  const history = useHistory();
  const { t } = useTranslation();
  const [showPassword] = useState(false);
  const [index, setIndex] = useState(0);

  const [urlSitesList, setUrlSitesList] = useState([]);
  const [urlSite, setUrlSite] = useState(null);

  const [consultant, setConsultant] = useState('');

  const [coconsTextNbr, setCoconsTextNbr] = useState(0);
  const [coconsNbr, setCoconsNbr] = useState(0);
  const [faqNbr, setFaqNbr] = useState(0);
  const [gmbNbr, setGmbNbr] = useState(0);
  const [serviceDetails, setServiceDetails] = useState('');
  const [textOptimizerNbr, setTextOptimizerNbr] = useState(0);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const dispatch = useDispatch();
  const formRef = useRef(null);
  const namelInputRef = useRef(null);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    document.title = t('Typix - Mon Profile');
    dispatch(fetchWebsiteDisplay());
  }, [dispatch, t]);

  useEffect(() => {
    if (!isLoading) {
      setUrlSitesList(webSitesList.map((elem) => elem.urlSite));
      setUrlSite(webSitesList[index].urlSite);
      setConsultant(webSitesList[index].consultantName);
      setCoconsTextNbr(webSitesList[index].coconsTextNbr);
      setCoconsNbr(webSitesList[index].coconsNbr);
      setTextOptimizerNbr(webSitesList[index].textOptimizerNbr);
      setFaqNbr(webSitesList[index].faqNbr);
      setGmbNbr(webSitesList[index].gmbNbr);
      setServiceDetails(webSitesList[index].serviceDetails);
    }
  }, [isLoading, webSitesList, index]);

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      await profileSchema.validate(data, {
        abortEarly: false,
      });

      const userData = {
        id: user.id,
        name: data.name,
        account: data.account,
        mail: data.mail,
        phoneNumber: data.phoneNumber,
        password: data.password ? data.password : null,
        role: ROLES.CLIENT,
      };
      axiosPrivate
        .put(UPDATE_PROFILE_URL, userData)
        .then((res) => {
          dispatch(updateUser(res.data));
          setErrorSuccessMsg(t(`Votre profil a été mis à jour avec succès.`));
          setIsSuccess(true);
          setErrorSuccess(true);
        })
        .catch(() => {
          setErrorSuccessMsg(
            t(
              `Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.`
            )
          );
          setIsSuccess(false);
          setErrorSuccess(true);
        });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = t(error.message);
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <div className="content-center">
      <div className="box-header-top">
        <span onClick={history.goBack} className="bt-circle me-3"></span>
        <h1>{t('Mon profil')}</h1>
      </div>
      <div className="box-over" style={{ position: 'relative' }}>
        <div className="box-img-bg bg-4" />
        <div className="box-img-bg bg-5" />
        <div className="box-img-bg bg-6" />
        <Form ref={formRef} initialData={user} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-md-4 box-screen">
              <div className="box-wrapper">
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          ref={namelInputRef}
                          type="text"
                          name="name"
                          label={t('Nom/prénom')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          type="text"
                          name="mail"
                          label={t('E-mail')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-6 col-md-5">
                      <div>
                        <InputField
                          type="tel"
                          label={t('Téléphone')}
                          name="phoneNumber"
                        />
                      </div>
                    </div>
                    <div className="col-6 col-md-7">
                      <div>
                        <InputField
                          type="text"
                          label={t('Compte')}
                          name="account"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          label={t('Mot de passe')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          name="confirmPassword"
                          type="text"
                          label={t('Confirmer le mot de passe')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-more">
                  *
                  {t(
                    'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom'
                  )}
                </div>
              </div>
              <div>
                <button className="bt-submit-y float-end" type="submit">
                  {' '}
                  {t('Sauvegarder')}
                </button>
              </div>
            </div>

            <div className="col-12 col-md-4 box-screen">
              <div className="box-wrapper" style={{ height: '485px' }}>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="label-title">{t('Site')}</div>
                      <CustomAutocompleteDisabled
                        disabled
                        list={urlSitesList}
                        value={urlSite}
                        onInputChange={(event, value) => setUrlSite(value)}
                        setValue={(value) => {
                          const url = urlSitesList.find((x) => x === value);
                          if (url) setUrlSite(url);
                          const index = urlSitesList.findIndex(
                            (x) => x === value
                          );
                          setIndex(index);
                        }}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="label-title">{t('Consultant')}</div>
                      <div className="number-wrapper">
                        <input
                          type="text"
                          className="form-control form-ct"
                          value={consultant}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="label-title">
                        {t('Détail de la prestation')}
                      </div>
                      <div className="number-wrapper">
                        <input
                          type="text"
                          className="form-control form-ct"
                          value={serviceDetails}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Nb de Silo')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={coconsNbr}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Nb de FAQ')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={faqNbr}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Nb de GMB')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={gmbNbr}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-6">
                    <div className="label-title">
                      {t('Nb de silo par texte')}
                    </div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={coconsTextNbr}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="label-title">
                      {t('Nb de textes à optimiser')}
                    </div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={textOptimizerNbr}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
}

export default ClientProfile;
