import { Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  switch_track: {
    backgroundColor: 'grey',
  },
  switch_base: {
    color: 'grey',
    '&.Mui-disabled': {
      color: '#e886a9',
    },
    '&.Mui-checked': {
      color: '#FFE426',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#FFE426',
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: '#FFE426',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#FFE426',
    },
  },
}));

export const CustomSWitch = (props) => {
  const classes = useStyles();

  return (
    <Switch
      classes={{
        track: classes.switch_track,
        switchBase: classes.switch_base,
        colorPrimary: classes.switch_primary,
      }}
      color={'primary'}
      checked={props.value}
      onChange={props.toggleValue}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
};
