import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setOrderId, setOrderType } from 'store/Admin/Analyser';
import { setStatus } from 'store/Admin/OrderDisplay';

import { ORDERSTATUS } from 'utils/Constants';
import { formatDate } from 'utils/DateUtils';

import { adminOrderListSelector } from 'store/Admin/OrderList';
import { updateWriterOffer } from 'store/Admin/OrderList';
import {
  fetchAdminOrderDisplay,
  fetchAdminTextOrderDisplay,
  fetchAdminOrderLineDisplay,
} from 'store/Admin/OrderDisplay/features';
import { CustomSlider } from 'components/shared/CustomSlider';
import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';

export const RenderOneWriterPropositionTableRow = (props) => {
  let { writersOffers } = useSelector(adminOrderListSelector);
  const [disabled, setDisabled] = useState(false);
  const [writerOffer, setWriterOffer] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let obj = writersOffers.find(
      (writerOffer) => writerOffer.orderId === props.order.id
    );
    setWriterOffer(obj);
  }, [props.order, writersOffers]);

  const updateValue = useCallback(
    (option, value) => {
      dispatch(
        updateWriterOffer({
          orderId: props.order.id,
          option,
          value,
          writerId: props.order.writerPropositions[0].id,
        })
      );
    },
    [dispatch, props.order]
  );

  useEffect(() => {
    setTimeout(() => {
      setDisabled(props.order.remainingTextAmount < writerOffer.textAmount);
    }, 200);
    if (props.order.remainingTextAmount < writerOffer.textAmount)
      updateValue('isAccepted', 2);
  }, [writerOffer.textAmount, props.order.remainingTextAmount, updateValue]);

  return (
    <tr key={props.index}>
      <td
        style={{ cursor: 'pointer' }}
        onClick={() => {
          dispatch(setStatus(ORDERSTATUS.NONATTRIBUTED));
          dispatch(setOrderId(props.order.id));
          dispatch(setOrderType(props.order.type));

          dispatch(fetchAdminOrderDisplay(props.order.id));
          dispatch(fetchAdminTextOrderDisplay(props.order.id));
          dispatch(fetchAdminOrderLineDisplay(props.order.id));
          history.push('/Admin/DetailsDeLaCommande');
        }}
      >
        <div className="d-flex">
          <div style={{ width: '270px' }}>{props.order.title}</div>
          {props.order.type === 'SMART_WITH_REVIEW' ? (
            <div className="smart_order_icon ">
              <img src="Images/icon-eye.jpg" alt="icon-eye" />
            </div>
          ) : (
            ''
          )}
        </div>
      </td>
      <td>
        <span> {writerOffer.name} </span>{' '}
      </td>
      <td>
        <div
          className="number-wrapper wd-80"
          style={{ width: '85px', margin: '0 auto' }}
        >
          <input
            type="number"
            className="form-control form-ct"
            value={writerOffer.textAmount}
            min="0"
            max={props.order.remainingTextAmount}
            onChange={(e) => updateValue('textAmount', e.target.value)}
          />
          <UpDownInputArrows disabled={false} />
        </div>
      </td>
      <td>
        <div
          className="number-wrapper wd-80"
          style={{ display: 'inline-block', verticalAlign: 'top' }}
        >
          <input
            type="number"
            value={writerOffer.minWordsAmount}
            className="form-control form-ct"
            min="0"
            onChange={(e) => updateValue('minWordsAmount', e.target.value)}
          />
          <UpDownInputArrows disabled={false} />
        </div>
        <div
          className="number-wrapper wd-80"
          style={{ display: 'inline-block', verticalAlign: 'top' }}
        >
          <input
            type="number"
            min="0"
            className="form-control form-ct"
            value={writerOffer.maxWordAmount}
            onChange={(e) => updateValue('maxWordAmount', e.target.value)}
          />
          <UpDownInputArrows disabled={false} />
        </div>
      </td>
      <td>
        <div className="box-checkbox" style={{ margin: '0px' }}>
          <input
            className="form-check-input"
            onChange={(e) => updateValue('actualBilling', e.target.checked)}
            checked={writerOffer.actualBilling}
            type="checkbox"
          />
        </div>
      </td>
      <td>
        <div
          className="number-wrapper wd-80"
          style={{ display: 'inline-block', verticalAlign: 'top' }}
        >
          <input
            type="number"
            min="0"
            className="form-control form-ct"
            style={{
              backgroundColor: writerOffer.actualBilling
                ? 'rgb(224,224,224)'
                : null,
              color: writerOffer.actualBilling ? 'grey' : null,
            }}
            disabled={writerOffer.actualBilling}
            onChange={(e) => updateValue('wordsManagedNumber', e.target.value)}
            value={writerOffer.wordsManagedNumber}
          />

          <UpDownInputArrows disabled={writerOffer.actualBilling} />
        </div>
      </td>
      <td>
        <span>{writerOffer.price} €</span>
      </td>
      <td>
        <span>
          {writerOffer.textAmount}/{props.order.remainingTextAmount}{' '}
        </span>
      </td>
      <td>
        <span>
          {writerOffer.deadline ? formatDate(writerOffer.deadline) : null}
        </span>
      </td>
      <td>
        <CustomSlider
          value={writerOffer.isAccepted}
          setValue={(value) => updateValue('isAccepted', value)}
          order={props.order}
          disabled={disabled}
        ></CustomSlider>
      </td>
    </tr>
  );
};
