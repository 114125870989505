import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { connect } from 'react-redux';

import { UserSessionSelector } from 'store/UserSession';

import { NavMenuDetails } from './NavMenuDetails.js';
import SideNavBar from './SideNavBar.js';
import { getPathsByUserId } from 'utils/navigationPaths';

import './styles.css';

export const NavMenu = (props) => {
  const [currentPage, setCurrentPage] = useState(window.location.pathname);
  const user = useSelector(UserSessionSelector);
  const paths = getPathsByUserId(user.role).pagePaths;

  useEffect(() => {
    window.addEventListener('locationchange', () => {
      setCurrentPage(window.location.pathname);
    });
  }, []);

  if ((user && user.role === 'CLIENT') || user.role === 'CONSULTANT') {
    return (
      <div>
        <NavMenuDetails user={user} paths={paths} currentPage={currentPage} />
      </div>
    );
  } else {
    return (
      <div>
        <NavMenuDetails user={user} paths={paths} currentPage={currentPage} />
        <SideNavBar user={user} paths={paths} />
      </div>
    );
  }
};

export default connect()(NavMenu);
