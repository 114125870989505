import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { lower } from 'utils/StringUtils';

import { selectWriter } from 'store/Admin/WriterDisplay';

import { PAGENUMBERSLARGELIMIT } from 'utils/Constants';
import { useHistoryState } from 'utils/hooks/useHistoryState';
import { handleSortingChange } from 'helpers/SortingHelper';

import StarIcon from '@mui/icons-material/Star';
import { CustomWhiteTooltip } from 'components/shared/CustomTooltip';

import { Box, Rating, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

import './writers.css';

const columns = [
  { label: 'Prénom/Nom', field: 'name', sortable: true, sort: 'asc' },
  { label: 'Langues', field: 'language', sortable: false },
  { label: 'Notes', field: 'rating', sortable: true },
  { label: 'Contacter', field: 'contact', sortable: false },
];

const pageNbOptions = [15, 20, 30];

export const WritersSearchResult = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [writersPerPage, setWritersPerPage] = useState(15);
  const [pageNumbers, setPageNumbers] = useState([]);

  const [nameSearch, setNameSearch] = useHistoryState('nameSearch', '');
  const [currentWriters, setCurrentWriters] = useState([]);
  const [foundWriters, setFoundWriters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredWriters, setFilteredWriters] = useState([]);

  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    setFoundWriters(props.result);
  }, [props]);

  useEffect(() => {
    if (foundWriters && nameSearch && nameSearch.length > 0) {
      setCurrentPage(1);
      setFilteredWriters(
        foundWriters.filter((x) => {
          return lower(x.name).includes(lower(nameSearch));
        })
      );
    } else {
      setFilteredWriters([...foundWriters]);
    }
  }, [nameSearch, foundWriters]);

  useEffect(() => {
    const indexOfLastWriter = currentPage * writersPerPage;
    const indexOfFirstWriter = indexOfLastWriter - writersPerPage;
    setCurrentWriters(
      filteredWriters.slice(indexOfFirstWriter, indexOfLastWriter)
    );
    setPageNumbers(
      [...Array(Math.ceil(filteredWriters.length / writersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredWriters, writersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const handleSorting = (sortField, sortOrder) => {
    let sorted;

    if (sortField === 'name') {
      sorted = [...filteredWriters].sort((a, b) => {
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true,
          }) * (sortOrder === 'asc' ? 1 : -1)
        );
      });
    } else if (sortField === 'rating') {
      sorted = [...filteredWriters].sort((a, b) => {
        return (a.note - b.note) * (sortOrder === 'asc' ? 1 : -1);
      });
    }

    setFilteredWriters(sorted);
  };

  return (
    <div>
      <div className="box-wrapper">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="box-fr">
              <div onClick={props.return} className="bt-circle me-3" />
              <div className="text-h">
                {props.result.length} {t('rédacteurs trouvés')}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
              <div className="input-group input-cs w-100">
                <div className="input-group-text">
                  <SearchIcon />
                </div>
                <input
                  type="text"
                  value={nameSearch}
                  onChange={(event) => setNameSearch(event.target.value)}
                  className="form-control input-cs ps-0"
                  placeholder={t('Rechercher')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: '-7px' }}>
          <div className="wrapbox-table">
            <table className="table-lists table-redact">
              <thead>
                <tr>
                  {columns.map(({ label, field, sortable }) => (
                    <th
                      style={{
                        cursor: sortable ? 'pointer' : 'default',
                        width: field === 'name' ? '28%' : '',
                      }}
                      onClick={
                        sortable
                          ? () =>
                              handleSortingChange(
                                field,
                                sortField,
                                sortOrder,
                                setSortField,
                                setSortOrder,
                                handleSorting
                              )
                          : null
                      }
                      key={field}
                    >
                      {t(label)}
                      {!sortable ? null : field === sortField &&
                        sortOrder === 'asc' ? (
                        <span>
                          <ChevronUpIcon style={{ marginLeft: '3px' }} />
                        </span>
                      ) : (
                        <span>
                          <ChevronDownIcon style={{ marginLeft: '3px' }} />
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentWriters.map((writer) => (
                  <tr
                    style={{
                      backgroundColor: 'rgb(255, 244, 168)',
                      cursor: 'pointer',
                    }}
                    key={writer.id}
                  >
                    <td
                      onClick={() => {
                        dispatch(selectWriter(writer));
                        history.push('/Admin/VoirRedacteur');
                      }}
                    >
                      <span> {writer.name} </span>
                    </td>

                    <td>
                      <span>
                        {writer.languages.map((x) => x.abbreviation).join('/')}
                      </span>
                    </td>
                    <td>
                      <Box sx={{ marginLeft: 1, marginRight: 1, fontSize: 16 }}>
                        {' '}
                        <Rating
                          name={`client rating`}
                          value={writer.note}
                          readOnly
                          precision={0.5}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.3 }}
                              fontSize="inherit"
                            />
                          }
                          icon={<StarIcon style={{ fontSize: 'inherit' }} />}
                        />{' '}
                        <Tooltip
                          title={
                            Number.isInteger(writer.note)
                              ? writer.note
                              : writer.note?.toFixed(2)
                          }
                          placement="top"
                        >
                          <button
                            type="button"
                            className="btn-circle"
                            style={{ marginLeft: '5px' }}
                          ></button>
                        </Tooltip>
                      </Box>{' '}
                    </td>
                    <td>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open('mailto:' + writer.mail)}
                        className="bt-icon-msg me-3"
                      >
                        <img
                          src="/Images/icon-message.png"
                          alt="icon-message"
                        />
                      </span>
                      <CustomWhiteTooltip
                        title={writer.phoneNumber}
                        placement="bottom"
                      >
                        <span
                          style={{ cursor: 'pointer' }}
                          className="bt-icon-phone"
                        >
                          <img
                            src="/Images/icon-phone.png"
                            alt="icon-message"
                          />
                        </span>
                      </CustomWhiteTooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="box-rectangle" />
          </div>
        </div>
      </div>
      <div className="box-page">
        <div className="d-flex align-items-center" style={{ marginLeft: 10 }}>
          <span className="me-2">{t('Afficher')}</span>
          <div className="dropdown" style={{ width: 67 }}>
            <button
              className="btn dropdown-toggle dropdown-custom w-100"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {writersPerPage}

              <span>
                <ChevronDownIcon />
              </span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {pageNbOptions
                .filter((nb) => nb !== writersPerPage)
                .map((item, index) => (
                  <li key={index} onClick={() => setWritersPerPage(item)}>
                    <button className="dropdown-item">{item}</button>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <div className="box-pagination">
          {filteredWriters.length === 0 || currentPage === 1 ? (
            <div style={{ cursor: 'not-allowed' }} className="text-page">
              {t('Précédent')}
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              {t('Précédent')}
            </div>
          )}
          <div className="list-page-number">
            {pageNumbers
              .slice(
                Math.max(0, currentPage - PAGENUMBERSLARGELIMIT),
                Math.min(
                  currentPage + PAGENUMBERSLARGELIMIT,
                  pageNumbers.length
                )
              )
              .map((pageNum, index) => (
                <span
                  key={index}
                  className={pageNum === currentPage ? 'active' : ''}
                  onClick={() => {
                    setCurrentPage(pageNum);
                  }}
                >
                  {pageNum}
                </span>
              ))}

            {filteredWriters.length === 0 ||
            currentPage ===
              Math.ceil(filteredWriters.length / writersPerPage) ? (
              <span style={{ cursor: 'not-allowed' }} className="text-page">
                {t('Suivant')}
              </span>
            ) : (
              <span onClick={() => setCurrentPage(currentPage + 1)}>
                {t('Suivant')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
