import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { writerOrderDisplaySelector } from 'store/Writer/OrderDisplay';
import OrderDetails from 'components/shared/OrderDetails';
import OrderHistory from 'components/shared/OrderHistory';

import { OrderTextsDetailsList } from 'components/shared/OrderDetails/OrderTextsDetailsList';

import { CustomTooltip } from 'components/shared/CustomTooltip';
import { Loader } from 'components/shared/Loader';

import { pageNbOptions } from 'utils/Constants';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

const navItems = [
  { text: 'Détail de la commande', active: 1 },
  { text: 'Historique', active: 2 },
];

function WriterOrderDisplay() {
  const history = useHistory();
  const { t } = useTranslation();

  const { order, isLoading, isError } = useSelector(writerOrderDisplaySelector);
  const [title, setTitle] = useState('');

  const [searchValue, setSearchValue] = useState('');
  const [currentPage] = useState(1);

  const [active, setActive] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(10);

  useEffect(() => {
    document.title = 'Typix - Détails de la commande';
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  useEffect(() => {
    if (!isLoading && !isError) {
      setTitle(order.title);
    }
  }, [isError, isLoading, order]);

  if (isLoading || isError) {
    return <Loader />;
  }

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{title}</h1>
      </div>
      <div className="row">
        <div className="col-12 col-md-7">
          <ul className="nav nav-tabs nav-tabs-ct">
            {navItems.map((item, index) => (
              <li key={index} className="nav-item">
                <button
                  onClick={() => setActive(item.active)}
                  className={`nav-link ${
                    item.active === active ? 'active' : ''
                  }`}
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                >
                  {t(item.text)}
                </button>
              </li>
            ))}
          </ul>
          {active === 1 && (
            <OrderDetails
              searchValue={searchValue}
              currentPage={currentPage}
              textsPerPage={elementsPerPage}
            />
          )}
        </div>

        {
          active === 1 ? (
            <div className="col-12 col-md-5">
              <div className="box-text-title">
                <div className="box-ch">
                  <span>{t('Contenu disponible')}</span>
                  <CustomTooltip
                    title={
                      <span>
                        <p>
                          {t('Noir')} :{' '}
                          {t('contenu en attente de vérification client')}
                        </p>
                        <p>
                          {t('Orange')} :{' '}
                          {t('contenu en attente de repasse rédacteur')}
                        </p>
                        <p>
                          {t('Violet')} :{' '}
                          {t('contenu repassé, en attente validation client')}
                        </p>
                        <p>
                          {t('Vert')} : {t('contenu validé par le client')}
                        </p>
                      </span>
                    }
                    placement="top"
                  >
                    <button type="button" className="btn-circle"></button>
                  </CustomTooltip>
                </div>
              </div>
              <OrderTextsDetailsList
                orderTextAmount={order.textAmount}
                orderType={order.type}
                currentPage={currentPage}
                textsPerPage={elementsPerPage}
              />
            </div>
          ) : (
            ''
          )
          // (
          //   <div className="col-12 col-md-4">
          //     <div className="box-list-ui justify-content-end">
          //       <div className="box-input-text pe-0" style={{ width: '53%' }}>
          //         <div className="input-group input-cs w-100 pe-0">
          //           <div className="input-group-text">
          //             <SearchIcon />
          //           </div>
          //           <input
          //             type="text"
          //             className="form-control ps-0"
          //             value={searchValue}
          //             placeholder={t('Rechercher')}
          //             onChange={(event) => setSearchValue(event.target.value)}
          //           />
          //         </div>
          //       </div>
          //       <div className="d-inline-flex align-items-center">
          //         <span className="me-2">{t('Afficher les résultats')}</span>
          //         <div className="dropdown" style={{ width: '70px' }}>
          //           <button
          //             className="btn dropdown-toggle dropdown-custom w-100"
          //             type="button"
          //             id="dropdownMenuButton1"
          //             data-bs-toggle="dropdown"
          //             aria-expanded="false"
          //           >
          //             {elementsPerPage}
          //             <span>
          //               <ChevronDownIcon />
          //             </span>
          //           </button>
          //           <ul
          //             className="dropdown-menu"
          //             aria-labelledby="dropdownMenuButton1"
          //           >
          //             {pageNbOptions
          //               .filter((nb) => nb !== elementsPerPage)
          //               .map((item, index) => (
          //                 <li key={index}>
          //                   <button
          //                     className="dropdown-item"
          //                     onClick={() => setElementsPerPage(item)}
          //                   >
          //                     {item}
          //                   </button>
          //                 </li>
          //               ))}
          //           </ul>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // )
        }
      </div>
      {active === 2 && (
        <OrderHistory
          searchValue={searchValue}
          textsPerPage={elementsPerPage}
        />
      )}
    </div>
  );
}

export default WriterOrderDisplay;
