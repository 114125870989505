import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { BillDisplaySelector } from 'store/Writer/BillDisplay/reducers';
import { BILL_MODIFICATION, BILL_STATUS_NOTGENERATED } from 'utils/Constants';
import './styles.css';
import { getBills } from 'store/Writer/BillDisplay/actions';
import { UserSessionSelector } from 'store/UserSession';
import zIndex from '@mui/material/styles/zIndex';

function SideNavBar({ paths }) {
  const { t } = useTranslation();
  const location = useLocation();
  const user = useSelector(UserSessionSelector);
  const dispatch = useDispatch();
  const { bills } = useSelector(BillDisplaySelector);
  const [nbOfToValidate, setNbToValidate] = useState(0);
  const [isExpended, setExpended] = useState(false);

  useEffect(() => {
    dispatch(getBills());
  }, [dispatch]);

  useEffect(() => {
    setNbToValidate(
      bills.filter((bill) =>
        [BILL_STATUS_NOTGENERATED, BILL_MODIFICATION].includes(bill.status)
      ).length
    );
  }, [bills]);

  const toggleNavbar = () => {
    setExpended((prev) => !prev);
  };

  const currentPage = location.pathname;

  return (
    <div className="box-menu-left">
      <div className={`box-radius-3 ${isExpended ? 'expand' : ''}`} />
      <div className="box-radius bg-img-1">
        <Link to={paths.mainMenu}>
          <img
            className="logo-m"
            src="Images/logo-typix.png"
            alt="logo-typix"
          />
        </Link>
      </div>
      <div className={`box-radius-2 ${isExpended ? 'active' : ''}`} />
      <div
        className="wrapper-box-menu-left"
        onMouseEnter={toggleNavbar}
        onMouseLeave={toggleNavbar}
      >
        <Link to={paths.mainMenu}>
          <div
            className={`box-menu-block bt-icon-dashboard ${
              currentPage === paths.mainMenu ? 'active' : ''
            }`}
          ></div>
        </Link>
        <Link to={paths.nonAttributedOrders}>
          <div
            className={`box-menu-block bt-icon-non-attr ${
              currentPage === paths.nonAttributedOrders ? 'active' : ''
            }`}
          ></div>
        </Link>
        <Link to={paths.pendingOrders}>
          <div
            className={`box-menu-block bt-icon-encour ${
              currentPage === paths.pendingOrders ? 'active' : ''
            }`}
          ></div>
        </Link>
        <Link to={paths.lateOrders}>
          <div
            className={`box-menu-block bt-icon-enretard ${
              currentPage === paths.lateOrders ? 'active' : ''
            }`}
          ></div>
        </Link>
        <Link to={paths.validatedOrders}>
          <div
            className={`box-menu-block bt-icon-validee ${
              currentPage === paths.validatedOrders ? 'active' : ''
            }`}
          ></div>
        </Link>
        <Link to={paths.facturation}>
          <Badge badgeContent={nbOfToValidate} color="error">
            <div
              className={`box-menu-block bt-icon-facturation ${
                currentPage === paths.facturation ? 'active' : ''
              }`}
            ></div>
          </Badge>
        </Link>
        {user && user.role === 'ADMIN' && (
          <Link to={paths.analyzer}>
            <Badge color="error">
              <div className="box-menu-block bt-icon-analyzer"></div>
            </Badge>
          </Link>
        )}
        {user && user.role === 'WRITER' && (
          <Link to={paths.faq}>
            <Badge color="error">
              <div
                className={`box-menu-block-faq bt-icon-faq ${
                  currentPage === paths.faq ? 'active' : ''
                }`}
              ></div>
            </Badge>
          </Link>
        )}
        <div
          className={`box-sub-menu ${isExpended ? 'active' : ''}`}
          id="sidebar"
        >
          <div className="box-subtext-menu">
            <div className="box-text-menu">Dashboard</div>
            <div className="box-text-menu">{t('Non attribuées')}</div>
            <div className="box-text-menu">{t('En cours')}</div>
            <div className="box-text-menu">{t('En retard')}</div>
            <div className="box-text-menu">{t('Validées')}</div>
            <div className="box-text-menu">{t('Factures')}</div>
            {user && user.role === 'ADMIN' && (
              <div className="box-text-menu">{t('Analyzer')}</div>
            )}
            {user && user.role === 'WRITER' && (
              <div className="box-text-menu">{'FAQ'}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideNavBar;
