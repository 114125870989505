import { setOrderType } from 'store/Admin/Analyser';
import { setStatus } from 'store/Admin/OrderDisplay';

import { formatDate } from 'utils/DateUtils';
import BillActionCell from '../Cells/BillActionCell';

import {
  BILL_MODIFICATION,
  BILL_MODIFIED,
  BILL_STATUS_PAID,
  BILL_STATUS_PAYMENT,
  BILL_STATUS_VALIDATION,
} from 'utils/Constants';
import '../../../shared/Table/styles.css';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  fetchAdminOrderDisplay,
  fetchAdminOrderLineDisplay,
  fetchAdminTextOrderDisplay,
} from 'store/Admin/OrderDisplay/features';

function TableRow({ bill, index, dispatch, value }) {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <tr key={bill.id}>
      <td
        style={{ cursor: 'pointer' }}
        onClick={() => {
          dispatch(setStatus('INPROGRESS'));
          dispatch(setOrderType('PREMIUM'));
          dispatch(fetchAdminOrderDisplay(bill.commandID));
          dispatch(fetchAdminTextOrderDisplay(bill.commandID));
          dispatch(fetchAdminOrderLineDisplay(bill.commandID));

          history.push('/Admin/DetailsDeLaCommande');
        }}
      >
        <span>{bill?.commandTitle}</span>
      </td>
      <td style={{ cursor: 'pointer' }}>
        <span>{bill?.writerName}</span>
      </td>
      <td>
        <span>
          <div
            style={{
              color:
                bill?.totalPrice > bill?.totalCalculatedPrice
                  ? 'red'
                  : 'inherit',
            }}
          >
            {bill?.totalPrice.toFixed(2)}€{' '}
          </div>
          {bill?.totalCalculatedPrice.toFixed(2)}€
        </span>
      </td>
      <td>
        <span>
          {bill?.status === BILL_STATUS_PAID
            ? t('Payée')
            : bill?.status === BILL_STATUS_PAYMENT
            ? t('En cours de paiement')
            : bill?.status === BILL_STATUS_VALIDATION
            ? t('En cours de validation')
            : bill?.status === BILL_MODIFICATION
            ? t('En cours de modification')
            : bill?.status === BILL_MODIFIED
            ? t('Modification à valider')
            : ''}{' '}
        </span>
      </td>
      <td>
        <span>{bill.validatedTime ? formatDate(bill.validatedTime) : '-'}</span>
      </td>

      <BillActionCell
        commandID={bill.commandID}
        writerID={bill.writerID}
        writerCommandID={bill.writerCommandID}
        value={value}
        bill={bill}
        index={index}
      />
    </tr>
  );
}

export default TableRow;
