import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { filterTextByType } from 'utils/Constants';

function CustomFilterMenu(props) {
  const {
    selectedStatus,
    handleClick,
    anchorEl,
    open,
    handleClose,
    isTranslateOnly,
    isTextOnly,
    isCombined,
    t,
    isText,
    isTranslate,
    isCombo,
    setIsTranslate,
    setIsText,
    setIsCombo,
    setSelectedStatus,
    filterTextByStatusArr,
    filterTextByType,
    setSelectedType,
    flag,
  } = props;

  const textType = filterTextByType;

  return (
    <div>
      <div
        className={
          selectedStatus.field
            ? 'bt-filter-circle-selected'
            : 'bt-filter-circle'
        }
        onClick={handleClick}
      />

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {((isTranslateOnly && isTextOnly) || isCombined) && (
          <>
            {textType.map((item, index) => (
              <MenuItem
                style={
                  (isText && item.field === 'NON_TRANSLATED') ||
                  (isTranslate && item.field === 'TRANSLATED') ||
                  (isCombo && item.field === 'COMBINED')
                    ? { backgroundColor: '#ffe426' }
                    : {}
                }
                key={index}
                className={
                  index === 0 ? 'first-child' : index === 5 ? 'last-child' : ''
                }
                //onClick={() => setSelectedType(item)}

                onClick={() => {
                  setSelectedType(item);
                  if (item.field === 'TRANSLATED') setIsTranslate(!isTranslate);
                  if (item.field === 'NON_TRANSLATED') setIsText(!isText);
                  if (item.field === 'COMBINED') setIsCombo(!isCombo);
                }}
              >
                <button className="dropdown-item">
                  {item.text.indexOf('+') > -1
                    ? `${t(item.text.split('+')[0])} + ${t(
                        item.text.split('+')[1]
                      )}`
                    : t(item.text)}
                  {(isText && item.field === 'NON_TRANSLATED') ||
                  (isTranslate && item.field === 'TRANSLATED') ||
                  (isCombo && item.field === 'COMBINED') ? (
                    <span className="bt-check">
                      <img src="/Images/icon-check.png" alt="check icon" />
                    </span>
                  ) : null}
                </button>
              </MenuItem>
            ))}
            <hr className="m-1" />
          </>
        )}

        {filterTextByStatusArr.map((item, index) => (
          <MenuItem
            style={
              item.field === selectedStatus.field &&
              item.value === selectedStatus.value
                ? { backgroundColor: '#ffe426' }
                : {}
            }
            key={index}
            className={
              index === 0 ? 'first-child' : index === 5 ? 'last-child' : ''
            }
            onClick={() => setSelectedStatus(item)}
          >
            <button className="dropdown-item">
              {t(item.text)}
              {item.field === selectedStatus.field &&
              item.value === selectedStatus.value ? (
                <span className="bt-check">
                  <img src="/Images/icon-check.png" alt="check icon" />
                </span>
              ) : null}
            </button>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default CustomFilterMenu;
