import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from 'config/axiosPrivate';

export const fetchWriterOrdersList = createAsyncThunk(
  'adminWriterDisplay/fetchWriterOrderList',
  async (id, thunkAPI) => {
    try {
      if (!id) return thunkAPI.rejectWithValue(404);
      const response = await axiosPrivate.get('/commands/byWriter/' + id);
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
