import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import axiosPrivate from 'config/axiosPrivate';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { updateUser, UserSessionSelector } from 'store/UserSession';

import { InputField } from 'components/shared/InputField';
import { ROLES } from 'utils/Constants';
import { profileSchema } from 'utils/schemas';

const UPDATE_PROFILE_URL = '/user/updateProfile';

function ConsultantProfile() {
  const { user } = useSelector(UserSessionSelector);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const namelInputRef = useRef(null);

  const [showPassword] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      await profileSchema.validate(data, {
        abortEarly: false,
      });

      const userData = {
        id: user.id,
        name: data.name,
        companyName: user.companyName,
        address: user.address,
        mail: user.mail,
        phoneNumber: data.phoneNumber,
        password: data.password ? data.password : null,
        role: ROLES.CONSULTANT,
      };
      axiosPrivate
        .put(UPDATE_PROFILE_URL, userData)
        .then((res) => {
          dispatch(updateUser(res.data));
          setErrorSuccessMsg(t(`Votre profil a été mis à jour avec succès.`));
          setIsSuccess(true);
          setErrorSuccess(true);
        })
        .catch(() => {
          setErrorSuccessMsg(
            t(
              `Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.`
            )
          );
          setIsSuccess(false);
          setErrorSuccess(true);
        });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = t(error.message);
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <div className="content-center">
      <div className="box-header-top">
        <span onClick={history.goBack} className="bt-circle me-3"></span>
        <h1>{t('Mon profil')}</h1>
      </div>
      <div className="box-over" style={{ position: 'relative' }}>
        <div className="box-img-bg bg-12" />
        <div className="box-img-bg bg-5" style={{ right: '-49px' }} />
        <div className="box-img-bg bg-6" style={{ top: '-56px' }} />
        <Form ref={formRef} initialData={user} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-md-5 box-screen">
              <div className="box-wrapper">
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          ref={namelInputRef}
                          type="text"
                          name="name"
                          label={t('Nom/prénom')}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          type="text"
                          name="mail"
                          label={t('E-mail')}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          type="tel"
                          label={t('Téléphone')}
                          name={'phoneNumber' || ''}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          label={t('Mot de passe')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          name="confirmPassword"
                          type="text"
                          label={t('Confirmer le mot de passe')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-more">
                  *
                  {t(
                    'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.'
                  )}
                </div>
              </div>
              <div>
                <button className="bt-submit-y float-end" type="submit">
                  {' '}
                  {t('Sauvegarder')}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
}

export default ConsultantProfile;
