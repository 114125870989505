import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: '#fff', // Set your desired background color here
        },
      },
    },
  },
});
export const customStyle = {
  root: {
    padding: '2px 10px',
    display: 'flex',
    fontFamily: "'Poppins', sans-serif !important",
  },
  option: {
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '5px',
    fontFamily: "'Poppins', sans-serif !important",
    '&:hover': {
      color: '#3c3c3c',
      backgroundColor: 'rgba(255, 228, 38, 0.4)!important',
    },
    '&[aria-selected="true"]': {
      backgroundColor: '#ffe426!important',
    },
  },
  chip: {
    display: 'flex !important',
    padding: '3px 10px !important',
    borderRadius: '20px',
    backgroundColor: '#ffe426 !important',
    alignItems: 'start',
    color: '#3c3c3c !important',
    letterSpacing: '-0.28px',
    textAlign: 'center',
    fontSize: '14px',
    float: 'left !important',
    top: 'unset !important',
    marginTop: '5px',
    marginRight: '5px',
    position: 'unset !important',
    right: 'unset !important',
  },

  tag: {
    backgroundColor: '#a0a',
    height: 24,
    position: 'relative',
    zIndex: 0,
    '& .MuiChip-label': {
      color: '#fff',
    },
    '&:after': {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: 'absolute',
      backgroundColor: 'white',
      zIndex: -1,
    },
  },

  textField: {
    display: 'block',
    width: '100%',
    fontWeight: '400',
    lineHeight: 1.5,
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
    borderRadius: '.25rem',
    minHeight: '40px',
    color: '#3c3c3c',
    fontSize: '16px !important',
  },

  textFieldDisabled: {
    backgroundColor: 'rgb(224,224,224)',
    color: 'grey',
    display: 'block',
    width: '100%',
    fontWeight: '400',
    lineHeight: 1.5,
    border: '1px solid #e0e0e0',
    borderRadius: '.25rem',
    height: '40px',
    fontSize: '16px !important',
  },

  clearIndicator: {
    '& span': {
      '& svg': {
        color: '#3c3c3c',
        fontSize: '15px',
      },
    },
  },
  popupIndicator: {
    '& span': {
      '& svg': {
        color: '#3c3c3c',
        '& path': {
          d: "path('M 17 10 L 12 15 L 7 10 L 6 10 l 6 6 l 6 -6 z')",
        },
      },
    },
  },
};
