import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { consultantOrderDisplaySelector } from 'store/Consultant/ConsultantOrderDisplay';
import OrderDetails from 'components/shared/OrderDetails';
import { OrderTextsDetailsList } from 'components/shared/OrderDetails/OrderTextsDetailsList';
import { ExcelExportButton } from 'components/shared/ExcelExport/ExcelExportButton';

import { CustomTooltip } from 'components/shared/CustomTooltip';
import { Loader } from 'components/shared/Loader';

// const pageNbOptions = [10, 15, 20]

const navItems = [{ text: 'Détail de la commande', active: 1 }];

function ConsultantOrderDisplay() {
  const history = useHistory();
  const { t } = useTranslation();

  const { order, textList, isLoading, isError } = useSelector(
    consultantOrderDisplaySelector
  );
  const [title, setTitle] = useState('');

  const [searchValue] = useState('');
  const [currentPage] = useState(1);

  const [active, setActive] = useState(1);
  const [elementsPerPage] = useState(10);

  useEffect(() => {
    document.title = `${t('Typix - Détails de la commande')}`;
    window.dispatchEvent(new Event('locationchange'));
  }, [t]);

  useEffect(() => {
    if (!isLoading && !isError) {
      setTitle(order.title);
    }
  }, [isError, isLoading, order]);

  if (isLoading || isError) {
    return <Loader />;
  }

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{title}</h1>
      </div>
      <div className="row">
        <div className="col-12 col-md-8">
          <ul className="nav nav-tabs nav-tabs-ct">
            {navItems.map((item, index) => (
              <li key={index} className="nav-item">
                <button
                  onClick={() => setActive(item.active)}
                  className={`nav-link ${
                    item.active === active ? 'active' : ''
                  }`}
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                >
                  {t(item.text)}
                </button>
              </li>
            ))}
          </ul>
          {active === 1 && (
            <OrderDetails
              searchValue={searchValue}
              currentPage={currentPage}
              textsPerPage={elementsPerPage}
            />
          )}
        </div>

        <div className="col-12  col-md-4">
          <div className="box-text-title">
            <div className="box-ch">
              <span>{t('Contenu disponible')}</span>
              <CustomTooltip
                title={
                  <span>
                    <p>
                      {t('Noir')} :{' '}
                      {t('contenu en attente de vérification client')}
                    </p>
                    <p>
                      {t('Orange')} :{' '}
                      {t('contenu en attente de repasse rédacteur')}
                    </p>
                    <p>
                      {t('Violet')} :{' '}
                      {t('contenu repassé, en attente validation client')}
                    </p>
                    <p>
                      {t('Vert')} : {t('contenu validé par le client')}
                    </p>
                  </span>
                }
                placement="top"
              >
                <button type="button" className="btn-circle"></button>
              </CustomTooltip>
            </div>
            {textList.length > 0 && (
              <div className="justify-content-end">
                <ExcelExportButton
                  orderTitle={order.title}
                  orderId={order.id}
                  orderLanguage={order.language}
                />
              </div>
            )}
          </div>
          <OrderTextsDetailsList
            orderTextAmount={order.textAmount}
            currentPage={currentPage}
            textsPerPage={elementsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default ConsultantOrderDisplay;
