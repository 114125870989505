import ReceiptIcon from '@mui/icons-material/Receipt';
import { Button, ThemeProvider, Typography, createTheme } from '@mui/material';
import { classes } from 'components/shared/modal/modalClasses';
const theme = createTheme({
  palette: {
    primary: {
      main: '#757575',
    },
  },
});
const FileReader = ({ t, file }) => {
  return (
    <div>
      {file ? (
        <>
          <div style={{ display: 'flex', alignItems: 'left' }}>
            <h5 style={classes.FactureInserted} className="modal-title">
              {t('Facture')}
            </h5>

            <ThemeProvider theme={theme}>
              <Button
                disabled={false}
                size="small"
                variant="outlined"
                startIcon={<ReceiptIcon />}
                sx={{ marginBottom: theme.spacing(2) }}
              >
                {' '}
                <Typography>{file.name}</Typography>
              </Button>
            </ThemeProvider>
          </div>{' '}
          <div>
            <iframe
              src={URL.createObjectURL(file)}
              width="100%"
              height="500px"
              title="PDF Viewer"
            />
          </div>
        </>
      ) : (
        <>
          {' '}
          <h5 style={classes.RcapitulatifFacturation} className="modal-title">
            {t('Importer votre facture')}
            <br />
          </h5>
          <div style={classes.Dropzone}>
            (
            <div>
              <p style={classes.DropzoneText}>
                {t('Pas de fichier disponible')}...
              </p>
            </div>
            )
          </div>
        </>
      )}
    </div>
  );
};

export default FileReader;
