import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ClientEvaluationModal from 'components/Client/Evaluation/EvaluationModals/ClientEvaluationModel';
import { Modal } from 'reactstrap';
import { setOrderId, setTextType, updateForm } from 'store/Writer/Analyser';
import { GenerateBillModal } from './GenerateBill/GenerateBillModal';
import { classes } from './modalClasses';
import { animationUrl } from 'config/endpoints';

const ErrorSuccesModal = ({
  open,
  toggle,
  handleClose,
  message,
  success,
  info,
  allTextsValidated,
  orderType,
  isTextDelivered,
  remainingTextAmount,
  isAllTextesDelivered,
  orderId,
  resetFields,
  showCreditButton,
  executeAction,
  user,
  textType,
  imageUrl,
  ...others
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const [factureOpen, setFactureOpen] = useState(false);
  const [formattedMessage, setFormattedMessage] = useState('');

  const boldTextRegex = /n°(\d+)/;
  const match = message.match(boldTextRegex);
  const boldText = match ? match[0] : '';

  // Regular expression for detecting email in message
  const emailRegex = /support-typix@pixalione.com/;

  useEffect(() => {
    if (open) {
      setModalOpen(true);

      // Delay the setting of the formatted message to avoid synchronicity issues
      setTimeout(() => {
        if (emailRegex.test(message)) {
          setFormattedMessage(
            message.replace(
              emailRegex,
              `<a href="mailto:support-typix@pixalione.com">support-typix@pixalione.com</a>`
            )
          );
        } else {
          setFormattedMessage(
            message.replace(boldTextRegex, `<strong>${boldText}</strong>`)
          );
        }
      }, 0); // Delay by 0 to let React update state
    }
  }, [open, message, boldTextRegex, emailRegex, boldText]);

  const handleModalClose = () => {
    setModalOpen(false);
    handleClose();
  };

  // Function to handle the conditional rendering of the message
  const renderFormattedMessage = () => {
    // Check if the message contains the email, and handle accordingly

    if (message) {
      if (emailRegex.test(message)) {
        console.log('i m here 1');
        // Only render the formatted message with the mailto link
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: message.replace(
                emailRegex,
                `<a href="mailto:support-typix@pixalione.com">support-typix@pixalione.com</a>`
              ),
            }}
          />
        );
      }

      // Handle bold text normally for other cases
      return message.replace(
        boldTextRegex,
        <span style={{ fontWeight: 'bold' }}>{boldText}</span>
      );
    }
  };
  const renderButtons = () => {
    if (orderType === 'PREMIUM' && isTextDelivered && remainingTextAmount > 0) {
      return textType !== 'EDITORIAL_AND_TRANSLATION' ? (
        <div className="d-flex justify-content-center mt-4">
          <button
            className="bt-submit-y"
            onClick={() => {
              setOrderId(orderId);
              dispatch(updateForm(null));
              resetFields();
              handleModalClose();
            }}
          >
            {t('Soumettre un nouveau texte')}
          </button>
        </div>
      ) : (
        <div className="">
          {textType && textType === 'EDITORIAL_AND_TRANSLATION' && (
            <div
              style={{
                fontSize: '16px',
                letterSpacing: '-0.32px',
                color: '#3c3c3c',
                margin: '5px',
              }}
            >
              {t('Soumettre un nouveau texte')} :
            </div>
          )}
          <div className="d-flex w-100">
            <div
              className="bt-submit-y me-1"
              onClick={() => {
                dispatch(setOrderId(orderId));
                dispatch(updateForm(null));
                resetFields();
                dispatch(setTextType('NON_TRANSLATED'));
                handleModalClose();
              }}
            >
              {' '}
              {t('Rédaction')}
            </div>
            <div
              className="bt-submit-y me-1"
              onClick={() => {
                dispatch(setOrderId(orderId));
                dispatch(updateForm(null));
                resetFields();
                dispatch(setTextType('TRANSLATED'));
                handleModalClose();
              }}
            >
              {' '}
              {t('Traduction')}{' '}
            </div>
            <div
              className="bt-submit-y me-1"
              onClick={() => {
                dispatch(setOrderId(orderId));
                dispatch(updateForm(null));
                resetFields();
                dispatch(setTextType('COMBINED'));
                handleModalClose();
              }}
            >
              {`${t('Rédaction')} + ${t('Traduction')}`}
            </div>
          </div>
        </div>
      );
    } else if (remainingTextAmount === 0 && isAllTextesDelivered) {
      return (
        <div className="d-flex justify-content-center mt-4">
          <button
            className="CTA_validation"
            onClick={() => {
              setOrderId(orderId);
              setFactureOpen(true);
            }}
          >
            {t('Générer une facture')}
          </button>
          <GenerateBillModal
            isOpen={factureOpen}
            commandID={orderId}
            handleClose={handleModalClose}
            visualize={false}
          />
        </div>
      );
    } else if (
      success === true &&
      (others.partTextValidated === true || allTextsValidated === true)
    ) {
      return (
        <ClientEvaluationModal
          orderId={orderId}
          user={user}
          handleClose={handleModalClose}
          t={t}
          allTextsValidated={allTextsValidated}
          writerCommandId={others.writerCommandId}
        />
      );
    }

    return null;
  };

  return (
    <>
      <Modal
        style={classes.modalCustom}
        isOpen={modalOpen}
        toggle={handleModalClose} // Close the modal when toggle is called
        backdrop={true} // Enable clicking outside to close the modal
        keyboard={false} // Disable closing the modal when the Esc key is pressed
      >
        <>
          <div style={classes.modalHeader} className="modal-header">
            <h5 style={classes.title} className="modal-title">
              {!info
                ? success === true
                  ? others.partTextValidated === true &&
                    allTextsValidated !== true
                    ? `${t('Partie')} ${others.nbValidatedParts}/${
                        others.nbParts
                      }`
                    : `${t('Félicitations !')}`
                  : 'Ooops !'
                : `${t('Information')}`}
            </h5>
            <button
              type="button"
              onClick={handleModalClose}
              style={classes.btnClose}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div style={classes.modalPad} className="modal-body">
            <div className="row row-mb-lg">
              <div className="col-12 col-md-12">
                <div className="mb-1">
                  <img
                    style={classes.imgConfirm}
                    src={
                      success
                        ? remainingTextAmount === 0 && isAllTextesDelivered
                          ? animationUrl + '/06_Commande_livrée.gif' ||
                            '/Images/modal/img-felicitation.png'
                          : imageUrl || '/Images/modal/img-felicitation.png'
                        : animationUrl + '/11_Animation_Crash.gif'
                    }
                    alt="confirm"
                  />
                </div>

                <div style={classes.textConfirm}>
                  {/* Render the formatted message after ensuring it's set */}
                  <span
                    dangerouslySetInnerHTML={{ __html: formattedMessage }}
                  />

                  {success === true && allTextsValidated === true && (
                    <>
                      <br /> <br />
                      {t(
                        'Votre retour est précieux pour nous et nous aide à améliorer nos services.'
                      )}
                    </>
                  )}
                  {success === true &&
                    others.partTextValidated === true &&
                    allTextsValidated === false && (
                      <>
                        <br /> <br />
                        {t(
                          'Vous venez de valider tous les contenus rédigés par '
                        )}
                        <span style={{ fontWeight: 'bold' }}>
                          {' '}
                          {others.userName}
                        </span>
                        .
                        <br />
                        {t("Qu'en avez vous pensé")} ?
                      </>
                    )}
                </div>

                <div className="container">
                  <div className="row">
                    {/* Your logic to render other buttons */}
                    <div className="d-flex justify-content-center mt-2">
                      {renderButtons()}
                    </div>
                    {/* The button you want to center */}
                    {showCreditButton && (
                      <div className="d-flex justify-content-center mt-2">
                        <button className="bt-submit-y" onClick={executeAction}>
                          {t('Demander des crédits')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default ErrorSuccesModal;
