export const KeyWordListFormatter = (input) => {
  let list = {};
  let keywords = input;
  if (keywords[keywords.indexOf(')') + 1] === ',') {
    const lines = keywords.split(',');
    const firstItem = lines[0];
    if (firstItem.length > 0) {
      const number = parseInt(
        firstItem.substring(
          firstItem.lastIndexOf('(') + 1,
          firstItem.lastIndexOf(')')
        )
      );
      const value = firstItem.substring(0, firstItem.indexOf('('));
      list = {
        ...list,
        [value.trim()]: number,
      };
    }

    lines.slice(1).forEach((item) => {
      if (item.length > 0) {
        const number = parseInt(
          item.substring(item.lastIndexOf('(') + 1, item.lastIndexOf(')'))
        );
        const value = item.substring(1, item.indexOf('('));
        list = {
          ...list,
          [value.trim()]: number,
        };
      }
    });
  } else {
    const lines = keywords.split('\n');
    lines.forEach((item) => {
      if (item.length > 0) {
        const number = parseInt(
          item.substring(item.lastIndexOf('(') + 1, item.lastIndexOf(')'))
        );
        const value = item.substring(0, item.indexOf('('));
        list = {
          ...list,
          [value.trim()]: number,
        };
      }
    });
  }
  return list;
};

export default KeyWordListFormatter;
