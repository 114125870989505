import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { consultantClientListSelector } from 'store/Consultant/ClientList';
import { consultantOrderListSelector } from 'store/Consultant/ConsultantOrderList';
import { fetchConsultantOrderList } from 'store/Consultant/ConsultantOrderList/features';
import {
  fetchConsultantOrderDisplay,
  fetchConsultantTextOrderDisplay,
} from 'store/Consultant/ConsultantOrderDisplay/features';

import { fetchClientList } from 'store/Consultant/ClientList/features';
import { handleSorting } from 'store/Consultant/ConsultantOrderList';

import { CONSULTANT_ORDERS_COLUMN } from 'utils/Constants';
import { lower } from 'utils/StringUtils';
import { formatDate } from 'utils/DateUtils';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import ConsultantClientList from 'components/Consultant/ConsultantClientList';
import ErrorComponent from 'components/shared/ErrorComponent';
import { Paginator } from 'components/shared/Paginator';
import { Loader } from 'components/shared/Loader';
import { ProgressBar } from 'components/shared/ProgressBar';
import { CircularBar } from 'components/shared/CircularBar';
import { pageNbOptions } from 'utils/Constants';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

const columns = CONSULTANT_ORDERS_COLUMN;

function DashboardPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  let { orders, isLoading, isError } = useSelector(consultantOrderListSelector);

  const [currentPage, setCurrentPage] = useState(1);

  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [currentOrders, setCurrentOrders] = useState([]);

  const [filteredOrders, setFilteredOrders] = useState([]);

  const [pageNumbers, setPageNumbers] = useState([]);

  const [titleSearch, setTitleSearch] = useHistoryState('titleSearch', '');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    document.title = t('Typix - Mon tableau de bord');
    window.dispatchEvent(new Event('locationchange'));

    dispatch(fetchConsultantOrderList());
    dispatch(fetchClientList());
  }, [dispatch, t]);

  useEffect(() => {
    if (!orders) return;
    if (titleSearch && titleSearch.length > 0) {
      setCurrentPage(1);
      setFilteredOrders(
        orders.filter((x) => {
          return lower(x.title).includes(lower(titleSearch));
        })
      );
    } else {
      setFilteredOrders([...orders]);
    }
  }, [titleSearch, orders]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const handleSortingChange = (field) => {
    const direction =
      field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
    dispatch(
      handleSorting({
        sortField: field,
        sortOrder: direction,
      })
    );
    setSortField(field);
    setSortOrder(direction);
  };

  return (
    <div>
      <div className="content-center">
        <div className="mb-2">
          <h1>Dashboard</h1>
        </div>
        <div className="row">
          <ConsultantClientList />
          <div className="col-12 col-md-9">
            <div className="box-wrapper">
              <div className="box-list-header">
                <div className="box-title"> {t('Liste des commandes')} </div>
                <div className="box-input-text">
                  <div className="input-group input-cs w-100">
                    <div className="input-group-text">
                      <SearchIcon />
                    </div>
                    <input
                      type="text"
                      value={titleSearch}
                      onChange={(event) => setTitleSearch(event.target.value)}
                      className="form-control ps-0"
                      placeholder={t('Rechercher')}
                    />
                  </div>
                </div>
              </div>
              {isLoading ? (
                <Loader />
              ) : isError ? (
                <ErrorComponent />
              ) : (
                <div className="table-responsive">
                  <div>
                    <table className="table-lists table-commandes">
                      <thead>
                        <tr>
                          {columns.map(({ label, field, sortable }) => (
                            <th
                              style={{
                                cursor: sortable ? 'pointer' : 'default',
                                width:
                                  field === 'title'
                                    ? '26%'
                                    : field === 'deadline'
                                    ? '20%'
                                    : '',
                              }}
                              onClick={
                                sortable
                                  ? () => handleSortingChange(field)
                                  : null
                              }
                              key={field}
                            >
                              {t(label)}
                              {!sortable ? null : field === sortField &&
                                sortOrder === 'asc' ? (
                                <span>
                                  <ChevronUpIcon
                                    style={{ marginLeft: '3px' }}
                                  />
                                </span>
                              ) : (
                                <span>
                                  <ChevronDownIcon
                                    style={{ marginLeft: '3px' }}
                                  />
                                </span>
                              )}{' '}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {currentOrders.map((order, index) => (
                          <tr key={index}>
                            <td
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                dispatch(fetchConsultantOrderDisplay(order.id));
                                dispatch(
                                  fetchConsultantTextOrderDisplay(order.id)
                                );
                                history.push('/Consultant/DetailsDeLaCommande');
                              }}
                            >
                              <div style={{ display: 'flex' }}>
                                <div style={{ width: '350px' }}>
                                  {order?.title}
                                </div>
                              </div>
                            </td>
                            <td>
                              <span>
                                {order.deadline
                                  ? formatDate(order.deadline)
                                  : '-'}
                              </span>
                            </td>

                            <td>
                              <ProgressBar
                                amount={order.deliveredTextsAmount}
                                total={order.textAmount}
                              />
                            </td>
                            <td>
                              <ProgressBar
                                amount={order.validatedTextsAmount}
                                total={order.textAmount}
                              />
                            </td>
                            <td>
                              <ProgressBar
                                amount={order.deployedTextsAmount}
                                total={order.textAmount}
                              />
                            </td>
                            <td>
                              {!order.areAllTextValidated && (
                                <CircularBar
                                  finishedAt={order.finishedAt}
                                  orderId={order.id}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <span className="me-2">{t('Afficher')}</span>

                <div className="dropdown" style={{ width: '70px' }}>
                  <button
                    className="btn dropdown-toggle dropdown-custom w-100"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {ordersPerPage}
                    <span>
                      <ChevronDownIcon />
                    </span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {pageNbOptions
                      .filter((nb) => nb !== ordersPerPage)
                      .map((item, index) => (
                        <li key={index}>
                          <button
                            className="dropdown-item"
                            onClick={() => setOrdersPerPage(item)}
                          >
                            {item}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div>
                <Paginator
                  isLoading={isLoading}
                  listLength={filteredOrders.length}
                  currentPage={currentPage}
                  pageNumbers={pageNumbers}
                  nbElementsPerPage={ordersPerPage}
                  goToPage={(page) => setCurrentPage(page)}
                  hideTotalPageCount={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
