import React, { useState } from 'react';
import { pageNbOptions } from 'utils/Constants';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

const CustomSearchBox = ({
  searchValue,
  setSearchValue,
  elementsPerPage,
  setElementsPerPage,
  pageNbOptions,
  t,
}) => {
  return (
    <div>
      <div className="box-list-ui justify-content-end">
        <div className="box-input-text pe-0">
          <div className="input-group input-cs w-100 pe-0">
            <div className="input-group-text">
              <SearchIcon />
            </div>
            <input
              type="text"
              className="form-control ps-0"
              value={searchValue}
              placeholder={t('Rechercher')}
              onChange={(event) => setSearchValue(event.target.value)}
            />
          </div>
        </div>
        <div className="d-inline-flex align-items-center">
          <span className="me-2">{t('Afficher les résultats')}</span>
          <div className="dropdown" style={{ width: '70px' }}>
            <button
              className="btn dropdown-toggle dropdown-custom w-100"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {elementsPerPage}
              <span>
                <ChevronDownIcon />
              </span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {pageNbOptions
                .filter((nb) => nb !== elementsPerPage)
                .map((item, index) => (
                  <li key={index} onClick={() => setElementsPerPage(item)}>
                    <button className="dropdown-item">{item}</button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSearchBox;
