import React from 'react';
import { DropdownMenu } from 'components/shared/DropDown/DropDownMenu';
// import pdf_FR from 'components/TutorialDocuments/Typix_Tutorial_Version_FR_client.pdf';
// import pdf_EN from 'components/TutorialDocuments/Typix_Tutorial_Version_EN_client.pdf';

// import pdf_EN_WRITER from 'components/TutorialDocuments/Tuto_process_Typix_global_EN_writer.pdf';
// import pdf_FR_WRITER from 'components/TutorialDocuments/Tuto_process_Typix_global_FR_writer.pdf';
// import pdf_Facturation_FR from 'components/TutorialDocuments/Tuto_Facturation_FR_writer.pdf';
// import pdf_Mise_En_Ligne from 'components/TutorialDocuments/Tuto_Mise_en_ligne_des_contenus.pdf';

export const DownloadMenu = ({ t, user }) => {
  const clientOptions = [
    {
      href: 'https://typixwebsitedocs.blob.core.windows.net/tutorial/Typix_Tutorial_Version_FR_client.pdf',
      download: 'Typix_Tutorial_Version_FR_client',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>{t('Tuto process Typix global')} (FR)</span>
        </div>
      ),
    },
    {
      href: 'https://typixwebsitedocs.blob.core.windows.net/tutorial/Typix_Tutorial_Version_EN_client.pdf',
      download: 'Typix_Tutorial_Version_EN_client',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>{t('Tuto process Typix global')} (EN)</span>
        </div>
      ),
    },
    {
      href: 'https://typixwebsitedocs.blob.core.windows.net/faq/Client/Comment indiquer le statut de mise en ligne des contenus à créer.pdf',
      download: 'Tuto_Mise_en_ligne_des_contenus',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>{t('Tuto mise en ligne des contenus')}</span>
        </div>
      ),
    },
    {
      href: 'https://typixwebsitedocs.blob.core.windows.net/faq/Comment modifier mon mot de passe.pdf',
      download: 'Tuto_Mise_en_ligne_des_contenus',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>{t('Modification mot de passe')}</span>
        </div>
      ),
    },
    {
      href: 'https://typixwebsitedocs.blob.core.windows.net/faq/Client/Le test ne me convient pas.pdf',
      download: 'Tuto_Mise_en_ligne_des_contenus',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>{t('Le test ne me convient pas')}</span>
        </div>
      ),
    },
    {
      href: 'https://typixwebsitedocs.blob.core.windows.net/faq/Client/Comment valider un contenu dans Typix.pdf',
      download: 'Tuto_Mise_en_ligne_des_contenus',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>{t('Comment valider un contenu dans Typix ?')} </span>
        </div>
      ),
    },
  ];

  const writerOptions = [
    {
      href: 'https://typixwebsitedocs.blob.core.windows.net/tutorial/Tuto_process_Typix_global_EN_writer.pdf',
      download: 'Tuto_process_Typix_global_EN_writer',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>{t('Tuto process Typix global')} (EN)</span>
        </div>
      ),
    },
    {
      href: 'https://typixwebsitedocs.blob.core.windows.net/tutorial/Tuto_process_Typix_global_FR_writer.pdf',
      download: 'Tuto_process_Typix_global_FR_writer',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>{t('Tuto process Typix global')} (FR)</span>
        </div>
      ),
    },
  ];

  return (
    <>
      {user && user.role === 'CLIENT' ? (
        <DropdownMenu role={'CLIENT'} options={clientOptions} />
      ) : user && user.role === 'WRITER' ? (
        <DropdownMenu role={'WRITER'} options={writerOptions} />
      ) : null}
    </>
  );
};

export default DownloadMenu;
