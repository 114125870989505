import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { writerOrderListSelector } from 'store/Writer/OrderList';
import OrdersStatusTable from 'components/shared/Table/OrdersStatusTable';
import { WRITER_VALIDATED_ORDERS_COLUMN } from 'utils/Constants';
import { ORDERSTATUS } from 'utils/Constants';

function WriterValidatedOrders() {
  const columns = WRITER_VALIDATED_ORDERS_COLUMN;
  const { t } = useTranslation();

  //selected the validated orders from the orderlist store
  const { validatedOrders, isLoading } = useSelector(writerOrderListSelector);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  return (
    <>
      <OrdersStatusTable
        title={t('Commandes validées')}
        columns={columns}
        orders={validatedOrders}
        isLoading={isLoading}
        orderStatus={ORDERSTATUS.VALID}
      />
    </>
  );
}

export default WriterValidatedOrders;
