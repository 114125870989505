import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import reportWebVitals from './reportWebVitals';

import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import './i18n';

import axios from 'axios';
import { persistor, store } from './store';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';

axios.defaults.baseURL = 'http://docker-dev.monetoring.com:9000/';
//axios.defaults.baseURL = 'https://localhost:5001';
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  shadows: ['none'],
  palette: {
    background: {
      paper: '#F5F5F5',
    },

    primary: {
      main: '#FFE426',
    },
    white: { main: '#FFFFFF' },
    black: { main: '#000000' },
    green: { main: '#a1dc94' },
    text: {
      secondary: '#828282',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: 'none',
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        slotProps: {
          paper: {
            sx: {
              backgroundColor: '#fff',
              overflow: 'hidden',
              borderRadius: '1rem',
              color: '#212529',
              backgroundClip: 'padding-box',
              boxShadow: '0 1px 10px rgba(0, 0, 0, 0.2)',
              minWidth: '13rem',
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingY: '4px',
          ':hover': {
            backgroundColor: 'rgba(255, 228, 38, 0.4)',
          },
        },
      },
    },
  },
});

root.render(
  <Suspense fallback="loading">
    <BrowserRouter basename={baseUrl}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
