import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { utils as xlsxUtils, writeFile as xlsxWriteFile } from 'xlsx';

import axiosPrivate from 'config/axiosPrivate';

import { setOrderId, setOrderType } from 'store/Admin/Analyser';
import {
  fetchAdminOrderDisplay,
  fetchAdminOrderLineDisplay,
  fetchAdminTextOrderDisplay,
} from 'store/Admin/OrderDisplay/features';
import { adminWebsiteDetailsSelector } from 'store/Admin/WebsiteDisplay';

import CheckBox from 'components/shared/CheckBox';
import { CustomTooltip } from 'components/shared/CustomTooltip';
import { CustomDropdownReadOnly } from 'components/shared/DropDown/CustomDropdown';
import ErrorComponent from 'components/shared/ErrorComponent';
import OrderTypeFilter from 'components/shared/Filter/OrderTypeFilter';
import { Loader } from 'components/shared/Loader';
import { Paginator } from 'components/shared/Paginator';
import { ProgressBar } from 'components/shared/ProgressBar';
import DownloadModal from 'components/shared/modal/DownloadModal';
import { exportRoadMap } from 'helpers/ExportRoadmapHelpers';
import { filterOrders } from 'helpers/FilterOrders';
import { sortByField } from 'helpers/SortingHelper';
import { handleSortingChange } from 'helpers/SortingHelper';

import { formatDate } from 'utils/DateUtils';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { FILTER_ORDER_TYPE_LIST, ORDER_TYPE } from 'utils/Constants';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

import './order.css';
import { Button } from '@mui/material';

const pageNbOptions = [10, 15, 20, 50, 100];
const fieldWidths = {
  title: '26%',
  deadline: '20%',
  type: '10%',
  selected: '5%',
};

export default function OrdersListOfWebsite() {
  let { urlSite, websiteId, account, ordersList, isListLoading, isListError } =
    useSelector(adminWebsiteDetailsSelector);

  const { t } = useTranslation();

  const columns = [
    {
      label: t('Titre de la commande'),
      field: 'title',
      sortable: true,
      sort: 'asc',
    },
    { label: t('Type'), field: 'type', sortable: false },

    { label: t('Deadline'), field: 'deadline', sortable: true, sort: 'asc' },
    { label: t('Rédacteurs'), field: 'writers', sortable: false },
    {
      label: t('Textes livrés'),
      field: 'deliveredTextsAmount',
      sortable: true,
      sort: 'asc',
    },
    {
      label: t('Textes validés'),
      field: 'validatedTextsAmount',
      sortable: true,
      sort: 'asc',
    },
    {
      label: '',
      field: 'selected',
      sortable: false,
    },
    {
      label: '',
      field: 'downloadAll',
      sortable: false,
    },
  ];
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);

  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [allOrders, setAllOrders] = useState([]);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedType, setSelectedType] = useState(FILTER_ORDER_TYPE_LIST[0]);

  const [pageNumbers, setPageNumbers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [titleSearch, setTitleSearch] = useHistoryState('titleSearch', '');

  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedOrders, setSelectedOrders] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [showDownloadModal, setShowDownloadModel] = useState(false);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  useEffect(() => {
    setAllOrders(ordersList);
  }, [ordersList]);

  useEffect(() => {
    if (!allOrders) return;

    const filtered = filterOrders(allOrders, selectedType, titleSearch);
    if (titleSearch && titleSearch.length > 0) {
      setCurrentPage(1);
    }
    setFilteredOrders(filtered);
  }, [selectedType, titleSearch, allOrders]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  // const setPage = (pageNum) => {
  //   setCurrentPage(pageNum)
  // }

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSorting = (sortField, sortOrder) => {
    const sorted = sortByField(allOrders, sortField, sortOrder);
    setAllOrders(sorted);
  };

  function onAllSelectedChange(isChecked) {
    setIsAllSelected(isChecked);

    let newSet = new Set();
    if (isChecked) {
      allOrders.forEach((order) => newSet.add(order.id));
    }
    setSelectedOrders(newSet);
  }

  function onOrderSelectedChange(orderId, isChecked) {
    let newSet = new Set(selectedOrders);

    if (isChecked) {
      newSet.add(orderId);
    } else {
      newSet.delete(orderId);
    }

    setSelectedOrders(newSet);
    if (newSet.size > 1) setIsAllSelected(true);
    if (newSet.size === 0) setIsAllSelected(false);
  }

  function exportRoadMapBywebsite() {
    if (selectedOrders.size === allOrders.length || selectedOrders.size === 0) {
      const timeout = setTimeout(() => setShowDownloadModel(true), 500);
      axiosPrivate
        .get('/commands/' + websiteId + '/getStatsByWebsite')
        .then((res) => {
          const workBook = xlsxUtils.book_new();
          const formattedData = res.data.map((order) => {
            return {
              [t('Mot clé')]: order.keyword,
              [t('Intitulé commande')]: order.titled,
              [t('Titre')]: order.titleTag,
              [t('Meta-description')]: order.metaDescriptionTag,
              [t('URL')]: order.url,
              [t('Nombre de mots gérés')]: order.wordsManagedNumber,
              [t('Min/Max')]: `${order.minWords}/${order.maxWords}`,
              [t('Nombre de mots rédigés')]: order.totalNumberOfTextAdded,
              [t('Status texte')]: order.status,
              [t('Date de livraison')]: order.deliveredAt,
              [t('Score')]: order.analyseResult,
              [t('Date deadline validation client')]: order.deadline,
              [t('Date de validation')]: order.validateAt,
              [t('ONLINE')]: order.deployed === true ? t('OUI') : t('NON'),
              [t('Texte')]: order.text,
            };
          });
          const xlsx = xlsxUtils.json_to_sheet(formattedData);
          xlsxUtils.book_append_sheet(workBook, xlsx, 'Orders');
          xlsxWriteFile(workBook, `Export roadmap - ${account}.xlsx`);
          clearTimeout(timeout);
          setShowDownloadModel(false);
        });
    } else {
      exportRoadMap(selectedOrders, null, account, t);
    }
  }

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{urlSite}</h1>
      </div>
      <div className="box-info-header" style={{ marginBottom: 10 }}>
        <div className="b-child-1">
          <h2>{t('Liste des commandes')}</h2>
        </div>
        <div className="b-child-2">
          <div className="d-flex  justify-content-end">
            <div className="box-input-text pe-0">
              <div className="input-group input-cs w-100 pe-0">
                <div className="input-group-text">
                  <SearchIcon />
                </div>
                <input
                  value={titleSearch}
                  onChange={(event) => setTitleSearch(event.target.value)}
                  type="text"
                  className="form-control ps-0"
                  placeholder={t('Rechercher')}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className="me-2">{t('Afficher les résultats')}</span>
              <div className="dropdown" style={{ width: '70px' }}>
                <button
                  className="btn dropdown-toggle dropdown-custom w-100"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {ordersPerPage}
                  <span>
                    <ChevronDownIcon />
                  </span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {pageNbOptions
                    .filter((nb) => nb !== ordersPerPage)
                    .map((item, index) => (
                      <li key={index}>
                        <button
                          className="dropdown-item"
                          onClick={() => setOrdersPerPage(item)}
                        >
                          {item}
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-wrapper">
        {isListLoading ? (
          <Loader />
        ) : isListError ? (
          <ErrorComponent />
        ) : (
          <div className="table-responsive">
            <div>
              <table className="table-lists">
                <thead>
                  <tr>
                    {columns.map(({ label, field, sortable }) => (
                      <th
                        style={{
                          cursor: sortable ? 'pointer' : 'default',
                          width: fieldWidths[field] || '',
                        }}
                        onClick={
                          sortable
                            ? () =>
                                handleSortingChange(
                                  field,
                                  sortField,
                                  sortOrder,
                                  setSortField,
                                  setSortOrder,
                                  handleSorting
                                )
                            : null
                        }
                        key={field}
                      >
                        {label}
                        {!sortable ? null : field === sortField &&
                          sortOrder === 'asc' ? (
                          <span>
                            <ChevronUpIcon style={{ marginLeft: '3px' }} />
                          </span>
                        ) : (
                          <span>
                            <ChevronDownIcon style={{ marginLeft: '3px' }} />
                          </span>
                        )}
                        {field === 'selected' ? (
                          <CheckBox
                            name="select-all"
                            tick={selectedOrders.size === allOrders.length}
                            onCheck={(e) =>
                              onAllSelectedChange(e.target.checked)
                            }
                          />
                        ) : field === 'downloadAll' ? (
                          isAllSelected ? (
                            <div style={{ position: 'relative' }}>
                              <div className="d-flex justify-content-center">
                                <span
                                  className="bt-download-circle-selected"
                                  onClick={() => exportRoadMapBywebsite()}
                                ></span>
                              </div>
                              <CustomTooltip
                                title={t(
                                  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune"
                                )}
                                placement="top"
                              >
                                <button
                                  type="button"
                                  className="btn-circle"
                                  style={{
                                    position: 'absolute',
                                    top: '-5px',
                                    right: '-5px',
                                  }}
                                ></button>
                              </CustomTooltip>
                            </div>
                          ) : (
                            <div style={{ position: 'relative' }}>
                              <div className="d-flex justify-content-center">
                                <span
                                  className="bt-download-circle"
                                  onClick={() => exportRoadMapBywebsite()}
                                ></span>
                              </div>
                              <CustomTooltip
                                title={t(
                                  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune"
                                )}
                                placement="top"
                              >
                                <button
                                  type="button"
                                  className="btn-circle"
                                  style={{
                                    position: 'absolute',
                                    top: '-5px',
                                    right: '-5px',
                                  }}
                                ></button>
                              </CustomTooltip>
                            </div>
                          )
                        ) : null}
                        {field === 'type' ? (
                          <OrderTypeFilter
                            orderTypeList={FILTER_ORDER_TYPE_LIST}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                            handleClick={handleClick}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                          />
                        ) : null}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order, index) => (
                    <tr key={order.id}>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          dispatch(setOrderId(order.id));
                          dispatch(setOrderType(order.type));
                          dispatch(fetchAdminOrderDisplay(order.id));
                          dispatch(fetchAdminTextOrderDisplay(order.id));
                          dispatch(fetchAdminOrderLineDisplay(order.id));
                          history.push('/Admin/DetailsDeLaCommande');
                        }}
                      >
                        <p className="text-left">{order?.title}</p>
                      </td>
                      <td>
                        {
                          ORDER_TYPE.find((elem) => elem.type === order.type)
                            .name
                        }
                      </td>

                      <td>
                        <span>
                          {order.deadline ? formatDate(order.deadline) : '-'}
                        </span>
                      </td>
                      <td>
                        <div>
                          {order.writers.length === 0 ? (
                            <Button disabled> {t('0 rédacteurs')} </Button>
                          ) : (
                            <>
                              <Button
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                onClick={(e) => handleClick(index, e)}
                              >
                                <img
                                  src="/Images/icon-users-down.png"
                                  alt="icon-users-down"
                                />
                              </Button>
                              <CustomDropdownReadOnly
                                handleClose={handleClose}
                                anchorEl={anchorEl && anchorEl[index]}
                                list={order.writers}
                                getOptionLabel={(option) => {
                                  return option.mail;
                                }}
                              />
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.deliveredTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.validatedTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <CheckBox
                          key={index}
                          name={order.title}
                          tick={selectedOrders.has(order.id)}
                          onCheck={(e) =>
                            onOrderSelectedChange(order.id, e.target.checked)
                          }
                        />
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <span
                            className="bt-download-circle "
                            onClick={() =>
                              exportRoadMap(
                                selectedOrders,
                                order.id,
                                account,
                                t
                              )
                            }
                          ></span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <Paginator
        isLoading={isListLoading}
        listLength={filteredOrders.length}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        nbElementsPerPage={ordersPerPage}
        goToPage={(page) => setCurrentPage(page)}
      />

      <DownloadModal
        open={showDownloadModal}
        handleClose={() => setShowDownloadModel(false)}
      />
    </div>
  );
}
