import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axiosPrivate from 'config/axiosPrivate';
import TokenService from 'config/token.service';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { updateUser, UserSessionSelector } from 'store/UserSession';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { CustomSWitch } from 'components/shared/CustomSwitch';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';
import { CustomAutocompleteMultipleChoice } from 'components/shared/DropDown/CustomAutocompleteMultipleChoice';
import { InputField } from 'components/shared/InputField';
import { profileSchema } from 'utils/schemas';
import { MAIN_LANGUAGES, ROLES } from 'utils/Constants';

import 'pages/shared/Profile.css';
import './styles.css';

const UPDATE_PROFILE_URL = '/user/updateProfile';
const API = {
  getAvailableThematics: '/thematic/all',
  getAvailableLanguages: '/language/all',
};

function WriterProfile() {
  const { user } = useSelector(UserSessionSelector);
  const history = useHistory();
  const { t } = useTranslation();

  const [showPassword] = useState(false);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [availableLanguagesList, setAvailableLanguagesList] = useState([]);

  const [mainWriterLanguages, setMainWriterLanguages] = useState([]);
  const [minorWriterLanguages, setMinorWriterLanguages] = useState([]);

  const [writerThematics, setWriterThematics] = useState([]);

  const [available, setAvailable] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const namelInputRef = useRef(null);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    document.title = `${t('Typix - Mon Profile')}`;
    axiosPrivate
      .get(API.getAvailableLanguages)
      .then((res) => setAvailableLanguagesList(res.data));
    axiosPrivate
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));
  }, [t]);

  useEffect(() => {
    if (user) {
      setAvailable(user.isAvailable);
      setWriterThematics(user.thematics);
      setMainWriterLanguages(
        user.languages.filter((x) => MAIN_LANGUAGES.includes(x.language))
      );
      setMinorWriterLanguages(
        user.languages.filter((x) => !MAIN_LANGUAGES.includes(x.language))
      );
    }
  }, [user]);

  const callbackFromThematicMultiSelect = (newList) => {
    setWriterThematics(newList);
  };

  const callbackFromLanguagesMultiSelect = (newList) => {
    setMinorWriterLanguages(newList);
  };

  const findLanguageIndex = (language) => {
    return mainWriterLanguages.findIndex((element) =>
      element.language.toLowerCase().includes(language.toLowerCase())
    );
  };

  const checkLanguage = (language) => {
    let index = findLanguageIndex(language);
    let array = [...mainWriterLanguages];
    if (index > -1) {
      array.splice(index, 1);
    } else {
      let item = availableLanguagesList.find((element) =>
        element.language.toLowerCase().includes(language.toLowerCase())
      );
      array.push(item);
    }

    setMainWriterLanguages(array);
  };

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      await profileSchema.validate(data, {
        abortEarly: false,
      });

      const userData = {
        id: user.id,
        name: data.name,
        companyName: user.companyName,
        address: user.address,
        limit: user.limit,
        analysis: user.analysis,
        isAvailable: available,
        mail: data.mail,
        phoneNumber: data.phoneNumber,
        password: data.password ? data.password : null,
        languages: mainWriterLanguages.concat(minorWriterLanguages),
        thematics: writerThematics,
        role: ROLES.WRITER,
      };
      axiosPrivate
        .put(UPDATE_PROFILE_URL, userData)
        .then((res) => {
          if (res.status === 202) {
            TokenService.setUser(res.data);
            dispatch(updateUser(res.data.user));
          } else {
            dispatch(updateUser(res.data));
          }
          setErrorSuccessMsg(
            `${t('Votre profil a été mis à jour avec succès.')}`
          );
          setIsSuccess(true);
          setErrorSuccess(true);
        })
        .catch(() => {
          setErrorSuccessMsg(
            `${t(
              "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard."
            )}`
          );
          setIsSuccess(false);
          setErrorSuccess(true);
        });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = t(error.message);
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <div className="content-center">
      <div className="box-header-top">
        <span onClick={history.goBack} className="bt-circle me-3"></span>
        <h1>{t('Mon profil')}</h1>
      </div>
      <div className="box-over" style={{ position: 'relative' }}>
        <div className="box-img-bg bg-12" />
        <div className="box-img-bg bg-5" style={{ right: '-49px' }} />
        <div className="box-img-bg bg-6" style={{ top: '-56px' }} />
        <Form ref={formRef} initialData={user} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-md-5 box-screen">
              <div className="box-wrapper">
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          ref={namelInputRef}
                          type="text"
                          name="name"
                          label={t('Nom/prénom')}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          type="text"
                          name="mail"
                          label={t('E-mail')}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          type="tel"
                          label={t('Téléphone')}
                          name="phoneNumber"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          label={t('Mot de passe')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div>
                        <InputField
                          name="confirmPassword"
                          type="text"
                          label={t('Confirmer le mot de passe')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-more">
                  *
                  {t(
                    'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.'
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7 box-screen">
              <div
                className="box-wrapper"
                style={{ height: '485px', overflowY: 'auto' }}
              >
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="row row-mb">
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <div>
                            <InputField
                              ref={namelInputRef}
                              type="text"
                              name="id"
                              label={t('ID rédacteur')}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="label-title">{t('Tarif/Mot')}*</div>
                          <div className="number-wrapper input-group input-grl">
                            <span
                              style={{ paddingRight: '5px' }}
                              className="input-group-text"
                            >
                              €
                            </span>
                            <input
                              disabled={true}
                              value={(
                                (user.minRate + user.maxRate) /
                                2
                              ).toFixed(2)}
                              type="number"
                              min="0"
                              max="0.99"
                              className="form-control form-control-valid form-ct"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row-mb" style={{ marginTop: '35px' }}>
                      <div className="col-12 col-md-12">
                        <div className="label-title">
                          {t('Thématiques d’expertise')}
                        </div>
                        <CustomChipsAutocomplete
                          callbackFromMultiSelect={
                            callbackFromThematicMultiSelect
                          }
                          value={writerThematics}
                          disabled={!availableThematicsList}
                          list={availableThematicsList}
                          setValue={(option) => setWriterThematics(option)}
                          getOptionLabel={(option) => {
                            return t(option.thematic);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row row-mb mt-4">
                      <div className="col-12 col-md-12">
                        <div className="label-title mb-2">{t('Langues')} :</div>
                        <div className="box-wrapper-cols">
                          {MAIN_LANGUAGES.slice(0, 3).map((language, key) => (
                            <div key={key} className="cols">
                              <div className="box-checkbox box-list-check">
                                <input
                                  checked={findLanguageIndex(language) > -1}
                                  onChange={() => checkLanguage(language)}
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                <div> {t(language)}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="box-wrapper-cols mt-4">
                          {MAIN_LANGUAGES.slice(3).map((language, key) => (
                            <div key={key} className="cols">
                              <div className="box-checkbox box-list-check">
                                <input
                                  checked={findLanguageIndex(language) > -1}
                                  onChange={() => checkLanguage(language)}
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                <div> {t(language)}</div>
                              </div>
                            </div>
                          ))}
                          <div className="col-6">
                            <CustomAutocompleteMultipleChoice
                              callbackFromMultiSelect={
                                callbackFromLanguagesMultiSelect
                              }
                              value={minorWriterLanguages}
                              disabled={!availableLanguagesList}
                              list={availableLanguagesList.filter(
                                (x) =>
                                  !MAIN_LANGUAGES.map((lan) =>
                                    lan.toLowerCase()
                                  ).includes(x.language.toLowerCase())
                              )}
                              setValue={(option) =>
                                setMinorWriterLanguages(option)
                              }
                              getOptionLabel={(option) => {
                                return t(option.language);
                              }}
                            />
                          </div>
                        </div>
                        <div className="row row-mb" style={{ top: '570px' }}>
                          <div className="col-12 col-md-12 ">
                            <div className="label-title mb-2">
                              {t('Disponibilité')} :
                            </div>

                            <div className="box-list-check">
                              <CustomSWitch
                                value={available}
                                toggleValue={() => setAvailable(!available)}
                              />
                              <div>
                                {available
                                  ? `${t('disponible')}`
                                  : `${t('indisponible')}`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button className="bt-submit-y float-end" type="submit">
              {' '}
              {t('Sauvegarder')}
            </button>
          </div>
        </Form>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
}

export default WriterProfile;
