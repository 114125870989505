import { specialCharsToEntities } from 'utils/StringUtils';
function removeDuplicatesAndEmptyStrings(arr) {
  // Create a new Set to store unique values
  const uniqueValues = new Set();

  // Iterate over the array and add non-empty strings to the Set
  for (const item of arr) {
    if (item !== '' && !uniqueValues.has(item)) {
      uniqueValues.add(item);
    }
  }

  // Convert the Set back to an array
  const resultArray = [...uniqueValues];

  return resultArray;
}
export function extractWords(text) {
  // Remove (1) from the text
  const cleanedText = text.replace(/\(\d\)/g, '').toLowerCase();

  // Split the cleaned text into an array of words
  const words = cleanedText.split(/\s+/);
  const cleanedWords = words.map((word) => word.replace(',', ''));
  // Remove duplicates using Set
  const uniqueWords = [...new Set(cleanedWords)];

  return uniqueWords;
}
export function filterkeywordsStuffing(objects) {
  // Define a function to check the conditions for each object
  const meetsConditions = (obj) => {
    return obj.tfPercentage > 0.04 && obj.word.length > 1;
  };

  // Use the filter method to get the objects that meet the conditions
  const filteredObjects = objects.filter(meetsConditions);

  const sortedObjects = filteredObjects.sort(
    (a, b) => b.tfPercentage - a.tfPercentage
  );

  return sortedObjects;
}
export function has75PercentCommonChars(word1, word2) {
  const minLength = Math.min(word1.length, word2.length);
  const commonCharCount = word1.split('').reduce((count, char, index) => {
    return count + (word2[index] === char ? 1 : 0);
  }, 0);

  return commonCharCount >= 0.75 * minLength;
}

export function filterWordsCommon75(wordsList) {
  const filteredWords = [];
  for (let i = 0; i < wordsList.length; i++) {
    let omitWord = false;
    for (let j = 0; j < wordsList.length; j++) {
      if (
        i !== j &&
        has75PercentCommonChars(wordsList[i].word, wordsList[j].word)
      ) {
        const lengthDifference = Math.abs(
          wordsList[i].word.length - wordsList[j].word.length
        );
        if (
          lengthDifference >= 3 &&
          wordsList[i].word.length < wordsList[j].word.length
        ) {
          omitWord = true;
          break;
        }
      }
    }
    if (!omitWord) {
      filteredWords.push(wordsList[i]);
    }
  }

  return filteredWords;
}

function extractWordsListWith75PercentCommonChars(listA, listB) {
  const resultList = [];

  for (const wordA of listA) {
    // Check if wordA shares 75% common characters with at least one word in listB
    if (
      listB.some((wordB) => {
        if (has75PercentCommonChars(wordA, wordB)) {
          // Check the absolute difference in word lengths
          const lengthDifference = Math.abs(wordA.length - wordB.length);
          // Exclude words with a difference of more than 5 characters
          if (lengthDifference <= 3) {
            resultList.push(wordA);
          }
        }
      })
    );
  }

  return resultList;
}
export function cleanWordListTotal(originalWordList) {
  try {
    const regex = /\([^)]+\)|\d+/g; // Regular expression to match words in parentheses and numbers
    const cleanedWords = originalWordList
      .replace(regex, '')
      .split(',')
      .map((word) => word.replace(',', '').trim())
      .filter((word) => word.includes(' '));
    return cleanedWords;
  } catch (error) {
    console.log(error);
  }
}
function removeWhitespace(string) {
  return string.replace(/^\s+|\s+$/g, '');
}

export function getTheAddedText(intialText, newText) {
  let newContent = removeWhitespace(intialText);
  let oldContent = removeWhitespace(newText);

  const oldContentArray = oldContent.split(/\s+/);
  let textInput = newContent
    .split(/\s+/)
    .filter((x) => {
      if (oldContentArray.indexOf(x) !== -1) {
        const index = oldContentArray.indexOf(x);
        oldContentArray.splice(index, 1);
        return false;
      }
      return true;
    })
    .join(' ');
  return oldContentArray.join(' ');
}

export function getPotentialKeywordStuffingVarients(keywordsStuffed) {
  const keywordsStuffedVarients = removeDuplicatesAndEmptyStrings(
    generateWordFamilies(removeDuplicatesAndEmptyStrings(keywordsStuffed))
  );
  const codedkeywordsStuffedVarients = keywordsStuffedVarients.map(
    (keywordsStuffedVarient) => specialCharsToEntities(keywordsStuffedVarient)
  );
  return codedkeywordsStuffedVarients;
}

export function replaceTextInColor(text, array, color) {
  let content = text;
  array.map((x) => {
    content = content.replace(
      new RegExp(
        `(?<![A-Za-zÀ-ÖØ-öø-ÿ])(${x})(?![A-Za-zÀ-ÖØ-öø-ÿ=])(?![^<>]*>)`,
        'gi'
      ),
      `<span style="color:${color}">` + x + '</span>'
    );
  });
  while (
    content.includes(
      `<span style="color:${color}"><span style="color:${color}">`
    ) &&
    content.includes('</span></span>')
  ) {
    content = content.replaceAll(
      `<span style="color:${color}"><span style="color:${color}">`,
      `<span style="color:${color}">`
    );
    content = content.replaceAll('</span></span>', '</span>');
  }
  return content;
}

function splitTextIntoWords(text) {
  // Use regular expression to split the text into words
  return text.split(/\s+/);
}

function combineAndRemoveDuplicates(list1, list2) {
  const combinedList = list1.concat(list2);
  const uniqueList = Array.from(new Set(combinedList));
  return uniqueList;
}

function generateWordFamilies(wordList) {
  const frenchPrefixes = [
    '',
    're',
    'pré',
    'in',
    'un',
    'dis',
    'sur',
    'sous',
    'mal',
    'ex',
    'trans',
    'auto',
    'pro',
    'inter',
    'anti',
    'bi',
    'tri',
    'co',
    'extra',
    'en',
    'sous',
    'super',
    'sur',
    'hyper',
    'ultra',
    'infra',
    'mono',
    'multi',
    'para',
    'micro',
    'macro',
  ];
  const frenchSuffixes = [
    '',
    's',
    'x',
    'ment',
    'age',
    'tion',
    'eur',
    'iste',
    'aire',
    'eur',
    'ette',
    'tion',
    'ier',
    'eur',
    'oir',
    'oire',
    'oire',
    'ette',
    'ion',
    'tion',
    'oir',
    'oire',
    'isme',
    'age',
    'ance',
    'ience',
  ];

  let wordFamilies = [];

  for (const word of wordList) {
    const family = [];

    for (const prefix of frenchPrefixes) {
      for (const suffix of frenchSuffixes) {
        const newWord = prefix + word + suffix;
        family.push(newWord);
      }
    }

    wordFamilies = [...wordFamilies, ...family, word];
  }
  return wordFamilies;
}
