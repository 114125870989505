import axiosEvaluation from 'config/axiosEvaluation';
import React, { useCallback, useEffect, useState } from 'react';
import { ROLES } from 'utils/Constants';
import { Loader } from 'components/shared/Loader';
import {
  Box,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { clientOrderDisplaySelector } from 'store/Client/OrderDisplay';
import { useSelector } from 'react-redux';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';

import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  textField: {
    width: '150%',
    '& .MuiFilledInput-root': {
      backgroundColor: '#f0f0f0', // Set background color
      '&:hover, &:active': {
        backgroundColor: '#f0f0f0', // Prevent hover and active effects
      },
    },
    '& .Mui-focused, & .Mui-hover': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23) !important', // Grey color value
        borderWidth: '1px !important', // Maintain the width
      },
    },
    '& .MuiFilledInput-input': {
      color: 'black', // Set text color to black
    }, // Disable pointer events
    '& .MuiOutlinedInput-input': {
      color: 'black',
      cursor: 'default',
    },
  },
}));

function ClientEvaluation({ user, orderId, t, ...others }) {
  const classes = useStyles();

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [noteClient, setNoteClient] = useState(0);
  const [evaluationModalToShow, setEvaluationModalToShow] = useState('');
  const [message, setMessage] = useState('');
  const [evaluationStatus, setEvaluationStatus] = useState('idle');
  const { textList } = useSelector(clientOrderDisplaySelector);
  const [formData, setFormData] = useState([]);

  const getRatingsForCommand = useCallback(async () => {
    setEvaluationStatus('loading');
    try {
      if (user && user.role === ROLES.CLIENT) {
        const response = await axiosEvaluation.get(
          `/client/evaluations/getCommandEvaluation?command=${orderId}`
        );

        setNoteClient(response.data.note);
        setMessage(response.data.comment);
        setEvaluationStatus('succeeded');
      }
    } catch (error) {}
  }, [orderId, user]);

  useEffect(() => {
    setFormData(
      textList.map((item) => ({
        id: item.id,
        textTitle: item.keyword,
        writerCommandId: item.writerCommandId,
      }))
    );
  }, [textList]);

  useEffect(() => {
    getRatingsForCommand();
  }, [getRatingsForCommand]);

  return (
    <div>
      {evaluationStatus === 'succeeded' && noteClient > 0 && (
        <>
          <div className="label-title mt-3">
            {t('Votre avis pour la commande')}{' '}
          </div>
          <Table style={{ width: '50%' }}>
            <TableBody>
              <TableRow style={{ border: 'none' }}>
                <TableCell style={{ width: '28%', border: 'none' }}>
                  {' '}
                  <Box
                    sx={{
                      marginLeft: 1,
                      marginRight: 1,
                      fontSize: 16,
                    }}
                  >
                    <Rating
                      name={`client rating`}
                      readOnly
                      defaultValue={noteClient}
                      precision={0.5}
                      emptyIcon={
                        <StarIcon style={{ opacity: 0.3 }} fontSize="inherit" />
                      }
                      icon={<StarIcon style={{ fontSize: 'inherit' }} />}
                    />
                    <Tooltip
                      title={
                        Number.isInteger(noteClient)
                          ? noteClient
                          : noteClient?.toFixed(2)
                      }
                      placement="top"
                    >
                      <button
                        type="button"
                        className="btn-circle"
                        style={{ marginLeft: '5px', marginBottom: '3px' }}
                      ></button>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
              {message !== null && message.length > 0 && (
                <TableRow>
                  <TableCell style={{ width: '28%', border: 'none' }}>
                    {' '}
                    <TextField
                      id="filled-multiline-flexible"
                      placeholder={t('Votre commentaire')}
                      multiline
                      readOnly
                      value={message}
                      maxRows={6}
                      minRows={4}
                      className={classes.textField}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </>
      )}
      {evaluationStatus === 'loading' && (
        <>
          {' '}
          <div className="label-title mt-3">
            {t('Evaluation de la commande')}{' '}
          </div>
          <Loader />
        </>
      )}{' '}
      {evaluationModalToShow === 'ERRORSUCCESS' && (
        <ErrorSuccesModal
          open={errorSuccess}
          message={errorSuccessMsg}
          handleClose={() => {
            setErrorSuccess(false);
            others.handleClose();
          }}
          success={evaluationStatus === 'error' ? false : true}
          toggle={() => setErrorSuccess(!errorSuccess)}
        />
      )}
    </div>
  );
}

export default ClientEvaluation;
