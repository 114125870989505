import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'reactstrap';

import { classes } from './modalClasses';

function DownloadModal(props) {
  const { t } = useTranslation();

  return (
    <Modal style={classes.modalCustom} isOpen={props.open}>
      <div style={classes.modalHeader} className="modal-header">
        <button
          type="button"
          onClick={props.handleClose}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div style={classes.modalPad} className="modal-body">
        <div className="row row-mb-lg">
          <div className="col-12 col-md-12">
            <div>
              <img
                style={classes.imgConfirm}
                src="/Images/modal/img-download.png"
                alt="download icon"
              />
            </div>
            <div ClassName="d-flex justify-content-center">
              {t('Export en cours de téléchargement…')}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DownloadModal;
