import { createSlice } from '@reduxjs/toolkit';
import { fetchAvailableLanguages } from './features';

export const languageStore = createSlice({
  name: 'languageStore',
  initialState: {
    availableLanguages: null,
    selectedLanguage: localStorage.getItem('language'),
    isLoading: true,
    isError: true,
  },
  reducers: {
    setSelectedLanguage: (state, { payload }) => {
      state.selectedLanguage = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchAvailableLanguages.fulfilled, (state, { payload }) => {
  //       state.availableLanguages = payload;
  //       state.isLoadingProjects = false;
  //       state.isError = false;
  //     })
  //     .addCase(fetchAvailableLanguages.pending, (state) => {
  //       state.isLoadingProjects = true;
  //       state.isError = false;
  //     })
  //     .addCase(fetchAvailableLanguages.rejected, (state) => {
  //       state.isLoadingProjects = false;
  //       state.isError = true;
  //     });
  // },
});

export default languageStore.reducer;

export const { setSelectedLanguage } = languageStore.actions;

export const languageStoreSelector = (state) => state.languageStore;
