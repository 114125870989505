import React from 'react';

import AdminModal from './modal/AdminModal';
import ClientModal from './modal/ClientModal';
import ConsultantModal from './modal/ConsultantModal';
import WriterModal from './modal/WriterModal';

import { ROLES } from 'utils/Constants';

export const CreateUpdateUser = (props) => {
  if (props.selected.role === ROLES.ADMIN)
    return (
      <AdminModal
        handleClose={props.Close}
        open={true}
        user={props.selected.user}
      />
    );

  if (props.selected.role === ROLES.CLIENT)
    return (
      <ClientModal
        handleClose={props.Close}
        open={true}
        user={props.selected.user}
      />
    );

  if (props.selected.role === ROLES.CONSULTANT)
    return (
      <ConsultantModal
        handleClose={props.Close}
        open={true}
        user={props.selected.user}
      />
    );

  if (props.selected.role === ROLES.WRITER)
    return (
      <WriterModal
        handleClose={props.Close}
        open={true}
        user={props.selected.user}
      />
    );
};
