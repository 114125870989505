import axios from 'axios';
import TokenService from './token.service';
import { fetchUser } from 'store/UserSession/features';

import { store } from 'store';
import endpoints from 'config/endpoints.json';

const BASE_URL = endpoints.axiosEvaluationAPI;

const axiosEvaluation = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

axiosEvaluation.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosEvaluation.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalReq = error?.config;
    if (error.response && error.response.status === 403) {
      // auto logout if 403 Forbidden response returned from api
      TokenService.removeUser();
      store.dispatch(fetchUser(null));

      originalReq._retry = true;
    }
    return Promise.reject(error);
  }
);

export default axiosEvaluation;
