import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ClientMainLayout } from 'components/shared/Layout/MainLayout';
import DashboardPage from 'pages/consultant/DashboardPage';
import WebsiteDisplay from 'pages/consultant/WebsiteDisplay';
import ConsultantProfile from 'pages/consultant/ConsultantProfile';
import ConsultantOrderDisplay from 'pages/consultant/ConsultantOrderDisplay';
import ConsultantTextDisplay from 'pages/consultant/ConsultantTextDisplay';
import OrderListOfClient from 'pages/consultant/OrderListOfClient';

function ConsultantMainMenu() {
  const routes = [
    <Route
      path="/Consultant/MainMenu"
      render={(props) => <DashboardPage {...props} reload={true} />}
    />,
    <Route path="/Consultant/FicheClient" component={WebsiteDisplay} />,
    <Route path="/Consultant/MonProfile" component={ConsultantProfile} />,
    <Route
      path="/Consultant/DetailsDeLaCommande"
      component={ConsultantOrderDisplay}
    />,
    <Route path="/Consultant/TextDetails" component={ConsultantTextDisplay} />,
    <Route
      path="/Consultant/CommandesduClient"
      component={OrderListOfClient}
    />,

    <Route path="*">
      <Redirect to="/Consultant/MainMenu" />
    </Route>,
  ];

  return (
    <ClientMainLayout>
      {routes.map((route, index) => (
        <div key={index}>{route}</div>
      ))}
    </ClientMainLayout>
  );
}

export default ConsultantMainMenu;
