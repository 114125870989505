import React, { useState, useEffect } from 'react';

const RED = '#EE3900';
const ORANGE = '#FF8D4B';
const BLACK = '#3c3c3c';

function checkColorConditions(textsWordCountDifference, minWords, maxWords) {
  // compute 10% of minWords
  const calcul1 = (10 * minWords) / 100;

  // compute minwords - difference of Text Word count
  const calcul2 = minWords - textsWordCountDifference;

  // compute 10% of maxWords
  const calcul3 = -((10 * maxWords) / 100);

  // compute maxwords - difference of Text Word count
  const calcul4 = maxWords - textsWordCountDifference;

  // 10% of maxWords >  maxwords - difference of Text Word count
  const condition1 = calcul3 > calcul4;

  // minwords - difference of Text Word count > 10% of minWords
  const condition2 = calcul2 >= calcul1;

  return { condition1, condition2 };
}

export const DisplayTextAmountColor = (props) => {
  const [color, setColor] = useState(BLACK);
  const [textsWordCountDifference, setTextsWordCountDifference] = useState(0);

  useEffect(() => {
    setTextsWordCountDifference(
      props.actualTextWordCount - props.initialTextWordCount
    );
    let conditions = checkColorConditions(
      textsWordCountDifference,
      props.minWords,
      props.maxWords
    );

    if (conditions.condition1) {
      setColor(ORANGE);
      return;
    }

    if (conditions.condition2) {
      setColor(RED);
      return;
    }
  }, [props, textsWordCountDifference]);

  return <span style={{ color: color }}> {textsWordCountDifference} </span>;
};

export const DisplayTextAmountColorAndCheckValidity = (props) => {
  const [color, setColor] = useState(BLACK);
  const [textsWordCountDifference, setTextsWordCountDifference] = useState(0);
  useEffect(() => {
    try {
      if (
        !props.formData ||
        !props.actualTextWordCount ||
        props.actualTextWordCount ||
        !props.minWords ||
        !props.maxWords
      )
        setColor(BLACK);
      const count = props.initialText
        ? props.actualTextWordCount - props.initialTextWordCount
        : props.actualTextWordCount;

      setTextsWordCountDifference(count);
      let conditions = checkColorConditions(
        textsWordCountDifference,
        props.minWords,
        props.maxWords
      );

      if (conditions.condition1) {
        setColor(ORANGE);
        props.setValid(true);
      }

      if (conditions.condition2) {
        setColor(RED);
        props.setValid(false);
      }

      if (!conditions.condition1 && !conditions.condition2) {
        props.setValid(true);
      }
    } catch (err) {
      console.log('NbrTextColor : ', err);
    }
  }, [props, textsWordCountDifference]);

  return <span style={{ color: color }}> {textsWordCountDifference} </span>;
};
