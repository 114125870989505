import axiosEvaluation from 'config/axiosEvaluation';
import React, { useEffect, useState } from 'react';
import { ROLES } from 'utils/Constants';
import { Loader } from 'components/shared/Loader';
import { Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import CommentEvaluationModal from './CommentEvaluationModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { animationUrl } from 'config/endpoints';

import { classes } from 'components/shared/modal/modalClasses';

function ClientEvaluationModal({
  user,
  orderId,
  t,
  writerCommandId,
  allTextsValidated,
  ...others
}) {
  const [deadline, setDeadline] = useState(0);
  const [instructions, setInstructions] = useState(0);
  const [writing, setWriting] = useState(0);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);

  const [noteClient, setNoteClient] = useState(0);
  const [evaluationModalToShow, setEvaluationModalToShow] = useState('');
  const [evaluationStatus, setEvaluationStatus] = useState('idle');

  const handleSubmit = async (e, value) => {
    setEvaluationStatus('loading');
    try {
      if (user && user.role === ROLES.CLIENT) {
        setNoteClient(value);
        if (deadline !== 0 && instructions !== 0 && writing !== 0) {
          const formData = {
            deadline: deadline,
            instructions: instructions,
            writing: writing,
          };
          await axiosEvaluation.put(
            `/client/evaluations?writerCommandId=${writerCommandId}`,
            formData
          );

          setEvaluationStatus('succeeded');

          setEvaluationModalToShow('COMMENT');
          setErrorSuccess(true);

          setErrorSuccessMsg(
            t('Votre évaluation est précieuse pour nous ! Merci !')
          );
          setErrorSuccessImg(animationUrl + '/07_Commande_valide%CC%81e.gif');
        } else {
          setErrorSuccess(true);
          setEvaluationModalToShow('INCOMPLETE');
          setEvaluationStatus('succeeded');

          setErrorSuccessMsg(
            `${t("Oups, il semblerait que l'évaluation n'est pas complète 😢")}
             ${t(
               'Merci de bien vouloir compléter votre évaluation afin que le rédacteurs puisse obtenir une note à la hauteur de son niveau.'
             )}`
          );
        }
      }
    } catch (error) {
      setEvaluationModalToShow('ERRORSUCCESS');
      setEvaluationStatus('error');
      setErrorSuccessMsg(
        t(
          "Une erreur s'est produite lors de la soumission de votre évaluation."
        )
      );
    }
  };

  return (
    <div>
      {evaluationStatus !== 'loading' && (
        <>
          {allTextsValidated === true && (
            <div className="label-title ">
              {t('Votre avis pour la commande')}{' '}
            </div>
          )}
          <div className="container">
            <div className="col-12 d-flex justify-content-between">
              <div className="col-md-4 ms-2 mt-1">
                <span>{t('Deadline')}: </span>
                <div className="d-flex flex-column align-items-center ">
                  <Rating
                    name={`writer-command-rating`}
                    value={deadline}
                    onChange={(e, value) => setDeadline(value)}
                    emptyIcon={
                      <StarIcon
                        style={{ fontSize: '10px !important', opacity: 0.3 }}
                      />
                    }
                    icon={<StarIcon style={{ fontSize: '10px !important' }} />}
                  />
                </div>
              </div>
              <div className="col-md-4 ms-2 mt-1">
                <span>{t('Instructions')}: </span>
                <div className="d-flex flex-column align-items-center ">
                  <Rating
                    name={`writer-command-rating`}
                    value={instructions}
                    onChange={(e, value) => setInstructions(value)}
                    emptyIcon={
                      <StarIcon
                        style={{ fontSize: '10px !important', opacity: 0.3 }}
                      />
                    }
                    icon={<StarIcon />}
                  />
                </div>
              </div>
              <div className="col-md-4 ms-2 mt-1">
                <span>{t('Rédaction')}: </span>
                <div className="d-flex flex-column align-items-center ">
                  <Rating
                    name={`writer-command-rating`}
                    value={writing}
                    onChange={(e, value) => setWriting(value)}
                    emptyIcon={
                      <StarIcon
                        style={{ fontSize: '10px !important', opacity: 0.3 }}
                      />
                    }
                    icon={<StarIcon />}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={classes.boxBtConfirm} className="mt-4">
            <button className="bt-submit-y" onClick={handleSubmit}>
              {t('Valider')}
            </button>
          </div>
        </>
      )}
      {evaluationStatus === 'loading' && (
        <>
          {' '}
          <div className="label-title mt-3">
            {t('Evaluation de la commande')}{' '}
          </div>
          <Loader />
        </>
      )}{' '}
      {evaluationModalToShow === 'COMMENT' && (
        <CommentEvaluationModal
          isOpen={errorSuccess}
          handleClose={() => {
            setErrorSuccess(false);
            others.handleClose();
          }}
          toggle={() => setErrorSuccess(!errorSuccess)}
          setEvaluationModalToShow={setEvaluationModalToShow}
          setEvaluationStatus={setEvaluationStatus}
          setErrorSuccessMsg={setErrorSuccessMsg}
          writerCommandId={writerCommandId}
          t={t}
          orderId={orderId}
        />
      )}
      {evaluationModalToShow === 'INCOMPLETE' && (
        <ErrorSuccesModal
          open={errorSuccess}
          message={errorSuccessMsg}
          handleClose={() => {
            setErrorSuccess(false);
          }}
          success={false}
          toggle={() => setErrorSuccess(!errorSuccess)}
        />
      )}
      {evaluationModalToShow === 'ERRORSUCCESS' && (
        <ErrorSuccesModal
          open={errorSuccess}
          message={errorSuccessMsg}
          imageUrl={errorSuccessImg}
          handleClose={() => {
            setErrorSuccess(false);
            others.handleClose();
          }}
          success={evaluationStatus === 'error' ? false : true}
          toggle={() => setErrorSuccess(!errorSuccess)}
        />
      )}
    </div>
  );
}

export default ClientEvaluationModal;
