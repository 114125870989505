import React from 'react';
import './styles.css';

function Card({ title, icon, boxBgColor, color, children }) {
  return (
    <div className={`card box-wrapper box-pd ${boxBgColor}`}>
      {icon ? (
        <div className="box-header-icon">
          <div className={`bt-icon-list ${icon}`} />
        </div>
      ) : null}

      <div className="text-container label-text text-center ">{title}</div>
      <div className={`box-circle ${color}`}>{children}</div>
    </div>
  );
}

export default Card;
