export const classes = {
  ModificationDemande: {
    width: '1098px',
    height: '56px',
    flexGrow: '1',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.32px',
    textAlign: 'left',
    margin: '0px 33px 0px 33px',
    padding: '0 1px 16.2px 0',
    color: '#ee3900',
  },
  ModificationDemandeTitle: {
    fontWeight: '600',
    color: '#000',
    paddingRight: '5px',
  },

  IconAlertModif: {
    width: '46.1px',
    height: '40px',
    flexGrow: '0',
    objectFit: 'contain',
  },
  Description: {
    width: '33px',
    height: '16px',
    flexGrow: '0',
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#828282',
  },
  RcapitulatifFacturation: {
    width: '324px',
    height: '36px',
    flexGrow: '0',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.48px',
    textAlign: 'left',
    color: '#3c3c3c',
  },
  FactureInserted: {
    width: '180px',
    height: '36px',
    flexGrow: '0',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.48px',
    textAlign: 'left',
    color: '#3c3c3c',
  },
  Rectangle107: {
    width: 'auto',
    height: 'auto',
    margin: '83px 57px 41px 161px',
    padding: '0 1px 0 0',
    borderRadius: '12px',
    boxShadow: '0 0 12px 1px rgba(0, 0, 0, 0.05)',
    border: 'solid 1px #e5e4e4',
    backgroundColor: ' var(--white)',
  },
  facturationTitle: {
    width: 'auto',
    height: '18px',
    margin: '0 581px 6px 0',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.13',
    letterSpacing: '-0.32px',
    textAlign: 'left',
    color: '#3c3c3c',
  },
  facturationTitleSpan: {
    fontWeight: '600',
  },
  line1: {
    width: '1161px',
    height: '1px',
    margin: '6px 0 18px',
    backgroundColor: '#e5e4e4',
  },
  formLabels: {
    flexGrow: '0',
    margin: '0 103px 20px 0',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.13',
    letterSpacing: '-0.32px',
    textAlign: 'left',
    color: '#3c3c3c',
  },
  formLabelsRight: {
    textAlign: 'right',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '10px',
    flexGrow: '0',
    // margin: "0 103px 20px 0",
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.13',
    letterSpacing: '-0.32px',
    color: '#3c3c3c',
  },
  CTA_Button: {
    width: '255px',
    height: '52px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    margin: '10px 30px 12px 34px',
    padding: '14px 18px',
    borderRadius: '48px',
    boxShadow: '0 0 12px 1px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#43b929',
  },
  CTA_Button_modif: {
    width: '255px',
    height: '52px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    margin: '10px 30px 12px 34px',
    padding: '14px 18px',
    borderRadius: '48px',
    boxShadow: '0 0 12px 1px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#ee3900',
  },
  CTA_Button_disabled: {
    width: '255px',
    height: '52px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    margin: '10px 30px 12px 34px',
    padding: '14px 18px',
    borderRadius: '48px',
    boxShadow: '0 0 12px 1px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#e0e0e0',
    color: '#bdbdbd',
  },
  Dropzone: {
    width: 'auto',
    height: '212px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '26px 30px 8px 30px',
    padding: '0',
    borderRadius: '8px',
    border: 'solid 1px #d7d7d7',
  },
  DropzoneActive: {
    width: '1161px',
    height: '948px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',
    borderRadius: '8px',
    border: 'solid 1px #d7d7d7',
    backgroundColor: '#f2f2f2',
  },
  CTATelecharger: {
    width: '42px',
    height: '42px',
    flexGrow: '0',
    objectFit: 'contain',
  },
  Frame8: {
    width: '368px',
    height: '68px',
    flexGrow: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
    padding: '0',
  },
  DropzoneText: {
    width: '326px',
    height: '18px',
    flexGrow: '0',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.13',
    letterSpacing: '-0.32px',
    textAlign: 'center',
    color: '#181818',
  },
  largeModalCustomForBill: {
    padding: '8px 10px',
    borderRadius: '12px',
    maxWidth: '1222px',
    boxShadow: '0 0 12px 1px rgba(0, 0, 0, 0.05)',
    border: 'solid 1px #e5e4e4',
    backgroundColor: '#fff',
    height: 'auto',
  },
  modalCustom: {
    padding: '8px 10px',
    borderRadius: '12px',
    boxShadow: '0 0 12px 1px rgba(0, 0, 0, 0.05)',
    border: 'solid 1px #e5e4e4',
    backgroundColor: '#fff',
    height: 'auto',
    maxWidth: '550px',
    width: '100%',
  },

  largeModalCustom: {
    padding: '8px 10px',
    borderRadius: '12px',
    maxWidth: '850px',
    boxShadow: '0 0 12px 1px rgba(0, 0, 0, 0.05)',
    border: 'solid 1px #e5e4e4',
    backgroundColor: '#fff',
    height: 'auto',
  },

  modalHeader: { borderBottom: '0px' },
  title: {
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    textAlign: 'left',
    color: '#3c3c3c',
  },
  btnClose: {
    color: '#3c3c3c !important',
    opacity: 'unset',
    marginTop: '-35px',
  },
  imgConfirm: {
    width: '100%',
    height: '250px',
    objectFit: 'contain',
  },
  textConfirm: {
    fontSize: '16px',
    letterSpacing: '-0.32px',
    textAlign: 'center',
    color: '#3c3c3c',
  },
  modalPad: {
    padding: '0px 15px 15px 15px',
  },
  boxBtConfirm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxBtConfirmRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  boxBtConfirmCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxBtConfirmLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  btn: {
    width: '114px',
    height: '52px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '48px',
    boxShadow: '0 0 12px 1px rgba(0, 0, 0, 0.05)',
    color: '#fff !important',
    letterSpacing: '-0.32px',
    fontSize: '16px',
  },
};
