import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { writerOrderListSelector } from 'store/Writer/OrderList';
import OrdersStatusTable from 'components/shared/Table/OrdersStatusTable';
import { WRITER_VALIDATED_ORDERS_COLUMN } from 'utils/Constants';
import { ORDERSTATUS } from 'utils/Constants';

function WriterInProgressOrders() {
  const columns = WRITER_VALIDATED_ORDERS_COLUMN;
  const { t } = useTranslation();

  //select the in progress orders from the orderlist store
  const { inProgressOrders, isLoading, isError } = useSelector(
    writerOrderListSelector
  );
  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  return (
    <>
      <OrdersStatusTable
        title={t('Commandes en cours')}
        columns={columns}
        orders={inProgressOrders}
        isLoading={isLoading}
        isError={isError}
        orderStatus={ORDERSTATUS.INPROGRESS}
      />
    </>
  );
}

export default WriterInProgressOrders;
