import axios from 'axios';
import { axiosMonetoringAPI } from './endpoints';

const BASE_URL = axiosMonetoringAPI;

const axiosMonetoring = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: '0620dd19-6f38-303e-b6f5-e11c92f06941 ',
    'Content-Type': 'application/json',
  },
});

export default axiosMonetoring;
