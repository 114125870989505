import BillTable from 'components/Admin/table/BillTableAdmin';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getBills } from 'store/Admin/BillDisplay/actions';
import { AllBillsSelector } from 'store/Admin/BillDisplay/reducers';
import { BILL_COMLUMNS_ADMIN } from 'utils/Constants';

function AdminBillsPage() {
  const columns = BILL_COMLUMNS_ADMIN;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { bills, status } = useSelector(AllBillsSelector);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  useEffect(() => {
    dispatch(getBills());
  }, [dispatch]);

  return (
    <>
      <BillTable
        title={t('Factures')}
        columns={columns}
        bills={bills}
        isLoading={status === 'succeeded' ? false : true}
      />
    </>
  );
}

export default AdminBillsPage;
