import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '@unform/web';
import { classes } from '../modalClasses';
import axiosFacturation from 'config/axiosFacturation';
import ErrorSuccesModal from '../ErrorSuccesModal';
import * as yup from 'yup';
import ModalHeader from './BillFormComponents/ModalHeader';
import FormFields from './BillFormComponents/FormFields';
import DropzoneComponent from './BillFormComponents/DropZoneComponent';
import { Loader } from 'components/shared/Loader';
import { useDispatch } from 'react-redux';
import { getBills } from 'store/Writer/BillDisplay/actions';
import { DEMAND_MODIFICATION } from 'utils/Constants';

function GenerateBillFormModal({
  bill,
  commandID,
  priceWord,
  handleClose,
  allPrice,
  setAllPrice,
  billStatus,
  file,
  billRef,
  message,
  visualize,
}) {
  const { t } = useTranslation();
  const [fieldValues, setFieldValues] = useState({
    billRef: billRef ? billRef : '',
    price: priceWord,
    totalPrice: allPrice,
  });

  const [fileField, setFileField] = useState(file ? file : null);
  const dispatch = useDispatch();

  const [totalValidationPrice, setTotalValidationPrice] = useState(
    allPrice ? allPrice : Infinity
  );
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const formRef = useRef(null);
  const [status, setStatus] = useState('idle');
  const [validationErrors, setValidationErrors] = useState({});
  const [disableButton, setDisableButton] = useState(true);
  const [clickedFields, setClickedFields] = useState({
    billRef: false,
    price: false,
    totalPrice: false,
  });

  const handleFieldClick = (fieldName) => {
    if (visualize) return;
    setClickedFields((prevClickedFields) => ({
      ...prevClickedFields,
      [fieldName]: true,
    }));
  };

  const handleFieldChange = (fieldName, value) => {
    if (visualize === true) {
      return;
    }
    if (visualize !== true) {
      setFieldValues((prevFieldValues) => ({
        ...prevFieldValues,
        [fieldName]: value,
      }));
    }
  };

  const validationSchema = yup.object().shape({
    billRef: yup.string().required(t('Référence de facture est obligatoire')),
    price: yup
      .number()
      .typeError(t('Le coût au mot doit être un nombre'))
      .positive(t('Le coût au mot doit être un nombre positif'))
      .max(priceWord, t('Le cout est trop élevé'))
      .required(t('Le coût au mot est obligatoire')),
    totalPrice: yup
      .number()
      .typeError(t('Le montant global de la commande doit être un nombre'))
      .positive(
        t('Le montant global de la commande doit être un nombre positif')
      )
      // .max(totalValidationPrice, t('Le montant global est trop élevé'))

      .required(t('Le montant global de la commande est obligatoire')),
  });
  const updateTotalPrice = useCallback(async () => {
    try {
      if (allPrice != null) {
        const response = await axiosFacturation.get(
          `/writer/changeTotalPrice?command%20id=${commandID}&price=${fieldValues.price}`
        );
        handleFieldChange('totalPrice', response.data);
        setAllPrice(response.data);
        setTotalValidationPrice(response.data);
        validateForm();
      }
    } catch (error) {
      setStatus('failed');
      // setError(error.response.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValues.price, commandID, setAllPrice, allPrice]);

  useEffect(() => {
    updateTotalPrice();
  }, [updateTotalPrice]);
  const validateAndSend = async (data) => {
    setStatus('loading');
    try {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('commandID', data.commandID);
      formData.append('billReference', data.billRef);
      formData.append('price', data.price);
      formData.append('totalPrice', data.totalPrice);

      billStatus === DEMAND_MODIFICATION
        ? await axiosFacturation.put('/writer/update', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        : await axiosFacturation.post('/writer/validateBill', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

      setErrorSuccess(true);

      setStatus('succeeded');
      setErrorSuccessMsg(t('La facture a été envoyée avec succès!'));
      // handleClose();
    } catch (error) {
      setStatus('error');
      const statusCode = error.response && error.response.status;

      if (statusCode === 409) {
        setErrorSuccessMsg(
          t('Bill reference existe déja , veuillez réessayer')
        );
      } else {
        setErrorSuccessMsg(t("Une erreur s'est produite, veuillez réessayer"));
      }
    }
    setErrorSuccess(true);
  };

  const handleCloseErrorSuccess = () => {
    setErrorSuccess(false);
    if (status !== 'error') {
      handleClose();
      dispatch(getBills());
    }
  };

  const validateForm = useCallback(async () => {
    try {
      const billRef = fieldValues.billRef;
      const price = fieldValues.price;
      const totalPrice = fieldValues.totalPrice;
      const data = {
        commandID,
        billRef,
        price,
        totalPrice,
      };

      await validationSchema.validate(data, { abortEarly: false });

      setValidationErrors({});
      setDisableButton(false);
    } catch (error) {
      const errors = {};

      if (error.inner) {
        error.inner.forEach((err) => {
          errors[err.path] = err.message;
        });
      }

      setValidationErrors(errors);

      setDisableButton(
        clickedFields.billRef || clickedFields.price || clickedFields.totalPrice
      );
    }
  }, [clickedFields, commandID, fieldValues, validationSchema]);

  useEffect(() => {
    if (
      clickedFields.billRef ||
      clickedFields.price ||
      clickedFields.totalPrice
    ) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedFields]);

  const handleFormSubmit = (event) => {
    const billRef = fieldValues.billRef;
    const price = fieldValues.price;
    const totalPrice = fieldValues.totalPrice;
    const file = fileField;
    const data = {
      file,
      commandID,
      billRef,
      price,
      totalPrice,
      message,
    };
    validateAndSend(data);
  };

  return (
    <div>
      <ModalHeader
        title={t('Récapitulatif de facturation')}
        handleClose={handleClose}
      />

      <div style={classes.modalPad} className="modal-body">
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          {status !== 'loading' ? (
            <>
              <FormFields
                bill={bill}
                fieldValues={fieldValues}
                handleFieldChange={handleFieldChange}
                handleFieldClick={handleFieldClick}
                validationErrors={validationErrors}
                visualize={visualize}
              />
              <div style={classes.line1}></div>

              <DropzoneComponent
                message={message}
                t={t}
                file={fileField}
                setFileField={setFileField}
                visualize={visualize}
              />
              {visualize !== true && (
                <div style={classes.boxBtConfirmRight}>
                  <button
                    style={
                      !disableButton && fileField
                        ? classes.CTA_Button
                        : classes.CTA_Button_disabled
                    }
                    className={'confirm'}
                    type="submit"
                    disabled={disableButton && fileField}
                  >
                    {t('Valider et envoyer la facture')}
                  </button>{' '}
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}
        </Form>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={handleCloseErrorSuccess}
        success={status === 'error' ? false : true}
        toggle={handleCloseErrorSuccess}
      />
    </div>
  );
}

export default GenerateBillFormModal;
