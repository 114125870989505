import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import axiosPrivate from 'config/axiosPrivate';
import TokenService from 'config/token.service';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { clientNotificationSelector } from 'store/Client/ClientNotification';
import { fetchUser } from 'store/UserSession/features';

import { VideoInstructionsModal } from 'components/shared/modal/VideoInstructionsModal';
import { DownloadMenu } from 'components/shared/DropDown/DownloadMenu';

import { LANGUAGES } from 'utils/Constants';
import useLanguage from 'utils/hooks/useLanguage';

export function NavMenuDetails({ user, paths, currentPage }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { newNotifications } = useSelector(clientNotificationSelector);
  const { language, changeLanguage } = useLanguage();
  const [showInstructions, setShowInstructions] = useState(false);

  const handleChangeLanguage = (language) => {
    changeLanguage(language);
    axiosPrivate.put(`/user/${language}/updateLanguage`).catch((err) => {
      console.log(err);
    });
  };

  const renderLanguageOptions = () =>
    LANGUAGES.map(({ text, img }, index) => (
      <li key={index} onClick={() => handleChangeLanguage(text)}>
        <button
          className="dropdown-item d-flex justify-content-center"
          type="button"
        >
          <div className="flag-img-container">
            <img src={img} alt={text} />
          </div>
        </button>
      </li>
    ));

  return (
    <header className="border-bottom fixed-top header-fix bg-light-wh bg-img">
      <div
        className="container-fluid d-grid gap-3 align-items-center"
        style={{ gridTemplateColumns: '1fr 2fr' }}
      >
        <div className="dropdown">
          {(user?.role === 'CLIENT' || user?.role === 'CONSULTANT') && (
            <Link to={paths.mainMenu}>
              <img
                className="logo-m"
                src="Images/logo-typix.png"
                alt="logo-typix"
              />
            </Link>
          )}
        </div>
        <div className="d-flex align-items-center">
          <form className="box-menu-list w-100 text-end">
            {user?.role !== 'CONSULTANT' && (
              <div className="btn-menu-icon d-flex justify-content-start align-items-center">
                <div
                  className="box-icon-menu bt-icon-video "
                  onClick={() => setShowInstructions(true)}
                ></div>
              </div>
            )}
            <DownloadMenu t={t} user={user} />
            {user?.role === 'CLIENT' && (
              <Link to={paths.notification}>
                <div className="btn-menu-icon icon-notification">
                  <div className="box-icon-menu bt-icon-alert"></div>
                  {newNotifications.length > 0 && (
                    <span className="icon-notification-badge-nav">
                      {newNotifications.length}
                    </span>
                  )}
                </div>
              </Link>
            )}
            <div className="btn-menu-icon">
              <div
                className={`box-icon-menu bt-icon-user ${
                  currentPage === paths.userProfile ? 'active' : ''
                }`}
                id="dropdownMenu2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ></div>
              <ul
                className="dropdown-menu menu-list-nav menu-list-sm"
                aria-labelledby="dropdownMenu2"
              >
                <li className="first-child">
                  <Link to={paths.userProfile}>
                    <button className="dropdown-item" type="button">
                      {t('Mon profil')}
                    </button>
                  </Link>
                </li>
                <li className="last-child">
                  <button
                    onClick={() => {
                      TokenService.removeUser();
                      dispatch(fetchUser(null));
                    }}
                    className="dropdown-item"
                    type="button"
                  >
                    {t('Déconnexion')}
                  </button>
                </li>
              </ul>
            </div>
            <div className="btn-menu-icon">
              <div
                className="box-icon-menu dropdown-toggle d-flex flex-row align-items-center"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="flag-img-container-country">
                  <img
                    src={LANGUAGES.find(({ text }) => text === language)?.img}
                    alt={language}
                  />
                </div>
              </div>
              <ul
                className="dropdown-menu menu-list-nav menu-list-lng"
                aria-labelledby="dropdownMenuButton1"
              >
                {renderLanguageOptions()}
              </ul>
            </div>
          </form>
        </div>
      </div>
      <VideoInstructionsModal
        showInstruction={showInstructions}
        setShowInstruction={setShowInstructions}
        user={user}
      />
    </header>
  );
}
