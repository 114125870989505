import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchBar from 'components/shared/SearchBar';

import { PAGE_NB_OPTIONS } from 'utils/Constants';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

function TableHeader({
  title,
  titleSearch,
  setTitleSearch,
  ordersPerPage,
  setOrdersPerPage,
  ...others
}) {
  const pageNbOptions = PAGE_NB_OPTIONS;
  const { t } = useTranslation();

  const handleSearch = (event) => setTitleSearch(event.target.value);

  return (
    <div className="box-info-header">
      <div className="b-child-1">
        <h1>{title}</h1>
      </div>
      <div className="b-child-2">
        <div className="d-flex justify-content-end">
          <SearchBar
            urlSearch={titleSearch}
            placeholder={others?.placeholder ? others.placeholder : null}
            handleChangeText={handleSearch}
          />
          <div className="d-flex align-items-center">
            <span className="me-2">{t('Afficher les résultats')}</span>
            <div className="dropdown" style={{ width: '70px' }}>
              <button
                className="btn dropdown-toggle dropdown-custom w-100"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {ordersPerPage}

                <span>
                  <ChevronDownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {pageNbOptions
                  .filter((nb) => nb !== ordersPerPage)
                  .map((item, index) => (
                    <li key={index}>
                      <button
                        className="dropdown-item"
                        onClick={() => setOrdersPerPage(item)}
                      >
                        {item}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableHeader;
