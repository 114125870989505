import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getBills } from 'store/Admin/BillDisplay/actions';

import { ValidateBillModal } from 'components/Admin/modal/ValidateBill/ValidateBillModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import axiosFacturation from 'config/axiosFacturation';
import { saveAs } from 'file-saver';

import {
  BILL_MODIFICATION,
  BILL_MODIFIED,
  BILL_STATUS_PAID,
  BILL_STATUS_PAYMENT,
  BILL_STATUS_VALIDATION,
} from 'utils/Constants';

const BillActionCell = ({
  writerID,
  writerCommandID,
  commandID,
  value,
  bill,
  index,
}) => {
  const [open, setOpen] = useState(false);
  const [visualize, setVisualize] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [validatePaymentStatus, setValidatePaymentStatus] = useState('idle');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    visualize === true && setVisualize(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDownload = () => {
    const fileBytes = bill.file.content;
    const fileName = 'Facture.pdf';
    const base64String = window.atob(fileBytes);
    const decodedBytes = new Uint8Array(base64String.length);
    for (let i = 0; i < base64String.length; i++) {
      decodedBytes[i] = base64String.charCodeAt(i);
    }

    const blob = new Blob([decodedBytes], { type: 'application/pdf' });
    saveAs(blob, fileName);
  };
  const handleValidatePayment = async () => {
    setValidatePaymentStatus('loading');
    try {
      await axiosFacturation.post(
        `/admin/validate payment?writer command id=${writerCommandID}`
      );
      setValidatePaymentStatus('succeeded');
      setIsSuccess(true);
      setErrorSuccessMsg(t('La facture a été payée avec succès !'));
    } catch (error) {
      setValidatePaymentStatus('failed');
      setIsSuccess(false);

      setErrorSuccessMsg(t("Une erreur s'est produite, veuillez réessayer"));
    }
    setErrorSuccess(true);
  };

  const handleCloseErrorSuccess = () => {
    setErrorSuccess(false);
    handleClose();
    dispatch(getBills());
  };
  const handleVisualize = () => {
    setVisualize(true);
    handleOpen();
  };
  return (
    <>
      <td>
        {value !== 0 && bill.status === BILL_STATUS_PAID && (
          <>
            {' '}
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={handleDownload}
            >
              {t('Télécharger')}
            </span>
          </>
        )}
        {value !== 0 &&
          bill.status === BILL_STATUS_PAYMENT &&
          validatePaymentStatus !== 'loading' && (
            <>
              {' '}
              <span
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={handleValidatePayment}
              >
                {t('Valider le paiement')}
              </span>
            </>
          )}
        {value !== 0 &&
          bill.status === BILL_STATUS_PAYMENT &&
          validatePaymentStatus === 'loading' && (
            <div className={'bt-reload-dynamic '} />
          )}

        {value === 0 && bill.status === BILL_MODIFICATION && (
          <div>
            <span style={{ color: '#bdbdbd' }}>
              {t('Voir la modification')}
            </span>
          </div>
        )}
        {value === 0 &&
          (bill.status === BILL_MODIFIED ||
            bill.status === BILL_STATUS_VALIDATION) && (
            <div>
              <span
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() => handleOpen()}
              >
                {t('Verifier la facture')}
              </span>
            </div>
          )}
      </td>
      {value !== 0 && (
        <td>
          <div
            onClick={handleVisualize}
            className={'bt-visualize d-flex justify-content-center'}
          />
        </td>
      )}

      {((value === 0 &&
        (bill.status === BILL_MODIFIED ||
          bill.status === BILL_STATUS_VALIDATION)) ||
        value !== 0) &&
        open && (
          <ValidateBillModal
            isOpen={open}
            commandID={commandID}
            writerID={writerID}
            writerCommandID={writerCommandID}
            file={bill.file}
            actualBilling={bill.actualBilling}
            billRef={bill.billReference}
            handleClose={handleClose}
            visualize={visualize}
          />
        )}
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={handleCloseErrorSuccess}
        success={isSuccess}
        toggle={handleCloseErrorSuccess}
      />
    </>
  );
};

export default BillActionCell;
