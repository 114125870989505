import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { consultantWebsiteDetailsSelector } from 'store/Consultant/WebsiteDisplay';
import axiosPrivate from 'config/axiosPrivate';

import { Paginator } from 'components/shared/Paginator';
import { Loader } from 'components/shared/Loader';
import { Button } from '@mui/material';

import { formatDate } from 'utils/DateUtils';
import { lower } from 'utils/StringUtils';
import { ProgressBar } from 'components/shared/ProgressBar';
import { CustomDropdownReadOnly } from 'components/shared/DropDown/CustomDropdown';
import DownloadModal from 'components/shared/modal/DownloadModal';

import {
  fetchConsultantOrderDisplay,
  fetchConsultantTextOrderDisplay,
} from 'store/Consultant/ConsultantOrderDisplay/features';
import { utils as xlsxUtils, writeFile as xlsxWriteFile } from 'xlsx';
import CheckBox from 'components/shared/CheckBox';
import { CustomTooltip } from 'components/shared/CustomTooltip';

import { setConsultantOrderId } from 'store/Consultant/ConsultantAnalyser';

import ErrorComponent from 'components/shared/ErrorComponent';
import { exportRoadMap } from 'helpers/ExportRoadmapHelpers';
import { sortByField } from 'helpers/SortingHelper';
import { handleSortingChange } from 'helpers/SortingHelper';

import { pageNbOptions } from 'utils/Constants';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

const columns = [
  {
    label: 'Titre de la commande',
    field: 'title',
    sortable: true,
    sort: 'asc',
  },
  { label: 'Deadline', field: 'deadline', sortable: true, sort: 'asc' },

  {
    label: 'Textes livrés',
    field: 'deliveredTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: 'Textes validés',
    field: 'validatedTextsAmount',
    sortable: true,
    sort: 'asc',
  },
  {
    label: '',
    field: 'selected',
    sortable: false,
  },
  {
    label: '',
    field: 'downloadAll',
    sortable: false,
  },
];

export default function OrdersListOfWebsite() {
  let { account, ordersList, isListLoading, isListError } = useSelector(
    consultantWebsiteDetailsSelector
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);

  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [allOrders, setAllOrders] = useState([]);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [pageNumbers, setPageNumbers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [titleSearch, setTitleSearch] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedOrders, setSelectedOrders] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showDownloadModal, setShowDownloadModel] = useState(false);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  useEffect(() => {
    setAllOrders(ordersList);
  }, [ordersList]);

  useEffect(() => {
    if (titleSearch && titleSearch.length > 0) {
      setPage(1);
      setFilteredOrders(
        allOrders.filter((x) => {
          return lower(x.title).includes(lower(titleSearch));
        })
      );
    } else {
      setFilteredOrders([...allOrders]);
    }
  }, [titleSearch, allOrders]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const setPage = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSorting = (sortField, sortOrder) => {
    const sorted = sortByField(allOrders, sortField, sortOrder);
    setAllOrders(sorted);
  };

  function onAllSelectedChange(isChecked) {
    setIsAllSelected(isChecked);

    let newSet = new Set();
    if (isChecked) {
      allOrders.forEach((order) => newSet.add(order.id));
    }
    setSelectedOrders(newSet);
  }

  function onOrderSelectedChange(orderId, isChecked) {
    let newSet = new Set(selectedOrders);

    if (isChecked) {
      newSet.add(orderId);
    } else {
      newSet.delete(orderId);
    }

    setSelectedOrders(newSet);
    if (newSet.size > 1) setIsAllSelected(true);
    if (newSet.size === 0) setIsAllSelected(false);
  }

  function exportRoadMapByAccount() {
    if (selectedOrders.size === allOrders.length || selectedOrders.size === 0) {
      const timeout = setTimeout(() => setShowDownloadModel(true), 500);

      axiosPrivate
        .get('/commands/' + account + '/getStatsByAccount')
        .then((res) => {
          const workBook = xlsxUtils.book_new();
          const formattedData = res.data.map((order) => {
            return {
              [t('Mot clé')]: order.keyword,
              [t('Intitulé commande')]: order.titled,
              [t('Titre')]: order.titleTag,
              [t('Meta-description')]: order.metaDescriptionTag,
              [t('Nombre de mots gérés')]: order.wordsManagedNumber,
              [t('Min/Max')]: `${order.minWords}/${order.maxWords}`,
              [t('Nombre de mots rédigés')]: order.totalNumberOfTextAdded,
              [t('Status texte')]: order.status,
              [t('Date de livraison')]: order.deliveredAt,
              [t('Score')]: order.analyseResult,
              [t('Date deadline validation client')]: order.deadline,
              [t('Date de validation')]: order.validateAt,
            };
          });
          const xlsx = xlsxUtils.json_to_sheet(formattedData);
          xlsxUtils.book_append_sheet(workBook, xlsx, 'Orders');
          xlsxWriteFile(workBook, `Export roadmap - ${account}.xlsx`);
          clearTimeout(timeout);
          setShowDownloadModel(false);
        });
    } else {
      exportRoadMap(selectedOrders, null, account, t);
    }
  }

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{account}</h1>
      </div>
      <div className="box-info-header" style={{ marginBottom: 10 }}>
        <div className="b-child-1">
          <h2>{t('Liste des commandes')}</h2>
        </div>
        <div className="b-child-2">
          <div className="d-flex  justify-content-end">
            <div className="box-input-text pe-0">
              <div className="input-group input-cs w-100 pe-0">
                <div className="input-group-text">
                  <SearchIcon />
                </div>
                <input
                  onChange={(event) => setTitleSearch(event.target.value)}
                  type="text"
                  className="form-control ps-0"
                  placeholder={t('Rechercher')}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className="me-2">{t('Afficher les résultats')}</span>
              <div className="dropdown" style={{ width: '70px' }}>
                <button
                  className="btn dropdown-toggle dropdown-custom w-100"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {ordersPerPage}
                  <span>
                    <ChevronDownIcon />
                  </span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {pageNbOptions
                    .filter((nb) => nb !== ordersPerPage)
                    .map((item, index) => (
                      <li key={index}>
                        <button
                          className="dropdown-item"
                          onClick={() => setOrdersPerPage(item)}
                        >
                          {item}
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-wrapper">
        {isListLoading ? (
          <Loader />
        ) : isListError ? (
          <ErrorComponent />
        ) : (
          <div className="table-responsive">
            <div>
              <table className="table-lists table-commandes">
                <thead>
                  <tr>
                    {columns.map(({ label, field, sortable }) => (
                      <th
                        style={{
                          cursor: sortable ? 'pointer' : 'default',
                          width:
                            field === 'selected'
                              ? '5%'
                              : field === 'title'
                              ? '26%'
                              : field === 'deadline'
                              ? '20%'
                              : '',
                        }}
                        onClick={
                          sortable
                            ? () =>
                                handleSortingChange(
                                  field,
                                  sortField,
                                  sortOrder,
                                  setSortField,
                                  setSortOrder,
                                  handleSorting
                                )
                            : null
                        }
                        key={field}
                      >
                        {t(label)}
                        {!sortable ? null : field === sortField &&
                          sortOrder === 'asc' ? (
                          <span>
                            <ChevronUpIcon style={{ marginLeft: '3px' }} />
                          </span>
                        ) : (
                          <span>
                            <ChevronDownIcon style={{ marginLeft: '3px' }} />
                          </span>
                        )}
                        {field === 'selected' ? (
                          <CheckBox
                            name="select-all"
                            tick={selectedOrders.size === allOrders.length}
                            onCheck={(e) =>
                              onAllSelectedChange(e.target.checked)
                            }
                          />
                        ) : field === 'downloadAll' ? (
                          isAllSelected ? (
                            <div style={{ position: 'relative' }}>
                              <div className="d-flex justify-content-center">
                                <span
                                  className="bt-download-circle-selected"
                                  onClick={() => exportRoadMapByAccount()}
                                ></span>
                              </div>
                              <CustomTooltip
                                title={t(
                                  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune"
                                )}
                                placement="top"
                              >
                                <button
                                  type="button"
                                  className="btn-circle"
                                  style={{
                                    position: 'absolute',
                                    top: '-5px',
                                    right: '-5px',
                                  }}
                                ></button>
                              </CustomTooltip>
                            </div>
                          ) : (
                            <div style={{ position: 'relative' }}>
                              <div className="d-flex justify-content-center">
                                <span
                                  className="bt-download-circle"
                                  onClick={() => exportRoadMapByAccount()}
                                ></span>
                              </div>
                              <CustomTooltip
                                title={t(
                                  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune"
                                )}
                                placement="top"
                              >
                                <button
                                  type="button"
                                  className="btn-circle"
                                  style={{
                                    position: 'absolute',
                                    top: '-5px',
                                    right: '-5px',
                                  }}
                                ></button>
                              </CustomTooltip>
                            </div>
                          )
                        ) : null}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order, index) => (
                    <tr key={index}>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          dispatch(setConsultantOrderId(order.id));
                          dispatch(fetchConsultantOrderDisplay(order.id));
                          dispatch(fetchConsultantTextOrderDisplay(order.id));
                          history.push('/Consultant/DetailsDeLaCommande');
                        }}
                      >
                        <span>{order?.title}</span>
                      </td>
                      <td>
                        <span>
                          {order.deadline ? formatDate(order.deadline) : '-'}
                        </span>
                      </td>

                      <td>
                        <ProgressBar
                          amount={order.deliveredTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.validatedTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <CheckBox
                          key={index}
                          name={order.title}
                          tick={selectedOrders.has(order.id)}
                          onCheck={(e) =>
                            onOrderSelectedChange(order.id, e.target.checked)
                          }
                        />
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <span
                            className="bt-download-circle "
                            onClick={() =>
                              exportRoadMap(
                                selectedOrders,
                                order.id,
                                account,
                                t
                              )
                            }
                          ></span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <Paginator
        isLoading={isListLoading}
        listLength={filteredOrders.length}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        nbElementsPerPage={ordersPerPage}
        goToPage={(page) => setCurrentPage(page)}
      />

      <DownloadModal
        open={showDownloadModal}
        handleClose={() => setShowDownloadModel(false)}
      />
    </div>
  );
}
