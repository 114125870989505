import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchUserSession = createAsyncThunk(
  'userSession/fetchUserSession',
  async (userSession, thunkAPI) => {
    try {
      if (!userSession) return thunkAPI.rejectWithValue(404);
      axios.defaults.headers[
        'Authorization'
      ] = `Bearer ${userSession.accessTokenJwt}`;
      return {
        user: userSession.user,
        role: userSession.user.role,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchUser = createAsyncThunk(
  'userSession/fetchUser',
  async (user, thunkAPI) => {
    try {
      if (!user) return thunkAPI.rejectWithValue(404);
      return user;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
