import React, { useEffect, useState } from 'react';

import { lower } from 'utils/StringUtils';

import { useDispatch } from 'react-redux';
import { selectWriter } from 'store/Admin/WriterDisplay';
import { useHistory } from 'react-router-dom';

import { PAGENUMBERSTINYLIMIT } from 'utils/Constants';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { Loader } from 'components/shared/Loader';

import './writers.css';
import { useTranslation } from 'react-i18next';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

const pageNbOptions = [15, 20, 30];

export const WritersList = (props) => {
  const { t } = useTranslation();
  const [writersPerPage, setWritersPerPage] = useState(15);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentWriters, setCurrentWriters] = useState([]);
  const [filteredWriters, setFilteredWriters] = useState([]);

  const [nameSearch, setNameSearch] = useHistoryState('nameSearch', '');

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (props.writersList && nameSearch && nameSearch.length > 0) {
      setCurrentPage(1);
      setFilteredWriters(
        props.writersList.filter((x) => {
          return lower(x.name).includes(lower(nameSearch));
        })
      );
    } else {
      setFilteredWriters([...props.writersList]);
    }
  }, [nameSearch, props.writersList]);

  useEffect(() => {
    const indexOfLastWriter = currentPage * writersPerPage;
    const indexOfFirstWriter = indexOfLastWriter - writersPerPage;
    setCurrentWriters(
      filteredWriters.slice(indexOfFirstWriter, indexOfLastWriter)
    );
    setPageNumbers(
      [...Array(Math.ceil(filteredWriters.length / writersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredWriters, writersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  return (
    <div>
      <div className="box-wrapper">
        <div className="txt-title">
          {t('Liste des rédacteurs')} &nbsp;
          <span className="txt-normal">
            ({props.loading ? 'xxxx' : props.writersList.length})
          </span>
        </div>
        <div className="box-list-header box-block-line">
          <div className="box-input-text w-88">
            <div className="input-group input-cs w-100">
              <div className="input-group-text">
                <SearchIcon />
              </div>
              <input
                type="text"
                value={nameSearch}
                onChange={(event) => setNameSearch(event.target.value)}
                className="form-control input-cs ps-0"
                placeholder={t('Rechercher un rédacteur')}
              />
            </div>
          </div>
          <div>
            <span
              onClick={() => history.push('/Admin/CreerRedacteur')}
              className="bt-plus-circle-yellow active"
            />{' '}
          </div>
        </div>
        {props.loading ? (
          <Loader />
        ) : (
          <div className="wrapbox-list-col">
            {currentWriters.map((item, key) => (
              <div
                onClick={() => {
                  dispatch(selectWriter(item));
                  history.push('/Admin/VoirRedacteur');
                }}
                key={item.id}
                className="box-list-col"
              >
                {item.name}
              </div>
            ))}

            <div className="box-rectangle" />
          </div>
        )}
      </div>
      <div className="box-page">
        <div className="d-flex align-items-center" style={{ marginLeft: 10 }}>
          <span className="me-2">{t('Afficher')}</span>
          <div className="dropdown" style={{ width: 67 }}>
            <button
              className="btn dropdown-toggle dropdown-custom w-100"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {writersPerPage}
              <span>
                <ChevronDownIcon />
              </span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {pageNbOptions
                .filter((nb) => nb !== writersPerPage)
                .map((item, index) => (
                  <li key={index} onClick={() => setWritersPerPage(item)}>
                    <button className="dropdown-item">{item}</button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="box-pagination">
          <div className="list-page-number">
            {pageNumbers
              .slice(
                Math.max(0, currentPage - PAGENUMBERSTINYLIMIT),
                Math.min(currentPage + PAGENUMBERSTINYLIMIT, pageNumbers.length)
              )
              .map((pageNum, index) => (
                <span
                  key={index}
                  className={pageNum === currentPage ? 'active' : ''}
                  onClick={() => setCurrentPage(pageNum)}
                >
                  {pageNum}
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
