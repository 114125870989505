import { Autocomplete, Box, InputBase, createStyles } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { customStyle, theme } from './CustomAutocompleteStyle';
import * as React from 'react';

import { ThemeProvider } from '@mui/material/styles';

const useStyles = makeStyles(() => createStyles(customStyle));

export const CustomAutocomplete = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    backgroundColor: '#FFE426',
  }));

  const GroupItems = styled('ul')({
    padding: 0,
  });

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        options={props.list}
        disabled={props.disabled}
        defaultValue={props.value}
        isOptionEqualToValue={(option, value) => option === value}
        value={props.value}
        noOptionsText={t("Pas d'options")}
        getOptionLabel={(option) => props.getOptionLabel(option)}
        fullWidth
        classes={{
          option: classes.option,
          clearIndicatorDirty: classes.clearIndicator,
          popupIndicator: classes.popupIndicator,
        }}
        className={`${
          props.disabled ? classes.textFieldDisabled : classes.textField
        }`}
        onChange={(event, value) => props.setValue(value)}
        getOptionDisabled={(option) => props.getOptionDisabled(option)}
        groupBy={(option) => option.availabilityGroup}
        renderGroup={(params) => {
          if (props.isWriterList) {
            return (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            );
          } else {
            return (
              <div key={params.key}>
                <div>{params.group}</div>
                <div>{params.children}</div>
              </div>
            );
          }
        }}
        renderOption={(_props, option, state) => {
          let optionStyle = {};
          if (props.isWriterList) {
            const isAvailable = option.isAvailable;
            optionStyle = {
              color: isAvailable ? 'inherit' : 'gray',
            };
          }
          if (state.selected)
            return (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  ...optionStyle,
                }}
                {..._props}
              >
                <Box>{props.getOptionLabel(option)}</Box>
                <Box>
                  <img
                    style={{ float: 'right' }}
                    src="Images/icon-check.png"
                    alt="icon-check"
                  />
                </Box>
              </Box>
            );
          else
            return (
              <span {..._props} style={optionStyle}>
                {props.getOptionLabel(option)}
              </span>
            );
        }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              classes={{ root: classes.root }}
              variant="outlined"
              {...params.InputProps}
              {...rest}
            />
          );
        }}
      />
    </ThemeProvider>
  );
};

export const CustomAutocompleteFreeSolo = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        freeSolo
        options={props.list}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => props.getOptionLabel(option)}
        noOptionsText={t("Pas d'options")}
        value={props.value}
        onInputChange={(event, value) => props.onInputChange(value)}
        onChange={(event, value) => props.setValue(value)}
        fullWidth
        classes={{
          option: classes.option,
          clearIndicatorDirty: classes.clearIndicator,
          popupIndicator: classes.popupIndicator,
        }}
        className={`${
          props.disabled ? classes.textFieldDisabled : classes.textField
        }`}
        renderOption={(_props, option, state) => {
          const isAvailable = option.isAvailable;
          const optionStyle = {
            color: isAvailable ? 'inherit' : 'gray',
          };
          if (state.selected)
            return (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                {..._props}
              >
                <Box>{props.getOptionLabel(option)}</Box>
                <Box>
                  <img
                    style={{ float: 'right' }}
                    src="Images/icon-check.png"
                    alt="icon-check"
                  />
                </Box>
              </Box>
            );
          else return <span {..._props}>{props.getOptionLabel(option)}</span>;
        }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              classes={{ root: classes.root }}
              variant="outlined"
              {...params.InputProps}
              {...rest}
            />
          );
        }}
      />
    </ThemeProvider>
  );
};

export const CustomAutocompleteDisabled = (props) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        readOnly
        disableClearable
        options={props.list}
        getOptionLabel={(option) => props.getOptionLabel(option)}
        value={props.value}
        onChange={(event, value) => props.setValue(value)}
        fullWidth
        classes={{
          option: classes.option,
          clearIndicatorDirty: classes.clearIndicator,
          popupIndicator: classes.popupIndicator,
        }}
        className={`${
          props.disabled ? classes.textFieldDisabled : classes.textField
        }`}
        renderOption={(_props, option, state) => {
          if (state.selected)
            return (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                {..._props}
              >
                <Box>{props.getOptionLabel(option)}</Box>
                <Box>
                  <img
                    style={{ float: 'right' }}
                    src="Images/icon-check.png"
                    alt="icon-check"
                  />
                </Box>
              </Box>
            );
          else return <span {..._props}>{props.getOptionLabel(option)}</span>;
        }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              readOnly
              classes={{ root: classes.root }}
              variant="outlined"
              {...params.InputProps}
              {...rest}
            />
          );
        }}
      />
    </ThemeProvider>
  );
};
