import { Loader } from 'components/shared/Loader';
import axiosFacturation from 'config/axiosFacturation';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import ValidateBillFormModal from './ValidateBillFormModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { classes } from 'components/shared/modal/modalClasses';

export const ValidateBillModal = ({
  isOpen,
  handleClose,
  commandID,
  writerID,
  writerCommandID,
  billRef,
  file,
  visualize,
  actualBilling,
}) => {
  const [status, setStatus] = useState('idle');
  const [bill, setBill] = useState(null);
  const [filePdf, setFilePdf] = useState(null);
  // const [error, setError] = useState(null);
  const { t } = useTranslation();

  const generateBill = useCallback(async () => {
    setStatus('loading');
    try {
      const response = await axiosFacturation.get(
        `/admin/getBill?command%20id=${commandID}&writer%20id=${writerID}`
      );
      setStatus('succeeded');
      setBill(response.data);
    } catch (error) {
      setStatus('failed');
    }
  }, [commandID, writerID]);

  const byteArrayToPDFFile = useCallback(async () => {
    const fileBytes = file.content;
    const fileName = file.name;
    const base64String = window.atob(fileBytes);
    const decodedBytes = new Uint8Array(base64String.length);
    for (let i = 0; i < base64String.length; i++) {
      decodedBytes[i] = base64String.charCodeAt(i);
    }
    const pdfBlob = new Blob([new Uint8Array(decodedBytes)], {
      type: 'application/pdf',
    });
    const pdfFile = new File([pdfBlob], fileName, { type: 'application/pdf' });
    setFilePdf(pdfFile);
  }, [file]);

  useEffect(() => {
    generateBill();
    if (file != null) {
      byteArrayToPDFFile();
    }
  }, [byteArrayToPDFFile, file, generateBill]);

  const [errorSuccessMsg] = useState(
    t('Impossible de générer la facture ! Veuillez réessayer')
  );

  return (
    <div>
      <Modal
        style={classes.largeModalCustomForBill}
        isOpen={isOpen && status !== 'failed'}
        toggle={handleClose}
      >
        {status === 'loading' ? (
          <Loader />
        ) : status === 'succeeded' && bill != null ? (
          <ValidateBillFormModal
            bill={bill}
            writerCommandID={writerCommandID}
            priceWord={bill.price}
            allPrice={bill.totalPrice}
            handleClose={handleClose}
            actualBilling={actualBilling}
            billRef={billRef}
            file={filePdf}
            visualize={visualize}
          />
        ) : (
          ''
        )}
      </Modal>
      {status === 'failed' ? (
        <ErrorSuccesModal
          open={isOpen}
          message={errorSuccessMsg}
          handleClose={handleClose}
          success={false}
        ></ErrorSuccesModal>
      ) : (
        ''
      )}
    </div>
  );
};
