import axiosPrivate from 'config/axiosPrivate';

export const autoSaveText = (
  newActualTextContent,
  newActualHtmlContent,
  textForm,
  initialHtmlContent,
  initialTextContent,
  SavingState,
  setSaving,
  setTimer,
  timer,
  actualTextContent,
  orderType
) => {
  clearTimeout(timer);

  setSaving(SavingState.NOT_SAVED);

  setTimer(
    setTimeout(() => {
      if (
        newActualTextContent !== actualTextContent ||
        newActualHtmlContent !== actualTextContent
      ) {
        const textData = {
          actualHtmlContent: newActualHtmlContent,
          actualTextContent:
            orderType !== 'PREMIUM'
              ? newActualHtmlContent
              : newActualTextContent,
          initialHtmlContent: initialHtmlContent,
          initialTextContent: initialTextContent,
        };
        axiosPrivate
          .put(`/texts/auto-save/${textForm.id}`, textData)
          .then((res) => {
            setSaving(SavingState.SAVED);
          })
          .catch((e) => console.log('error'));
      }

      setSaving(SavingState.SAVING);
    }, 60000)
  );
};
