import axiosPrivate from 'config/axiosPrivate';
import React, { useEffect, useState } from 'react';
import { JsonToExcel } from 'react-json-to-excel';
import { utils as xlsxUtils, writeFile as xlsxWriteFile } from 'xlsx';
import he from 'he';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { adminOrderDisplaySelector } from 'store/Admin/OrderDisplay';
import { fetchAdminTextOrderDisplay } from 'store/Admin/OrderDisplay/features';

import { stripHTML } from 'utils/StringUtils';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import DownloadModal from 'components/shared/modal/DownloadModal';

export const ExcelExportButton = ({ orderId }) => {
  const dispatch = useDispatch();
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const { t } = useTranslation();

  let { textList } = useSelector(adminOrderDisplaySelector);
  useEffect(() => {
    if (!textList) {
      dispatch(fetchAdminTextOrderDisplay());
    }
  }, [dispatch, textList]);

  function getTextBetweenValues(inputString, startValue, endValue) {
    const startIndex = inputString.indexOf(startValue);

    if (startIndex === -1) {
      return null; // Return null if startValue is not found
    }

    let endIndex = inputString.length;

    if (endValue) {
      endIndex = inputString.indexOf(endValue, startIndex + startValue.length);

      if (endIndex === -1) {
        return null; // Return null if endValue is not found after the startOffset
      }
    }

    return inputString.substring(startIndex + startValue.length, endIndex);
  }

  function extractDescriptionSubstring(text, endIndex) {
    const lastH2Index = text.lastIndexOf(endIndex);
    if (lastH2Index !== -1) {
      const startIndex = lastH2Index + endIndex.length;
      return text.substring(startIndex);
    }
    return '';
  }

  function extractProductName(text) {
    let productName = '';

    if (text !== null) {
      const extractedText = getTextBetweenValues(text, '<h1>', '</h1>');
      productName = extractedText ? extractedText : '';
    }

    return productName;
  }

  function keepOnlyH1H2(text) {
    // Use a regular expression to remove all HTML tags except <h1> and <h2>
    return text
      .replace(/<(?!\/?(h1|h2)\b)[^<>]*>/g, '')
      .replace(/&nbsp;/g, ' ');
  }

  function exportTextExcel() {
    const timeout = setTimeout(() => setShowDownloadModal(true), 500);
    axiosPrivate
      .get(`/commands/${orderId}/getStatsByCommands`)
      .then((res) => {
        const workBook = xlsxUtils.book_new();
        const textLengthExceeded = res.data.some((order) => {
          if (
            order.text.length >= 32767 ||
            order.actualHtmlContent?.length >= 32767
          ) {
            clearTimeout(timeout);
            return true;
          }
          return false;
        });

        if (textLengthExceeded) {
          setErrorSuccessMsg(
            t('Un texte est trop long et ne peut pas être exporté sur Excel.')
          );
          setIsSuccess(false);
          setErrorSuccess(true);
          clearTimeout(timeout);
          setShowDownloadModal(false);
          return;
        } else {
          const formattedData = res.data.map((order) => ({
            [t('Intitulé commande')]: order.titled,
            [t('Mot clé')]: order.keyword,
            [t('Titre')]: order.titleTag,
            [t('Meta-description')]: order.metaDescriptionTag,
            [t('URL')]: order.url,
            [t('Score')]: order.analyseResult,
            [t('Nom du produit')]: stripHTML(extractProductName(order.text)),
            [t('ONLINE')]: order.deployed === true ? t('OUI') : t('NON'),
            [t('Texte')]: stripHTML(he.decode(order.text)),
            [t('Html')]: he.decode(order.actualHtmlContent || order.text),
          }));

          const xlsxSheet = xlsxUtils.json_to_sheet(formattedData);
          xlsxUtils.book_append_sheet(workBook, xlsxSheet, 'Orders');
          xlsxWriteFile(workBook, 'Export roadmap.xlsx');
          clearTimeout(timeout);
          setShowDownloadModal(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        clearTimeout(timeout);
        setShowDownloadModal(false);
        setErrorSuccessMsg(t("Une erreur est survenue lors de l'exportation."));
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  }

  return (
    <div className="excel-export-button" onClick={exportTextExcel}>
      <DownloadModal
        open={showDownloadModal}
        handleClose={() => setShowDownloadModal(false)}
      />
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
};
