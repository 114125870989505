import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'config/axios';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { PWD_REGEX } from 'utils/Regex';
import { Alert, CircularProgress } from '@mui/material';

const RESET_PASSWORD_URL = '/user/resetPassword';

const ResetPassword = () => {
  const history = useHistory();
  const search = useLocation().search;
  const { t } = useTranslation();

  const mail = new URLSearchParams(search).get('mail');
  const otp = parseInt(new URLSearchParams(search).get('otp'));
  const otpType = new URLSearchParams(search).get('otpType');

  const [pwd, setPwd] = useState('');
  const [touchedPwd, setTouchedPwd] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [touchedMatch, setTouchedMatch] = useState(false);
  const [showMatchPwd, setShowMatchPwd] = useState(false);
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    axios
      .put(
        RESET_PASSWORD_URL,
        { mail, otp, password: pwd },
        {
          params: {
            otpType,
          },
        }
      )
      .then(() => {
        setError(false);
        setLoading(false);
        setSuccessMsg(t('Mot de passe mis à jour avec succès'));
        setSuccess(true);
        setTimeout(() => {
          history.push('/login');
        }, 3000);
      })
      .catch((e) => {
        setLoading(false);
        switch (e.response.status) {
          case 400:
            setErrorMsg(
              t('Ce mot de passe est le mot de passe que vous avez oublié')
            );
            break;
          case 409:
            setErrorMsg(t(`Les données fournies sont invalides`));
            break;
          case 410:
            setErrorMsg(
              t(`Votre demande a expirée .. veuillez refaire une autre`)
            );
            break;
          default:
            setErrorMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setError(true);
      });
  };

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
  }, [pwd]);

  useEffect(() => {
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  return (
    <div>
      <div className="row d-flex justify-content-center">
        <div className="col-md-4">
          <div className="px-5 py-5">
            <div className="row row-mb">
              <div
                className="col-12 col-md-12"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={'/Images/logo.png'}
                  alt="logo"
                  width={'100%'}
                  style={{ marginBottom: 30 }}
                />
              </div>
            </div>

            <div className="row row-mb">
              <div className="col-12 col-md-12">
                <div
                  className={
                    touchedPwd && !validPwd
                      ? 'label-title-error'
                      : 'label-title'
                  }
                >
                  {t('Mot de passe')}*
                </div>
                <div className="input-group input-password">
                  <input
                    className={
                      touchedPwd && !validPwd
                        ? 'form-control form-control-error form-ct'
                        : 'form-control form-control-valid form-ct'
                    }
                    type={showPwd ? 'text' : 'password'}
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => {
                      setPwdFocus(false);
                      setTouchedPwd(true);
                    }}
                  />
                  <span
                    className={
                      touchedPwd && !validPwd
                        ? 'input-group-text error'
                        : 'input-group-text'
                    }
                    onClick={() => setShowPwd(!showPwd)}
                  >
                    {touchedPwd && !validPwd ? (
                      <img src="/Images/icon-eye-red.png" alt="icon-eye-red" />
                    ) : showPwd ? (
                      <img
                        src="/Images/icon-eye-black.png"
                        alt="icon-eye-black"
                      />
                    ) : (
                      <img
                        src="/Images/icon-eye-gray.png"
                        alt="icon-eye-gray"
                      />
                    )}
                  </span>
                </div>
                {pwdFocus && touchedPwd && !validPwd ? (
                  <p className="error-note">
                    {t('doit comprendre au moins 8 charactères')}.
                    <br />
                    {t(
                      'au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre'
                    )}{' '}
                    <br />
                  </p>
                ) : null}
              </div>
            </div>
            <div className="row row-mb">
              <div className="col-12 col-md-12">
                <div
                  className={
                    touchedMatch && !validMatch
                      ? 'label-title-error'
                      : 'label-title'
                  }
                >
                  {t('Confirmer le nouveau mot de passe')}*
                </div>
                <div className="input-group input-password">
                  <input
                    className={
                      touchedMatch && !validMatch
                        ? 'form-control form-control-error form-ct'
                        : 'form-control form-control-valid form-ct'
                    }
                    type={showMatchPwd ? 'text' : 'password'}
                    value={matchPwd}
                    onChange={(e) => setMatchPwd(e.target.value)}
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => {
                      setMatchFocus(false);
                      setTouchedMatch(true);
                    }}
                  />
                  <span
                    className={
                      touchedMatch && !validMatch
                        ? 'input-group-text error'
                        : 'input-group-text'
                    }
                    onClick={() => setShowMatchPwd(!showMatchPwd)}
                  >
                    {touchedMatch && !validMatch ? (
                      <img src="/Images/icon-eye-red.png" alt="icon-eye-red" />
                    ) : showMatchPwd ? (
                      <img
                        src="/Images/icon-eye-black.png"
                        alt="icon-eye-black"
                      />
                    ) : (
                      <img
                        src="/Images/icon-eye-gray.png"
                        alt="icon-eye-gray"
                      />
                    )}
                  </span>
                </div>
                {matchFocus && touchedMatch && !validMatch ? (
                  <p className="error-note">
                    {t('Les deux mots de passe ne sonts pas identiques')}
                  </p>
                ) : null}
              </div>
            </div>
            <div className='="row row-mb'>
              {error ? (
                <Alert severity="error">
                  <strong>{errorMsg}</strong>
                </Alert>
              ) : null}

              {success ? (
                <Alert severity="success">
                  <strong>{successMsg}</strong>
                </Alert>
              ) : null}
              <div style={{ margin: 2, position: 'relative' }}>
                <p align="right">
                  {!mail || !otp || !otpType || !validPwd || !validMatch ? (
                    <button
                      style={{
                        width: '40%',
                        marginTop: 20,
                        marginBottom: 20,
                        left: '50% !important',
                      }}
                      className="bt-submit-disabled-y"
                    >
                      {' '}
                      {t('Réinitialiser')}{' '}
                    </button>
                  ) : (
                    <button
                      style={{
                        width: '40%',
                        marginTop: 20,
                        marginBottom: 20,
                        left: '50% !important',
                      }}
                      className="bt-submit-y float-end"
                      onClick={() => {
                        setLoading(true);
                        handleSubmit();
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          size={24}
                          style={{ color: 'white' }}
                        />
                      ) : (
                        t('Réinitialiser')
                      )}
                    </button>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
