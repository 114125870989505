import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ErrorComponent from 'components/shared/ErrorComponent';
import { Paginator } from 'components/shared/Paginator';
import { Loader } from 'components/shared/Loader';
import { ProgressBar } from 'components/shared/ProgressBar';
import { CircularBar } from 'components/shared/CircularBar';
import { CustomDropdownReadOnly } from 'components/shared/DropDown/CustomDropdown';
import TableHeader from 'components/shared/Table/TableHeader';

import { handleSorting } from 'store/Admin/OrderList';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { formatDate } from 'utils/DateUtils';
import { ORDERSTATUS } from 'utils/Constants';
import {
  fetchAdminOrderDisplay,
  fetchAdminTextOrderDisplay,
  fetchAdminOrderLineDisplay,
} from 'store/Admin/OrderDisplay/features';

import { setStatus } from 'store/Admin/OrderDisplay';
import { setOrderId, setOrderType } from 'store/Admin/Analyser';
import { filterOrders } from 'helpers/FilterOrders';
import { Button } from '@mui/material';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

function AdminOrderListTable({
  title,
  columns,
  orders,
  orderStatus,
  isLoading,
  isError,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [pageNumbers, setPageNumbers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [titleSearch, setTitleSearch] = useHistoryState('titleSearch', '');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const fieldWidths = {
    title: '26%',
    deadline: '20%',
  };

  useEffect(() => {
    if (!orders) return;

    const filtered = filterOrders(orders, null, titleSearch);
    if (titleSearch && titleSearch.length > 0) {
      setCurrentPage(1);
    }
    setFilteredOrders(filtered);
  }, [titleSearch, orders]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortingChange = (field) => {
    const direction =
      field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
    dispatch(
      handleSorting({
        table: orderStatus,
        sortField: field,
        sortOrder: direction,
      })
    );
    setSortField(field);
    setSortOrder(direction);
  };

  return (
    <div className="content-center">
      <TableHeader
        title={title}
        titleSearch={titleSearch}
        setTitleSearch={setTitleSearch}
        ordersPerPage={ordersPerPage}
        setOrdersPerPage={setOrdersPerPage}
      />
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <ErrorComponent />
      ) : (
        <div className="box-wrapper">
          <div className="table-responsive">
            <div>
              <table className="table-lists table-commandes">
                <thead>
                  <tr>
                    {columns.map(({ label, field, sortable }) => (
                      <th
                        style={{
                          cursor: sortable ? 'pointer' : 'default',
                          width: fieldWidths[field] || '',
                        }}
                        onClick={
                          sortable ? () => handleSortingChange(field) : null
                        }
                        key={field}
                      >
                        {t(label)}
                        {!sortable ? null : field === sortField &&
                          sortOrder === 'asc' ? (
                          <span>
                            <ChevronUpIcon style={{ marginLeft: '3px' }} />
                          </span>
                        ) : (
                          <span>
                            <ChevronDownIcon style={{ marginLeft: '3px' }} />
                          </span>
                        )}{' '}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order, index) => (
                    <tr key={order.id}>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          dispatch(setStatus(ORDERSTATUS.INPROGRESS));
                          dispatch(setOrderId(order.id));
                          dispatch(setOrderType(order.type));
                          dispatch(fetchAdminOrderDisplay(order.id));
                          dispatch(fetchAdminTextOrderDisplay(order.id));
                          dispatch(fetchAdminOrderLineDisplay(order.id));
                          history.push('/Admin/DetailsDeLaCommande');
                        }}
                      >
                        <span>{order?.title}</span>
                      </td>
                      <td>
                        <span>
                          {order.deadline ? formatDate(order.deadline) : '-'}
                        </span>
                      </td>
                      <td>
                        <div>
                          {order.writers.length === 0 ? (
                            <Button disabled>{t('0 rédacteurs')} </Button>
                          ) : (
                            <>
                              <Button
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                onClick={(e) => handleClick(index, e)}
                              >
                                <img
                                  src="/Images/icon-users-down.png"
                                  alt="icon-users-down"
                                />
                              </Button>
                              <CustomDropdownReadOnly
                                handleClose={handleClose}
                                anchorEl={anchorEl && anchorEl[index]}
                                list={order.writers}
                                getOptionLabel={(option) => {
                                  return option.mail;
                                }}
                              />
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.deliveredTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.validatedTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.deployedTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <CircularBar
                          finishedAt={order.finishedAt}
                          orderId={order.id}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <Paginator
        isLoading={isLoading}
        listLength={filteredOrders.length}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        nbElementsPerPage={ordersPerPage}
        goToPage={(page) => setCurrentPage(page)}
      />
    </div>
  );
}

export default AdminOrderListTable;
