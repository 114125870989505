import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axiosPrivate from 'config/axiosPrivate';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { lower } from 'utils/StringUtils';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { adminOrderListSelector } from 'store/Admin/OrderList';
import { adminClientListSelector } from 'store/Admin/ClientList';

import { fetchWebsiteDisplay } from 'store/Admin/WebsiteDisplay/features';
import { fetchWebsiteOrdersList } from 'store/Admin/WebsiteDisplay/features';
import { fetchClientList } from 'store/Admin/ClientList/features';
import { fetchAllOrders } from 'store/Admin/OrderList/features';
import { removeClient } from 'store/Admin/ClientList';
import { setUrlSiteForDisplay } from 'store/Admin/WebsiteDisplay';

import { Loader } from 'components/shared/Loader';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';

import { getBills } from 'store/Admin/BillDisplay/actions';
import SearchIcon from 'icons/SearchIcon';
import './Homepage.css';

const API = {
  deleteWebsite: '/clientWebsite/',
};

export const HomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  let {
    inProgressOrders,
    delayedOrders,
    validatedOrders,
    nonAttributedOrders,
    smartOrders,
    smartWithReviewOrders,
    isLoading,
  } = useSelector(adminOrderListSelector);

  const { t } = useTranslation();
  let { clients, isLoadingClients } = useSelector(adminClientListSelector);

  const [selectedWebsite, setSelectedWebsite] = useState(false);
  const [urlSearch, setUrlSearch] = useHistoryState('urlSearch', '');

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  useEffect(() => {
    document.title = t('Typix - Mon tableau de bord');
    window.dispatchEvent(new Event('locationchange'));
    dispatch(fetchAllOrders());
    dispatch(fetchClientList());
    dispatch(getBills());
  }, [dispatch, t]);

  const DeleteWebsite = () => {
    axiosPrivate
      .delete(API.deleteWebsite + selectedWebsite.id)
      .then(() => {
        setConfirmation(false);
        dispatch(removeClient(selectedWebsite.id));

        setErrorSuccessMsg(
          `${t('Le contrat de')} ${selectedWebsite.urlSite} ${t(
            'a été supprimé avec succès'
          )} !`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setConfirmation(false);
        switch (e.response.status) {
          case 400:
            setErrorSuccessMsg(
              t("il y'a des commandes liés au site web ") +
                selectedWebsite.urlSite
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  return (
    <div>
      <div className="content-center">
        <div className="mb-2">
          <h1>Dashboard</h1>
        </div>
        <div className="row">
          <div className="col-12 col-md-7">
            <div className="box-wrapper">
              <div className="box-list-header">
                <div className="box-title"> {t('Liste des clients')} </div>
                <div className="box-input-text">
                  <div className="input-group input-cs w-100">
                    <div className="input-group-text">
                      <SearchIcon />
                    </div>
                    <input
                      type="text"
                      value={urlSearch}
                      onChange={(event) => setUrlSearch(event.target.value)}
                      className="form-control ps-0"
                      placeholder={t('Rechercher')}
                    />
                  </div>
                </div>
                <div onClick={() => history.push('/Admin/AjouterClient')}>
                  <span className="bt-plus-circle" />
                </div>
              </div>
              {isLoadingClients ? (
                <Loader />
              ) : (
                <div className="table-responsive">
                  <div className="box-table">
                    <table className="table-lists">
                      <thead>
                        <tr>
                          <th>{t('Nom client')}</th>
                          <th>{t('Commandes')}</th>
                          <th>{t('Éditer')}</th>
                          <th>{t('Supprimer')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clients
                          ?.filter((x) => {
                            if (urlSearch && urlSearch.length > 0) {
                              return lower(x.urlSite).includes(
                                lower(urlSearch)
                              );
                            }
                            return true;
                          })
                          .map((item) => (
                            <tr key={item.id} style={{ cursor: 'pointer' }}>
                              <td
                                onClick={() => {
                                  dispatch(fetchWebsiteDisplay(item.urlSite));
                                  history.push('/Admin/FicheClient');
                                }}
                              >
                                <span>{item.urlSite}</span>
                              </td>

                              <td
                                onClick={() => {
                                  dispatch(setUrlSiteForDisplay(item.urlSite));
                                  dispatch(fetchWebsiteOrdersList(item.id));
                                  history.push('/Admin/CommandesduSiteweb');
                                }}
                              >
                                <span className="bt-circle-wh">
                                  {item.commandsAmount}
                                </span>
                              </td>

                              <td
                                onClick={() => {
                                  dispatch(fetchWebsiteDisplay(item.urlSite));
                                  history.push('/Admin/FicheClient');
                                }}
                              >
                                <span className="bt-circle-wh">
                                  <img
                                    src="Images/icon-edit.png"
                                    alt="icon-edit"
                                  />{' '}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="bt-circle-wh"
                                  onClick={() => {
                                    setSelectedWebsite(item);
                                    setConfirmationMsg(
                                      `${t(
                                        'Voulez-vous vraiment supprimer le contrat de'
                                      )} ${item.urlSite} ? `
                                    );
                                    setConfirmation(true);
                                  }}
                                >
                                  <img
                                    src="Images/icon-trash.png"
                                    alt="icon-trash"
                                  />
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-5">
            <div className="row">
              <div className="col-12 col-md-6">
                <div
                  className="card box-wrapper box-pd box-pd-orange"
                  onClick={() => history.push('/Admin/CommandesNonAttribuees')}
                >
                  <div className="box-header-icon">
                    <div className="bt-icon-list bt-icon-attr" />
                  </div>
                  <div className=" text-container label-text text-center text-wrap">
                    {t('Commandes non attribuées')}
                  </div>
                  <div className="box-circle bg-orange">
                    {!isLoading ? nonAttributedOrders.length : 'XXX'}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div
                  className=" card box-wrapper box-pd box-pd-blue"
                  onClick={() => history.push('/Admin/CommandesEnCours')}
                >
                  <div className="box-header-icon">
                    <div className="bt-icon-list bt-icon-en-cour" />
                  </div>
                  <div className=" text-container label-text text-center text-wrap">
                    {t('Commandes en cours')}
                  </div>
                  <div className="box-circle bg-blue">
                    {!isLoading ? inProgressOrders.length : 'XXX'}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div
                  className="card box-wrapper box-pd box-pd-darkorange"
                  onClick={() => history.push('/Admin/CommandesEnRetard')}
                >
                  <div className="box-header-icon">
                    <div className="bt-icon-list bt-icon-en-retard" />
                  </div>
                  <div className="text-container label-text text-center text-wrap">
                    {t('Commandes en retard')}
                  </div>
                  <div className="box-circle bg-darkorange">
                    {!isLoading ? delayedOrders.length : 'XXX'}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div
                  className="card box-wrapper box-pd box-pd-green"
                  onClick={() => history.push('/Admin/CommandesValides')}
                >
                  <div className="box-header-icon">
                    <div className="bt-icon-list bt-icon-valid" />
                  </div>
                  <div className="text-container label-text text-center text-wrap">
                    {t('Commandes validées')}
                  </div>
                  <div className="box-circle bg-green">
                    {!isLoading ? validatedOrders.length : 'XXX'}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div
                  className="card box-wrapper box-pd box-pd-pink"
                  onClick={() => history.push('/Admin/CommandesReview')}
                >
                  <div className="box-header-icon">
                    <div className="bt-icon-list bt-icon-smart-with-review" />
                  </div>
                  <div
                    className="text-container label-text text-center text-wrap"
                    style={{ width: '10rem' }}
                  >
                    {t('Commandes avec révision')}
                  </div>

                  <div className="box-circle bg-pink">
                    {!isLoading ? smartWithReviewOrders.length : 'XXX'}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div
                  className="card box-wrapper box-pd box-pd-grey"
                  onClick={() => history.push('/Admin/CommandesIntelligentes')}
                >
                  <div className="box-header-icon">
                    <div className="bt-icon-list bt-icon-smart" />
                  </div>
                  <div className="text-container label-text text-center text-wrap">
                    {t('Commandes intelligentes')}
                  </div>
                  <div className="box-circle bg-grey">
                    {!isLoading ? smartOrders.length : 'XXX'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={DeleteWebsite}
      />
    </div>
  );
};
