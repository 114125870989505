import CustomDropzone from 'components/shared/Dropzone/ustiomDropzone';
import { memo } from 'react';
import { useDropzone } from 'react-dropzone';

const DropzoneComponent = memo(
  ({ file, t, setFileField, message, visualize }) => {
    const onDrop = (acceptedFiles) => {
      if (acceptedFiles[0].name.endsWith('.pdf')) {
        setFileField(acceptedFiles[0]);
      }
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple: false,
      accept: {
        'application/pdf': ['.pdf'],
      },
    });
    const handleRemoveFile = () => {
      setFileField(null);
    };

    return (
      <CustomDropzone
        name="file"
        message={message}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        isDragActive={isDragActive}
        t={t}
        file={file}
        onRemoveFile={handleRemoveFile}
        visualize={visualize}
      />
    );
  }
);
export default DropzoneComponent;
