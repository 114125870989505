import React from 'react';

import { customStyle, theme } from './CustomAutocompleteStyle';
import { Autocomplete, Box, InputBase, createStyles } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';

const useStyles = makeStyles(() => createStyles(customStyle));

export const CustomChipsAutocomplete = (props) => {
  const classes = useStyles();

  const defaultProps = {
    options: props.list,
    getOptionLabel: (option) => option.thematic,
  };

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        {...defaultProps}
        size="small"
        disableCloseOnSelect
        limitTags={5}
        disabled={props.disabled}
        multiple
        value={props.value}
        onChange={(event, newValues) => {
          props.callbackFromMultiSelect(newValues);
        }}
        isOptionEqualToValue={(option, value) =>
          option.thematic === value.thematic
        }
        className={`${
          props.disabled ? classes.textFieldDisabled : classes.textField
        }`}
        classes={{
          option: classes.option,
          clearIndicatorDirty: classes.clearIndicator,
          popupIndicator: classes.popupIndicator,
        }}
        renderOption={(_props, option, { selected }) => {
          if (props.value.findIndex((element) => element.id === option.id) > -1)
            return (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                {..._props}
              >
                <Box>{props.getOptionLabel(option)}</Box>
                <Box>
                  <img
                    style={{ float: 'right' }}
                    src="Images/icon-check.png"
                    alt="icon-check"
                  />
                </Box>
              </Box>
            );
          else return <span {..._props}>{props.getOptionLabel(option)}</span>;
        }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              classes={{ root: classes.root }}
              variant="outlined"
              {...params.InputProps}
              {...rest}
            />
          );
        }}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <span
              key={props.getOptionLabel(option)}
              className={`${classes.chip}`}
            >
              {props.getOptionLabel(option)}
            </span>
          ));
        }}
      />
    </ThemeProvider>
  );
};
