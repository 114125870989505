import React from 'react';

import './shared.css';

export const CustomSlider = (props) => {
  if (!props.disabled)
    return (
      <div className="slidecontainer">
        <input
          type="range"
          min={1}
          max={3}
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          className={`sliderd slider${props.value}`}
        />
        <span className="ic-false" />
        <span className="ic-true" />
      </div>
    );

  return (
    <div className="slidecontainer slider-disabled">
      <input
        type="range"
        min={1}
        max={3}
        defaultValue={2}
        className="sliderd slider2"
        disabled
      />

      <span className="ic-false" />
      <span className="ic-true" />
    </div>
  );
};
