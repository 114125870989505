import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { adminUserManagementSelector } from 'store/Admin/UserManagement';
import { fetchAdminUserManagement } from 'store/Admin/UserManagement/features';

import { removeWWW, lower } from 'utils/StringUtils';
import { ROLES } from 'utils/Constants';
import { handleSortingChange } from 'helpers/SortingHelper';

import { Paginator } from 'components/shared/Paginator';

import { DeleteUser } from 'components/Admin/DeleteUser';
import { DisableUser } from 'components/Admin/DisableUser';

import { CreateUpdateUser } from 'components/Admin/CreateUpdateUser';
import {
  CustomDropdownReadOnly,
  CustomDropdownWithSelect,
} from 'components/shared/DropDown/CustomDropdown';
import { Loader } from 'components/shared/Loader';

import ErrorComponent from 'components/shared/ErrorComponent';
import { Button } from '@mui/material';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

const pageNbOptions = [15, 20, 30];

export default function UsersManagement() {
  let { userList, isLoading, isError } = useSelector(
    adminUserManagementSelector
  );
  const { t } = useTranslation();
  const columns = [
    { label: t('Prénom/Nom'), field: 'name', sortable: true, sort: 'asc' },
    { label: t('Mail'), field: 'mail', sortable: true, sort: 'asc' },
    { label: t('Rôle'), field: 'role', sortable: false },
    { label: t('Site'), field: 'websitesUrls', sortable: true, sort: 'asc' },
    {
      label: t('Analyse/Limite'),
      field: 'analysis',
      sortable: true,
      sort: 'asc',
    },
    { label: t('Modifier'), field: 'modify', sortable: false },
    { label: t('Désactiver'), field: 'disable', sortable: false },
    { label: t('Supprimer'), field: 'delete', sortable: false },
  ];

  const createUserMenuList = [
    { text: t('Ajouter un admin'), role: ROLES.ADMIN },
    { text: t('Ajouter un consultant'), role: ROLES.CONSULTANT },
    { text: t('Ajouter un client'), role: ROLES.CLIENT },
    { text: t('Ajouter un rédacteur'), role: ROLES.WRITER },
  ];

  const filterRoleMenuList = [
    { text: t('Tous les rôles'), role: '' },
    { text: t('Admins'), role: ROLES.ADMIN },
    { text: t('Clients'), role: ROLES.CLIENT },
    { text: t('Consultants'), role: ROLES.CONSULTANT },
    { text: t('Rédacteurs'), role: ROLES.WRITER },
  ];
  const dispatch = useDispatch();

  const [selectedUserForDelete, setSelectedUserForDelete] = useState(null);
  const [selectedUserForDisable, setSelectedUserForDisable] = useState(null);

  const [selectedUserForCreateUpdate, setSelectedUserForCreateUpdate] =
    useState(null);

  const [selectedRole, setSelectedRole] = useState(filterRoleMenuList[0]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [usersPerPage, setUsersPerPage] = useState(15);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const [showDelete, setShowDelete] = useState(false);
  const [showDisable, setShowDisable] = useState(false);

  const [showCreateUpdate, setShowCreateUpdate] = useState(false);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    document.title = t('Typix - Gestion des utilisateurs');
    dispatch(fetchAdminUserManagement());
  }, [dispatch, t]);

  const formatRole = useCallback(
    (role) => {
      return role === ROLES.WRITER ? t('rédacteur') : lower(role);
    },
    [t]
  );

  useEffect(() => {
    let array = [...userList];

    if (selectedRole.role.length > 0) {
      array = array.filter((x) => {
        return lower(x.role).includes(lower(selectedRole.role));
      });
    }

    if (searchValue && searchValue.length > 0) {
      let lowerCaseValue = lower(searchValue);
      array = array.filter((x) => {
        return (
          x.name?.toLowerCase().includes(lowerCaseValue) ||
          x.firstName?.toLowerCase().includes(lowerCaseValue) ||
          x.lastName?.toLowerCase().includes(lowerCaseValue) ||
          lower(x.mail).includes(lowerCaseValue) ||
          formatRole(x.role).includes(lowerCaseValue) ||
          (x.websitesUrls?.length > 0 &&
            lower(x.websitesUrls[0]).includes(lowerCaseValue))
        );
      });
    }

    setFilteredUsers([...array]);
  }, [selectedRole, searchValue, userList, formatRole]);

  useEffect(() => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    setCurrentUsers(filteredUsers.slice(indexOfFirstUser, indexOfLastUser));
    setPageNumbers(
      [...Array(Math.ceil(filteredUsers.length / usersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredUsers, usersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      setCurrentPage(1);
      const sorted = [...filteredUsers].sort((a, b) => {
        //admin have name null instead he has firstname and lastname

        if (sortField === 'name' && a['role'] === ROLES.ADMIN) {
          if (b['role'] === ROLES.ADMIN) {
            return (
              a['firstName']
                .toString()
                .localeCompare(b['firstName'].toString(), 'en', {
                  numeric: true,
                }) * (sortOrder === 'asc' ? 1 : -1)
            );
          } else
            return (
              a['firstName']
                .toString()
                .localeCompare(b[sortField].toString(), 'en', {
                  numeric: true,
                }) * (sortOrder === 'asc' ? 1 : -1)
            );
        }

        if (a[sortField] === undefined || a[sortField] === null) return 1;
        if (b[sortField] === undefined || b[sortField] === null) return -1;

        if (sortField === 'websitesUrls') {
          if (a[sortField].length === 0) return 1;
          if (b[sortField].length === 0) return -1;

          return (
            a[sortField][0]
              .toString()
              .localeCompare(b[sortField][0].toString(), 'en', {
                numeric: true,
              }) * (sortOrder === 'asc' ? 1 : -1)
          );
        }

        if (sortField === 'analysis') {
          return (
            (a['limit'] - a['analysis'] - (b['limit'] - b['analysis'])) *
            (sortOrder === 'asc' ? 1 : -1)
          );
        }

        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true,
          }) * (sortOrder === 'asc' ? 1 : -1)
        );
      });

      setFilteredUsers(sorted);
    }
  };

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="content-center">
      <div className="box-info-header">
        <div className="b-child-1 d-flex align-items-center">
          <h1>{t('Gestion des utilisateurs')}</h1>
          <div className="dropdown">
            <span
              className="bt-plus-circle-yellow ms-2"
              id="dropdownMenu2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            />
            <ul
              className="dropdown-menu menu-list menu-st"
              aria-labelledby="dropdownMenu2"
            >
              {createUserMenuList.map((item, index) => (
                <li
                  key={index}
                  className={
                    index === 0
                      ? 'first-child'
                      : index === 3
                      ? 'last-child'
                      : ''
                  }
                >
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelectedUserForCreateUpdate({
                        user: null,
                        role: item.role,
                      });
                      setShowCreateUpdate(true);
                    }}
                  >
                    {item.text}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="b-child-2">
          <div className="d-flex  justify-content-end">
            <div className="box-input-text pe-0">
              <div className="input-group input-cs w-100 pe-0">
                <div className="input-group-text">
                  <SearchIcon />
                </div>
                <input
                  type="text"
                  onChange={(event) => setSearchValue(event.target.value)}
                  className="form-control ps-0"
                  placeholder={t('Rechercher par mail, prénom, nom, site...')}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className="me-2">{t('Afficher les résultats')}</span>
              <div className="dropdown" style={{ width: 67 }}>
                <button
                  className="btn dropdown-toggle dropdown-custom w-100"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {usersPerPage}

                  <span>
                    <ChevronDownIcon />
                  </span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {pageNbOptions
                    .filter((nb) => nb !== usersPerPage)
                    .map((item, index) => (
                      <li key={index} onClick={() => setUsersPerPage(item)}>
                        <button className="dropdown-item">{item}</button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : isError ? (
        <ErrorComponent />
      ) : (
        <div className="box-wrapper">
          <div className="table-responsive">
            <div>
              <table className="table-lists table-guestion">
                <thead>
                  <tr>
                    {columns.map(({ label, field, sortable }) => (
                      <th
                        style={{
                          cursor: sortable ? 'pointer' : 'default',
                          width: field === 'name' ? '26%' : '20%',
                        }}
                        onClick={
                          sortable
                            ? () =>
                                handleSortingChange(
                                  field,
                                  sortField,
                                  sortOrder,
                                  setSortField,
                                  setSortOrder,
                                  handleSorting
                                )
                            : null
                        }
                        key={field}
                      >
                        {label}
                        {!sortable ? null : field === sortField &&
                          sortOrder === 'asc' ? (
                          <span>
                            <ChevronUpIcon style={{ marginLeft: '3px' }} />
                          </span>
                        ) : (
                          <span>
                            <ChevronDownIcon style={{ marginLeft: '3px' }} />
                          </span>
                        )}
                        {field === 'role' ? (
                          <>
                            <button
                              aria-controls="customized-menu"
                              aria-haspopup="true"
                              onClick={(e) => handleClick('-1', e)}
                              style={{
                                padding: '5px',
                                border: 'none',
                                background: 'none',
                              }}
                            >
                              {selectedRole.role.length > 0 ? (
                                <img
                                  style={{
                                    width: '25px',
                                    height: '25px',
                                  }}
                                  src="/Images/icon-filter-hover.png"
                                  alt="filter"
                                />
                              ) : (
                                <img
                                  src="/Images/icon-filter.png"
                                  alt="filter"
                                />
                              )}
                            </button>
                            <CustomDropdownWithSelect
                              value={selectedRole}
                              handleClose={handleClose}
                              anchorEl={anchorEl && anchorEl['-1']}
                              list={filterRoleMenuList}
                              setValue={(index) =>
                                setSelectedRole(filterRoleMenuList[index])
                              }
                              getOptionLabel={(option) => {
                                return option.text;
                              }}
                            />
                          </>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user, index) => (
                    <tr key={index}>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedUserForCreateUpdate({
                            user: user,
                            role: user.role,
                          });
                          setShowCreateUpdate(true);
                        }}
                      >
                        <span>
                          {' '}
                          {user.role === ROLES.ADMIN
                            ? user.firstName + ' ' + user.lastName
                            : user.name}
                        </span>
                      </td>
                      <td>
                        <a href={`mailto:${user.mail}`}>{user.mail}</a>
                      </td>
                      <td>
                        <span>{formatRole(user.role)}</span>
                      </td>
                      <td>
                        <span style={{ color: '#bdbdbd' }}>
                          {user.role === ROLES.CLIENT ? (
                            user.websitesUrls.length === 0 ? (
                              <span>Aucun site</span>
                            ) : (
                              <>
                                <Button
                                  style={{
                                    textTransform: 'none',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    color: '#3C3C3C',
                                    fontFamily:
                                      "'Poppins', sans-serif !important",
                                  }}
                                  aria-controls="customized-menu"
                                  aria-haspopup="true"
                                  onClick={(e) => handleClick(index, e)}
                                >
                                  {removeWWW(user.websitesUrls[0])}
                                </Button>
                                <CustomDropdownReadOnly
                                  handleClose={handleClose}
                                  anchorEl={anchorEl && anchorEl[index]}
                                  list={user.websitesUrls}
                                  getOptionLabel={(option) => {
                                    return option;
                                  }}
                                />
                              </>
                            )
                          ) : null}
                        </span>
                      </td>
                      <td>
                        <span>
                          {' '}
                          {user.role === ROLES.WRITER &&
                            user.analysis + '/' + user.limit}
                        </span>
                      </td>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedUserForCreateUpdate({
                            user: user,
                            role: user.role,
                          });
                          setShowCreateUpdate(true);
                        }}
                      >
                        <span className="bt-circle-wh">
                          <img src="/Images/icon-edit.png" alt="edit" />{' '}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedUserForDisable({
                              user: user,
                              role: user.role,
                            });

                            setShowDisable(true);
                          }}
                          className="bt-circle-wh"
                          role="button"
                        >
                          {user && user.active ? (
                            <img src="/Images/icon-disable.png" alt="disable" />
                          ) : (
                            <img src="Images/icon-enable.png" alt="enable" />
                          )}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedUserForDelete({
                              mail: user.mail,
                              role: user.role,
                            });
                            setShowDelete(true);
                          }}
                          className="bt-circle-wh"
                          role="button"
                        >
                          <img src="/Images/icon-trash.png" alt="trash" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <Paginator
        isLoading={isLoading}
        listLength={filteredUsers.length}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        nbElementsPerPage={usersPerPage}
        goToPage={(page) => setCurrentPage(page)}
      />

      {showDelete && (
        <DeleteUser
          selected={selectedUserForDelete}
          Close={() => {
            setShowDelete(false);
          }}
        />
      )}
      {showDisable && (
        <DisableUser
          selected={selectedUserForDisable}
          Close={() => {
            setShowDisable(false);
          }}
        />
      )}

      {showCreateUpdate && (
        <CreateUpdateUser
          selected={selectedUserForCreateUpdate}
          Close={() => {
            setShowCreateUpdate(false);
          }}
        />
      )}
    </div>
  );
}
