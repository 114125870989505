import { createSlice } from '@reduxjs/toolkit';
import { fetchClientText } from './features';

export const clientAnalyzer = createSlice({
  name: 'clientAnalyzer',
  initialState: {
    orderId: null,
    orderType: null,
    textForm: null,
    isLoading: true,
    isError: true,
    writerCommandId: null,
    textType: 'NON_TRANSLATED',
  },
  reducers: {
    updateForm: (state, { payload }) => {
      state.textForm = payload;
      state.isLoading = false;
      state.isError = false;
    },
    updateTextForm: (state, { payload }) => {
      let object = { ...state.textForm };
      object[payload.key] = payload.value;
      state.textForm = { ...object };
    },
    setClientOrderId: (state, { payload }) => {
      state.orderId = payload;
    },
    setWriterCommandId: (state, { payload }) => {
      state.writerCommandId = payload;
    },
    setOrderType: (state, { payload }) => {
      state.orderType = payload;
    },
    setTextType: (state, { payload }) => {
      state.textType = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientText.fulfilled, (state, { payload }) => {
        state.textForm = payload;
        state.isLoading = false;
      })
      .addCase(fetchClientText.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchClientText.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default clientAnalyzer.reducer;

export const {
  updateForm,
  updateTextForm,
  setClientOrderId,
  setOrderType,
  setWriterCommandId,
  setTextType,
} = clientAnalyzer.actions;
export const clientAnalyzerSelector = (state) => state.clientAnalyzer;
