import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnalyseText from 'components/Admin/AnalyseText';

import { ANALYSELANGAUGESLIST } from 'utils/Constants';

import { CustomEditor } from 'components/shared/CustomEditor';
import { CustomHighcharts } from 'components/shared/CustomHighcharts';
import { CustomTooltip } from 'components/shared/CustomTooltip';
import { Loader } from 'components/shared/Loader';

import { capitalize } from 'helpers/Capitalize';
import { ChevronDownIcon } from 'icons/ChevronIcon';
import { useHistory } from 'react-router-dom';

function Analyzer() {
  const { t } = useTranslation();

  const history = useHistory();
  const [actualHtmlContent, setActualHtmlContent] = useState('');
  const [analyseText, setAnalyseText] = useState('');

  const [analyseResult, setAnalyseResult] = useState('');
  const [list, setList] = useState({});

  const [keywords, setKeywords] = useState('');
  const [language, setLanguage] = useState(ANALYSELANGAUGESLIST[0]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = t('Typix - Détails du texte');
    window.dispatchEvent(new Event('locationchange'));
  }, [t]);

  return (
    <div className="content-center">
      <div className="content-center">
        <div className="box-header-top header-info">
          <span className="bt-circle me-3" onClick={history.goBack}></span>
          <h1>OFFLINE COMPARISON</h1>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <div className="mt-3">
              <div className="label-title label-text-h">
                {t('Gap sémantique')} :
                <CustomTooltip
                  title={
                    <div style={{ marginLeft: '10px' }}>
                      <div className="row">{t('Format souhaité')} : </div>
                      <div className="row">{t('mot-clé (fréquence)')}</div>
                      <div className="row">
                        {t('Séparez les mots-clés par un " Enter ".')}
                      </div>
                      <div className="row">
                        ---------------------------------
                      </div>
                      <div className="row">{t('Exemple')} : </div>
                      <div className="row">{t('mot-clé')}1 (2)</div>
                      <div className="row">{t('mot-clé')}2 (1)</div>
                      <div className="row">{t('mot-clé')}3 (1)</div>
                      <div className="row">{t('mot-clé')}4 (2)</div>
                    </div>
                  }
                  placement="top"
                >
                  <button
                    type="button"
                    className="btn-circle"
                    style={{ marginLeft: 5 }}
                  ></button>
                </CustomTooltip>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-5 box-wrapper">
                <textarea
                  className=" col-12 box-list-text"
                  placeholder={t(
                    'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n…'
                  )}
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                >
                  <div></div>
                </textarea>
              </div>
              <div className="col-12 col-md-3">
                <div
                  className="box-wrapper box-list-text"
                  style={{ height: '284px; !important' }}
                >
                  <div className="header-title mb-0">
                    {t('Résultat sémantique')}
                  </div>
                  <div className="sub-header-title">
                    {t('Enrichissement sémantique')} :
                  </div>
                  <div className="box-graph">
                    {loading && <Loader />}
                    {analyseResult && analyseResult.semanticEnrichment >= 0 ? (
                      <div className="d-flex justify-content-center">
                        <CustomHighcharts
                          value={analyseResult.semanticEnrichment}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="box-wrapper">
                  <div className="header-title mb-0">
                    {t('Mots clés manquants')} :
                  </div>
                  <br />
                  <div className="box-list-text box-motcle">
                    {loading && <Loader />}
                    <ul>
                      {analyseResult &&
                      analyseResult.greenWordsByFreq
                        .greenWordsWithRemainingFreq &&
                      Object.keys(
                        analyseResult.greenWordsByFreq
                          .greenWordsWithRemainingFreq
                      ).length > 0 ? (
                        <div>
                          <b>
                            {t('Ajout de mots-clés avec la fréquence restante')}{' '}
                            :{' '}
                          </b>
                          <p>
                            {Object.keys(
                              analyseResult.greenWordsByFreq
                                .greenWordsWithRemainingFreq
                            ).map((item, key) => (
                              <li key={key}>
                                {' '}
                                <span>
                                  {' '}
                                  {item} (
                                  <span
                                    style={{
                                      textDecorationLine: 'line-through',
                                    }}
                                  >
                                    {list[item]}
                                  </span>
                                  ,
                                  {
                                    analyseResult.greenWordsByFreq
                                      .greenWordsWithRemainingFreq[item]
                                  }
                                  ){' '}
                                </span>{' '}
                              </li>
                            ))}
                          </p>
                        </div>
                      ) : null}
                      {analyseResult &&
                      analyseResult.remainingKeywords &&
                      Object.keys(analyseResult.remainingKeywords).length >
                        0 ? (
                        <div>
                          <b>{t('Mots clés restants')} :</b>
                          <p>
                            {Object.keys(analyseResult.remainingKeywords).map(
                              (item, key) => (
                                <li key={key}>
                                  {' '}
                                  <span>
                                    {' '}
                                    {item} (
                                    {analyseResult.remainingKeywords[item]}){' '}
                                  </span>{' '}
                                </li>
                              )
                            )}
                          </p>
                        </div>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 col-md-12">
                <div>
                  <div className="label-title label-text-h">
                    {t('Contenu rédigé')}
                  </div>

                  <div className="box-wrapper-editor">
                    <CustomEditor
                      disabled={false}
                      content={actualHtmlContent}
                      onChange={(value, editor) => {
                        setActualHtmlContent(value);
                        let textValue = editor.getContent({
                          format: 'text',
                        });
                        setAnalyseText(textValue);
                      }}
                      t={t}
                      orderType={'PREMIUM'}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-12 col-md-6">
                <div className="box-botom-flex">
                  <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                    <div>
                      <div className="box-list-dropdown">
                        {t('Langue')}
                        <div
                          className="dropdown"
                          style={{ width: 170, marginLeft: 5 }}
                        >
                          <button
                            className="btn dropdown-toggle dropdown-custom w-100"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {t(capitalize(language.language.toLowerCase()))}
                            <span>
                              <ChevronDownIcon />
                            </span>
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            {' '}
                            {ANALYSELANGAUGESLIST.map((item, index) => (
                              <li key={index} onClick={() => setLanguage(item)}>
                                <button className="dropdown-item">
                                  {t(
                                    capitalize(item.language.toLowerCase())
                                  ).toUpperCase()}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="box-botom-flex">
                  <div
                    className="d-flex "
                    style={{ paddingLeft: 10, paddingTop: 10 }}
                  >
                    <div>
                      <div className="d-flex justify-content-end">
                        <span className="me-2">
                          <AnalyseText
                            orderType={'PREMIUM'}
                            keywords={keywords}
                            text={analyseText}
                            content={actualHtmlContent}
                            setContent={(value) => {
                              setActualHtmlContent(value);
                            }}
                            language={language}
                            setList={setList}
                            setResult={(value) => setAnalyseResult(value)}
                            setLoading={(value) => setLoading(value)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analyzer;
