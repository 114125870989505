import { useField } from '@unform/core';
import { useEffect, useRef } from 'react';

import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import './styles.css';

export default function InputFieldWithArrow({
  name,
  style,
  greyButton,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div
        style={style}
        className={
          name === 'textAmount'
            ? 'number-wrapper'
            : name === 'price'
            ? 'number-wrapper input-group input-grl  '
            : name === 'bill'
            ? 'number-wrapper input-group input-bill number-group  input-grl bill-number'
            : 'number-wrapper input-group input-gr number-group'
        }
      >
        {name === 'price' || name === 'bill' ? (
          <span
            style={greyButton ? { backgroundColor: '#e0e0e0' } : {}}
            className={'input-group-text '}
          >
            €
          </span>
        ) : null}
        <input
          ref={inputRef}
          className="form-control form-ct-bill"
          style={
            greyButton ? { backgroundColor: '#e0e0e0', color: '#828282' } : {}
          }
          defaultValue={defaultValue}
          {...rest}
        />
        <UpDownInputArrows disabled={greyButton ? true : false} />
        {name === 'Min' || name === 'Max' || name === 'bill' ? (
          <span className="input-group-text">{name}.</span>
        ) : null}
      </div>
      {error && <span className="error-note">{error}</span>}
      {rest.error && <span className="error-note">{rest.error}</span>}
    </>
  );
}
