import React from 'react';
import { NavMenu } from 'components/shared/Layout/NavMenu';

export const MainLayout = ({ children }) => {
  return (
    <div>
      <NavMenu />
      <div className="wrapper-content">
        {children}
        <div className="text-note">
          Typix | support-typix@pixalione.com | © Pixalione
        </div>
      </div>
    </div>
  );
};

export const ClientMainLayout = ({ children }) => {
  return (
    <div>
      <NavMenu />
      <div className="wrapper-content-c">
        {children}
        <div className="text-note">
          Typix | support-typix@pixalione.com | © Pixalione
        </div>
      </div>
    </div>
  );
};
