import { classes } from 'components/shared/modal/modalClasses';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@mui/material';
import { Loader } from 'components/shared/Loader';
import axiosFacturation from 'config/axiosFacturation';
import { Modal } from 'reactstrap';

export const ModifcationDemandModal = ({
  open,
  handleClose,
  writerCommandID,
  changeErrorSuccessMsg,
  setErrorSuccess,
  modifStatus,
  setModifStatus,
}) => {
  const [text, setText] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const maxCharacterCount = 500;

  const handleTextChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= maxCharacterCount) {
      setText(inputValue);
      setWordCount(inputValue.length);
    }
  };

  const handleSendMessage = async (data) => {
    setModifStatus('loading');
    try {
      await axiosFacturation.post(
        `/admin/modification demand?writer command id=${writerCommandID}`,
        {
          message: text,
        }
      );
      setModifStatus('succeeded');

      changeErrorSuccessMsg(
        t('La demande de modification a été envoyée avec succès !')
      );
    } catch (error) {
      setModifStatus('error');
      changeErrorSuccessMsg(t("Oups un erreur c'est produit!"));
    }
    setErrorSuccess(true);
  };

  const { t } = useTranslation();
  return (
    <Modal style={classes.modalCustom} isOpen={open}>
      <div style={classes.modalHeader} className="modal-header">
        <h5 style={classes.title} className="modal-title">
          {t('Demande de modification')} ?
        </h5>
        <button
          type="button"
          onClick={handleClose}
          style={classes.btnClose}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      {modifStatus !== 'loading' ? (
        <div style={classes.modalPad} className="modal-body">
          <div className="row row-mb-lg">
            <div className="col-12 col-md-12">
              <TextField
                id="outlined-multiline-flexible"
                placeholder={t('Vos demandes')}
                multiline
                value={text}
                maxRows={6}
                minRows={4}
                onChange={handleTextChange}
                style={{ width: '398px' }}
              />{' '}
              <p style={classes.Description}>
                {' '}
                {Math.round(wordCount)}/{maxCharacterCount}
              </p>
            </div>
          </div>
          <div style={classes.boxBtConfirmRight}>
            <button
              style={{ backgroundColor: '#ee3900' }}
              onClick={handleClose}
              className="confirm me-2"
            >
              {t('Annuler')}
            </button>
            <button
              style={{ backgroundColor: '#43b929' }}
              onClick={handleSendMessage}
              className="confirm"
            >
              {t('Envoyer')}
            </button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};

export default ModifcationDemandModal;
