import React from 'react';
import { useTranslation } from 'react-i18next';

import axiosPrivate from 'config/axiosPrivate';

export const DownloadRevisionDocument = ({ textId, fileName }) => {
  const { t } = useTranslation();

  const downloadFile = async (fileName) => {
    const response = await axiosPrivate.get(
      `texts/${textId}/downloadRevisionFile/${fileName}
    `,
      { responseType: 'blob' }
    );

    const downloadUrl = window.URL.createObjectURL(response.data);

    let a = document.createElement('a');
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
    a.remove();
  };

  return (
    <div className="download-review">
      <button onClick={() => downloadFile(fileName)}>
        {t('Télécharger le document de révision')}
      </button>
    </div>
  );
};
