import React from 'react';
import TextCheckBox from 'components/shared/TextCheckBox';

const TextCheckboxTableRow = ({
  text,
  order,
  selectedTextsForRevision,
  deploymentQueue,
  setDeploymentQueue,
  selectedTextsForValidation,
  setSelectedTextsForRevision,
  setSelectedTextsForValidation,
  isEligibleForRevision,
  isAwaitingDeployment,
  isEligibleForValidation,
}) => {
  return (
    <td>
      <TextCheckBox
        orderType={order.type}
        text={text}
        selectedTextsForRevision={selectedTextsForRevision}
        deploymentQueue={deploymentQueue}
        setDeploymentQueue={setDeploymentQueue}
        selectedTextsForValidation={selectedTextsForValidation}
        setSelectedTextsForRevision={setSelectedTextsForRevision}
        setSelectedTextsForValidation={setSelectedTextsForValidation}
        isEligibleForRevision={isEligibleForRevision}
        isAwaitingDeployment={isAwaitingDeployment}
        isEligibleForValidation={isEligibleForValidation}
      />
    </td>
  );
};

export default TextCheckboxTableRow;
