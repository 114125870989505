import React from 'react';
import { useTranslation } from 'react-i18next';

import { customStyle, theme } from './CustomAutocompleteStyle';
import { Autocomplete, InputBase, createStyles } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';

const useStyles = makeStyles(() => createStyles(customStyle));

export const CustomAutocompleteMultipleChoice = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const defaultProps = {
    options: props.list,
    getOptionLabel: (option) => option.language,
  };

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        {...defaultProps}
        size="small"
        multiple
        disableCloseOnSelect
        limitTags={2}
        value={props.value}
        onChange={(event, newValues) => {
          props.callbackFromMultiSelect(newValues);
        }}
        // getOptionSelected={(option, value) =>
        //   option.language === value.language
        // }
        classes={{
          option: classes.option,
          clearIndicatorDirty: classes.clearIndicator,
          popupIndicator: classes.popupIndicator,
        }}
        className={`${classes.textField}`}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              classes={{ root: classes.root }}
              variant="outlined"
              placeholder={t('Autres')}
              {...params.InputProps}
              {...rest}
            />
          );
        }}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <span
              key={props.getOptionLabel(option)}
              className={`${classes.chip}`}
            >
              {props.getOptionLabel(option)}
            </span>
          ));
        }}
      />
    </ThemeProvider>
  );
};
