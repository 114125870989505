import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { MainLayout } from 'components/shared/Layout/MainLayout';
import DashboardPage from 'pages/writer/DashboardPage';
import WriterProfile from 'pages/writer/WriterProfile';
import OrderDisplayByClient from 'pages/writer/OrderDisplayByClient';
import WriterValidatedOrders from 'pages/writer/WriterValidatedOrders';
import WriterDelayedOrders from 'pages/writer/WriterDelayedOrders';
import WriterInProgressOrders from 'pages/writer/WriterInProgressOrders';
import WriterNonAttributedOrders from 'pages/writer/WriterNonAttributedOrders';
import WriterOrderDisplay from 'pages/writer/WriterOrderDisplay';
import WriterCreateText from 'pages/writer/WriterCreateText';
import WriterReviewOrders from 'pages/writer/WriterReviewOrders';
import WriterFacutarionPage from 'pages/writer/Facturation';
import WriterFAQ from 'pages/writer/WiterFAQ';

function WriterMainMenu() {
  const routes = [
    <Route
      path="/Redacteur/MainMenu"
      render={(props) => <DashboardPage {...props} reload={true} />}
    />,
    <Route path="/Redacteur/MonProfile" component={WriterProfile} />,
    <Route
      path="/Redacteur/AffichageDesCommandes"
      component={OrderDisplayByClient}
    />,
    <Route path="/Redacteur/MesFactures" component={WriterFacutarionPage} />,
    <Route
      path="/Redacteur/CommandesValides"
      component={WriterValidatedOrders}
    />,
    <Route
      path="/Redacteur/CommandesEnRetard"
      component={WriterDelayedOrders}
    />,
    <Route
      path="/Redacteur/CommandesEnCours"
      component={WriterInProgressOrders}
    />,
    <Route
      path="/Redacteur/CommandesNonAttribuée"
      component={WriterNonAttributedOrders}
    />,
    <Route path="/Redacteur/CommandesReview" component={WriterReviewOrders} />,
    <Route
      path="/Redacteur/DetailsDeLaCommande"
      component={WriterOrderDisplay}
    />,
    <Route path="/Redacteur/TextDetails" component={WriterCreateText} />,
    <Route path="/Redacteur/FAQ" component={WriterFAQ} />,
    <Route path="*">
      <Redirect to="/Redacteur/MainMenu" />
    </Route>,
  ];

  return (
    <MainLayout>
      {routes.map((route, index) => (
        <div key={index}>{route}</div>
      ))}
    </MainLayout>
  );
}

export default WriterMainMenu;
