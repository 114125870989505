import axiosPrivate from 'config/axiosPrivate';
import { fetchAllOrders } from 'store/Admin/OrderList/features';
import {
  fetchAdminTextOrderDisplay,
  fetchAdminOrderLineDisplay,
} from 'store/Admin/OrderDisplay/features';
import { fetchWebsiteOrdersList } from 'store/Admin/WebsiteDisplay/features';
import { deliverTextsOfTextList } from 'store/Admin/OrderDisplay';
import { clientValidateTextsOfTextList } from 'store/Client/OrderDisplay';

import { ROLES } from 'utils/Constants';
import { fetchClientOrderDisplay } from 'store/Client/OrderDisplay/features';

export const GlobalTextsValidation = async (
  selectedTextsForValidation,
  orderId,
  orderTextAmount,
  websiteId,
  role,
  setConfirmation,
  setIsSuccess,
  setErrorSuccessMsg,
  setErrorSuccess,
  t,
  dispatch
) => {
  try {
    const isAllTextsSelected =
      selectedTextsForValidation.length === orderTextAmount;

    if (isAllTextsSelected) {
      await axiosPrivate.put(`/commands/${orderId}/validateAllTexts`);
    } else {
      await axiosPrivate.post(
        '/texts/multi-validate',
        selectedTextsForValidation
      );
    }

    setConfirmation(false);
    await dispatch(
      clientValidateTextsOfTextList({
        selectedTextsForValidation,
        key: 'validated',
        value: true,
      })
    );

    await dispatch(fetchClientOrderDisplay(orderId));

    setIsSuccess(true);
    setErrorSuccessMsg(
      t(
        selectedTextsForValidation.length > 1
          ? 'Les textes ont été validés avec succès.'
          : 'Le texte a été validé avec succès.'
      )
    );
    setErrorSuccess(true);
  } catch (error) {
    console.error('An error occurred during text validation:', error.message);
  }
};

export const GlobalTextsDlivery = async (
  selectedTextsForDelivery,
  orderId,
  orderTextAmount,
  websiteId,
  role,
  setConfirmation,
  setIsSuccess,
  setErrorSuccessMsg,
  setErrorSuccess,
  t,
  dispatch
) => {
  try {
    const isAllTextsSelected =
      selectedTextsForDelivery.length === orderTextAmount;

    if (isAllTextsSelected) {
      await axiosPrivate.put(`/commands/${orderId}/deliverAllTexts`);
    } else {
      await axiosPrivate.post('/texts/admin-deliver', selectedTextsForDelivery);
    }

    setConfirmation(false);
    await dispatch(
      deliverTextsOfTextList({
        selectedTextsForDelivery,
        key: 'delivered',
        value: true,
      })
    );

    await dispatch(fetchAllOrders());
    await dispatch(fetchWebsiteOrdersList(websiteId));
    await dispatch(fetchAdminOrderLineDisplay(orderId));

    setIsSuccess(true);
    setErrorSuccessMsg(
      t(
        selectedTextsForDelivery.length > 1
          ? 'Les textes ont été livrés avec succès.'
          : 'Le texte a été livré avec succès.'
      )
    );
    setErrorSuccess(true);
  } catch (error) {
    console.error('An error occurred during text validation:', error.message);
  }
};
