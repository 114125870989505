import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { formatDate } from 'utils/DateUtils';

import { setStatus } from 'store/Admin/OrderDisplay';
import {
  fetchAdminOrderDisplay,
  fetchAdminTextOrderDisplay,
  fetchAdminOrderLineDisplay,
} from '../../store/Admin/OrderDisplay/features';

import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import { ORDERSTATUS } from 'utils/Constants';
import { adminOrderListSelector } from 'store/Admin/OrderList';
import { updateWriterOffer } from 'store/Admin/OrderList';

import { countAcceptedWritersOffers } from 'helpers/WritersHelper';

import { setOrderId, setOrderType } from 'store/Admin/Analyser';

import { CustomSlider } from '../shared/CustomSlider';
import i18n from 'i18next';

export const RenderMultipleWriterPropositionTableRow = (props) => {
  let { writersOffers } = useSelector(adminOrderListSelector);

  const history = useHistory();
  const dispatch = useDispatch();

  const OrderRow = ({ order }) => (
    <tr>
      <td rowSpan={order.writerPropositions.length + 1}>
        <div className="d-flex">
          {order.title}{' '}
          <div
            onClick={() => props.toggleExpanded(order)}
            className="box-collapse pt-2"
          >
            <img src="/Images/icon-users-right.png" alt="icon-users" />
          </div>
        </div>
      </td>
    </tr>
  );

  const WriterRow = ({ writer }) => (
    <tr>
      <td>{writer.name}</td>
      <td>
        <div
          className="number-wrapper wd-66"
          style={{ width: '85px', margin: '0 auto' }}
        >
          <input
            type="number"
            className="form-control form-ct"
            min="0"
            value={getValue('textAmount', writer.id)}
            onChange={(e) =>
              updateValue('textAmount', e.target.value, writer.id)
            }
          />
          <UpDownInputArrows disabled={false} />
        </div>
      </td>
      <td>
        <div style={{ marginTop: '5px', marginBottom: '5px' }}>
          <div
            className="number-wrapper wd-80"
            style={{ display: 'inline-block', verticalAlign: 'top' }}
          >
            <input
              type="number"
              min="0"
              value={getValue('minWordsAmount', writer.id)}
              className="form-control form-ct"
              onChange={(e) =>
                updateValue('minWordsAmount', e.target.value, writer.id)
              }
            />
            <UpDownInputArrows disabled={false} />
          </div>
          <div
            className="number-wrapper wd-80"
            style={{ display: 'inline-block', verticalAlign: 'top' }}
          >
            <input
              type="number"
              min="0"
              value={getValue('maxWordAmount', writer.id)}
              className="form-control form-ct"
              onChange={(e) =>
                updateValue('maxWordAmount', e.target.value, writer.id)
              }
            />
            <UpDownInputArrows disabled={false} />
          </div>
        </div>
      </td>
      <td>
        <div className="box-sub-ch" style={{ marginTop: '0px' }}>
          <div className="box-checkbox">
            <input
              className="form-check-input"
              value={getValue('actualBilling', writer.id)}
              onChange={(e) =>
                updateValue('actualBilling', e.target.checked, writer.id)
              }
              type="checkbox"
            />
          </div>
        </div>
      </td>
      <td>
        <div style={{ marginTop: '5px', marginBottom: '5px' }}>
          <div className="number-wrapper wd-66">
            <input
              type="number"
              min="0"
              className="form-control form-ct"
              value={getValue('wordsManagedNumber', writer.id)}
              onChange={(e) =>
                updateValue('wordsManagedNumber', e.target.value, writer.id)
              }
            />
            <UpDownInputArrows disabled={false} />
          </div>
        </div>
      </td>
      <td>
        <span>{getValue('price', writer.id)}€</span>
      </td>
      <td>
        <span>
          {getValue('textAmount', writer.id)}/{props.order.remainingTextAmount}
        </span>
      </td>
      <td>
        <span>{formatDate(getValue('deadline', writer.id))}</span>
      </td>
      <td>
        <CustomSlider
          value={getValue('isAccepted', writer.id)}
          setValue={(value) => updateWriterProposition(value, writer)}
          order={props.order}
        />
      </td>
    </tr>
  );

  const updateValue = (option, value, writerId) => {
    dispatch(
      updateWriterOffer({ orderId: props.order.id, option, value, writerId })
    );
  };

  const getValue = (option, writerId) => {
    let index = writersOffers.findIndex(
      (writerOffer) =>
        writerOffer.orderId === props.order.id && writerOffer.id === writerId
    );
    if (index > -1) return writersOffers[index][option];
  };

  const updateWriterProposition = (value, currentProposition) => {
    if (
      value === 3 &&
      props.order.remainingTextAmount <=
        countAcceptedWritersOffers(props.order.id, writersOffers) +
          getValue('textAmount', currentProposition.id)
    ) {
      props.order.writerPropositions
        .filter(
          (proposition) =>
            getValue('isAccepted', proposition.id) !== 3 &&
            currentProposition.id !== proposition.id
        )
        .forEach((proposition) => updateValue('isAccepted', 1, proposition.id));
    }

    updateValue('isAccepted', value, currentProposition.id);
  };

  return props.order.expanded === false ? (
    <tr key={props.index}>
      <td
        style={{ cursor: 'pointer' }}
        onClick={() => {
          dispatch(setStatus(ORDERSTATUS.NONATTRIBUTED));
          dispatch(setOrderId(props.order.id));
          dispatch(setOrderType(props.order.type));
          dispatch(fetchAdminOrderDisplay(props.order.id));
          dispatch(fetchAdminTextOrderDisplay(props.order.id));
          dispatch(fetchAdminOrderLineDisplay(props.order.id));
          history.push('/Admin/DetailsDeLaCommande');
        }}
      >
        <div className="d-flex">
          <div style={{ width: '270px' }}>{props.order.title}</div>
          {props.order.type === 'SMART_WITH_REVIEW' ? (
            <div className="smart_order_icon ">
              <img src="Images/icon-eye.jpg" alt="eye icon" />
            </div>
          ) : (
            ''
          )}
        </div>
      </td>
      <td onClick={() => props.toggleExpanded(props.order)}>
        <div className="box-collapse">
          <img src="/Images/icon-users-down.png" alt="icon-users" />
        </div>
      </td>
      {[...Array(8)].map((e, i) => (
        <td key={i}>
          <span>{i18n.t('voir')}</span>
        </td>
      ))}
    </tr>
  ) : (
    <>
      <OrderRow key={props.order.id} order={props.order} />
      {props.order.writerPropositions.map((writer) => (
        <WriterRow key={writer.name} writer={writer} />
      ))}
    </>
  );

  //return props.order.expanded === false ? (
  //   <tr key={props.index}>
  //     <td
  //       style={{ cursor: 'pointer' }}
  //       onClick={() => {
  //         dispatch(setStatus(ORDERSTATUS.NONATTRIBUTED));
  //         dispatch(setOrderId(props.order.id));
  //         dispatch(setOrderType(props.order.type));
  //         dispatch(fetchAdminOrderDisplay(props.order.id));
  //         dispatch(fetchAdminTextOrderDisplay(props.order.id));
  //         dispatch(fetchAdminOrderLineDisplay(props.order.id));
  //         history.push('/Admin/DetailsDeLaCommande');
  //       }}
  //     >
  //       <div className="d-flex">
  //         <div style={{ width: '270px' }}>{props.order.title}</div>
  //         {props.order.type === 'SMART_WITH_REVIEW' ? (
  //           <div className="smart_order_icon ">
  //             <img src="Images/icon-eye.jpg" alt="eye icon" />
  //           </div>
  //         ) : (
  //           ''
  //         )}
  //       </div>
  //     </td>
  //     <td onClick={() => props.toggleExpanded(props.order)}>
  //       <div className="box-collapse">
  //         <img src="/Images/icon-users-down.png" alt="icon-users" />
  //       </div>
  //     </td>
  //     {[...Array(8)].map((e, i) => (
  //       <td key={i}>
  //         <span>{i18n.t('voir')}</span>
  //       </td>
  //     ))}
  //   </tr>
  // ) : (
  //   <tr></tr>
  // <tr key={props.index}>
  //   <td>
  //     <span>{props.order.title}</span>
  //   </td>
  //   <td>
  //     <div
  //       onClick={() => props.toggleExpanded(props.order)}
  //       className="box-collapse pt-2"
  //     >
  //       <img src="/Images/icon-users-right.png" alt="icon-users" />
  //     </div>
  //   </td>
  //   <td>
  //     <div className="box-info">
  //       {props.order.writerPropositions.map((proposition) => (
  //         <div className="box-sub-ch">{proposition.name}</div>
  //       ))}
  //     </div>
  //   </td>
  //   <td>
  //     <span>{}</span>
  //     <div className="box-info box-mt">
  //       {props.order.writerPropositions.map((proposition) => (
  //         <div style={{ marginTop: '5px', marginBottom: '5px' }}>
  //           <div
  //             className="number-wrapper wd-66"
  //             style={{ width: '85px', margin: '0 auto' }}
  //           >
  //             <input
  //               type="number"
  //               className="form-control form-ct"
  //               min="0"
  //               value={getValue('textAmount', proposition.id)}
  //               onChange={(e) =>
  //                 updateValue('textAmount', e.target.value, proposition.id)
  //               }
  //             />
  //             <UpDownInputArrows disabled={false} />
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   </td>
  //   <td>
  //     <div className="box-info box-mt">
  //       {props.order.writerPropositions.map((proposition) => (
  //         <div style={{ marginTop: '5px', marginBottom: '5px' }}>
  //           <div
  //             className="number-wrapper wd-80"
  //             style={{ display: 'inline-block', verticalAlign: 'top' }}
  //           >
  //             <input
  //               type="number"
  //               min="0"
  //               value={getValue('minWordsAmount', proposition.id)}
  //               className="form-control form-ct"
  //               onChange={(e) =>
  //                 updateValue(
  //                   'minWordsAmount',
  //                   e.target.value,
  //                   proposition.id
  //                 )
  //               }
  //             />
  //             <UpDownInputArrows disabled={false} />
  //           </div>
  //           <div
  //             className="number-wrapper wd-80"
  //             style={{ display: 'inline-block', verticalAlign: 'top' }}
  //           >
  //             <input
  //               type="number"
  //               min="0"
  //               value={getValue('maxWordAmount', proposition.id)}
  //               className="form-control form-ct"
  //               onChange={(e) =>
  //                 updateValue('maxWordAmount', e.target.value, proposition.id)
  //               }
  //             />
  //             <UpDownInputArrows disabled={false} />
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   </td>

  //   <td>
  //     <div className="box-info box-mt">
  //       {props.order.writerPropositions.map((proposition) => (
  //         <div className="box-sub-ch" style={{ marginTop: '0px' }}>
  //           <div className="box-checkbox">
  //             <input
  //               className="form-check-input"
  //               value={getValue('actualBilling', proposition.id)}
  //               onChange={(e) =>
  //                 updateValue(
  //                   'actualBilling',
  //                   e.target.checked,
  //                   proposition.id
  //                 )
  //               }
  //               type="checkbox"
  //             />
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   </td>

  //   <td>
  //     <div className="box-info box-mt">
  //       {props.order.writerPropositions.map((proposition) => (
  //         <div style={{ marginTop: '5px', marginBottom: '5px' }}>
  //           <div className="number-wrapper wd-66">
  //             <input
  //               type="number"
  //               min="0"
  //               className="form-control form-ct"
  //               value={getValue('wordsManagedNumber', proposition.id)}
  //               onChange={(e) =>
  //                 updateValue(
  //                   'wordsManagedNumber',
  //                   e.target.value,
  //                   proposition.id
  //                 )
  //               }
  //             />
  //             <UpDownInputArrows disabled={false} />
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   </td>

  //   <td>
  //     <div style={{ marginTop: '75px' }}>
  //       {props.order.writerPropositions.map((proposition) => (
  //         <div className="box-sub-ch" style={{ marginBottom: 28 }}>
  //           <span>{getValue('price', proposition.id)}€</span>
  //         </div>
  //       ))}
  //     </div>
  //   </td>
  //   <td>
  //     <span>
  //       {props.order.remainingTextAmount -
  //         countAcceptedWritersOffers(props.order.id, writersOffers)}
  //     </span>

  //     <div className="box-mt">
  //       {props.order.writerPropositions.map((proposition) => (
  //         <div className="box-sub-ch" style={{ marginBottom: 28 }}>
  //           <span>
  //             {getValue('textAmount', proposition.id)}/
  //             {props.order.remainingTextAmount}
  //           </span>
  //         </div>
  //       ))}
  //     </div>
  //   </td>
  //   <td>
  //     <div style={{ marginTop: '75px' }}>
  //       {props.order.writerPropositions.map((proposition) => (
  //         <div className="box-sub-ch" style={{ marginBottom: 28 }}>
  //           <span>{formatDate(getValue('deadline', proposition.id))}</span>
  //         </div>
  //       ))}
  //     </div>
  //   </td>
  //   <td>
  //     <div style={{ marginTop: '75px' }}>
  //       {props.order.writerPropositions.map((proposition) => (
  //         <div className="box-sub-ch" style={{ marginBottom: '28px' }}>
  //           <CustomSlider
  //             value={getValue('isAccepted', proposition.id)}
  //             setValue={(value) =>
  //               updateWriterProposition(value, proposition)
  //             }
  //             order={props.order}
  //           />
  //         </div>
  //       ))}
  //     </div>
  //   </td>
  // </tr>
  //);
};
