import React, { useCallback, useEffect, useState } from 'react';
import axiosPrivate from 'config/axiosPrivate';

import { useHistory } from 'react-router-dom';

import { CustomAutocompleteMultipleChoice } from 'components/shared/DropDown/CustomAutocompleteMultipleChoice';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';
import { RatingStars } from 'components/shared/RatingStars';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';

import { EMAIL_REGEX, PHONENUMBER_REGEX } from 'utils/Regex';
import { MAIN_LANGUAGES, ROLES } from 'utils/Constants';
import { lower } from 'utils/StringUtils';

import 'components/Admin/writers.css';
import { useTranslation } from 'react-i18next';
import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';

const API = {
  createWriter: '/user/',
  getAvailableThematics: '/thematic/all',
  getAvailableLanguages: '/language/all',
};

export default function CreateWriter() {
  const history = useHistory();
  const { t } = useTranslation();
  const [mail, setMail] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [limit, setLimit] = useState(0);
  const [minRate, setMinRate] = useState(0);
  const [maxRate, setMaxRate] = useState(0);

  const [address, setAddress] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [availableLanguagesList, setAvailableLanguagesList] = useState([]);

  const [mainWriterLanguages, setMainWriterLanguages] = useState([]);
  const [minorWriterLanguages, setMinorWriterLanguages] = useState([]);
  const [writerThematics, setWriterThematics] = useState([]);

  const [disabledForm, setDisabledForm] = useState(true);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    document.title = t('Typix - Créer rédacteur');
    axiosPrivate
      .get(API.getAvailableLanguages)
      .then((res) => setAvailableLanguagesList(res.data));
    axiosPrivate
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));
  }, [t]);

  const checkValidForm = useCallback(() => {
    if (
      !mail ||
      !phoneNumber ||
      !name ||
      !companyName ||
      !address ||
      writerThematics.length === 0 ||
      (mainWriterLanguages.length === 0 && minorWriterLanguages.length === 0) ||
      limit > 2000000000 ||
      limit < 0
    ) {
      setDisabledForm(true);
      return;
    }

    if (mail.length > 0 && !mail.match(EMAIL_REGEX)) {
      setDisabledForm(true);
      return;
    }

    return setDisabledForm(false);
  }, [
    address,
    companyName,
    limit,
    mail,
    mainWriterLanguages.length,
    minorWriterLanguages.length,
    name,
    phoneNumber,
    writerThematics.length,
  ]);

  useEffect(() => {
    checkValidForm();
  }, [
    mail,
    name,
    phoneNumber,
    companyName,
    address,
    writerThematics,
    mainWriterLanguages,
    minorWriterLanguages,
    checkValidForm,
  ]);

  const callbackFromThematicMultiSelect = (newList) => {
    setWriterThematics(newList);
  };

  const callbackFromLanguagesMultiSelect = (newList) => {
    setMinorWriterLanguages(newList);
  };

  const findLanguageIndex = (language) => {
    return mainWriterLanguages.findIndex((element) =>
      lower(element.language).includes(lower(language))
    );
  };

  const checkLanguage = (language) => {
    let index = findLanguageIndex(language);
    let array = [...mainWriterLanguages];
    if (index > -1) {
      array.splice(index, 1);
    } else {
      let item = availableLanguagesList.find((element) =>
        lower(element.language).includes(lower(language))
      );
      array.push(item);
    }

    setMainWriterLanguages(array);
  };

  const handleSubmit = () => {
    let body = {
      mail,
      name,
      phoneNumber,
      companyName,
      address,
      limit,
      languages: mainWriterLanguages.concat(minorWriterLanguages),
      thematics: writerThematics,
      role: ROLES.WRITER,
      minRate: parseFloat(minRate),
      maxRate: parseFloat(maxRate),
    };

    axiosPrivate
      .post(API.createWriter, body)
      .then(() => {
        setErrorSuccessMsg(
          `${t('Le rédacteur')} ${mail} ${t('a été créé avec succès')} !`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              `${t("Un utilisateur existe déjà avec l'email")} ${mail}`
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{t('Ajouter un rédacteur')}</h1>
      </div>
      <div className="row">
        <div className="col-12 col-md-10 box-screen">
          <div className="box-img-bg bg-8" />
          <div className="box-img-bg bg-7" />
          <div className="box-img-bg bg-9" />
          <div className="header-title">{t('Fiche du rédacteur')}</div>
          <div className="box-wrapper">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Mail')}*</div>
                    <div>
                      <input
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                        type="text"
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                </div>

                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Adresse')}*</div>
                    <div>
                      <input
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        type="text"
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Société')}*</div>
                    <div>
                      <input
                        type="text"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        className="form-control form-ct"
                        style={{ width: 250 }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Analyse/Limite')}</div>
                    <div
                      className="form-control form-ct"
                      style={{ width: 250 }}
                    >
                      <span
                        style={{
                          backgroundColor: 'rgb(233, 236, 239)',
                          padding: '0 10px',
                          borderRadius: '15px',
                        }}
                      >
                        {' '}
                        0{' '}
                      </span>{' '}
                      /
                      <input
                        type="number"
                        min="0"
                        max="2147483647"
                        value={limit}
                        style={{ width: '75px' }}
                        // className="white-input "
                        className={
                          limit < 0 || limit > 2000000000
                            ? 'white-input-error'
                            : 'white-input'
                        }
                        onChange={(e) => setLimit(e.target.value)}
                      />
                    </div>
                    {(limit < 0 || limit > 2000000000) && (
                      <p className="error-note">
                        doit être inférieur ou égal à 2000000000
                      </p>
                    )}
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-4">
                    <div className="label-title">
                      {t('Tarif/Mot')}: {t('Min')}
                    </div>
                    <div className="number-wrapper input-group input-grl">
                      <span
                        style={{ paddingRight: '5px' }}
                        className="input-group-text"
                      >
                        €
                      </span>
                      <input
                        type="number"
                        min="0"
                        max="0.99"
                        value={minRate}
                        onChange={(e) => {
                          setMinRate(e.target.value);
                        }}
                        className={
                          parseFloat(minRate) >= 1
                            ? 'form-control form-control-error form-ct'
                            : 'form-control form-control-valid form-ct'
                        }
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {parseFloat(minRate) >= 1 ? (
                      <p className="error-note">
                        {t('Ce champs doit être sup à 0 et inférieur à 1')}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Max')}</div>
                    <div className="number-wrapper input-group input-grl">
                      <span
                        style={{ paddingRight: '5px' }}
                        className="input-group-text"
                      >
                        €
                      </span>
                      <input
                        value={maxRate}
                        type="number"
                        min="0"
                        max="0.99"
                        onChange={(e) => {
                          setMaxRate(e.target.value);
                        }}
                        className={
                          parseFloat(maxRate) >= 1
                            ? 'form-control form-control-error form-ct'
                            : 'form-control form-control-valid form-ct'
                        }
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {parseFloat(maxRate) >= 1 ? (
                      <p className="error-note">
                        {t('Ce champs doit être sup à 0 et inférieur à 1')}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="row row-mb">
                  <div className="col-12 col-md-6">
                    <div className="label-title">{t('Prénom/Nom')}*</div>
                    <div>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="label-title">{t('Téléphone')}*</div>
                    <div>
                      <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => {
                          if (
                            e.target.value === '' ||
                            PHONENUMBER_REGEX.test(e.target.value)
                          ) {
                            setPhoneNumber(e.target.value);
                          }
                        }}
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Thématiques')}*</div>
                    <CustomChipsAutocomplete
                      callbackFromMultiSelect={callbackFromThematicMultiSelect}
                      value={writerThematics}
                      disabled={!availableThematicsList}
                      list={availableThematicsList}
                      setValue={(option) => setWriterThematics(option)}
                      getOptionLabel={(option) => {
                        return t(option.thematic);
                      }}
                    />
                  </div>
                </div>
                <div className="row row-mb mt-4">
                  <div className="col-12 col-md-12">
                    <div className="label-title mb-2">{t('Langues')}*</div>
                    <div className="box-wrapper-cols">
                      {MAIN_LANGUAGES.slice(0, 3).map((language, key) => (
                        <div key={key} className="cols">
                          <div className="box-checkbox box-list-check">
                            <input
                              checked={findLanguageIndex(language) > -1}
                              onChange={() => checkLanguage(language)}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <div> {t(language)}</div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="box-wrapper-cols mt-4">
                      {MAIN_LANGUAGES.slice(3).map((language, key) => (
                        <div key={key} className="cols">
                          <div className="box-checkbox box-list-check">
                            <input
                              checked={findLanguageIndex(language) > -1}
                              onChange={() => checkLanguage(language)}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <div> {t(language)}</div>
                          </div>
                        </div>
                      ))}
                      <div className="cols">
                        <CustomAutocompleteMultipleChoice
                          callbackFromMultiSelect={
                            callbackFromLanguagesMultiSelect
                          }
                          value={minorWriterLanguages}
                          disabled={!availableLanguagesList}
                          list={availableLanguagesList.filter(
                            (x) =>
                              !MAIN_LANGUAGES.map((lan) => lower(lan)).includes(
                                lower(x.language)
                              )
                          )}
                          setValue={(option) => setMinorWriterLanguages(option)}
                          getOptionLabel={(option) => {
                            return t(option.language);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title mb-2">
                      {t('Note rédacteur')} :
                    </div>
                    <div className="box-line-star">
                      <span className="txt-dark">{t('Global :')}</span>
                      <RatingStars />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-button-bt">
            <div>
              {disabledForm ? (
                <button className="bt-submit-disabled-y">
                  {t('Sauvegarder')}{' '}
                </button>
              ) : (
                <button className="bt-submit-y" onClick={handleSubmit}>
                  {' '}
                  {t('Sauvegarder')}{' '}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() =>
          isSuccess ? history.push('/') : setErrorSuccess(false)
        }
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
}
