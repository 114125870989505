import React from 'react';
import { classes } from 'components/shared/modal/modalClasses';
import ModalHeader from 'components/shared/modal/GenerateBill/BillFormComponents/ModalHeader';

import { Modal } from 'reactstrap';

const PdfReaderModal = ({ open, handleClose, title, file }) => {
  return (
    <div>
      <Modal
        style={classes.largeModalCustomForBill}
        isOpen={open}
        toggle={handleClose}
      >
        <div style={classes.modalHeader} className="modal-header">
          <h2>{title}</h2>
          <button
            type="button"
            style={classes.btnClose}
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          />
        </div>

        {file && (
          <div style={classes.modalPad} className="modal-body">
            <div style={{ display: 'flex', alignItems: 'left' }}></div>{' '}
            <div>
              <iframe
                src={file}
                width="100%"
                height="500px"
                title="PDF Viewer"
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

// function PdfReaderModal({ open, handleClose, file }) {
//   return (
//     <Modal
//       style={classes.modalCustom}
//       isOpen={open}
//       toggle={handleClose} // Close the modal when toggle is called
//       backdrop={true} // Enable clicking outside to close the modal
//       keyboard={false} // Disable closing the modal when the Esc key is pressed
//     >
//       <div style={classes.modalHeader} className="modal-header">
//         {/* <h2>{t('Révision demandée')}</h2> */}
//         <button
//           type="button"
//           style={classes.btnClose}
//           className="btn-close"
//           data-bs-dismiss="modal"
//           aria-label="Close"
//           onClick={handleClose}
//         />
//       </div>

//       <div style={classes.modalPad} className="modal-body">
//         <>
//           <iframe src={file} width="100%" height="500px" title="PDF Viewer" />
//         </>
//       </div>
//     </Modal>
//   );
// }

export default PdfReaderModal;
