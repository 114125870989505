import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

function PageNbOptions(props) {
  const pageNbOptions = [10, 15, 20, 50, 100];

  return (
    <div className="d-inline-flex align-items-center">
      <div className="dropdown" style={{ width: '70px' }}>
        <button
          className="btn dropdown-toggle dropdown-custom w-100"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {props.elementsPerPage}
          <span>
            <ChevronDownIcon />
          </span>
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {pageNbOptions
            .filter((nb) => nb !== props.elementsPerPage)
            .map((item, index) => (
              <li key={index}>
                <button
                  onClick={() => props.setElementsPerPage(item)}
                  className="dropdown-item"
                >
                  {item}
                </button>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default PageNbOptions;
