import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDropdownWithSelect } from '../DropDown/CustomDropdown';

const OrderTypeFilter = ({
  orderTypeList,
  selectedType,
  setSelectedType,
  handleClick,
  handleClose,
  anchorEl,
}) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleButtonClick = (event) => {
    setIsDropdownOpen(true);
    handleClick('-1', event);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
    handleClose('-1');
  };

  return (
    <>
      <button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleButtonClick}
        style={{
          padding: '5px',
          border: 'none',
          background: 'none',
        }}
      >
        {selectedType.type.length > 0 ? (
          <img
            style={{
              width: '25px',
              height: '25px',
            }}
            src="/Images/icon-filter-hover.png"
            alt="filter"
          />
        ) : (
          <img src="/Images/icon-filter.png" alt="filter" />
        )}
      </button>
      <CustomDropdownWithSelect
        value={selectedType}
        handleClose={handleDropdownClose}
        anchorEl={anchorEl && anchorEl['-1']}
        list={orderTypeList}
        setValue={(index) => setSelectedType(orderTypeList[index])}
        getOptionLabel={(option) => {
          return t(option.text);
        }}
        open={isDropdownOpen}
      />
    </>
  );
};

export default OrderTypeFilter;
