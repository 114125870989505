import React from 'react';

export const RatingStars = () => {
  return (
    <form className="ratingForm">
      <fieldset className="rating">
        <input type="radio" id="star5" name="rating" defaultValue={5} />
        <label htmlFor="star5">5 stars</label>
        <input type="radio" id="star4" name="rating" defaultValue={4} />
        <label htmlFor="star4">4 stars</label>
        <input type="radio" id="star3" name="rating" defaultValue={3} />
        <label htmlFor="star3">3 stars</label>
        <input type="radio" id="star2" name="rating" defaultValue={2} />
        <label htmlFor="star2">2 stars</label>
        <input type="radio" id="star1" name="rating" defaultValue={1} />
        <label htmlFor="star1">1 star</label>
      </fieldset>
    </form>
  );
};
