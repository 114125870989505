import React from 'react';
import CheckBox from 'components/shared/CheckBox';
import {
  createHandleCheckBoxChange,
  handleDeployCheckBoxChange,
} from 'helpers/CheckBoxHelper';

const TextCheckBox = ({
  orderType,
  text,
  selectedTextsForRevision,
  deploymentQueue,
  setDeploymentQueue,
  selectedTextsForValidation,
  setSelectedTextsForRevision,
  setSelectedTextsForValidation,
  isEligibleForRevision,
  isAwaitingDeployment,
  isEligibleForValidation,
}) => {
  const textId = text.id;
  const keyword = text.keyword;
  const url = text.url;

  const handleCheckBoxChangeForRevision = createHandleCheckBoxChange(
    selectedTextsForRevision,
    setSelectedTextsForRevision
  );

  const handleCheckBoxChangeForValidation = createHandleCheckBoxChange(
    selectedTextsForValidation,
    setSelectedTextsForValidation
  );

  const getTick = () => {
    if (isEligibleForRevision) {
      return selectedTextsForRevision.includes(textId);
    } else if (isAwaitingDeployment) {
      return deploymentQueue.some((item) => item.id === textId);
    } else if (isEligibleForValidation) {
      return selectedTextsForValidation.includes(textId);
    }
    return false;
  };

  const getOnCheck = () => {
    if (isEligibleForRevision) {
      return () => handleCheckBoxChangeForRevision(textId);
    } else if (isAwaitingDeployment) {
      return () =>
        handleDeployCheckBoxChange(textId, keyword, url, setDeploymentQueue);
    } else if (isEligibleForValidation) {
      return () => handleCheckBoxChangeForValidation(textId);
    }
    return null;
  };

  return (
    (isEligibleForRevision ||
      isAwaitingDeployment ||
      (orderType === 'SMART' && isEligibleForValidation)) && (
      <CheckBox
        key={textId}
        name={`text-${textId}`}
        tick={getTick()}
        onCheck={getOnCheck()}
      />
    )
  );
};

export default TextCheckBox;
