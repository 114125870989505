import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axiosPrivate from 'config/axiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { adminOrderDisplaySelector } from 'store/Admin/OrderDisplay';
import { UserSessionSelector } from 'store/UserSession';

import {
  setAssistantId,
  setOrderContentType,
  setAdminOrderSiloName,
} from 'store/Admin/Analyser';
import { fetchText } from 'store/Admin/Analyser/features';
import { removeTextOfTextList } from 'store/Admin/OrderDisplay';
import {
  fetchAdminOrderLineDisplay,
  fetchAdminTextOrderDisplay,
} from 'store/Admin/OrderDisplay/features';

import { fetchAllOrders } from 'store/Admin/OrderList/features';
import { adminWebsiteDetailsSelector } from 'store/Admin/WebsiteDisplay';
import { fetchWebsiteOrdersList } from 'store/Admin/WebsiteDisplay/features';

import { lower } from 'utils/StringUtils';

import { PAGENUMBERSTINYLIMIT } from 'utils/Constants';
import { useTextFilter } from 'utils/hooks/useTextFilter';
import { filterTextByStatus, filterTextByType } from 'utils/Constants';
import {
  shouldShowRevisionCheckBox,
  shouldShowValidationCheckBox,
  handleRevisionCheck,
  handleValidationCheck,
} from 'helpers/CheckBoxHelper';

import { Loader } from 'components/shared/Loader';

import {
  DownloadOneTextWordType,
  DownloadTextsListWordType,
} from 'components/shared/DownloadFile/DownloadTextWordType';
import ErrorComponent from 'components/shared/ErrorComponent';
import PageNbOptions from 'components/shared/PageNbOptions';
import SearchComponent from 'components/shared/SearchBar/SearchComponent';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import CustomFilterMenu from 'components/shared/Filter/CustomFilterMenu';
import DownloadModal from 'components/shared/modal/DownloadModal';

import CheckBox from 'components/shared/CheckBox';

import { setTextType } from 'store/Admin/Analyser';
import CardTextIcon from 'icons/CardTextIcon';
import TranslateIcon from 'icons/TranslateIcon';
import { getTextColor } from 'helpers/TextColorHelper';
import { verifyAllTextsDeletionCriteria } from 'helpers/OrderTextsHelper';
import { GlobalTextsDlivery } from 'helpers/GlobalTextValidationHelper';
import OrderTextDeleteButton from 'components/shared/OrderDetails/OrderTextDeleteButton';

export const OrderTextsDetailsList = (props) => {
  let { order, textList, isTextLoading, isTextError } = useSelector(
    adminOrderDisplaySelector
  );

  let { websiteId } = useSelector(adminWebsiteDetailsSelector);
  const { user } = useSelector(UserSessionSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(filterTextByStatus[0]);
  const [selectedType, setSelectedType] = useState(filterTextByType[0]);
  const [selectedTextsForDelivery, setSelectedTextsForDelivery] = useState([]);

  const [currentTexts, setCurrentTexts] = useState([]);
  const [filteredTexts, setFilteredTexts] = useState([]);
  const [sortedText, setOrdredText] = useState([]);
  const [areAllTextsDeletable, setAreTextsDeletable] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [textsPerPage, setTextsPerPage] = useState(10);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [textId, setTextId] = useState(null);

  const [isTranslate, setIsTranslate] = useState(false);
  const [isText, setIsText] = useState(false);
  const [isCombo, setIsCombo] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeletable, setIsDeletable] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showDownloadModal, setShowDownloadModel] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const pageSource = urlParams.get('pageSource');
  const writerName = urlParams.get('writerName') || '';

  const {
    availableDeliveryNumber,
    isTextEligibleForDelivery,
    isTranslateOnly,
    isTextOnly,
    isCombined,
    isDraft,
    isDelivered,
  } = useTextFilter(sortedText);

  useEffect(() => {
    if (pageSource === 'pageA' && writerName) {
      setSearchValue(writerName);
    } else {
      setSearchValue(writerName);
    }
  }, [pageSource, writerName]);

  useEffect(() => {
    setOrdredText([
      ...filteredTexts.filter((text) => text['validated'] === false),
      ...filteredTexts.filter((text) => text['validated'] === true),
    ]);
  }, [filteredTexts]);

  useEffect(() => {
    if (!textList) {
      dispatch(fetchAdminTextOrderDisplay());
    }
  }, [dispatch, textList]);

  useEffect(() => {
    const allDeletable = verifyAllTextsDeletionCriteria(textList);
    setAreTextsDeletable(allDeletable);
  }, [textList]);

  useEffect(() => {
    if (selectedStatus.field.length > 0) {
      setCurrentPage(1);
      setFilteredTexts(
        textList.filter((x) => {
          switch (selectedStatus.field) {
            case 'revisionSent':
              return (
                (x['globalRevisionSent'] === true ||
                  x['revisionSent'] === true) &&
                x['revisionDone'] === false &&
                x['validated'] === false
              );
            case 'revisionDone':
              return (
                (x['globalRevisionSent'] === true ||
                  x['revisionSent'] === true) &&
                x['revisionDone'] === true &&
                x['validated'] === false
              );
            case 'delivered':
              return (
                x['globalRevisionSent'] === false &&
                x['revisionSent'] === false &&
                x['revisionDone'] === false &&
                x['validated'] === false &&
                x['delivered'] === true
              );
            case 'brouillon':
              return (
                x['globalRevisionSent'] === false &&
                x['revisionSent'] === false &&
                x['revisionDone'] === false &&
                x['validated'] === false &&
                x['delivered'] === false
              );

            default:
              return x[selectedStatus.field] === selectedStatus.value;
          }
        })
      );
    } else {
      setFilteredTexts([...textList]);
    }
  }, [selectedStatus, textList]);

  useEffect(() => {
    if (searchValue && searchValue.length > 0) {
      setCurrentPage(1);
      setFilteredTexts(
        textList.filter((x) => {
          return (
            lower(x.writer.name).includes(lower(searchValue)) ||
            lower(x.writer.mail).includes(lower(searchValue)) ||
            lower(x.keyword).includes(lower(searchValue))
          );
        })
      );
    } else {
      setFilteredTexts([...textList]);
    }
  }, [searchValue, textList]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * textsPerPage;
    const indexOfFirstOrder = indexOfLastOrder - textsPerPage;
    setCurrentTexts(sortedText.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(sortedText.length / textsPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, sortedText, textsPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const handleSearchValueChange = (newValue) => {
    setSearchValue(newValue);
  };

  const deleteText = (textId) =>
    axiosPrivate
      .delete(`/texts/${textId}/delete`)
      .then(() => {
        dispatch(removeTextOfTextList(textId));
        dispatch(fetchAllOrders());
        dispatch(fetchWebsiteOrdersList(websiteId));
        dispatch(fetchAdminOrderLineDisplay(props.orderId));
        setConfirmation(false);
        setIsSuccess(true);
        setErrorSuccessMsg(t(`Le texte a été supprimé avec succès`));
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
        setErrorSuccess(true);
      });

  if (props.regenerateTextLoader !== undefined) {
    if (isTextLoading || props.regenerateTextLoader) return <Loader />;

    if (isTextError) return <ErrorComponent />;
  } else {
    if (isTextLoading) return <Loader />;

    if (isTextError) return <ErrorComponent />;
  }

  const handleDelivery = async () => {
    await GlobalTextsDlivery(
      selectedTextsForDelivery,
      order.id,
      props.orderTextAmount,
      websiteId,
      user?.role,
      setConfirmation,
      setIsSuccess,
      setErrorSuccessMsg,
      setErrorSuccess,
      t,
      dispatch
    );
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckBoxChange = (textId) => {
    if (selectedTextsForDelivery.includes(textId)) {
      setSelectedTextsForDelivery((prevSelectedTexts) =>
        prevSelectedTexts.filter((id) => id !== textId)
      );
    } else {
      setSelectedTextsForDelivery((prevSelectedTexts) => [
        ...prevSelectedTexts,
        textId,
      ]);
    }
  };

  const renderTextIcon = (type) => {
    return (
      <>
        {(type === 'NON_TRANSLATED' || type === 'COMBINED') && <CardTextIcon />}
        {type === 'COMBINED' && ' + '}
        {(type === 'TRANSLATED' || type === 'COMBINED') && <TranslateIcon />}
      </>
    );
  };

  const renderDeleteIcon = (
    text,
    setTextId,
    setConfirmationMsg,
    setConfirmation,
    t
  ) => (
    <div>
      <span
        style={{ cursor: 'pointer' }}
        className="bt-circle-wh"
        role="button"
        onClick={() => {
          setTextId(text.id);
          setIsDeletable(true);
          setConfirmationMsg(t('Voulez-vous vraiment supprimer  le texte?'));
          setConfirmation(true);
        }}
      >
        <img src="/Images/icon-trash.png" alt="trash" />
      </span>
    </div>
  );

  const renderDownloadIcon = (text, user) => (
    <div>
      <span
        className="bt-download-circle"
        onClick={() => DownloadOneTextWordType(text.id, user)}
      />
    </div>
  );

  const renderTextActions = (
    text,
    user,
    setTextId,
    setConfirmationMsg,
    setConfirmation,
    t
  ) => {
    return (
      <div className="col-md-3 d-flex justify-content-end">
        <div>
          {isDelivered(text) || isDraft(text)
            ? renderDeleteIcon(
                text,
                setTextId,
                setConfirmationMsg,
                setConfirmation,
                t
              )
            : ''}
        </div>

        <div>{renderDownloadIcon(text, user)}</div>
      </div>
    );
  };

  return (
    <div>
      <div className="box-wrapper" style={{ height: '558px' }}>
        <div className="box-flex-cols">
          <div>
            {t('Textes livrés')} : {textList.filter((x) => x.delivered).length}/
            {props.orderTextAmount}
          </div>
          <div>
            {t('Textes validés')} : {textList.filter((x) => x.validated).length}
            /{props.orderTextAmount}
          </div>
        </div>
        <div className="box-flex-line">
          <CustomFilterMenu
            selectedStatus={selectedStatus}
            handleClick={handleClick}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            isTranslateOnly={isTranslateOnly}
            isTextOnly={isTextOnly}
            isCombined={isCombined}
            t={t}
            isText={isText}
            isTranslate={isTranslate}
            isCombo={isCombo}
            setIsTranslate={setIsTranslate}
            setIsText={setIsText}
            setIsCombo={setIsCombo}
            setSelectedStatus={setSelectedStatus}
            filterTextByStatusArr={filterTextByStatus}
            filterTextByType={filterTextByType}
            setSelectedType={setSelectedType}
            flag={false}
          />
          {availableDeliveryNumber > 0 && (
            <CheckBox
              name="select-all"
              tick={selectedTextsForDelivery.length === availableDeliveryNumber}
              onCheck={(e) =>
                handleValidationCheck(
                  e,
                  sortedText,
                  setSelectedTextsForDelivery,
                  isTextEligibleForDelivery
                )
              }
            />
          )}

          <SearchComponent
            placeholder={t('Recherche par rédacteur ou texte')}
            searchValue={searchValue}
            onSearchValueChange={handleSearchValueChange}
          />
          {order?.type === 'PREMIUM' &&
            textList.length > 0 &&
            areAllTextsDeletable && (
              <OrderTextDeleteButton
                orderTitle={order.title}
                orderId={order.id}
              />
            )}
          <div className="ms-2">
            {textList.filter((text) => text.delivered).length === 0 ? (
              <span className=" bt-download-circle float-end disabled"></span>
            ) : (
              <span
                className="  bt-download-circle float-end"
                onClick={() =>
                  DownloadTextsListWordType(
                    textList.filter((text) => text.delivered),
                    user,
                    setShowDownloadModel
                  )
                }
              ></span>
            )}
          </div>
        </div>
        <div className="box-wrap-sm">
          <div className="box-list-li ">
            {currentTexts
              .filter(
                (text) =>
                  (isCombo && text.type === 'COMBINED') ||
                  (isTranslate && text.type === 'TRANSLATED') ||
                  (isText && text.type === 'NON_TRANSLATED') ||
                  (!isCombo && !isTranslate && !isText)
              )
              .map((text, idx) => (
                <div key={text.index} className="box-list-n">
                  {' '}
                  <div className="col-md-1 ps-1">
                    {isTextEligibleForDelivery(text) && (
                      <CheckBox
                        key={text.id}
                        name={`text-${text.id}`}
                        tick={selectedTextsForDelivery.includes(text.id)}
                        onCheck={() => handleCheckBoxChange(text.id)}
                      />
                    )}
                  </div>
                  <div
                    onClick={() => {
                      dispatch(fetchText(text.id));
                      dispatch(setTextType(text.type));
                      dispatch(setAssistantId(order.clientDocumentation));
                      dispatch(setOrderContentType(order.contentType));
                      dispatch(setAdminOrderSiloName(order.nomSilo));
                      history.push('/Admin/TextDetails');
                    }}
                    style={{ cursor: 'pointer' }}
                    className="col-12 col-md-8"
                  >
                    <span className={`${getTextColor(text)}`}>
                      {(currentPage - 1) * textsPerPage + idx + 1}.{'  '}
                      {renderTextIcon(text.type)}
                      {'  '}
                      {text.keyword}
                    </span>
                  </div>
                  {renderTextActions(
                    text,
                    user,
                    setTextId,
                    setConfirmationMsg,
                    setConfirmation,
                    t
                  )}
                </div>
              ))}
          </div>
          <div className="box-rectangle" />
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <button
          className={
            selectedTextsForDelivery.length < 1 || availableDeliveryNumber < 1
              ? 'bt-submit-disabled-y'
              : 'bt-submit-y'
          }
          type="submit"
          onClick={() => {
            setConfirmationMsg(
              `${t('Voulez-vous vraiment livrer les Textes de la commande')} ${
                order.title
              } ? `
            );
            setConfirmation(true);
          }}
          disabled={
            selectedTextsForDelivery.length < 1 || availableDeliveryNumber < 1
          }
        >
          {t('Livrer les textes')}
        </button>
      </div>
      <div className="box-pagination">
        <div className="d-flex box-pagination">
          <div className="list-page-number me-3">
            {pageNumbers
              .slice(
                Math.max(0, currentPage - PAGENUMBERSTINYLIMIT),
                Math.min(currentPage + PAGENUMBERSTINYLIMIT, pageNumbers.length)
              )
              .map((pageNum, index) => (
                <span
                  key={index}
                  className={pageNum === currentPage ? 'active' : ''}
                  onClick={() => setCurrentPage(pageNum)}
                >
                  {pageNum}
                </span>
              ))}
          </div>
          <PageNbOptions
            elementsPerPage={textsPerPage}
            setElementsPerPage={setTextsPerPage}
          />
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => {
          setErrorSuccess(false);
        }}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={() =>
          isDeletable ? deleteText(textId) : handleDelivery()
        }
      />
      <DownloadModal
        open={showDownloadModal}
        handleClose={() => setShowDownloadModel(false)}
      />
    </div>
  );
};
