import React from 'react';
import { useTranslation } from 'react-i18next';

const AutoSaveDisplay = ({ saving }) => {
  let display;
  const { t } = useTranslation();

  switch (saving) {
    case 1:
      display = <em>{t('saving')}...</em>;
      break;
    case 2:
      display = (
        <>
          <em>{t('saved')}!</em>
        </>
      );
      break;
    default:
      display = <br />;
  }
  return <div className="auto-save-display">{display}</div>;
};

export default AutoSaveDisplay;
