import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { handleSorting } from 'store/Writer/BillDisplay/reducers';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

import {
  BILL_MODIFICATION,
  BILL_MODIFIED,
  BILL_STATUS_NOTGENERATED,
  BILL_STATUS_PAID,
  BILL_STATUS_PAYMENT,
  BILL_STATUS_VALIDATION,
} from 'utils/Constants';
import { useHistoryState } from 'utils/hooks/useHistoryState';
import TableHeader from './TableHeader';

import ErrorComponent from 'components/shared/ErrorComponent';
import { Loader } from 'components/shared/Loader';

import { Paginator } from 'components/shared/Paginator';

import { filterBills } from 'helpers/FilterOrders';
import './styles.css';

import { Badge, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TableRow from './Rows/TableRow';
import { CustomTooltip } from '../CustomTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  tabs: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'left',
    },
  },
  indicator: {
    backgroundColor: '#ffe426',
    height: 3,
    borderRadius: 4,
    width: '150px !important',
    marginLeft: '1% !important',
    marginBottom: '7px',
  },
}));

const fieldWidths = {
  title: '26%',
  deadline: '20%',
  type: '15%',
};

function BillTable({ title, columns, bills, isLoading, isError }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [nbOfToValidate, setNbToValidate] = useState(
    bills.filter((bill) =>
      [BILL_STATUS_NOTGENERATED, BILL_MODIFICATION].includes(bill.status)
    ).length
  );
  const [generatedBills, setGeneratedBills] = useState(
    bills.filter((bill) => {
      return bill.file !== null;
    })
  );
  useEffect(() => {
    setNbToValidate(
      bills.filter((bill) =>
        [BILL_STATUS_NOTGENERATED, BILL_MODIFICATION].includes(bill.status)
      ).length
    );
  }, [bills]);

  useEffect(() => {
    if (value === 0) {
      const filtered = bills.filter((bill) =>
        [
          BILL_STATUS_PAID,
          BILL_STATUS_PAYMENT,
          BILL_STATUS_VALIDATION,
          BILL_MODIFIED,
        ].includes(bill.status)
      );
      setGeneratedBills(filtered);
    } else {
      const filtered = bills.filter((bill) =>
        [BILL_STATUS_NOTGENERATED, BILL_MODIFICATION].includes(bill.status)
      );
      setGeneratedBills(filtered);
    }
  }, [value, bills]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [titleSearch, setTitleSearch] = useHistoryState('titleSearch', '');

  const [currentPage, setCurrentPage] = useState(1);

  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [pageNumbers, setPageNumbers] = useState([]);

  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  useEffect(() => {
    if (!generatedBills) return;
    const filtered = filterBills(generatedBills, titleSearch, false);
    if (titleSearch && titleSearch.length > 0) {
      setCurrentPage(1);
    }
    setFilteredOrders(filtered);
  }, [titleSearch, generatedBills]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const handleSortingChange = (field) => {
    const direction =
      field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
    dispatch(
      handleSorting({
        sortField: field,
        sortOrder: direction,
      })
    );
    setSortField(field);
    setSortOrder(direction);
  };

  return (
    <div className="content-center">
      <TableHeader
        title={title}
        titleSearch={titleSearch}
        setTitleSearch={setTitleSearch}
        ordersPerPage={ordersPerPage}
        setOrdersPerPage={setOrdersPerPage}
      />
      <Tabs
        value={value}
        onChange={handleChange}
        classes={{ root: classes.tabs, indicator: classes.indicator }}
        aria-label="icon label tabs example"
      >
        <Tab value={0} label={t('Factures traitées')} />
        <Tab
          value={1}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{t('Factures à envoyer')}</span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  marginLeft: '15px',
                  marginBottom: '18px',
                }}
              >
                <Badge badgeContent={nbOfToValidate} color="error" />
              </div>
            </div>
          }
        />{' '}
      </Tabs>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <ErrorComponent />
      ) : (
        <div className="box-wrapper">
          <div className="table-responsive">
            <div>
              <table className="table-lists table-commandes">
                <thead>
                  <tr>
                    {columns.map(({ label, field, sortable }) => (
                      <th
                        style={{
                          cursor: sortable ? 'pointer' : 'default',
                          width: fieldWidths[field] || '',
                          minWidth: '10vw',
                        }}
                        onClick={
                          sortable ? () => handleSortingChange(field) : null
                        }
                        key={field}
                      >
                        {t(label)}
                        {label === 'Action' && (
                          <CustomTooltip
                            title={t(
                              'La relance peut être faite 20 jours après la soumissions de la facture'
                            )}
                            placement="top"
                          >
                            <button
                              type="button"
                              className="btn-circle"
                              style={{ marginLeft: '5px' }}
                            ></button>
                          </CustomTooltip>
                        )}
                        {!sortable ? null : field === sortField &&
                          sortOrder === 'asc' ? (
                          <span>
                            <ChevronUpIcon style={{ marginLeft: '3px' }} />
                          </span>
                        ) : (
                          <span>
                            <ChevronDownIcon style={{ marginLeft: '3px' }} />
                          </span>
                        )}{' '}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((bill, index) => (
                    <TableRow
                      bill={bill}
                      index={index}
                      value={value}
                      dispatch={dispatch}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <Paginator
        isLoading={isLoading}
        listLength={filteredOrders.length}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        nbElementsPerPage={ordersPerPage}
        goToPage={(page) => setCurrentPage(page)}
      />
    </div>
  );
}

export default BillTable;
