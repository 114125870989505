import React, { useState } from 'react';

import { formatDate } from 'helpers/Constants';
import { DownloadRevisionDocument } from './DownloadFile/DownloadRevisionFile';

export const RevisionDetails = ({
  t,
  textId,
  revisionRequestAt,
  revisionText,
  revisionFileName,
}) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className="row mt-4">
      <div className="col-12 col-md-12">
        <div className="box-txt">
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setExpanded(!expanded)}
          >
            <strong>{t('Révision demandée')} :</strong>{' '}
          </div>
          <div
            className="i-13"
            style={{ cursor: 'pointer' }}
            onClick={() => setExpanded(!expanded)}
          >
            <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} />
          </div>
        </div>
        {expanded && (
          <div className="box-wrapper">
            <div className="box-review-text ">
              <div>
                <b>{t('Date')}:</b>
                <p>{formatDate(revisionRequestAt)}</p>
                <b>{t('Mail envoyé')}:</b>
                <p>{revisionText}</p>
              </div>
              {revisionFileName && (
                <DownloadRevisionDocument
                  fileName={revisionFileName}
                  textId={textId}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
