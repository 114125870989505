import { useMemo } from 'react';

export const useTextFilter = (sortedText) => {
  const isRevisionDone = (text) =>
    text.revisionDone === true &&
    text.validated === false &&
    ((text.globalRevisionSent === true && text.revisionSent === false) ||
      text.revisionSent === true);

  const isRevisionSent = (text) =>
    text &&
    ((text.globalRevisionSent === true && text.revisionDone === false) ||
      (text.revisionSent === true &&
        text.revisionDone === false &&
        text.validated === false));

  const isDraft = (text) =>
    text &&
    text.globalRevisionSent === false &&
    text.revisionSent === false &&
    text.revisionDone === false &&
    text.validated === false &&
    text.delivered === false;

  const isDelivered = (text) =>
    text &&
    text.globalRevisionSent === false &&
    text.revisionSent === false &&
    text.revisionDone === false &&
    text.validated === false &&
    text.delivered === true;

  const isValidated = (text) => text && text.validated;

  const isTextEligibleForValidation = (text) =>
    isRevisionDone(text) || isDelivered(text);

  const isTextEligibleForDelivery = (text) => isDraft(text);

  const isTextEligibleForRevision = (text, isRevisionDisabled, orderType) =>
    isDelivered(text) &&
    !isRevisionSent(text) &&
    !isRevisionDone(text) &&
    !isValidated(text) &&
    availbleRevisionNumber > 1 &&
    !isRevisionDisabled &&
    orderType !== 'SMART';

  const availbleRevisionNumber = useMemo(
    () =>
      sortedText.filter(
        (text) =>
          !text.revisionSent &&
          !text.globalRevisionSent &&
          !text.validated &&
          !text.revisionDone &&
          text.delivered
      ).length,
    [sortedText]
  );

  const availableValidationNumber = useMemo(
    () =>
      sortedText.filter((text) => isRevisionDone(text) || isDelivered(text))
        .length,
    [sortedText]
  );

  const availableDeliveryNumber = useMemo(
    () => sortedText.filter((text) => isDraft(text)).length,
    [sortedText]
  );

  const isAllTextesDelivered = (sortedText) => {
    if (sortedText.length === 0) return false;
    return sortedText.every((text) => text.delivered);
  };

  const isTranslateOnly = useMemo(
    () =>
      Boolean(sortedText.filter((text) => text.type === 'TRANSLATED').length),
    [sortedText]
  );

  const isTextOnly = useMemo(
    () =>
      Boolean(
        sortedText.filter((text) => text.type === 'NON_TRANSLATED').length
      ),
    [sortedText]
  );

  const isCombined = useMemo(
    () => Boolean(sortedText.filter((text) => text.type === 'COMBINED').length),
    [sortedText]
  );

  return {
    availbleRevisionNumber,
    availableValidationNumber,
    availableDeliveryNumber,
    isTextEligibleForValidation,
    isTextEligibleForDelivery,
    isTextEligibleForRevision,
    isTranslateOnly,
    isTextOnly,
    isCombined,
    isRevisionDone,
    isRevisionSent,
    isDraft,
    isDelivered,
    isValidated,
    isAllTextesDelivered,
  };
};
