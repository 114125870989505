import { InputField } from 'components/shared/InputField';
import InputFieldWithArrow from 'components/shared/InputField/InputFieldWithArrow';
import { classes } from 'components/shared/modal/modalClasses';
import { useTranslation } from 'react-i18next';
import { BILL_TRANSFERRED } from 'utils/Constants';

const FormFields = ({ bill, fieldValues, actualBilling }) => {
  const { t } = useTranslation();
  const [number, unit] = bill.deadline.split(' ');

  return (
    <div className="row row-mb-lg">
      <div style={classes.facturationTitle}>
        <span style={classes.facturationTitleSpan}>
          {t('Titre de la commande')} :{' '}
        </span>{' '}
        {bill.commandTitle}
      </div>
      <div style={classes.line1}></div>
      <div className="col-12 col-md-6">
        <p style={classes.formLabels}>
          <b>{t('Langue')}:</b> {t(bill.langue)}
        </p>
        <p style={classes.formLabels}>
          <b>{t('Nombre de texte')} :</b> {bill.nb_text}
        </p>
        <p style={classes.formLabels}>
          <b>{t('Nombre de mot par texte :')} </b>{' '}
          {actualBilling === true ? t('Au réel') : bill.nb_mots}
        </p>
        <p style={classes.formLabels}>
          <b>{t('Paiement')} : </b>{' '}
          {bill.payment === BILL_TRANSFERRED ? t('Virement') : t('Virement')}
        </p>
        <p style={classes.formLabels}>
          <b>{t('Délai')} :</b>
          {number + ' ' + t(unit)}
        </p>
      </div>
      <div className="col-12 col-md-6">
        <div style={classes.formLabelsRight}>
          <b>{t('Référence de votre facture')} : </b>{' '}
          <InputField
            style={{
              width: '340px',
              marginLeft: '5px',
              backgroundColor: '#e0e0e0',
              color: '#828282',
            }}
            type="text"
            name="bill reference"
            value={fieldValues.billRef}
            disabled={true}
          />
        </div>
        <div style={classes.formLabelsRight}>
          <b>{t('Coût au mot')} : </b>{' '}
          <InputFieldWithArrow
            style={{
              width: '164px',
              marginLeft: '5px',
            }}
            name="price"
            type="number"
            className="form-control form-ct"
            value={fieldValues.price}
            greyButton={true}
            readOnly={true}
          />
        </div>
        <div style={classes.formLabelsRight}>
          <b>{t('Montant global de la commande')} : </b>{' '}
          <InputFieldWithArrow
            style={{
              width: '164px',
              marginLeft: '5px',
              backgroundColor: '#e0e0e0',
            }}
            name="price"
            type="number"
            className="form-control form-ct"
            value={fieldValues.totalPrice}
            greyButton={true}
            readOnly={true}
          />
        </div>
      </div>
    </div>
  );
};
export default FormFields;
