import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import axiosPrivate from 'config/axiosPrivate';
import ConfirmationModal from '../modal/ConfirmationModal';
import ErrorSuccesModal from '../modal/ErrorSuccesModal';
import { deleteAllTexts } from 'store/Admin/OrderDisplay';

const OrderTextDeleteButton = ({ orderTitle, orderId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');

  const deleteTextes = () => {
    axiosPrivate
      .delete(`/commands/${orderId}/deleteTexts`)
      .then(() => {
        setConfirmation(false);
        dispatch(deleteAllTexts());
        setErrorSuccessMsg(
          `${t('Les textes de la commande ont été supprimés avec succès')} !`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        setConfirmation(false);
        if (e) {
          setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
        }
        setErrorSuccess(true);
      });
  };
  return (
    <>
      <div
        className="bt-icon-trash"
        onClick={() => {
          setConfirmationMsg(
            `${t(
              'Voulez-vous vraiment supprimer les Textes de la commande'
            )} ${orderTitle} ? `
          );
          setConfirmation(true);
        }}
      >
        <img src="Images/icon-trash.png" alt="icon-trash" />
      </div>
      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={deleteTextes}
      />
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </>
  );
};

export default OrderTextDeleteButton;
