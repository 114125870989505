import React from 'react';

import { Editor } from '@tinymce/tinymce-react';
import { useSelector } from 'react-redux';
import { languageStoreSelector } from 'store/Languages';
import { UserSessionSelector } from 'store/UserSession';

import { ROLES } from 'utils/Constants';

export const TINY_API = 'kgyse4iueutjt36vd1qbqphc0cvv0oqf124t0g7xo43kkwzu';
export const api_key = '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';
const accessToken = 'XUlnLnewifdxKywK4PoBmDldpRLnr9QwWI1fDgKQZ20=';
export const CustomEditor = ({
  content,
  disabled,
  isReadOnly,
  onChange,
  onInit,
  t,
  threadId,
  assistantId,
  orderType,
  orderContentType,
}) => {
  const { selectedLanguage } = useSelector(languageStoreSelector);
  const { user } = useSelector(UserSessionSelector);

  const isAiPluginActivated =
    user?.role === ROLES.ADMIN && orderType === 'SMART';

  return (
    <Editor
      value={content}
      apiKey={TINY_API}
      disabled={disabled}
      onEditorChange={onChange}
      onInit={onInit}
      init={{
        height: 400,
        editable_root: isReadOnly,
        selector: 'textarea#comments-embedded',
        language: selectedLanguage === 'fr' ? 'fr_FR' : 'en',
        plugins: isAiPluginActivated
          ? [
              'ai',
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'advcode',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'help',
              'wordcount',
              'tinymcespellchecker',
              'link',
              'export',
              'powerpaste',
            ]
          : [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'advcode',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'help',
              'wordcount',
              'tinymcespellchecker',
              'link',
              'export',
              'powerpaste',
            ],
        toolbar: isAiPluginActivated
          ? 'undo redo |aidialog aishortcuts|fontfamily|fontsize| blocks | bold italic underline strikethrough backcolor forecolor| fullscreen | link image media table mergetags | export | spellchecker language spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat'
          : 'undo redo |fontfamily|fontsize| blocks | bold italic underline strikethrough backcolor forecolor| fullscreen | link image media table mergetags | export | spellchecker language spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        spellchecker_active: false,
        content_langs: [
          { title: 'English (US)', code: 'en_US' },
          { title: 'English (UK)', code: 'en_UK' },
          { title: 'Spanish', code: 'es' },
          { title: 'French', code: 'fr' },
          { title: 'German', code: 'de' },
          { title: 'Portuguese', code: 'pt' },
        ],

        ai_shortcuts: !isAiPluginActivated
          ? []
          : [
              {
                id: 'improveWriting',
                title: t('Régénérer'),
                prompt:
                  'Rewrite this content with no spelling mistakes, proper grammar, and with more descriptive language, using best writing practices without losing the original meaning.The response should preserve the words between strong tags, any HTML formatting, links, styles and the language in the context',
                selection: true,
              },
              {
                id: 'removeSpaces',
                title: t('Nettoyer les espaces inutiles'),
                prompt:
                  'Trim all the ineeded spaces in this text. Make sure to eliminate multiple spaces between words and spaces at the beginning and end of a paragraph.',
                selection: true,
              },
              {
                id: 'simplify',
                title: t('Simplifier'),
                prompt:
                  'Simplify and shorten the paragraph by removing redundant information and merging sentences, ensuring the original idea remains clear.',
                selection: true,
              },
            ],

        ai_request: (request, respondWith) => {
          const productOrderbaseUrl =
            'https://prod.typixweb.com/chatgpt/assistants/calcualte/message/one-message-thread';
          const queryParams = [`assistantId=${assistantId}`];

          const otherOrderBaseUrl = `https://prod.typixweb.com/chatgpt/gpt-call/new-generation-front?maxTokens=2000&temperature=0.3`;

          if (threadId) {
            queryParams.push(`threadId=${threadId}`);
          }

          const productType =
            orderType === 'PRODUCT' || orderType === 'LONG_PRODUCT';

          const url = productType
            ? `${productOrderbaseUrl}?${queryParams.join('&')}`
            : otherOrderBaseUrl;

          const isImproveWriting = request.query.includes(
            'Rewrite this content with no spelling mistakes, proper grammar, and with more descriptive language, using best writing practices without losing the original meaning.The response should preserve the words between strong tags, any HTML formatting, links, styles and the language in the context'
          );

          // Extract the context from the request
          let context = request.context ? request.context : '';

          if (isImproveWriting && productType) {
            // Create a new DOM element to parse the context HTML
            const parser = new DOMParser();
            const doc = parser.parseFromString(context, 'text/html');
            const h2Element = doc.querySelector('h2');

            if (h2Element) {
              // Extracting the outerHTML of the h2 element which includes the element itself and its content
              context = h2Element.outerHTML;
            } else {
              // Fallback or handle the absence of an <h2> element
              console.warn('No <h2> element found in the context');
            }
          }

          // Define the system messages and format them.
          const systemMessages =
            'Answer the question based on the context below.\n' +
            'Remove lines with ``` from the response start and response end.\n' +
            'The response should preserve any HTML formatting, links, and styles and the language in the context.\n\n'; //const context = request.context ? request.context : '';

          // Combine the context and the query with the system messages to form the prompt
          const prompt = request.query ? request.query : '';

          let currentPrompt = '';

          if (isImproveWriting && productType) {
            currentPrompt =
              systemMessages +
              'Rédige moi un paragraphe sur le titre \n' +
              'titre: """' +
              context +
              '"""\n\n' +
              'Question: """' +
              'Utilise un ton commercial.\n' +
              'Utilise le vouvoiement.\n' +
              'Assure-toi de ne pas mentionner les marques concurrentes\n' +
              '"""\n\n';
          } else {
            currentPrompt =
              systemMessages +
              'Context: """' +
              context +
              '"""\n\n' +
              'Question: """' +
              prompt +
              '"""\n\n' +
              'Answer:';
          }

          const requestBody = {
            accessToken: accessToken,
            prompt: currentPrompt,
          };

          const openAiOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(productType ? currentPrompt : requestBody),
          };

          respondWith.string((signal) => {
            openAiOptions.signal = signal;

            return window
              .fetch(url, openAiOptions)
              .then(async (response) => {
                if (response.ok) {
                  if (!productType) {
                    console.log(response);
                    const textData = await response.text();
                    return textData.trim();
                  } else {
                    const jsonData = await response.json();
                    const responseId = jsonData.responseId;
                    if (typeof responseId === 'string') {
                      return responseId.trim();
                    } else {
                      throw new Error(
                        'responseId not found or is not a string'
                      );
                    }
                  }
                } else {
                  // Handle HTTP errors
                  const errorData = await response.text(); // Assuming the error is in plain text
                  throw new Error(`Error: ${errorData}`);
                }
              })
              .catch((error) => {
                // Handle fetch errors
                throw new Error(
                  'Failed to communicate with the API: ' + error.message
                );
              });
          });
        },
      }}
    />
  );
};
