import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export default function DateInputField({ name, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="wrapper-date">
      <input
        min={today}
        name="pastdate"
        className="form-control form-ct"
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
      <div id="date-button" className="box-icon-calendar">
        <img
          src="Images/icon-calendar.png"
          alt="icon-calendar"
          style={{ backgroundColor: 'white' }}
        />
      </div>
      {error && <span className="error-note">{error}</span>}
    </div>
  );
}
