import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import axiosPrivate from 'config/axiosPrivate';

import { Loader } from 'components/shared/Loader';
import { Paginator } from 'components/shared/Paginator';
import ErrorComponent from 'components/shared/ErrorComponent';
import { CustomDropdownWithSelect } from 'components/shared/DropDown/CustomDropdown';

import { CustomTooltip } from 'components/shared/CustomTooltip';

import { removeNonAttributedOrder } from 'store/Admin/OrderList';
import { adminOrderListSelector } from 'store/Admin/OrderList';

import { RenderNoWriterPropositionTableRow } from 'components/Admin/RenderNoWriterPropositionTableRow';
import { RenderOneWriterPropositionTableRow } from 'components/Admin/RenderOneWriterPropositionTableRow';
import { RenderMultipleWriterPropositionTableRow } from 'components/Admin/RenderMultipleWriterPropositionTableRow';

import { countAcceptedWritersOffers } from 'helpers/WritersHelper';
import { useHistoryState } from 'utils/hooks/useHistoryState';
import { filterOrders } from 'helpers/FilterOrders';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

import './order.css';

const API = {
  forwardDecision: 'commands/attributionDecision',
};
const FILTER_ORDER_TYPE_LIST = [
  { text: 'Tous les types', type: '' },
  { text: 'Rédaction premium', type: 'PREMIUM' },
  { text: 'Rédaction avec révision', type: 'SMART_WITH_REVIEW' },
];
export default function NonAttributedOrders() {
  let { nonAttributedOrders, writersOffers, isLoading, isError } = useSelector(
    adminOrderListSelector
  );
  const { t } = useTranslation();
  const columns = [
    { label: t('Titre de la commande'), field: 'title' },
    { label: t('Rédacteurs'), field: 'writers' },
    { label: t('Nb de textes'), field: 'textAmount' },
    { label: t('Nb de mots'), field: 'wordsAmount' },
    { label: t('Au réel'), field: 'actualBilling' },
    { label: t('Nb mots/texte'), field: 'wordsManagedNumber' },
    { label: t('Prix'), field: 'price' },
    { label: t('Textes'), field: 'texts' },
    { label: t('Deadline'), field: 'deadline' },
    { label: t('Validation'), field: 'validation' },
  ];
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(10);

  const [disabled, setDisabled] = useState(false);

  const [allOrders, setAllOrders] = useState([]);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedType, setSelectedType] = useState(FILTER_ORDER_TYPE_LIST[0]);

  const [pageNumbers, setPageNumbers] = useState([]);
  const [titleSearch, setTitleSearch] = useHistoryState('titleSearch', '');
  const [anchorEl, setAnchorEl] = useState(null);

  const pageNbOptions = [10, 15, 20, 50, 100];

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  useEffect(() => {
    setAllOrders(
      nonAttributedOrders.map((order) => ({ ...order, expanded: false }))
    );
  }, [nonAttributedOrders]);

  useEffect(() => {
    if (!allOrders) return;

    const filtered = filterOrders(allOrders, selectedType, titleSearch);
    if (titleSearch && titleSearch.length > 0) {
      setCurrentPage(1);
    }
    setFilteredOrders(filtered);
  }, [selectedType, titleSearch, allOrders]);

  useEffect(() => {
    const indexOfLastPost = currentPage * ordersPerPage;
    const indexOfFirstPost = indexOfLastPost - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstPost, indexOfLastPost));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  useEffect(() => {
    setDisabled(
      writersOffers.filter(
        (writerOffer) => parseInt(writerOffer.isAccepted) !== 2
      ).length === 0 ||
        currentOrders.filter(
          (order) =>
            order.remainingTextAmount -
              countAcceptedWritersOffers(order.id, writersOffers) <
            0
        ).length > 0
    );
  }, [currentOrders, writersOffers]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    let body = writersOffers
      .filter((writerOffer) => parseInt(writerOffer.isAccepted) !== 2)
      .map((writerOffer) => ({
        actualBilling: writerOffer.actualBilling,
        commandId: writerOffer.orderId,
        deadline: new Date(writerOffer.deadline).toISOString(),
        isAccepted: parseInt(writerOffer.isAccepted) === 3 ? true : false,
        maxWords: writerOffer.maxWordAmount,
        minWords: writerOffer.minWordsAmount,
        textAmount: writerOffer.textAmount,
        wordsManagedNumber: writerOffer.actualBilling
          ? null
          : parseInt(writerOffer.wordsManagedNumber),
        writerId: writerOffer.id,
      }));

    axiosPrivate
      .post(API.forwardDecision, body)
      .then(() => {
        body.forEach((writerOffer) =>
          dispatch(
            removeNonAttributedOrder({
              orderId: writerOffer.commandId,
              writerId: writerOffer.writerId,
            })
          )
        );

        setErrorSuccessMsg(
          t(`Votre décision a été prise en compte avec succès`)
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch(() => {
        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  const toggleExpanded = (order) => {
    let array = [...allOrders];
    let index = allOrders.findIndex((element) => element.id === order.id);
    array[index].expanded = !array[index].expanded;
    setAllOrders(array);
  };

  return (
    <div className="content-center">
      <div className="box-info-header">
        <div className="b-child-1">
          <h1>{t('Commandes non attribuées')}</h1>
        </div>
        <div className="b-child-2">
          <div className="d-flex  justify-content-end">
            <div className="box-input-text pe-0">
              <div className="input-group input-cs w-100 pe-0">
                <div className="input-group-text">
                  <SearchIcon />
                </div>
                <input
                  type="text"
                  className="form-control ps-0"
                  value={titleSearch}
                  onChange={(event) => setTitleSearch(event.target.value)}
                  placeholder={t('Rechercher')}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className="me-2">{t('Afficher les résultats')}</span>

              <div className="dropdown" style={{ width: '70px' }}>
                <button
                  className="btn dropdown-toggle dropdown-custom w-100"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {ordersPerPage}
                  <span>
                    <ChevronDownIcon />
                  </span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {pageNbOptions
                    .filter((nb) => nb !== ordersPerPage)
                    .map((item, index) => (
                      <li key={index}>
                        <button
                          className="dropdown-item"
                          onClick={() => setOrdersPerPage(item)}
                        >
                          {item}
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <ErrorComponent />
      ) : (
        <div className="box-wrapper">
          <div className="table-responsive">
            <div>
              <table className="table-lists table-attribees">
                <thead>
                  <tr>
                    {columns.map(({ label, field }) => (
                      <th
                        style={{
                          cursor: 'default',
                          width: field === 'title' ? '23%' : '',
                        }}
                        key={field}
                      >
                        {label}
                        {field === 'texts' ? (
                          <CustomTooltip
                            title={t(
                              'à cocher si le nombre de mots est différent par texte'
                            )}
                            placement="top"
                          >
                            <button
                              type="button"
                              className="btn-circle"
                              style={{
                                display: 'inline-block',
                                marginLeft: 3,
                                verticalAlign: 'middle',
                              }}
                            ></button>
                          </CustomTooltip>
                        ) : null}
                        {field === 'title' ? (
                          <>
                            <button
                              aria-controls="customized-menu"
                              aria-haspopup="true"
                              onClick={(e) => handleClick('-1', e)}
                              style={{
                                padding: '5px',
                                border: 'none',
                                background: 'none',
                              }}
                            >
                              {selectedType.type.length > 0 ? (
                                <img
                                  style={{
                                    width: '25px',
                                    height: '25px',
                                  }}
                                  src="/Images/icon-filter-hover.png"
                                  alt="filter"
                                />
                              ) : (
                                <img
                                  src="/Images/icon-filter.png"
                                  alt="filter"
                                />
                              )}
                            </button>
                            <CustomDropdownWithSelect
                              value={t(selectedType)}
                              handleClose={handleClose}
                              anchorEl={anchorEl && anchorEl['-1']}
                              list={FILTER_ORDER_TYPE_LIST}
                              setValue={(index) =>
                                setSelectedType(FILTER_ORDER_TYPE_LIST[index])
                              }
                              getOptionLabel={(option) => {
                                return t(option.text);
                              }}
                            />
                          </>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order, index) =>
                    order.writerPropositions.length === 0 ? (
                      <RenderNoWriterPropositionTableRow
                        order={order}
                        index={index}
                      />
                    ) : order.writerPropositions.length === 1 ? (
                      <RenderOneWriterPropositionTableRow
                        index={index}
                        order={order}
                        writersOffers={writersOffers}
                      />
                    ) : (
                      <RenderMultipleWriterPropositionTableRow
                        index={index}
                        order={order}
                        toggleExpanded={toggleExpanded}
                      />
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="text-end">
                {disabled ? (
                  <button className="bt-submit-y-disabled-v2">
                    {' '}
                    {t('Sauvegarder')}{' '}
                  </button>
                ) : (
                  <button className="bt-submit-y-v2" onClick={handleSubmit}>
                    {' '}
                    {t('Sauvegarder')}{' '}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Paginator
        isLoading={isLoading}
        listLength={filteredOrders.length}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        nbElementsPerPage={ordersPerPage}
        goToPage={(page) => setCurrentPage(page)}
      />

      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
}
