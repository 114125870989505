import { createSlice } from '@reduxjs/toolkit';
import { fetchWriterText } from './features';

export const writerAnalyzer = createSlice({
  name: 'writerAnalyzer',
  initialState: {
    orderId: null,
    orderType: null,
    orderSiloName: '',
    textType: 'NON_TRANSLATED',
    formData: null,
    isLoading: true,
    isError: true,
  },
  reducers: {
    updateForm: (state, { payload }) => {
      state.formData = payload;
      state.isLoading = false;
      state.isError = false;
    },
    updateTextForm: (state, { payload }) => {
      let object = { ...state.formData };
      object[payload.key] = payload.value;
      state.formData = { ...object };
    },
    setOrderId: (state, { payload }) => {
      state.orderId = payload;
    },
    setOrderType: (state, { payload }) => {
      state.orderType = payload;
    },
    setTextType: (state, { payload }) => {
      state.textType = payload;
    },
    setOrderSiloName: (state, { payload }) => {
      state.orderSiloName = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWriterText.fulfilled, (state, { payload }) => {
        state.formData = payload;
        state.isLoading = false;
      })
      .addCase(fetchWriterText.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchWriterText.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default writerAnalyzer.reducer;

export const {
  updateForm,
  updateTextForm,
  setOrderId,
  setOrderType,
  setTextType,
  setOrderSiloName,
} = writerAnalyzer.actions;
export const writerAnalyzerSelector = (state) => state.writerAnalyzer;
