import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import {
  setUrlSiteForDisplay,
  setConsultantForDisplay,
} from 'store/Writer/OrderDisplayByClient';
import { fetchWebsiteOrdersList } from 'store/Writer/OrderDisplayByClient/features';

import { useHistoryState } from 'utils/hooks/useHistoryState';

import SearchBar from '../SearchBar';
import { Loader } from 'components/shared/Loader';
import './styles.css';

function UserProgressTable({ column, userData, isLoading, path }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [urlSearch, setUrlSearch] = useHistoryState('urlSearch', '');
  const handleSearch = (event) => setUrlSearch(event.target.value);

  return (
    <div className="col-12 col-md-7">
      <div className="box-wrapper">
        <div className="box-list-header">
          <div className="box-title">{t('Mes Projets')}</div>
          <SearchBar urlSearch={urlSearch} handleChangeText={handleSearch} />
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="table-responsive">
            <div className="box-table">
              <table className="table-lists">
                <thead>
                  <tr>
                    {column.map((item, index) => (
                      <th key={index}>{t(item.heading)}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {userData
                    ?.filter((x) => {
                      if (urlSearch && urlSearch.length > 0) {
                        return x.urlSite
                          .toLowerCase()
                          .includes(urlSearch.toLowerCase());
                      }
                      return true;
                    })
                    .map((item) => (
                      <tr key={item.id} style={{ cursor: 'pointer' }}>
                        {column.map((columnItem, index) => {
                          return (
                            <td
                              key={index}
                              onClick={() => {
                                dispatch(setUrlSiteForDisplay(item.urlSite));
                                dispatch(fetchWebsiteOrdersList(item.id));
                                dispatch(
                                  setConsultantForDisplay(item.consultant)
                                );

                                history.push(path);
                              }}
                            >
                              {item[`${columnItem.value}`]}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProgressTable;
