import axiosPrivate from 'config/axiosPrivate';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import endpoints from 'config/endpoints.json';

import { AssignClient } from 'components/Admin/AssignClient';
import {
  CustomAutocomplete,
  CustomAutocompleteFreeSolo,
} from 'components/shared/DropDown/CustomAutocomplete';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';
import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import { useHistory } from 'react-router-dom';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'utils/Constants';
import { animationUrl } from 'config/endpoints';

const API = {
  pixopClients: '/user/pixOpsClient',
  getAllConsultants: '/user/all',
  getAllClients: '/user/clients',
  getAvailableThematics: '/thematic/all',
  createWebsite: '/clientWebsite',
};

export default function CreateWebsite() {
  const history = useHistory();
  const { t } = useTranslation();
  const isDemo = endpoints.isDemo;

  const [pixOpData, setPixOpData] = useState([]);
  const [disabledForm, setDisabledForm] = useState(true);

  const [accountsList, setAccountsList] = useState([]);
  const [account, setAccount] = useState(null);

  const [urlSitesList, setUrlSitesList] = useState([]);
  const [urlSite, setUrlSite] = useState(null);

  const [consultant, setConsultant] = useState(null);
  const [consultantsList, setConsultantsList] = useState([]);

  const [clientsList, setClientsList] = useState([]);

  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [websiteThematics, setWebsiteThematics] = useState([]);

  const [serviceDetails, setServiceDetails] = useState('');

  const [coconsTextNbr, setCoconsTextNbr] = useState(0);
  const [coconsNbr, setCoconsNbr] = useState(0);
  const [faqNbr, setFaqNbr] = useState(0);
  const [gmbNbr, setGmbNbr] = useState(0);
  const [textOptimizerNbr, setTextOptimizerNbr] = useState(0);

  const validationSchema = yup.object().shape({
    coconsTextNbr: yup.number().min(0).required(),
    coconsNbr: yup.number().min(0).required(),
    faqNbr: yup.number().min(0).required(),
    gmbNbr: yup.number().min(0).required(),
    textOptimizerNbr: yup.number().min(0).required(),
  });

  const [assignedClients, setAssignedClients] = useState([
    {
      name: '',
      mail: '',
      phoneNumber: '',
      isNew: false,
    },
  ]);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    axiosPrivate.get(API.pixopClients).then((res) => {
      setPixOpData(res.data);
      setAccountsList(res.data.map((x) => x.clientName));
    });

    axiosPrivate
      .get(API.getAllConsultants, { params: { role: ROLES.CONSULTANT } })
      .then((res) => {
        setConsultantsList(res.data);
      });

    axiosPrivate
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));
  }, []);

  useEffect(() => {
    if (account && pixOpData.length > 0) {
      const matchingPixOp = pixOpData.find((x) => x.clientName === account);
      if (matchingPixOp) {
        setUrlSitesList(matchingPixOp.site);
      } else {
        setUrlSitesList([]);
      }
    }

    if (account) {
      axiosPrivate
        .get(API.getAllClients, { params: { account: account } })
        .then((res) => {
          setClientsList(res.data);
        });
    }
    setUrlSite('');
    setConsultant(null);
    setCoconsTextNbr('');
    setCoconsNbr('');
    setTextOptimizerNbr('');
    setFaqNbr('');
    setGmbNbr('');
    setServiceDetails('');
  }, [account, pixOpData]);

  useEffect(() => {
    if (urlSite) {
      axiosPrivate
        .get('/website/byUrlSite', { params: { urlSite: urlSite } })
        .then((res) => {
          if (res.status === 200) {
            setConsultant(res.data.consultant);
            setCoconsTextNbr(res.data.coconsTextNbr);
            setCoconsNbr(res.data.coconsNbr);
            setTextOptimizerNbr(res.data.textOptimizerNbr);
            setFaqNbr(res.data.faqNbr);
            setGmbNbr(res.data.gmbNbr);
            setServiceDetails(res.data.serviceDetails);
          } else {
            setConsultant('');
            setCoconsTextNbr('');
            setCoconsNbr('');
            setTextOptimizerNbr('');
            setFaqNbr('');
            setGmbNbr('');
            setServiceDetails('');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [urlSite]);

  useEffect(() => {
    const checkValidForm = async () => {
      const invalid = assignedClients.some(
        (x) =>
          !x.name ||
          !x.phoneNumber ||
          !x.mail ||
          !x.name.length ||
          !x.phoneNumber.length ||
          !x.mail.length
      );

      const positveNumbers = await validationSchema
        .validate({
          coconsTextNbr,
          coconsNbr,
          faqNbr,
          gmbNbr,
          textOptimizerNbr,
        })
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });

      if (
        !account ||
        !urlSite ||
        !consultant ||
        !positveNumbers ||
        websiteThematics.length === 0 ||
        serviceDetails.length === 0 ||
        invalid
      ) {
        setDisabledForm(true);
      } else {
        setDisabledForm(false);
      }
    };
    checkValidForm();
  }, [
    account,
    urlSite,
    consultant,
    websiteThematics,
    serviceDetails,
    assignedClients,
    validationSchema,
    coconsTextNbr,
    coconsNbr,
    faqNbr,
    gmbNbr,
    textOptimizerNbr,
  ]);

  const callbackFromMultiSelect = (newList) => {
    setWebsiteThematics(newList);
  };

  const createWebsite = () => {
    let body = {
      websiteDTO: {
        coconsNbr,
        coconsTextNbr,
        consultant,
        faqNbr,
        gmbNbr,
        serviceDetails,
        textOptimizerNbr,
        urlSite,
        thematics: websiteThematics,
      },
      clients: assignedClients
        .filter((x) => x.name)
        .map((x) => {
          return {
            mail: x.mail,
            name: x.name,
            phoneNumber: x.phoneNumber,
            account,
            role: ROLES.CLIENT,
          };
        }),
    };

    axiosPrivate
      .post(API.createWebsite, body)
      .then(() => {
        setIsSuccess(true);
        setErrorSuccessMsg(
          `${t('Le contrat pour le client')} ${account}  ${t(
            'a été créé avec succès'
          )}!`
        );
        setErrorSuccessImg(animationUrl + '/01_CommandeCrée.gif');
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              t('Contrat déjà existant, veuillez modifier les informations')
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{t('Nouveau client')}</h1>
      </div>
      <div className="row">
        <div className="col-12 col-md-10 box-screen">
          <div className="box-img-bg bg-8" />
          <div className="box-img-bg bg-7" style={{ zIndex: -1 }} />
          <div className="box-img-bg bg-9" />
          <div className="header-title">{t('Fiche du site web')} </div>
          <div className="box-wrapper">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="row row-mb">
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Compte client')} </div>
                    {!isDemo ? (
                      <CustomAutocomplete
                        disabled={accountsList.length <= 0}
                        list={accountsList}
                        getOptionSelected={(option) => option === account}
                        getOptionDisabled={(option) => false}
                        setValue={(option) => setAccount(option)}
                        groupBy={(option) => false}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                      />
                    ) : (
                      <CustomAutocompleteFreeSolo
                        list={accountsList}
                        value={account}
                        getOptionSelected={(option) => option === account}
                        onInputChange={(value) => {
                          setAccount(value);
                        }}
                        setValue={(option) => setAccount(option)}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                      />
                    )}
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Url du site')}</div>

                    <CustomAutocompleteFreeSolo
                      list={urlSitesList}
                      value={urlSite}
                      getOptionSelected={(option) => option === urlSite}
                      onInputChange={(value) => {
                        setUrlSite(value);
                      }}
                      setValue={(option) => {
                        setUrlSite(option);
                        setConsultant(option ? option.consultant : '');
                        setCoconsTextNbr(option ? option.coconsTextNbr : '');
                        setCoconsNbr(option ? option.coconsNbr : '');
                        setTextOptimizerNbr(
                          option ? option.textOptimizerNbr : ''
                        );
                        setFaqNbr(option ? option.faqNbr : '');
                        setGmbNbr(option ? option.gmbNbr : '');
                        setServiceDetails(option ? option.serviceDetails : '');
                      }}
                      getOptionLabel={(option) => {
                        return option;
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Consultant')}</div>
                    <CustomAutocomplete
                      disabled={!consultantsList}
                      list={consultantsList}
                      value={consultant}
                      getOptionSelected={(option) =>
                        option.id === consultant.id
                      }
                      getOptionDisabled={(option) => false}
                      setValue={(option) => setConsultant(option)}
                      groupBy={(option) => false}
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option; // Handle string values directly
                        }
                        return option.name;
                      }}
                    />

                    {/* <div>
                      <input
                        type="text"
                        className="form-control form-ct"
                        value={consultant?.name}
                        disabled
                      />
                    </div> */}
                  </div>
                </div>
                {assignedClients.map((client, index) => (
                  <AssignClient
                    id={index}
                    list={clientsList.filter(
                      (item) =>
                        !assignedClients.some(
                          (item2) => item2.mail === item.mail
                        )
                    )}
                    new={true}
                    data={client}
                    account={account}
                    key={index}
                    addClient={() =>
                      setAssignedClients([
                        ...assignedClients,
                        {
                          name: '',
                          mail: '',
                          phoneNumber: '',
                        },
                      ])
                    }
                    updateClientsList={(type, object) => {
                      const newValue = client;
                      newValue[type] = object;
                      const newList = assignedClients;
                      newList[index] = newValue;
                      setAssignedClients([...newList]);
                    }}
                    isDeleteable={assignedClients.length > 1}
                    deleteClient={(index) => {
                      let newList = assignedClients;
                      newList.splice(index, 1);
                      setAssignedClients([...newList]);
                    }}
                  />
                ))}

                <div className="row row-mb">
                  <div className="col-12 col-md-2" style={{ width: '18%' }}>
                    <div className="label-title">
                      {t('Nb de textes par Silo')}
                    </div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        onChange={(e) => {
                          setCoconsTextNbr(e.target.value);
                          setCoconsNbr(e.target.value);
                        }}
                        value={coconsTextNbr}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {coconsTextNbr < 0 && (
                      <p style={{ color: 'red' }}>
                        {t('les valeurs négatives ne sont pas autorisées')}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-2" style={{ width: '15.3%' }}>
                    <div className="label-title">{t('Nombre de FAQ')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={faqNbr}
                        onChange={(e) => setFaqNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {faqNbr < 0 && (
                      <p style={{ color: 'red' }}>
                        {t('les valeurs négatives ne sont pas autorisées')}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('Nombre de GMB')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={gmbNbr}
                        onChange={(e) => setGmbNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {gmbNbr < 0 && (
                      <p style={{ color: 'red' }}>
                        {t('les valeurs négatives ne sont pas autorisées')}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('Nombre de Silo')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={coconsTextNbr}
                        onChange={(e) => {
                          setCoconsNbr(coconsTextNbr);
                        }}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {coconsNbr < 0 && (
                      <p style={{ color: 'red' }}>
                        {t('les valeurs négatives ne sont pas autorisées')}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="label-title">
                      {t('Nb de textes à optimiser')}
                    </div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={textOptimizerNbr}
                        onChange={(e) => setTextOptimizerNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {textOptimizerNbr < 0 && (
                      <p style={{ color: 'red' }}>
                        {t('les valeurs négatives ne sont pas autorisées')}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="label-title">
                      {t('Thématiques d’expertise')}
                    </div>
                    <CustomChipsAutocomplete
                      callbackFromMultiSelect={callbackFromMultiSelect}
                      value={websiteThematics}
                      disabled={!availableThematicsList}
                      list={availableThematicsList}
                      setValue={(option) => setWebsiteThematics(option)}
                      getOptionLabel={(option) => {
                        return t(option.thematic) || '';
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="label-title">
                      {t('Détail de la prestation du contenu')}
                    </div>
                    <div>
                      <input
                        type="text"
                        className="form-control form-ct"
                        value={serviceDetails}
                        onChange={(e) => setServiceDetails(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-button-bt">
            <div>
              {disabledForm ? (
                <button className="bt-submit-disabled-y">
                  {' '}
                  {t('Sauvegarder')}{' '}
                </button>
              ) : (
                <button className="bt-submit-y" onClick={createWebsite}>
                  {t('Sauvegarder')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() =>
          isSuccess ? history.push('/') : setErrorSuccess(false)
        }
        toggle={() => setErrorSuccess(!errorSuccess)}
        success={isSuccess}
        imageUrl={errorSuccessImg}
      />
    </div>
  );
}
