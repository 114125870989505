export const englishTranslation = {
  'Mon profil': 'My profile',
  Déconnexion: 'Logout',
  'Tableau de bord': 'Dashboard',
  'Commandes non attribuées': 'Orders not attributed',
  'Commandes en cours': 'Orders in progress',
  'Commandes en retard': 'Overdue orders',
  'Commandes validées': 'Validated orders',
  'Non attribuées': 'Not attributed',
  'En cours': 'In progress',
  'En retard': 'Overdue',
  Validées: 'Validated',
  Rechercher: 'Search',
  'Mes Projets': 'My projects',
  'Titre de la commande': 'Order heading',
  'Textes livrés': 'Delivered',
  'Textes validés': 'Validated',
  'Nouveau texte': 'New text',
  'Nom du texte': 'Name of the text',
  'Mots clés': 'Mots clés',
  URL: 'URL',
  'Balise titre': 'Title tag',
  'Balise meta-description': 'Meta tag',
  'Détails du texte': 'Text details',
  Étape: 'Step',
  '(Titre, mot clé, url, balise, méta-description)':
    '(title, keyword, url, title and meta tag)',
  'ajouter un texte': 'ajouter un texte',
  'Séparer les mots clés par un "enter"':
    'Séparer les mots clés par un "enter"',
  'mot-clé': 'keyword',
  'Télécharger le document': 'Download the document',
  'Mots clés manquants': 'Missing keywords',
  'Ajout de mots-clé avec la fréquence restante':
    'Remaining keword frequencies',
  'Résultat sémantique': 'semantic result',
  'Enrichissement sémantique': 'semantic enrichment',
  'Insérez votre gap sémantique avec l’occurrence':
    'Insert your semantic gap with the occurrence',
  'Coller le texte initial dans le champs ci-dessous':
    'Paste the initial content in the field below',
  optionel: 'Only for optimization',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Add or complete the content below',
  'Analyses restantes': 'Remaining analysis',
  'Nombre total de mots (existants et ajoutés)':
    'Total number of words (existing and new)',
  'Nombre de mot à ajouter': 'Number of words to add',
  'Nombre total de mots ajoutés': 'Total number of added words',
  Sauvegarder: 'Save',
  'Enregistrer et informer client': 'Save and inform client',
  'Soumettre un nouveau texte': 'Submit a new text',
  'Bon pour livraison': 'Ready for delivery',
  Comparer: 'Compare',
  Analyser: 'Analyse',
  Brouillon: 'Draft',
  'Il semblerait que tu n’aies plus de crédit d’analyse':
    "Looks like you're out of scan credit",
  'Ce champ est obligatoire': 'This field is required',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Are you sure you want to save the text',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Please change the following information: title and/or keyword',
  "Une erreur s'est produite": 'An error has occurred',
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Please correct the format of your semantic gap in order to be able to save the text in draft',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Please add an initial text in order to be able to save the text in draft',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Please add a url so that you can save the draft text',
  'Le texte': 'The text',
  'a été mis en brouillon': 'has been drafted',
  'Le texte a été mis en brouillon': 'The text has been drafted',
  'Le texte a été livré avec succès': 'The text was delivered successfully',
  'Modification effectuée avec succès': 'Change made successfully',
  "La demande d'ajout de crédit est envoyée":
    'The request to add credit is sent',
  'La commande a été supprimé avec succès':
    'The order was successfully deleted',
  'Voulez-vous vraiment supprimer la commande de':
    'Are you sure you want to delete The order',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Are you sure you want to delete the order distribution for',
  'Voulez-vous vraiment supprimer  le texte?':
    'Are you sure you want to delete the text?',
  Postuler: 'Apply',
  Postulé: 'applied',
  Supprimer: 'Delete',
  'Typix - Mon profil': 'Typix - My profile',
  'Votre profil a été mis à jour avec succès.':
    'Your profile has been successfully updated.',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'An error has occurred. ',
  'Ce champs est obligatoire': 'This field is required',
  Prénom: 'First name',
  'E-mail': 'E-mail',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'This field is mandatory and can only have numbers',
  'Mot de passe': 'Password',
  'doit comprendre au moins 8 charactères': 'must be at least 8 characters',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'at least one lowercase letter and one uppercase letter, one special character and one number',
  'ne doit pas inclure votre nom/prénom':
    'must not include your first/last name',
  'Confirmer le mot de passe': 'Confirm password',
  'Les deux mots de passe ne sonts pas identiques':
    'The two passwords are not identical',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'At least 8 characters including 1 upper case letter, one number, a symbol and different from the name',
  'Typix - Nouvelle commande': 'Typix - New order',
  'La commande pour le client': 'The order for the client',
  'a été ajouté avec succès': 'was successfully added',
  'Le titre de la commande existe déjà': 'Order title already exists',
  "Oups....! Une erreur s'est produite": 'Whoops....! ',
  'Nouvelle commande': 'New order',
  'Réf devis': 'Quote reference',
  'Tâche - id tâche': 'Task - task ID',
  'Intitulé de la commande': 'Order heading',
  'URL du site': 'Website URL',
  'Nombre de textes': 'Number of texts',
  'Tarif au mot': 'price/word',
  Consultant: 'Consultant',
  'Contact edito': 'Editorial project manager ',
  'Coût d’achat max/mot': 'cost/word max',
  'Rédacteur attribué': 'Assigned Writer',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'This field must be greater than 0 and less than 1',
  'Ce champs doit être sup à 0': 'This field must be greater than 0',
  'Nb textes': 'Number of texts',
  ' Vous avez ': ' You have ',
  ' textes en plus': ' more texts',
  ' Min': ' Min',
  'Max doit être sup à Min': 'Max must be greater than Min',
  Max: 'Max',
  'Nombre de mots par texte': 'Number of words per text',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'This field must be greater than 0 because invoicing is not real',
  'Facturation au réel': 'Billing at cost',
  'Brief détaillé': 'Detailed brief',
  'Gap sémantique': 'Semantic gap',
  Documentation: 'Documentation',
  'Champs obligatoires': 'Required fields',
  'à cocher si le nombre de mots est différent par texte':
    'to be checked if the number of words is different per text',
  'Lien drive...': 'Drive link...',
  'Le contrat pour le client': 'The contract for the client',
  'a été créé avec succès': 'was created successfully',
  'Contrat déjà existant, veuillez modifier les informations':
    'Existing contract, please modify the information',
  'Nouveau client': 'New client',
  'Prénom / Nom du client': 'Client full name',
  'Fiche du site web ': 'Website sheet ',
  'Compte client': 'Client account',
  'Url du site': 'Site URL',
  'Nb de textes par Silo': 'Number of texts per Silo',
  'Nombre de FAQ': 'Number of Q&A',
  'Nombre de GMB': 'Number of GMB',
  'Nombre de Silo': 'Number of silo',
  'Nb de textes à optimiser': 'Number of texts to optimize',
  'Thématiques d’expertise': 'Fields of expertise',
  'Typix - Créer rédacteur': 'Typix - Create Writer',
  'Le rédacteur ': 'The writer ',
  'Fiche du rédacteur': "Writer's sheet",
  'Ajouter un rédacteur': 'Add a writer',
  Mail: 'Email',
  Adresse: 'Address',
  Société: 'Company',
  'Analyse/Limite': 'Analysis/Limit',
  'Prénom/Nom': 'Full name',
  Téléphone: 'Phone',
  Thématiques: 'Topics',
  'Note rédacteur': "Writer's level",
  Global: 'Global',
  Deadline: 'Deadline',
  Rédacteurs: 'Writers',
  'Afficher les résultats': 'Display results',
  Afficher: 'Display',
  '0 rédacteurs': '0 writers',
  'Typix - Mon tableau de bord': 'Typix - My Dashboard',
  'Le contrat de': 'The contract of',
  'a été supprimé avec succès': 'has been successfully deleted',
  "il y'a des commandes liés au site web ":
    'there are orders linked to the website ',
  'Liste des clients': 'Clients listing',
  Nom: 'Last name',
  'Nom client': 'Name',
  Commandes: 'Orders',
  Éditer: 'Edit',
  'non attribuées': 'unassigned',
  'en cours': 'in progress',
  'en retard': 'late',
  validées: 'validated',
  'Voulez-vous vraiment supprimer le contrat de':
    'Are you sure you want to delete the contract from',
  'Temps restant': 'Remaining time',
  'Nb de textes': 'Number of texts',
  'Nb total de textes': 'Total number of texts',
  'Nb de mots': 'Number of words',
  'Au réel': 'At cost',
  'Nb mots/texte': 'Nb words/text',
  Prix: 'Price',
  Textes: 'Texts',
  Validation: 'Validation',
  'Votre décision a été prise en compte avec succès':
    'Your decision has been successfully taken into account',
  'Détail de la commande': 'Order details',
  'Détail ligne de commande': 'Order distribution ',
  Historique: 'History',
  'Typix - Détails de la commande': 'Typix - Order Details',
  'Contenu disponible': 'Available contents',
  Noir: 'Black',
  Orange: 'Orange',
  Violet: 'Violet',
  Vert: 'Green',
  'contenu en attente de vérification client':
    'content awaiting client verification',
  'contenu en attente de repasse rédacteur': 'content awaiting editor review',
  'contenu repassé, en attente validation client':
    'content re-made, awaiting client validation',
  'contenu validé par le client': 'content validated by the client',
  'Tout télécharger': 'Download all',
  'Ajouter un texte': 'Add a text',
  Traduction: 'Translation',
  'Tous les textes': 'All texts',
  'Textes revisés': 'Reviewed texts',
  'Révision en attente': 'Pending review',
  Brouillons: 'Drafts',
  'Titre du texte': 'Text heading',
  "Date d'ajout": 'Publication date',
  'Édité par le client': 'Client edited',
  'Révision demandée': 'Client asked for a review',
  Validé: 'Validated',
  'résultats sur': 'results over',
  Précédent: 'Previous',
  Suivant: 'Next',
  Oui: 'Yes',
  Non: 'No',
  'Intitulé commande': 'Order title',
  'Nombre de mots gérés': 'Number of words managed',
  'Min/Max': 'Min/Max',
  'Nombre de mots rédigés': 'Number of words written',
  'Status texte': 'status text',
  'Date de livraison': 'Delivery date',
  Score: 'Score',
  'Date deadline validation client': 'Client validation deadline',
  'Date de validation': 'Validation date',
  'Liste des commandes': 'Orders Listing',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'You have the option of exporting the report of one or more orders. ',
  'Typix - Détails du texte': 'Typix - Text Details',
  'Le texte initial de': 'The original text of',
  'a été activé': 'has been activated',
  'a été livré avec succès': 'has been delivered successfully',
  'Mot clé': 'Keyword',
  'Format souhaité': 'Desired format',
  'mot-clé (fréquence)': 'keyword (frequency)',
  'Séparez les mots-clés par un Enter .':
    'Separate the keywords with an "Enter".',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n…': 'keyword1 (2)\n',
  'Ajout de mots-clés avec la fréquence restante':
    'Addition of keywords with the remaining frequency',
  'Mots clés restants': 'Remaining keywords',
  Langue: 'Language',
  Langues: 'Languages',
  Disponibilité: 'Availability',
  'ID rédacteur': 'Writer ID',
  Réactiver: 'Reactivate',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Add or complete your content below:',
  'Nombre total de mots (existants et ajoutés) :':
    'Total number of words (existing and new):',
  'Nombre de mot à ajouter (Min/Max) : ': 'Number of words to add (Min/Max): ',
  'Nombre total de mots ajoutés :': 'Total number of words added:',
  Rôle: 'Role',
  Site: 'Site',
  Modifier: 'Edit',
  Désactiver: 'Deactivate',
  'Ajouter un admin': 'Add an admin',
  'Ajouter un consultant': 'Add a consultant',
  'Ajouter un client': 'Add client',
  'Tous les rôles': 'All roles',
  Admins: 'admins',
  Clients: 'Clients',
  Consultants: 'Consultants',
  'Typix - Gestion des utilisateurs': 'Typix - User Management',
  'Gestion des utilisateurs': 'User Management',
  'Rechercher par mail, prénom, nom, site...':
    'Search by email, first name, last name, website...',
  'Typix - Fiche site web': 'Typix - Website sheet',
  "il y'a des commandes liés à au site web":
    'there are orders linked to the website',
  'Fiche du site web': 'Website sheet',
  'Prénom/Nom du client': "Client's first name/last name",
  'Mail client': 'Client email',
  'Détail de la prestation du contenu': 'Details of content delivery',
  'Voir les commandes': 'See orders',
  'Typix - Fiche rédacteur': "Typix - Editor's sheet",
  'Vous ne pouvez pas supprimer le rédacteur': 'You cannot delete the editor',
  'car celui-ci a déjà rédigé des textes':
    'because he has already written texts',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Are you sure you want to delete the editor',
  disponible: 'available',
  indisponible: 'unavailable',
  'Exporter .csv': 'Export .csv',
  'listeRedacteur-': 'WritersList-',
  Notifications: 'Notifications',
  'nouvelles notifications': 'new notifications',
  'Vous avez': 'You have',
  'textes livrés en attente de validation':
    'texts delivered awaiting validation',
  'révisions effectuées en attentes de validation':
    'completed revisions awaiting validation',
  'commandes prête': 'orders ready',
  'Toutes les notifications': 'All notices',
  'Révisions effectués': 'Revisions made',
  'Typix - Mon Profile': 'Typix - My Profile',
  'Le texte a été enregistré avec succès':
    'The text has been saved successfully',
  'La traduction a été enregistrée avec succès':
    'The translation has been successfully saved',
  'Texte + Traduction enregistré avec succès':
    'Text + Translation has been successfully saved',
  'Le texte a été validé avec succès':
    'The text has been validated successfully',
  'Gap sémantique :': 'Semantic gap:',
  'Séparez les mots-clés par un " Enter ".':
    'Separate the keywords with an "Enter".',
  'Valider le texte': 'Validate the text',
  'Demander une révision': 'Request a review',
  'Demander une repasse globale': 'Request a global review',
  'Mes Commandes': 'My orders',
  rédacteurs: 'writers',
  'Export en cours de téléchargement…': 'Export in progress...',
  "Pas d'options": 'No options',
  "Un utilisateur existe déjà avec l'email":
    'A user already exists with the email',
  "L'admin": 'The admin',
  'Le consultant': 'The consultant',
  'Le client': 'The client',
  'Le rédacteur': 'The writer',
  "l'admin": 'the admin',
  'le consultant': 'the consultant',
  'le client': 'the client',
  'le rédacteur': 'the writer',
  'La fiche du website': 'The website sheet',
  'a été mis à jour avec succès': 'has been updated successfully',
  'Votre candidature a bien été envoyée':
    'Your application has been sent successfully',
  'Postuler à la commande': 'Apply for order',
  'Livraison estimé au': 'Estimated delivery at',
  Annuler: 'Cancel',
  Confirmer: 'Confirm',
  'Vous êtes sûr': 'Are you sure',
  'Demander des crédits': 'Apply for credits',
  'Félicitations !': 'Congratulations !',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'an email containing a password reset link has been sent to your email',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'check your email and click the link to continue!',
  "cet email n'existe pas": 'this email does not exist',
  'Réinitialiser votre mot de passe': 'Reset your password',
  Réinitialiser: 'Reset',
  Envoyer: 'Send',
  Ou: 'Or',
  'Veuillez donner un descriptif au document téléchargé':
    'Please give a description of the uploaded file',
  'Votre demande de révision a bien été envoyée.':
    'Your review request has been sent.',
  'Veuillez saisir un texte ou importer un fichier.':
    'Please enter text or import a file.',
  'Demande de révision': 'Request for review',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Please indicate below your requests for modifications, taking into account our reviewing conditions',
  'Dans le cadre d’une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'As part of a review of content, only the following elements will be supported',
  'Si des phrases ne sont pas dans un français correct':
    'If sentences are not grammatically correct',
  'importer un document': 'import a document',
  'le contenu présente des inexactitudes': 'If the content has inaccuracies',
  'Si le contenu ne respecte pas les règles du brief':
    'If the content does not respect the rules of the brief',
  'Votre text ne de doit pas dépasser 500 mots':
    'Your text should not exceed 500 words',
  'Les demandes suivantes ne seront pas prises en compte':
    'The following requests will not be considered',
  'Coquilles (double espace, « s »…)': 'Shells (double space, “s”…)',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'English expressions or sentenses that do not suit you',
  'Toute demande n’ayant pas été précisée lors du brief':
    'Any request that was not specified during the brief',
  'Pour ajouter un nouveau texte': 'To add new text',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'To add text (optimizing existing content)',
  'Nombre de Jours': 'Number of days',
  'délai dépassé': 'missed deadline',
  'Livraison estimée': 'Estimated delivery',
  'Modifier l’admin': 'Edit admin',
  'Modifier le client': 'Edit client',
  'Modifier le rédacteur': 'Edit Writer',
  'Modifier le consultant': 'Edit Consultant',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Oops...! there is/are error(s) in the semantic gap window. Please check its contents to correct it/them and resubmit your text.',
  'Voulez-vous vraiment supprimer': 'Are you sure you want to delete',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'is the only client for a site, you must add others to be able to delete it',
  'ne peut pas être supprimé car il est lié à des sites web':
    'cannot be deleted because it is linked to websites',
  'ne peut pas être supprimé pour le moment': 'cannot be deleted at this time',
  "L'utilisateur": 'The user',
  'Voulez-vous vraiment désactiver': 'Are you sure you want to deactivate',
  'Voulez-vous vraiment activer': 'Do you really want to activate',
  'a été désactivé avec succès': 'has been deactivated successfully',
  'a été activé avec succès': 'has been successfully activated',
  Utilisateurs: 'Users',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'The number of texts in the order cannot be less than the number of texts attributed to the editor(s). ',
  'La commande a été mis à jour avec succès !':
    'The order was successfully updated!',
  "Cette commande n'existe pas": 'This order does not exist',
  'Mail rédacteurs': 'Writer Email',
  'Chef edito': 'chief editor',
  'Min.': 'Min.',
  'Max.': 'Max.',
  Consignes: 'Instructions',
  Rédaction: 'Writing',
  'La ligne de commande pour': 'The order distribution for',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'This command line cannot be deleted because part of its texts has been delivered',
  'Les lignes de commandes ont été mises à jour avec succès !':
    'The order distributionS have been successfully updated!',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Update not allowed because texts have been delivered',
  'Ce champs doit être sup ou égale nombre de texte':
    'This field must be greater than or equal to number of text',
  'livré et inférieur au nombre de texte non affecté':
    'delivered and less than the number of unassigned text',
  'Nombre de textes restants': 'Number of remaining texts',
  'Nombre de textes ajoutés': 'Number of texts added',
  voir: 'see',
  'Le texte a été supprimé avec succès': 'The text was successfully deleted',
  'Recherche par rédacteur ou texte': 'Search by author or text',
  'Mail envoyé': 'E-mail sent',
  Date: 'Date',
  'Liste des rédacteurs': 'Writers list',
  'Chercher un rédacteur': 'Find a writer',
  'rédacteurs trouvés': 'Writers found',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textes  de la commande':
    'This field is mandatory and must be less than or equal to the Total number of order texts',
  'Ce champ est obligatoire doit être supérieur à 0':
    'This field is required must be greater than 0',
  'Doit être inférieur au nombre maximum':
    'Must be less than the maximum number',
  "La date doit être ultérieure à aujourd'hui.":
    'The date must be later than today.',
  'Veuillez fournir une date valide': 'Please provide a valid date',
  'Veuillez préciser la date': 'Please specify the date',
  'email invalide': 'invalid email',
  'Numéro invalide': 'Invalid number',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Have at least 8 characters with a capital letter, a number, a special character',
  'Ne doit pas contenir votre nom': 'Must not contain your name',
  'Le mot de passe de confirmation ne correspond pas':
    'Confirmation password does not match',
  Compte: 'Account',
  'Nb de silo par texte': 'Number of silo per text',
  'Nb de FAQ': 'Number of Q&A',
  'Nb de GMB': 'Number of GMB',
  'Nb de Silo': 'Number of Silo',
  'Détail de la prestation': 'Content invoice detail',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Have at least 8 characters with a capital letter, a number, a special character and not be identical to the name',
  'Contenu rédigé': 'Written content',
  'Ne plus afficher ce message': 'Do not show this message again',
  'Mail clients': 'Client Email',
  'Notes rédacteurs': "Writer's grade",
  'Tarif/Mot': 'Price/Word',
  'Min/Max mots': 'Min/Max words',
  Livrés: 'Delivered',
  Validés: 'Validated ',
  'Coût d’achat': 'Purchase cost',
  'Total facturé': 'Total billed',
  'Rechercher un rédacteur': 'Find a writer',
  'Nom/prénom': 'Full Name',
  Contacter: 'Contact',
  Notes: 'Rating',
  email: 'email',
  'mot de passe oublié': 'forgotten password',
  'Veuillez saisir des champs valides': 'Please enter valid fields',
  'Mot de passe incorrect': 'Wrong password',
  "Une erreur s'est produite, veuillez réessayer":
    'An error has occurred, please try again',
  "L'émail": 'The e-mail',
  "n'existe pas": 'does not exist',
  'Réinitialiser  votre mot de passe': 'reset password',
  'Mot de passe mis à jour avec succès': 'Password updated successfully',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'This password is the one you have already forgotten',
  'Les données fournies sont invalides': 'The data provided is invalid',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Your request has expired...please make another one',
  'Confirmer le nouveau mot de passe': 'Confirm new password',
  'Mettez des mots-clés avec fréquence': 'Set keywords with frequency',
  'Mettez votre texte': 'Put your text',
  Autres: 'Other',
  rédacteur: 'writer',
  Différence: 'Difference',
  'Comparaison hors ligne': 'Offline comparison',
  Heures: 'Hours',
  Titre: 'Title',
  'Nombre de mots': 'Number of words',
  Texte: 'Text',
  Résultats: 'Results',
  Beauté: 'Beauty',
  'Tourisme/Loisirs': 'Tourism/Leisure',
  Voyage: 'Travel',
  'Immo/Defisc': 'Real Estate/Tax',
  Automobile: 'Auto',
  'Maison/Déco': 'Home/Decor',
  Technologie: 'Technology',
  Actualités: 'News',
  Art: 'Art',
  Associations: 'Associations',
  'Banque/Assurance': 'Banking/Insurance',
  'Business/Management': 'Business/Management',
  Commerce: 'Business',
  Droit: 'Law',
  Emploi: 'Employment',
  'Energie/Environnement': 'Energy/Environment',
  'Enfant/Puéri': 'Children/Parents',
  Enseignement: 'Education',
  Entreprise: 'Company',
  Finance: 'Finance',
  'Grande distribution': 'Large-scale distribution',
  Humanitaire: 'Humanitarian',
  Institutions: 'Institutions',
  Internet: 'Internet',
  Jeux: 'Games',
  Literie: 'Bedding',
  Mode: 'Fashion',
  Musique: 'Music',
  Outillage: 'Tools',
  Perso: 'Personal',
  Polyvalent: 'Polyvalent',
  Regional: 'Regional',
  'Santé/Bien-être': 'Health/Wellness',
  Sciences: 'Science',
  Services: 'Services',
  Societe: 'Company',
  Sortir: 'Go out',
  Sport: 'Sports',
  Téléphonie: 'Telephony',
  Transport: 'Transport',
  'Travaux/BTP': 'Works/BTP',
  Webmaster: 'Webmaster',
  Cuisine: 'Cooking',
  'Vos demandes': 'Your requests',
  'Importer un autre document': 'Import another document',
  Exemple: 'Example',
  Français: 'French',
  Anglais: 'English',
  Espagnol: 'Spanish',
  Italien: 'Italian',
  Allemand: 'German',
  Afar: 'Afar',
  Abkhazien: 'Abkhazian',
  Avestan: 'Avestan',
  Afrikaans: 'Afrikaans',
  Akan: 'Akan',
  Amharique: 'Amharic',
  Aragonais: 'Aragonese',
  Arabe: 'Arabic',
  Assamais: 'Assamese',
  Avaric: 'Avaric',
  Aymara: 'Aymara',
  Azerbaïdjanais: 'Azerbaijani',
  Bashkir: 'Bashkir',
  Biélorusse: 'Belarusian',
  Bulgare: 'Bulgarian',
  'Langues bihari': 'Bihari languages',
  Bislama: 'Bislama',
  Bambara: 'Bambara',
  Bengali: 'Bengali',
  Tibétain: 'Tibetan',
  Breton: 'Breton',
  Bosniaque: 'Bosnian',
  'Catalan ; Valencien': 'Catalan; Valencian',
  Tchétchène: 'Chechen',
  Chamorro: 'Chamorro',
  Corse: 'Corsican',
  Cree: 'Cree',
  Czech: 'Czech',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
  Tchouvache: 'Chuvash',
  Welsh: 'Welsh',
  Danois: 'Danish',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Maldivian',
  Dzongkha: 'Dzongkha',
  Ewe: 'Ewe',
  'Grec moderne (1453-)': 'Greek, Modern (1453-)',
  Grec: 'Greek',
  Estonien: 'Estonian',
  Basque: 'Basque',
  Persan: 'Persian',
  Fulah: 'Fulah',
  Finnois: 'Finnish',
  Fidjien: 'Fijian',
  Féroïen: 'Faroese',
  'Frison occidental': 'Western Frisian',
  Irlandais: 'Irish',
  Gaélique: 'Scottish Gaelic',
  Galicien: 'Galician',
  Guarani: 'Guarani',
  Gujarati: 'Gujarati',
  Manx: 'Manx',
  Hausa: 'Hausa',
  Hébreu: 'Hebrew',
  Hindi: 'Hindi',
  'Hiri Motu': 'Hiri Motu',
  Croate: 'Croatian',
  'Haïtien ; créole haïtien': 'Haitian Creole',
  Hongrois: 'Hungarian',
  Arménien: 'Armenian',
  Herero: 'Herero',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (International Auxiliary Language Association)',
  Indonésien: 'Indonesian',
  'Interlingue ; Occidental': 'Interlingue (Occidental)',
  Igbo: 'Igbo',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi (Nuosu)',
  Inupiaq: 'Inupiaq',
  Ido: 'Ido',
  Islandais: 'Icelandic',
  Inuktitut: 'Inuktitut',
  Japonais: 'Japanese',
  Javanais: 'Javanese',
  Géorgien: 'Georgian',
  Kongo: 'Kongo',
  'Kikuyu ; Gikuyu': 'Kikuyu (Gikuyu)',
  'Kuanyama ; Kwanyama': 'Kwanyama (Kuanyama)',
  Kazakh: 'Kazakh',
  'Kalaallisut ; Groenlandais': 'Greenlandic (Kalaallisut)',
  'Khmer central': 'Khmer (Central)',
  Kannada: 'Kannada',
  Coréen: 'Korean',
  Kanuri: 'Kanuri',
  Kashmiri: 'Kashmiri',
  Kurde: 'Kurdish',
  Komi: 'Komi',
  Cornouailles: 'Cornish',
  'Kirghiz ; Kirghiz': 'Kyrgyz (Kyrgyzstan)',
  Latin: 'Latin',
  'Luxembourgeois ; Letzeburgesch': 'Luxembourgish (Letzeburgesch)',
  Ganda: 'Ganda',
  'Limburgan ; Limburger ; Limburgish': 'Limburgish',
  Lingala: 'Lingala',
  Lao: 'Lao',
  Lituanien: 'Lithuanian',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Latvian',
  Malgache: 'Malagasy',
  Marshallais: 'Marshallese',
  Maori: 'Māori',
  Macédonien: 'Macedonian',
  Malayalam: 'Malayalam',
  Mongolien: 'Mongolian',
  Marathi: 'Marathi',
  Malay: 'Malay',
  Maltais: 'Maltese',
  Birmane: 'Burmese',
  Nauru: 'Nauruan',
  'Bokmål, norvégien ; Bokmål norvégien': 'Norwegian Bokmål',
  'Ndebele, Nord ; Ndebele Nord': 'Northern Ndebele',
  Népalais: 'Nepali',
  Ndonga: 'Ndonga',
  'Hollandais ; Flamand': 'Dutch (Flemish)',
  Néerlandais: 'Dutch',
  'Nynorsk norvégien': 'Norwegian Nynorsk',
  Norvégien: 'Norwegian',
  'Ndebele, South ; South Ndebele': 'South Ndebele',
  'Navajo ; Navaho': 'Navajo',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa',
  'Occitan (post 1500)': 'Occitan',
  Ojibwa: 'Ojibwe',
  Oromo: 'Oromo',
  Oriya: 'Oriya',
  'Ossétien ; Ossétique': 'Ossetian',
  'Panjabi ; Punjabi': 'Punjabi',
  Pali: 'Pali',
  Polish: 'Polish',
  'Pushto ; Pashto': 'Pashto',
  Portugais: 'Portuguese',
  Quechua: 'Quechua',
  Romanche: 'Romansh',
  Rundi: 'Rundi',
  'Roumain ; Moldave ; Moldovan': 'Romanian',
  Roumain: 'Romanian',
  Russe: 'Russian',
  Kinyarwanda: 'Kinyarwanda',
  Sanskrit: 'Sanskrit',
  Sarde: 'Sardinian',
  Sindhi: 'Sindhi',
  'Sami du Nord': 'Northern Sami',
  Sango: 'Sango',
  'Sinhala ; Cingalais': 'Sinhala',
  Slovaque: 'Slovak',
  Slovène: 'Slovenian',
  Samoan: 'Samoan',
  Shona: 'Shona',
  Somali: 'Somali',
  Albanais: 'Albanian',
  Serbe: 'Serbian',
  Swati: 'Swati',
  'Sotho, Southern': 'Southern Sotho',
  Sundanese: 'Sundanese',
  Suédois: 'Swedish',
  Swahili: 'Swahili',
  Tamil: 'Tamil',
  Telugu: 'Telugu',
  Tadjik: 'Tajik',
  Thai: 'Thai',
  Tigrinya: 'Tigrinya',
  Turkmène: 'Turkmen',
  Tagalog: 'Tagalog',
  Tswana: 'Tswana',
  'Tonga (Îles Tonga)': 'Tongan',
  Turc: 'Turkish',
  Tsonga: 'Tsonga',
  Tatar: 'Tatar',
  Twi: 'Twi',
  Tahitien: 'Tahitian',
  'Uighur ; Uyghur': 'Uighur',
  Ukrainien: 'Ukrainian',
  Urdu: 'Urdu',
  Uzbek: 'Uzbek',
  Venda: 'Venda',
  Vietnamien: 'Vietnamese',
  Volapük: 'Volapük',
  Wallon: 'Walloon',
  Wolof: 'Wolof',
  Xhosa: 'Xhosa',
  Yiddish: 'Yiddish',
  Yoruba: 'Yoruba',
  'Zhuang ; Chuang': 'Zhuang ; Chuang',
  Chinois: 'Chinese',
  Zoulou: 'Zulu',
  'Commandes intelligentes': 'Smart orders',
  'Commandes avec révision': 'Review + smart orders',
  'Type de contenu': 'Content type',
  'Fiche produit': 'Product sheet',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Premium writing',
  'Rédaction avec révision': 'Smart + review writing',
  'Rédaction intelligente': 'Smart writing',
  PREMIUM: 'PREMIUM',
  Type: 'Type',
  Editer: 'Edit',
  'Tous les types': 'All types',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Processed invoices',
  'Factures à valider': 'Invoices to validate',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Unable to generate the invoice! Please try again',
  'Récapitulatif de facturation': 'Billing summary',
  'Demander une modification': 'Request a modification',
  'Valider et envoyer la facture': 'Validate and send the invoice',
  'Demande de modification': 'Modification request',
  'La facture a été validé avec succès!':
    'The invoice has been successfully validated!',
  "Oups un erreur c'est produit!": 'Oops, an error occurred!',
  'Nombre de texte': 'Number of texts',
  'Nombre de mot par texte :': 'Number of words per text:',
  Paiement: 'Payment',
  Virement: 'Bank transfer',
  Délai: 'Deadline',
  'Référence de votre facture': 'Your invoice reference',
  'Coût au mot': 'Cost per word',
  'Montant global de la commande': 'Total amount of the order',
  'Votre facture': 'Your invoice',
  'Importer votre facture': 'Import your invoice',
  'Pas de fichier disponible': 'No file available',
  Télécharger: 'Download',
  Relancer: 'Resend',
  'Générer la facture': 'Generate the invoice',

  Payée: 'Paid',
  'En cours de paiement': 'Payment in progress',
  'En cours de validation': 'Validation in progress',
  'A envoyer': 'To be sent',
  'Modification demandée': 'Modification requested',
  'Référence de facture est obligatoire': 'Invoice reference is required',
  'Le coût au mot doit être un nombre': 'The cost per word must be a number',
  'Le coût au mot doit être un nombre positif':
    'The cost per word must be a positive number',
  'Le coût au mot est obligatoire': 'The cost per word is required',
  'Le montant global de la commande doit être un nombre':
    'The total amount of the order must be a number',
  'Le montant global de la commande doit être un nombre positif':
    'The total amount of the order must be a positive number',
  'Le montant global de la commande est obligatoire':
    'The total amount of the order is required',
  'Bill reference existe déja , veuillez réessayer':
    'Bill reference already exists, please try again',
  'La facture a été envoyée avec succès!':
    'The invoice has been successfully sent!',

  'Montant estimé': 'Estimated amount',
  Action: 'Action',
  Facture: 'Invoice',
  Statut: 'Status',
  Factures: 'Invoices',
  'Valider la facture': 'Validate the invoice',
  'La relance a été envoyée avec succès !':
    'The reminder has been successfully sent!',
  'La demande de modification a été envoyée avec succès !':
    'The modification request has been successfully sent!',
  'Le cout est trop élevé': 'The cost is too high',
  'Le montant global de la commande est trop élevé ':
    'The total amount of the order is too high',
  jours: 'days',
  'Factures à envoyer': 'Invoices to send',
  'Valider le paiement': 'Validate payment',

  'Générer une facture': 'Generate an invoice',
  'Montant rédacteur': "Writer's amount",
  'Montant estimé par Typix': 'Estimated amount by Typix',
  'Voir la modification': 'View modification',

  'Tag de Titre': 'Title Tag',
  'Meta description': 'Meta Description',
  'Nom du produit': 'Product Name',
  Avantages: 'Benefits',
  'Description détaillée': 'Detailed Description',
  'En cours de modification': 'Under modification',
  'Mes Factures': 'My invoices',
  'En attente de validation': 'Awaiting validation',
  'les valeurs négatives ne sont pas autorisées':
    'The negative values are not allowed.',
  'Texte Seulement': 'Text Only',
  'Traduction Seulement': 'Translation Only',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'The reminder can be sent 20 days after invoice submission',
  Instructions: 'Instructions',
  'Votre avis pour la commande': 'Your feedback for the order',
  'Votre évaluation est précieuse pour nous ! Merci !':
    'Your evaluation is valuable to us! Thank you!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    'An error occurred while submitting your evaluation. Please try again later!',
  Evaluer: 'Evaluate',
  Commentaire: 'Comment',
  'Votre commantaire': 'Your comment',
  Note: 'Rating',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Your feedback is valuable to us and helps us improve our services.',
  'Verifier la facture': 'Verify the invoice',

  ' Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Your feedback is valuable to us and helps us improve our services. Thank you for sharing your evaluation with us!',
  'Notes rédacteur': "Writer's notes",
  'Avis client': 'Customer review',
  'Pas de commantaire': 'No comments',
  "Qu'avez vous pensé de votre commande?": 'What did you think of your order?',
  'Evaluation de la commande': 'Order evaluation',
  'Rédiger un commentaire': 'Write a comment',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Contribute to enabling us to offer you the highest quality writing services by sharing your feedback.',
  'Que pouvons-nous améliorer?': 'What can we improve?',
  Continuer: 'Continue',
  'Le dernier texte a été validé avec succès':
    'The last text has been successfully validated',
  'Rechercher par rédacteur, commandes, et montant':
    'Search by writer, orders, and amount',
  obligatoire: 'required',
  'Note admin': 'Admin note',
  'Note client': 'Customer note',
  Partie: 'Part',
  'Vous venez de valider tous les contenus rédigés par ':
    'You have just validated all the content written by ',
  "Qu'en avez vous pensé": 'What did you think about it',
  'La facture a été générée avec succès !':
    'The invoice has been generated successfully!',
  'La taille du titre et/ou la méta-description est trop courte':
    'The title and/or meta-description length is too short',
  'Demande de révision globale': 'Request a global review',
  Valider: 'Validate',
  'Obligatoire en cas d’optimisation ou traduction':
    'Required in case of optimization or translation',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Your comment...',
  'Ex : "Contenus de très bonne qualité': 'E.g.: "Very good quality content"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    'Can you please assign this writer for future orders?',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'This button is to inform the client that the original text already contained keyword stuffing',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Edit the text',
  Ajouter: 'Add',
  'Demande examen': 'Request examination',
  'Recherche de mots clés à haute densité': 'Keyword stuffing research',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Oops, it appears that there is keyword stuffing in your text: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Oops, it appears that there is keyword stuffing (too many occurrences of one or more keywords) in the original text.',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Please ensure that keyword density is optimized for SEO (a maximum of one occurrence every 50 words).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'For this, please do not edit the existing text and kindly send an examination request to support.',
  'Votre demande a été bien envoyée !':
    'Your request has been successfully sent!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    'There was a problem sending your request.',
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Please wait for the keyword stuffing analysis.',
  'Inserez le maillage interne': 'Insert the internal linking',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Please ensure that keyword density is optimized for SEO (maximum one occurrence every 50 words).\nIf you come across a term that can have multiple meanings (Example: Former / Formation / Form or Matelas / Quilted), you can request a review from support.',
  'Mots clés à haute densité dans le texte actuel':
    'High-density keywords in the current text',
  'Mots clés à haute densité dans le texte initial':
    'High-density keywords in the initial text',
  'Texte en cours de rédaction !': 'Text in progress !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Are you sure you want to validate the text? First, make sure you have saved the changes.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    'Last-minute information! Your credit balance will soon be depleted :( To anticipate, feel free to request more credits now to complete your order.',
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    'Do you want to confirm the current URL or change it?',
  'Veuillez fournir une URL valide': 'Please provide a valid URL.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    'Please kindly provide the URL of the page where the content has been integrated below:',
  'Contenu en ligne': 'Online content',
  'Télécharger le document de révision': 'Download the revision document',
  'Date d’envoi': 'Date of sending',
  Information: 'Information',
  Enregistrer: 'Save',
  'Textes en ligne': 'Online texts',
  'Livrer les textes': 'Deliver the texts',
  Indisponible: 'Unavailable',
  Disponible: 'Available',
  'Les textes ont été livrés avec succès.':
    'The texts have been delivered successfully.',
  'Le texte a été livré avec succès.':
    'The text has been delivered successfully.',
  ' Les textes ont été validés avec succès.':
    'The texts have been successfully validated.',
  'Le texte a été validé avec succès.':
    'The text has been successfully validated.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Do you really want to validate the order texts?',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Do you really want to deliver the order texts?',
  'Fiche produit longue': 'Long product sheet',
  'Fiche produit courte': 'Short product sheet',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'A text is too long and cannot be exported to Excel.',
  'Mettre un texte en brouillon en livré':
    'Change a text from draft to delivered',
  'Remettre un texte validé à livré': 'Change a validated text to delivered',
  'Invalider le texte': 'Invalidate the text',
  'Je souhaiterai modifier mon mot de passe : comment faire ?':
    'I would like to change my password: how do I do it?',
  'Comment mettre un contenu en bon pour livraison ?':
    'How to set content ready for delivery?',
  'Je ne peux pas mettre mon contenu en « brouillon »':
    'I cannot save my content as "draft"',
  'Typix n’a pas enregistré mes dernières modifications':
    'Typix did not save my latest changes',
  'Comment rédiger une création de contenu ?': 'How to write content creation?',
  'Comment rédiger une optimisation de contenu ?':
    'How to write content optimization?',
  'Je ne vois plus ma commande – que faire ?':
    'I no longer see my order – what to do?',
  'Comment générer une facture ?': 'How to generate an invoice?',
  'Je ne peux pas generer ma facture : que faire ?':
    'I cannot generate my invoice: what to do?',
  'Tuto process Typix global': 'Global Typix process - tutorial',
  'Tuto mise en ligne des contenus': 'How to upload content - tutorial',
  'Modification mot de passe': 'Password change',
  'Comment valider un contenu dans Typix ?':
    'How to validate content in Typix?',
  'Tuto Facturation': 'Billing tutorial',
  'Le test ne me convient pas': 'The test does not suit me',
  'Le statut du contenu a bien été modifié':
    'The status of the content has been successfully modified',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'The semantic gap of this keyword has not yet been validated, please contact Typix support: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'This keyword does not exist on MONETORING, please check with Typix support: support-typix@pixalione.com',
  'Récupérer le Gap': 'Retrieve the Gap',
  'Le test ne me convient pas': 'The test does not suit me',
  'Le statut du contenu a bien été modifié':
    'The status of the content has been successfully modified',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'The semantic gap of this keyword has not yet been validated, please contact Typix support: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'This keyword does not exist on MONETORING, please check with Typix support: support-typix@pixalione.com',
  'Récupérer le Gap': 'Retrieve the Gap',
  'Traduction premium': 'Premium translation',
  'Rédaction et/ou Traduction premium': 'Premium writing and/or translation',
  'Rédaction et/ou Traduction premium': 'Premium writing and/or translation',
  'Rédaction et/ou Traduction avec révision':
    'Writing and/or Translation with revision',
  'Rédaction et/ou Traduction intelligente': 'Smart writing and/or translation',
  'Traduction avec révision': ' Smart + review translation ',
  'Traduction intelligente': 'Smart translation',
  'Le nombre de mots commandé est supérieur à celui vendu':
    'The number of texts ordered is greater than those sold',
  'Rédaction et/ou Traduction premium': 'Premium writing and/or translation',
  'Rédaction et/ou Traduction smart + review':
    'Smart writing and/or translation + review',
  'Rédaction et/ou Traduction smart': 'Smart writing and/or translation',
  'Nom Silo': 'Silo Name',
  'textes en plus': 'additional text(s)',
};
