import { createSlice } from '@reduxjs/toolkit';
import { fetchWriterOrdersList } from './features';

export const adminWriterDisplay = createSlice({
  name: 'adminWriterDisplay',
  initialState: {
    writer: null,
    writerName: '',
    writerId: '',
    isLoading: true,
    isError: true,
    ordersList: [],
    isListLoading: true,
    isListError: true,
  },
  reducers: {
    selectWriter: (state, action) => {
      state.writer = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    setWirterName: (state, action) => {
      state.writerName = action.payload;
    },
    setWriterId: (state, action) => {
      state.writerId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWriterOrdersList.fulfilled, (state, { payload }) => {
        state.ordersList = payload;
        state.isListLoading = false;
        state.isListError = false;
      })
      .addCase(fetchWriterOrdersList.pending, (state) => {
        state.isListLoading = true;
        state.isListError = false;
      })
      .addCase(fetchWriterOrdersList.rejected, (state) => {
        state.isListLoading = false;
        state.isListError = true;
      });
  },
});

export default adminWriterDisplay.reducer;

export const { selectWriter, setWirterName, setWriterId } =
  adminWriterDisplay.actions;

export const adminWriterDisplaySelector = (state) => state.adminWriterDisplay;
