import React from 'react';

export const DropdownMenu = ({ role, options }) => (
  <div className="btn-menu-icon d-flex align-items-center">
    <div
      className={`box-icon-menu
       ${role === 'WRITER' ? '  btn-icon-download-pdf' : '  bt-icon-faq'}`}
      id="dropdownMenu2"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    />
    <ul
      className="dropdown-menu menu-list-nav menu-list-sm"
      aria-labelledby="dropdownMenu2"
    >
      {options.map((option, index) => (
        <li key={index} className={index === 0 ? 'first-child' : 'last-child'}>
          <a
            href={option.href}
            download={option.download}
            target="_blank"
            rel="noreferrer"
          >
            <button
              className="dropdown-item d-flex justify-content-between"
              type="button"
            >
              {option.content}
            </button>
          </a>
        </li>
      ))}
    </ul>
  </div>
);
