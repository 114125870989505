import { useField } from '@unform/core';
import 'pages/shared/Profile.css';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
export const InputField = forwardRef(
  ({ name, type, label, required, value, ...rest }, ref) => {
    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);
    const [showPassword, setShowPassword] = useState(false);
    useEffect(() => {
      if (value === null || value === undefined) return;
      inputRef.current.value = value;
    });
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current.focus();
      },
    }));
    useEffect(() => {
      registerField({
        name: fieldName,
        ref: inputRef,
        getValue: (ref) => {
          return ref.current.value;
        },
        setValue: (ref, newValue) => {
          ref.current.value = newValue;
        },
        clearValue: (ref) => {
          ref.current.value = '';
        },
      });
    }, [fieldName, registerField]);
    return (
      <div>
        {' '}
        <label
          htmlFor={fieldName}
          className={error ? 'label-title-error' : 'label-title'}
        >
          {' '}
          {label} {required && <span>*</span>}{' '}
        </label>{' '}
        {name === 'password' || name === 'confirmPassword' ? (
          <div className="input-group input-password">
            {' '}
            <input
              ref={inputRef}
              defaultValue={defaultValue || ''}
              type={showPassword ? 'text' : 'password'}
              className={
                error
                  ? 'form-control form-control-error form-ct'
                  : 'form-control form-control-valid form-ct'
              }
              {...rest}
            />{' '}
            <span
              className={error ? 'input-group-text error' : 'input-group-text'}
              onClick={() => setShowPassword(!showPassword)}
            >
              {' '}
              {showPassword ? (
                <img src="Images/icon-eye-black.png" alt="icon-eye-black" />
              ) : (
                <img src="Images/icon-eye-gray.png" alt="icon-eye-black" />
              )}
            </span>
          </div>
        ) : (
          <input
            ref={inputRef}
            defaultValue={defaultValue || ''}
            className={
              error
                ? 'form-control form-control-error form-ct'
                : 'form-control form-control-valid form-ct'
            }
            {...rest}
          />
        )}{' '}
        {error && <span className="error-note">{error}</span>}{' '}
        {rest.error && <span className="error-note">{rest.error}</span>}{' '}
      </div>
    );
  }
);
