import { fetchEventSource } from '@microsoft/fetch-event-source';
import axiosPrivate from 'config/axiosPrivate';
import TokenService from 'config/token.service';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CircularBar } from 'components/shared/CircularBar';
import ErrorComponent from 'components/shared/ErrorComponent';
import { Loader } from 'components/shared/Loader';
import { Paginator } from 'components/shared/Paginator';
import { ProgressBar } from 'components/shared/ProgressBar';
import { CLIENT_ORDERS_COLUMN } from 'utils/Constants';

import { handleSorting } from 'store/Client/ClientOrderList';

import { formatDate } from 'utils/DateUtils';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { CustomTooltip } from 'components/shared/CustomTooltip';
import OrderTypeFilter from 'components/shared/Filter/OrderTypeFilter';
import DownloadModal from 'components/shared/modal/DownloadModal';
import { VideoInstructionsModal } from 'components/shared/modal/VideoInstructionsModal';
import { fetchClientNotification } from 'store/Client/ClientNotification/features';
import { clientOrderListSelector } from 'store/Client/ClientOrderList';
import { fetchClientOrderList } from 'store/Client/ClientOrderList/features';
import {
  fetchClientOrderDisplay,
  fetchClientTextOrderDisplay,
} from 'store/Client/OrderDisplay/features';
import { UserSessionSelector, updateShowTutorial } from 'store/UserSession';
import { utils as xlsxUtils, writeFile as xlsxWriteFile } from 'xlsx';

import CheckBox from 'components/shared/CheckBox';
import { exportRoadMap } from 'helpers/ExportRoadmapHelpers';
import { filterOrders } from 'helpers/FilterOrders';
import { useTranslation } from 'react-i18next';
import { setOrderType } from 'store/Client/ClientAnalyser';
import { FILTER_ORDER_TYPE_LIST, ORDER_TYPE } from 'utils/Constants';
import endpoints from 'config/endpoints.json';
import { pageNbOptions } from 'utils/Constants';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

const columns = CLIENT_ORDERS_COLUMN;

const fieldWidths = {
  title: '26%',
  deadline: '20%',
  type: '15%',
};

const serverBaseURL = endpoints.axiosNotificationsAPI;

const token = TokenService.getLocalAccessToken();

const bearer = 'Bearer ' + token;

export default function DashboardPage() {
  const { orders, isLoading, isError } = useSelector(clientOrderListSelector);
  const { user, showTutorial, flag } = useSelector(UserSessionSelector);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedType, setSelectedType] = useState(FILTER_ORDER_TYPE_LIST[0]);

  const [pageNumbers, setPageNumbers] = useState([]);

  const [titleSearch, setTitleSearch] = useHistoryState('titleSearch', '');
  const [anchorEl, setAnchorEl] = useState(null);

  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedOrders, setSelectedOrders] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [showInstructions, setShowInstructions] = useState(true);
  const [isTutorialVisible, setIsTutorialVisible] = useState(false);
  const [showDownloadModal, setShowDownloadModel] = useState(false);

  useEffect(() => {
    document.title = t('Typix - Mon tableau de bord');
    window.dispatchEvent(new Event('locationchange'));
    dispatch(fetchClientOrderList());
    dispatch(fetchClientNotification());
  }, [dispatch, t]);

  // useEffect(() => {
  //   const createNotificationSubscription = async () => {
  //     await fetchEventSource(
  //       `${serverBaseURL}/notification/subscribe?userMail=${user.mail}`,
  //       {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'text/event-stream',
  //           Authorization: bearer,
  //         },
  //         onopen(res) {
  //           if (res.ok && res.status === 200) {
  //             console.log('Connection made ', res);
  //           } else if (
  //             res.status >= 400 &&
  //             res.status < 500 &&
  //             res.status !== 429
  //           ) {
  //             console.log('Client side error ', res);
  //           }
  //         },
  //         onmessage(event) {
  //           console.log(event);
  //         },
  //         onclose() {
  //           console.log('Connection closed by the server');
  //         },
  //         onerror(err) {
  //           console.log('There was an error from server', err);
  //         },
  //       }
  //     );
  //   };
  //   createNotificationSubscription();
  // }, [user]);

  useEffect(() => {
    if (showTutorial && flag) {
      setIsTutorialVisible(true);
    } else {
      setIsTutorialVisible(false);
    }
  }, [flag, showTutorial]);

  useEffect(() => {
    if (!orders) return;

    const filtered = filterOrders(orders, selectedType, titleSearch);
    if (titleSearch && titleSearch.length > 0) {
      setCurrentPage(1);
    }
    setFilteredOrders(filtered);
  }, [selectedType, titleSearch, orders]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const handleSortingChange = (field) => {
    const direction =
      field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
    dispatch(
      handleSorting({
        sortField: field,
        sortOrder: direction,
      })
    );
    setSortField(field);
    setSortOrder(direction);
  };

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onAllSelectedChange(isChecked) {
    setIsAllSelected(isChecked);

    let newSet = new Set();
    if (isChecked) {
      orders.forEach((order) => newSet.add(order.id));
    }
    setSelectedOrders(newSet);
  }

  function onOrderSelectedChange(orderId, isChecked) {
    let newSet = new Set(selectedOrders);

    if (isChecked) {
      newSet.add(orderId);
    } else {
      newSet.delete(orderId);
    }

    setSelectedOrders(newSet);
    if (newSet.size > 1) setIsAllSelected(true);
    if (newSet.size === 0) setIsAllSelected(false);
  }

  function exportAllOrdersRoadMap() {
    if (isAllSelected) {
      exportRoadMap(selectedOrders, null, user.account, t);
    } else {
      let newSet = new Set();

      orders.forEach((order) => newSet.add(order.id));
      let commandsId = [...newSet].join(',');
      const timeout = setTimeout(() => setShowDownloadModel(true), 500);

      axiosPrivate
        .get('/commands/' + commandsId + '/getStatsByCommands')
        .then((res) => {
          const workBook = xlsxUtils.book_new();
          const formattedData = res.data.map((order) => {
            return {
              [t('Mot clé')]: order.keyword,
              [t('Intitulé commande')]: order.titled,
              [t('Titre')]: order.titleTag,
              [t('Meta-description')]: order.metaDescriptionTag,
              [t('URL')]: order.url,
              [t('Nombre de mots gérés')]: order.wordsManagedNumber,
              [t('Min/Max')]: `${order.minWords}/${order.maxWords}`,
              [t('Nombre de mots rédigés')]: order.totalNumberOfTextAdded,
              [t('Status texte')]: order.status,
              [t('Date de livraison')]: order.deliveredAt,
              [t('Score')]: order.analyseResult,
              [t('Date deadline validation client')]: order.deadline,
              [t('Date de validation')]: order.validateAt,
              [t('ONLINE')]: order.deployed === true ? t('OUI') : t('NON'),
              [t('Texte')]: order.text,
            };
          });
          const xlsx = xlsxUtils.json_to_sheet(formattedData);
          xlsxUtils.book_append_sheet(workBook, xlsx, 'Orders');
          xlsxWriteFile(
            workBook,
            `Export roadmap - ${user && user.account}.xlsx`
          );
          clearTimeout(timeout);
          setShowDownloadModel(false);
        });
    }
  }

  const handleOnchange = () => {
    axiosPrivate.put('/user/deactivateTutorial').then(() => {
      setIsTutorialVisible(false);
      dispatch(updateShowTutorial(false));
    });
  };

  return (
    <div className="content-center">
      <div className="box-info-header">
        <div className="b-child-1">
          <h1> {t('Mes Commandes')}</h1>
        </div>
        <div className="b-child-2">
          <div className="d-flex justify-content-end">
            <div className="box-input-text pe-0">
              <div className="input-group input-cs w-100 pe-0">
                <div className="input-group-text">
                  <SearchIcon />
                </div>
                <input
                  type="text"
                  className="form-control ps-0"
                  value={titleSearch}
                  onChange={(event) => setTitleSearch(event.target.value)}
                  placeholder={t('Rechercher')}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className="me-2"> {t('Afficher les résultats')}</span>

              <div className="dropdown" style={{ width: '70px' }}>
                <button
                  className="btn dropdown-toggle dropdown-custom w-100"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {ordersPerPage}
                  <span>
                    <ChevronDownIcon />
                  </span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {pageNbOptions
                    .filter((nb) => nb !== ordersPerPage)
                    .map((item, index) => (
                      <li key={index} onClick={() => setOrdersPerPage(item)}>
                        <button className="dropdown-item">{item}</button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <ErrorComponent />
      ) : (
        <div className="box-wrapper">
          <div className="table-responsive">
            <div>
              <table className="table-lists table-commandes">
                <thead>
                  <tr>
                    {columns.map(({ label, field, sortable }) => (
                      <th
                        style={{
                          cursor: sortable ? 'pointer' : 'default',
                          width: fieldWidths[field] || '',
                        }}
                        onClick={
                          sortable ? () => handleSortingChange(field) : null
                        }
                        key={field}
                      >
                        {t(label)}
                        {!sortable ? null : field === sortField &&
                          sortOrder === 'asc' ? (
                          <span>
                            <ChevronUpIcon style={{ marginLeft: '3px' }} />
                          </span>
                        ) : (
                          <span>
                            <ChevronDownIcon style={{ marginLeft: '3px' }} />
                          </span>
                        )}{' '}
                        {field === 'type' ? (
                          <OrderTypeFilter
                            orderTypeList={FILTER_ORDER_TYPE_LIST}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                            handleClick={handleClick}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                          />
                        ) : null}
                        {field === 'selected' ? (
                          <CheckBox
                            name="select-all"
                            tick={selectedOrders.size === orders.length}
                            onCheck={(e) =>
                              onAllSelectedChange(e.target.checked)
                            }
                          />
                        ) : field === 'downloadAll' ? (
                          isAllSelected ? (
                            <div style={{ position: 'relative' }}>
                              <div className="d-flex justify-content-center">
                                <span
                                  className="bt-download-circle-selected"
                                  onClick={() => exportAllOrdersRoadMap()}
                                ></span>
                              </div>
                              <CustomTooltip
                                title={t(
                                  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune"
                                )}
                                placement="top"
                              >
                                <button
                                  type="button"
                                  className="btn-circle"
                                  style={{
                                    position: 'absolute',
                                    top: '-5px',
                                    right: '-5px',
                                  }}
                                ></button>
                              </CustomTooltip>
                            </div>
                          ) : (
                            <div style={{ position: 'relative' }}>
                              <div className="d-flex justify-content-center">
                                <span
                                  className="bt-download-circle"
                                  onClick={() => exportAllOrdersRoadMap()}
                                ></span>
                              </div>
                              <CustomTooltip
                                title={t(
                                  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune"
                                )}
                                placement="top"
                              >
                                <button
                                  type="button"
                                  className="btn-circle"
                                  style={{
                                    position: 'absolute',
                                    top: '-5px',
                                    right: '-5px',
                                  }}
                                ></button>
                              </CustomTooltip>
                            </div>
                          )
                        ) : null}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order, index) => (
                    <tr key={index}>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          dispatch(setOrderType(order.type));
                          dispatch(fetchClientOrderDisplay(order.id));
                          dispatch(fetchClientTextOrderDisplay(order.id));
                          history.push('/Client/DetailsDeLaCommande');
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '350px' }}>{order?.title}</div>
                          {order.isNew ? (
                            <button
                              className="bt-submit-table"
                              style={{
                                background: '#43B929',
                                color: 'white',
                                height: '18px',
                                marginLeft: '10px',
                                fontSize: '14px',
                              }}
                            >
                              New
                            </button>
                          ) : null}
                        </div>
                      </td>
                      <td>
                        {
                          ORDER_TYPE.find((elem) => elem.type === order.type)
                            .name
                        }
                      </td>
                      <td>
                        <span>
                          {order.deadline ? formatDate(order.deadline) : '-'}
                        </span>
                      </td>

                      <td>
                        <ProgressBar
                          amount={order.deliveredTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.validatedTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.deployedTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        {!order.areAllTextValidated && (
                          <CircularBar
                            finishedAt={order.finishedAt}
                            orderId={order.id}
                            user={user}
                          />
                        )}
                      </td>
                      <td>
                        <CheckBox
                          key={index}
                          name={order.title}
                          tick={selectedOrders.has(order.id)}
                          onCheck={(e) =>
                            onOrderSelectedChange(order.id, e.target.checked)
                          }
                        />
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <span
                            className="bt-download-circle "
                            onClick={() =>
                              exportRoadMap(
                                selectedOrders,
                                order.id,
                                user.account,
                                t
                              )
                            }
                          ></span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {isTutorialVisible && (
        <VideoInstructionsModal
          showInstruction={showInstructions}
          setShowInstruction={() => setShowInstructions(false)}
          showInfoBanner={true}
          user={user}
          handleOnchange={handleOnchange}
        />
      )}
      <Paginator
        isLoading={isLoading}
        listLength={filteredOrders.length}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        nbElementsPerPage={ordersPerPage}
        goToPage={(page) => setCurrentPage(page)}
      />
      <DownloadModal
        open={showDownloadModal}
        handleClose={() => setShowDownloadModel(false)}
      />
    </div>
  );
}
