import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { GenerateBillModal } from 'components/shared/modal/GenerateBill/GenerateBillModal';
import axiosFacturation from 'config/axiosFacturation';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getBills } from 'store/Writer/BillDisplay/actions';
import {
  BILL_MODIFICATION,
  BILL_STATUS_PAID,
  DEMAND_MODIFICATION,
} from 'utils/Constants';

const BillActionCell = ({ commandID, value, bill, index }) => {
  const [daysSup20] = useState(
    bill.numberOfDaysRemaining >= 20 && bill.relaunch !== true
  );
  const [open, setOpen] = useState(false);
  const [visualize, setVisualize] = useState(false);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const dispatch = useDispatch();
  const [mailStatus, setMailStatus] = useState('idle');
  const [isSuccess, setIsSuccess] = useState(true);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    visualize === true && setVisualize(false);
  };
  const sendMail = async () => {
    setMailStatus('loading');
    try {
      await axiosFacturation.get(
        `/writer/relaunch email?command%20id=${commandID}`
      );
      setMailStatus('succeeded');
      setIsSuccess(true);
      setErrorSuccessMsg(t('La relance a été envoyée avec succès !'));
    } catch (error) {
      setMailStatus('failed');
      setIsSuccess(false);

      setErrorSuccessMsg(t("Une erreur s'est produite, veuillez réessayer"));
    }
    setErrorSuccess(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleRelaunch = (id) => {
    if (daysSup20) {
      sendMail(id);
    }
  };

  const handleDownload = () => {
    const fileBytes = bill.file.content;
    const fileName = 'Facture.pdf';
    const base64String = window.atob(fileBytes);
    const decodedBytes = new Uint8Array(base64String.length);
    for (let i = 0; i < base64String.length; i++) {
      decodedBytes[i] = base64String.charCodeAt(i);
    }

    const blob = new Blob([decodedBytes], { type: 'application/pdf' });
    saveAs(blob, fileName);
  };

  const handleCloseErrorSuccess = () => {
    setErrorSuccess(false);
    dispatch(getBills());
  };
  const handleVisualize = () => {
    setVisualize(true);
    handleOpen();
  };

  return (
    <>
      {' '}
      <td>
        {value === 0 && bill.status === BILL_STATUS_PAID && (
          <>
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={handleDownload}
            >
              {t('Télécharger')}
            </span>
          </>
        )}
        {value === 0 &&
          bill.status !== BILL_STATUS_PAID &&
          mailStatus !== 'loading' && (
            <>
              <span
                style={{
                  color: daysSup20 ? 'blue' : 'grey',
                  cursor: daysSup20 ? 'pointer' : 'text',
                }}
                onClick={() => handleRelaunch(commandID, index)}
              >
                {t('Relancer')}
              </span>
            </>
          )}
        {value === 0 &&
          bill.status !== BILL_STATUS_PAID &&
          mailStatus === 'loading' && <div className={'bt-reload-dynamic'} />}
        {value !== 0 && bill.status !== BILL_MODIFICATION && (
          <div>
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => handleOpen(index)}
            >
              {t('Générer la facture')}
            </span>
          </div>
        )}

        {value !== 0 && bill.status === BILL_MODIFICATION && (
          <div>
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => handleOpen()}
            >
              {t('Modifier')}
            </span>
          </div>
        )}
      </td>
      {value === 0 && (
        <td>
          <div
            onClick={handleVisualize}
            className={'bt-visualize d-flex justify-content-center'}
          />
        </td>
      )}
      {value !== 0 && bill.status !== BILL_MODIFICATION && open && (
        <GenerateBillModal
          isOpen={open}
          commandID={commandID}
          billStatus={'Generate'}
          handleClose={handleClose}
        />
      )}
      {((value !== 0 && bill.status === BILL_MODIFICATION) || value === 0) &&
        open && (
          <GenerateBillModal
            isOpen={open}
            commandID={commandID}
            file={bill.file}
            message={bill.message}
            billRef={bill.billReference}
            billStatus={DEMAND_MODIFICATION}
            handleClose={handleClose}
            visualize={visualize}
          />
        )}
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={handleCloseErrorSuccess}
        success={isSuccess}
        toggle={handleCloseErrorSuccess}
      />
    </>
  );
};

export default BillActionCell;
