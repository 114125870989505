import * as Yup from 'yup';

import { PHONENUMBER_REGEX } from 'utils/Regex';

const REQUIRED_ERROR_MESSAGE = 'Ce champ est obligatoire';

Yup.addMethod(Yup.string, 'passwordVlidity', function (errorMessage) {
  return this.test(`password-length`, errorMessage, function (value) {
    const { path, createError } = this;
    if (
      value.length > 0 &&
      (value.length < 8 ||
        !value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/))
    ) {
      return createError({ path, message: errorMessage });
    }
    return true;
  });
});

export const profileSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  mail: Yup.string().email('email invalide').required(REQUIRED_ERROR_MESSAGE),
  phoneNumber: Yup.string()
    .required(REQUIRED_ERROR_MESSAGE)
    .matches(PHONENUMBER_REGEX, 'Numéro invalide'),
  password: Yup.string()
    .passwordVlidity(
      'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial'
    )
    .test('psw', 'Ne doit pas contenir votre nom', function (psw) {
      const name = this.options.parent.name;
      // Check password contain user name
      if (psw.length > 0 && psw.toLowerCase().includes(name.toLowerCase())) {
        return false;
      }
      return true;
    }),
  confirmPassword: Yup.string()
    // .when('password', {
    //   is: (password) => password !== '',
    //   then: Yup.string().required('Field is required'),
    // })
    .oneOf(
      [Yup.ref('password'), null],
      'Le mot de passe de confirmation ne correspond pas'
    ),
});

export const applicationSchema = Yup.object().shape({
  textAmount: Yup.number().test(
    'textAmount',
    'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textes  de la commande',
    function (textAmount) {
      if (this.options.context.total < textAmount || textAmount === 0) {
        return false;
      }
      return true;
    }
  ),
  price: Yup.number().test(
    'price',
    'Ce champ est obligatoire doit être supérieur à 0',
    function (price) {
      if (price <= 0) {
        return false;
      }
      return true;
    }
  ),
  Max: Yup.number().test(
    'max',
    'Ce champ est obligatoire doit être supérieur à 0',
    function (max) {
      if (max <= 0) {
        return false;
      }
      return true;
    }
  ),
  Min: Yup.number()
    .test(
      'min',
      'Ce champ est obligatoire doit être supérieur à 0',
      function (min) {
        if (min <= 0) {
          return false;
        }
        return true;
      }
    )
    .test('min', 'Doit être inférieur au nombre maximum', function (min) {
      const max = this.options.parent.Max;
      if (min > max) {
        return false;
      }
      return true;
    }),
  deadline: Yup.date()
    .min(new Date(), "La date doit être ultérieure à aujourd'hui.")
    .typeError('Veuillez fournir une date valide')
    .required('Veuillez préciser la date'),
});

// export const TextFormSchema = Yup.object().shape({
//   textTitle: Yup.string().required(REQUIRED_ERROR_MESSAGE),
//   keyword: Yup.string().required(REQUIRED_ERROR_MESSAGE),
//   titleTag: Yup.string().required(REQUIRED_ERROR_MESSAGE),
//   metaDescriptionTag: Yup.string().required(REQUIRED_ERROR_MESSAGE),
// });

// Base schema
export const smartTextFormSchema = Yup.object().shape({
  textTitle: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  keyword: Yup.string().required(REQUIRED_ERROR_MESSAGE),
});

export const baseTextFormSchema = Yup.object().shape({
  textTitle: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  keyword: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  titleTag: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  metaDescriptionTag: Yup.string().required(REQUIRED_ERROR_MESSAGE),
});

export const UrlSchema = Yup.lazy((obj) =>
  Yup.object(
    Object.keys(obj).reduce((acc, key) => {
      acc[key] = Yup.string()
        .url('Veuillez fournir une url valide')
        .required(REQUIRED_ERROR_MESSAGE);
      return acc;
    }, {})
  )
);
