import StarIcon from '@mui/icons-material/Star';
import { Box, Rating, TableCell, TableRow, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axiosEvaluation from 'config/axiosEvaluation';
import { useCallback, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    '& .MuiFilledInput-root': {
      backgroundColor: '#f0f0f0', // Set background color
      '&:hover, &:active': {
        backgroundColor: '#f0f0f0', // Prevent hover and active effects
      },
    },
    '& .Mui-focused, & .Mui-hover': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23) !important', // Grey color value
        borderWidth: '1px !important', // Maintain the width
      },
    },
    '& .MuiFilledInput-input': {
      color: 'black', // Set text color to black
    }, // Disable pointer events
    '& .MuiOutlinedInput-input': {
      color: 'black',
      cursor: 'default',
    },
  },
}));

function Evaluation({ writersRating, orderID, index, t }) {
  const classes = useStyles();

  const [deadline, setDeadline] = useState(
    writersRating?.evaluationWriterCommand?.deadline
  );
  const [instructions, setInstructions] = useState(
    writersRating?.evaluationWriterCommand?.instructions
  );
  const [writing, setWriting] = useState(
    writersRating?.evaluationWriterCommand?.writing
  );

  const [deadlineClient] = useState(
    writersRating?.evaluationWriterCommandClient?.deadline
  );
  const [instructionsClient] = useState(
    writersRating?.evaluationWriterCommandClient?.instructions
  );
  const [writingClient] = useState(
    writersRating?.evaluationWriterCommandClient?.writing
  );
  const [commentClient] = useState(writersRating?.comment);

  const handleDeadlineChange = useCallback(
    (event, value) => {
      setDeadline(value);
      performOperation(value, instructions, writing);
    },
    [instructions, writing]
  );

  const handleInstructionsChange = useCallback(
    (event, value) => {
      setInstructions(value);
      performOperation(deadline, value, writing);
    },
    [deadline, writing]
  );

  const handleWritingChange = useCallback(
    (event, value) => {
      setWriting(value);
      performOperation(deadline, instructions, value);
    },
    [deadline, instructions]
  );
  const performOperation = async (newDeadline, newInstructions, newWriting) => {
    if (newDeadline > 0 && newInstructions > 0 && newWriting > 0) {
      try {
        const formData = new FormData();
        formData.append('deadline', newDeadline);
        formData.append('instructions', newInstructions);
        formData.append('writing', newWriting);
        await axiosEvaluation.put(
          `/admin/evaluations?writer=${writersRating.writerId}&command=${orderID}&admin=a`,
          formData
        );
      } catch (error) {}
    }
  };

  return (
    <div className="row row-mb" key={index}>
      <TableRow key={index}>
        <TableCell style={{ width: '28%', border: 'none' }}>
          {' '}
          <div className="label-title m-2">{writersRating.writerName}</div>
        </TableCell>
      </TableRow>

      <TableRow key={index}>
        <div className="col-12 col-md-12">
          {/* <div className="box-star-list"> */}
          <TableCell style={{ width: '5%', border: 'none' }}></TableCell>
          <TableCell
            colSpan={3}
            className="MuiTableCell-alignRight"
            style={{ width: '18%', border: 'none', textAlign: 'right' }}
          >
            {' '}
            <div className="label-title m-2">{t('Note admin')} :</div>
          </TableCell>
          <TableCell style={{ width: '28%', border: 'none' }}>
            <span>{t('Deadline')}: </span>
            <Box sx={{ marginLeft: 1, marginRight: 1, fontSize: 16 }}>
              <Rating
                name={`writer-rating-${index}`}
                value={deadline}
                onChange={handleDeadlineChange}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.3 }} fontSize="inherit" />
                }
                icon={<StarIcon style={{ fontSize: 'inherit' }} />}
              />
            </Box>
          </TableCell>
          <TableCell style={{ width: '28%', border: 'none' }}>
            <span>{t('Instructions')}: </span>
            <Box sx={{ marginLeft: 1, marginRight: 1, fontSize: 16 }}>
              <Rating
                name={`writer-rating-${index}`}
                value={instructions}
                onChange={handleInstructionsChange}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.3 }} fontSize="inherit" />
                }
                icon={<StarIcon style={{ fontSize: 'inherit' }} />}
              />
            </Box>
          </TableCell>{' '}
          <TableCell style={{ width: '28%', border: 'none' }}>
            <span>{t('Rédaction')}: </span>
            <Box sx={{ marginLeft: 1, marginRight: 1, fontSize: 16 }}>
              <Rating
                name={`writer-rating-${index}`}
                value={writing}
                onChange={handleWritingChange}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.3 }} fontSize="inherit" />
                }
                icon={<StarIcon style={{ fontSize: 'inherit' }} />}
              />
            </Box>
          </TableCell>
        </div>
        {/* </div> */}
      </TableRow>

      <TableRow key={index}>
        <div className="col-12 col-md-12">
          {/* <div className="box-star-list"> */}
          <TableCell style={{ width: '5%', border: 'none' }}></TableCell>
          <TableCell
            colSpan={3}
            className="MuiTableCell-alignRight"
            style={{ width: '18%', border: 'none', textAlign: 'right' }}
          >
            <div className="label-title m-2">{t('Note client')} : </div>
          </TableCell>
          <TableCell style={{ width: '28%', border: 'none' }}>
            <span>{t('Deadline')}: </span>
            <Box sx={{ marginLeft: 1, marginRight: 1, fontSize: 16 }}>
              <Rating
                name={`writer-rating-${index}`}
                value={deadlineClient}
                readOnly
                emptyIcon={
                  <StarIcon style={{ opacity: 0.3 }} fontSize="inherit" />
                }
                icon={<StarIcon style={{ fontSize: 'inherit' }} />}
              />
            </Box>
          </TableCell>
          <TableCell style={{ width: '28%', border: 'none' }}>
            <span>{t('Instructions')}: </span>
            <Box sx={{ marginLeft: 1, marginRight: 1, fontSize: 16 }}>
              <Rating
                name={`writer-rating-${index}`}
                value={instructionsClient}
                readOnly
                emptyIcon={
                  <StarIcon style={{ opacity: 0.3 }} fontSize="inherit" />
                }
                icon={<StarIcon style={{ fontSize: 'inherit' }} />}
              />
            </Box>
          </TableCell>{' '}
          <TableCell style={{ width: '28%', border: 'none' }}>
            <span>{t('Rédaction')}: </span>
            <Box sx={{ marginLeft: 1, marginRight: 1, fontSize: 16 }}>
              <Rating
                name={`writer-rating-${index}`}
                value={writingClient}
                readOnly
                emptyIcon={
                  <StarIcon style={{ opacity: 0.3 }} fontSize="inherit" />
                }
                icon={<StarIcon style={{ fontSize: 'inherit' }} />}
              />
            </Box>
          </TableCell>
        </div>
        {/* </div> */}
      </TableRow>

      {commentClient !== null && commentClient.length > 0 && (
        <>
          <TableRow style={{ border: 'none' }}>
            <TableCell style={{ width: '28%', border: 'none' }}>
              <div className="label-title m-2 mb-3">
                {t('Commentaire client')} :{' '}
              </div>

              <TextField
                id="filled-multiline-flexible"
                placeholder={t('Pas de commantaire')}
                multiline
                readOnly
                value={commentClient}
                maxRows={6}
                minRows={4}
                className={classes.textField}
              />
            </TableCell>
          </TableRow>
        </>
      )}
    </div>
  );
}

export default Evaluation;
