import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { consultantClientListSelector } from 'store/Consultant/ClientList';
import { fetchClientList } from 'store/Consultant/ClientList/features';
import { setAccount } from 'store/Consultant/WebsiteDisplay';
import { fetchWebsiteDisplay } from 'store/Consultant/WebsiteDisplay/features';
import { ShortPaginator } from 'components/shared/ShortPaginator';
import { Loader } from 'components/shared/Loader';
import { lower } from 'utils/StringUtils';
import { useHistoryState } from 'utils/hooks/useHistoryState';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

const pageNbOptions = [10, 15, 20, 50, 100];

function ConsultantClientList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { clients, isLoadingClients } = useSelector(
    consultantClientListSelector
  );

  const [urlSearch, setUrlSearch] = useHistoryState('urlSearch', '');
  const [currentPage, setCurrentPage] = useState(1);
  const [clientsCurrentPage, setClientsCurrentPage] = useState(1);

  const [currentClients, setCurrentClients] = useState([]);
  const [clientsPerPage, setClientsPerPage] = useState(10);

  const [filteredClients, setFilteredClients] = useState([]);

  const [clientspageNumbers, setClientsPageNumbers] = useState([]);

  useEffect(() => {
    dispatch(fetchClientList());
  }, [dispatch, t]);

  useEffect(() => {
    if (!clients) return;
    if (urlSearch && urlSearch.length > 0) {
      setClientsCurrentPage(1);
      setFilteredClients(
        clients.filter((x) => {
          return lower(x).includes(lower(urlSearch));
        })
      );
    } else {
      setFilteredClients([...clients]);
    }
  }, [urlSearch, clients]);

  useEffect(() => {
    const indexOfLastOrder = clientsCurrentPage * clientsPerPage;
    const indexOfFirstOrder = indexOfLastOrder - clientsPerPage;
    setCurrentClients(
      filteredClients.slice(indexOfFirstOrder, indexOfLastOrder)
    );
    setClientsPageNumbers(
      [...Array(Math.ceil(filteredClients.length / clientsPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [clientsCurrentPage, filteredClients, clientsPerPage]);

  useEffect(() => {
    if (
      clientspageNumbers.length > 0 &&
      clientspageNumbers[clientspageNumbers.length - 1] < currentPage
    )
      setCurrentPage(clientspageNumbers[clientspageNumbers.length - 1]);
  }, [clientspageNumbers, currentPage]);

  return (
    <div className="col-12 col-md-3">
      <div className="box-wrapper">
        <div className="box-list-header-c">
          <div className="box-title"> {t('Liste des clients')}</div>
          <div>
            <div className="input-group input-cs w-100">
              <div className="input-group-text">
                <SearchIcon />
              </div>
              <input
                type="text"
                value={urlSearch}
                onChange={(event) => setUrlSearch(event.target.value)}
                className="form-control ps-0"
                placeholder={t('Rechercher')}
              />
            </div>
          </div>
        </div>

        {isLoadingClients ? (
          <Loader />
        ) : (
          <div className="table-responsive">
            <div className="box-table">
              <table className="table-lists">
                <tbody>
                  {currentClients.map((item, index) => (
                    <tr key={index} style={{ cursor: 'pointer' }}>
                      <td
                        onClick={() => {
                          dispatch(fetchWebsiteDisplay(item));
                          dispatch(setAccount(item));
                          history.push('/Consultant/FicheClient');
                        }}
                      >
                        <span>{item}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <span className="me-2">{t('Afficher')}</span>

          <div className="dropdown" style={{ width: '80px' }}>
            <button
              className="btn dropdown-toggle dropdown-custom w-100"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {clientsPerPage}
              <span>
                <ChevronDownIcon />
              </span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {pageNbOptions
                .filter((nb) => nb !== clientsPerPage)
                .map((item, index) => (
                  <li key={index}>
                    <button
                      className="dropdown-item"
                      onClick={() => setClientsPerPage(item)}
                    >
                      {item}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div>
          <ShortPaginator
            isLoading={isLoadingClients}
            listLength={filteredClients.length}
            currentPage={clientsCurrentPage}
            pageNumbers={clientspageNumbers}
            nbElementsPerPage={clientsPerPage}
            goToPage={(page) => setClientsCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}

export default ConsultantClientList;
