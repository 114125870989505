import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { consultantWebsiteDetailsSelector } from 'store/Consultant/WebsiteDisplay';
import { fetchWebsiteOrdersList } from 'store/Consultant/WebsiteDisplay/features';
import { setAccount } from 'store/Consultant/WebsiteDisplay';

import axiosPrivate from 'config/axiosPrivate';

import { Loader } from 'components/shared/Loader';

import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import { CustomAutocompleteFreeSolo } from 'components/shared/DropDown/CustomAutocomplete';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import ErrorComponent from 'components/shared/ErrorComponent';

function WebsiteDisplay() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let { formData, account, isLoading, isError } = useSelector(
    consultantWebsiteDetailsSelector
  );
  // const [pixOpData, setPixOpData] = useState([])
  const [disabledForm, setDisabledForm] = useState(true);

  const [urlSite, setUrlSite] = useState(null);

  const [clientsList, setClientsList] = useState([]);

  const [clientMail, setClientMail] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientPhoneNumber, setClientPhoneNumber] = useState('');
  const [clientMailList, setClientMailList] = useState([]);

  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [websiteThematics, setWebsiteThematics] = useState([]);

  const [coconsTextNbr, setCoconsTextNbr] = useState(0);
  const [coconsNbr, setCoconsNbr] = useState(0);
  const [faqNbr, setFaqNbr] = useState(0);
  const [gmbNbr, setGmbNbr] = useState(0);
  const [serviceDetails, setServiceDetails] = useState('');
  const [textOptimizerNbr, setTextOptimizerNbr] = useState(0);

  const [index, setIndex] = useState(0);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDeleted] = useState(false);

  const API = {
    pixopClients: '/user/pixOpsClient',
    getAllClients: '/user/clients',
    getAvailableThematics: '/thematic/all',
    updateWebsite: '/clientWebsite',
  };

  useEffect(() => {
    document.title = 'Typix - Fiche site web';
    window.dispatchEvent(new Event('locationchange'));

    // axiosPrivate.get(API.pixopClients).then((res) => {
    //   setPixOpData(res.data)
    // })

    axiosPrivate
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));
  }, [API.getAvailableThematics, API.pixopClients]);

  const checkValidForm = useCallback(() => {
    if (
      !urlSite ||
      websiteThematics.length === 0 ||
      !serviceDetails ||
      !clientMail ||
      !clientName ||
      !clientPhoneNumber
    ) {
      setDisabledForm(true);
      return;
    }

    setDisabledForm(false);
    return;
  }, [
    clientMail,
    clientName,
    clientPhoneNumber,
    serviceDetails,
    urlSite,
    websiteThematics,
  ]);

  useEffect(
    () => checkValidForm(),
    [
      checkValidForm,
      urlSite,
      serviceDetails,
      clientMail,
      clientName,
      clientPhoneNumber,
      websiteThematics,
      serviceDetails,
    ]
  );

  useEffect(() => {
    if (!isLoading) {
      setUrlSite(formData.websites[0].urlSite);
      setTextOptimizerNbr(formData.websites[0].textOptimizerNbr);
      setCoconsTextNbr(formData.websites[0].coconsTextNbr);
      setCoconsNbr(formData.websites[0].coconsNbr);
      setFaqNbr(formData.websites[0].faqNbr);
      setGmbNbr(formData.websites[0].gmbNbr);
      setServiceDetails(formData.websites[0].serviceDetails);
      setWebsiteThematics(formData.websites[0].thematics);
      setClientsList(formData.clients);
      setClientMailList(formData.clients?.map((elem) => elem.mail));

      setClientMail(formData.clients[0].mail);
      setClientName(formData.clients[0].name);
      setClientPhoneNumber(formData.clients[0].phoneNumber);
    }
  }, [isLoading, formData]);

  const callbackFromMultiSelect = (newList) => {
    setWebsiteThematics(newList);
  };
  function updateClientsList(clientsList) {
    const newArr = [...clientsList];
    const client = JSON.parse(JSON.stringify(newArr[index]));
    client['mail'] = clientMail;
    client['name'] = clientName;
    client['phoneNumber'] = clientPhoneNumber;
    newArr[index] = client;
    clientsList = newArr;

    return clientsList;
  }

  const updateWebsite = () => {
    let body = {
      websiteDTO: {
        id: formData.websites[0].id,
        coconsNbr,
        coconsTextNbr,
        faqNbr,
        gmbNbr,
        serviceDetails,
        textOptimizerNbr,
        urlSite,
        thematics: websiteThematics,
      },
      clients: updateClientsList(clientsList),
    };

    axiosPrivate
      .put(API.updateWebsite, body)
      .then(() => {
        setErrorSuccessMsg(
          `${t('La fiche du website')} ${account} ${t(
            'a été mis à jour avec succès'
          )} !`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        switch (e.response.status) {
          case 403:
            setErrorSuccessMsg(
              t('Contrat déjà existant, veuillez modifier les informations')
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };
  if (isLoading) return <Loader />;

  if (isError) return <ErrorComponent />;

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{account}</h1>
      </div>
      <div className="row">
        <div className="col-12 col-md-9 box-screen">
          <div className="box-img-bg bg-8" style={{ right: -100 }} />
          <div className="box-img-bg bg-7" style={{ right: -300 }} />
          <div className="box-img-bg bg-9" />
          <div className="header-title">{t('Fiche du site web')} </div>

          <div className="box-wrapper">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="row row-mb">
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Compte client')}</div>
                    <div>
                      <input
                        type="text"
                        className="form-control form-ct"
                        style={{
                          backgroundColor: 'rgb(224,224,224)',
                          color: 'grey',
                        }}
                        disabled
                        value={account}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="label-title">{t('Url du site')}</div>
                    <div>
                      <input
                        type="text"
                        className="form-control form-ct"
                        value={urlSite}
                        onChange={(e) => setUrlSite(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb" style={{ zIndex: 200 }}>
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Mail client')}</div>
                    <CustomAutocompleteFreeSolo
                      list={clientMailList}
                      value={
                        clientMail ||
                        (clientMailList.length > 0 ? clientMailList[0] : '')
                      }
                      onInputChange={(event, value) => {
                        setClientMail(value);
                      }}
                      getOptionLabel={(option) => {
                        return option;
                      }}
                      setValue={(value) => {
                        const mail = clientMailList.find((x) => x === value);
                        if (mail) setClientMail(mail);
                        const index = clientMailList.findIndex(
                          (x) => x === value
                        );
                        setIndex(index);
                        setClientName(formData.clients[index].name);
                        setClientPhoneNumber(
                          formData.clients[index].phoneNumber
                        );
                      }}
                    />
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="label-title">
                      {t('Prénom/Nom du client')}
                    </div>

                    <input
                      type="text"
                      className="form-control form-ct"
                      value={clientName}
                      onChange={(e) => setClientName(e.target.value)}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="label-title">{t('Téléphone')}</div>
                    <input
                      type="text"
                      className="form-control form-ct"
                      value={clientPhoneNumber}
                      onChange={(e) => setClientPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row row-mb">
                  <div className="col-12 col-md-2" style={{ width: '18%' }}>
                    <div className="label-title">
                      {t('Nb de textes par Silo')}
                    </div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={coconsTextNbr}
                        onChange={(e) => setCoconsTextNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                  </div>
                  <div className="col-12 col-md-2" style={{ width: '15.3%' }}>
                    <div className="label-title">{t('Nombre de FAQ')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={faqNbr}
                        onChange={(e) => setFaqNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('Nombre de GMB')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={gmbNbr}
                        onChange={(e) => setGmbNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('Nombre de Silo')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={coconsNbr}
                        onChange={(e) => setCoconsNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="label-title">
                      {t('Nb de textes à optimiser')}
                    </div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={textOptimizerNbr}
                        onChange={(e) => setTextOptimizerNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="label-title">
                      {t('Thématiques d’expertise')}
                    </div>
                    <CustomChipsAutocomplete
                      callbackFromMultiSelect={callbackFromMultiSelect}
                      value={websiteThematics}
                      disabled={!availableThematicsList}
                      list={availableThematicsList}
                      setValue={(option) => setWebsiteThematics(option)}
                      getOptionLabel={(option) => {
                        return t(option.thematic);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="label-title">
                      {t('Détail de la prestation du contenu')}
                    </div>
                    <div>
                      <input
                        type="text"
                        className="form-control form-ct"
                        value={serviceDetails}
                        onChange={(e) => setServiceDetails(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-button-bt">
            <span>
              <button
                onClick={() => {
                  dispatch(setAccount(account));
                  dispatch(fetchWebsiteOrdersList(account));
                  history.push('/Consultant/CommandesduClient');
                }}
                className="bt-submit-y me-2"
              >
                {t('Voir les commandes')}
              </button>{' '}
            </span>
            <div>
              {disabledForm ? (
                <button className="bt-submit-disabled-y">
                  {' '}
                  {t('Sauvegarder')}{' '}
                </button>
              ) : (
                <button className="bt-submit-y" onClick={updateWebsite}>
                  {' '}
                  {t('Sauvegarder')}{' '}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() =>
          isDeleted ? history.goBack() : setErrorSuccess(false)
        }
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
}

export default WebsiteDisplay;
