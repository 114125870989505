import React from 'react';
import './styles.css';

import { useTranslation } from 'react-i18next';
import SearchIcon from 'icons/SearchIcon';

function SearchBar({ handleChangeText, urlSearch, ...others }) {
  const { t } = useTranslation();

  return (
    <div
      className={
        others?.placeholder
          ? 'box-input-text-with-filter-bill'
          : 'box-input-text'
      }
    >
      <div className="input-group input-cs w-100">
        <div className="input-group-text">
          <SearchIcon />
        </div>
        <input
          type="text"
          value={urlSearch}
          onChange={handleChangeText}
          className="form-control ps-0"
          placeholder={
            others?.placeholder ? others.placeholder : t('Rechercher')
          }
        />
      </div>
    </div>
  );
}

export default SearchBar;
