import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal } from 'reactstrap';
import { classes } from './modalClasses';
import { CustomTooltip } from '../CustomTooltip';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';

import axiosPrivate from 'config/axiosPrivate';
import { updateTextForm } from 'store/Client/ClientAnalyser';
import { animationUrl } from 'config/endpoints';

function RevisionRequestModal({
  textId,
  showRevisionModal,
  handleClose,
  requestText,
  selectedFile,
  setRequestText,
  setSelectedFile,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [wordCount, setWordCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);
  const [isSuccess, setIsSuccess] = useState(false);
  const inputFile = useRef(null);
  const textAreaRef = useRef();

  const showOpenFileDialog = () => {
    inputFile.current.click();
  };

  const uploadFile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  function handleSubmit() {
    const revisionFile = new FormData();
    revisionFile.append('file', selectedFile);

    if (requestText.length <= 0 && revisionFile) {
      setErrorMessage(
        t('Veuillez donner un descriptif au document téléchargé')
      );
    } else {
      axiosPrivate
        .post(`/texts/${textId}/sendRevision`, revisionFile, {
          params: {
            revisionText: requestText,
          },
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(() => {
          dispatch(updateTextForm({ key: 'revisionSent', value: true }));
          handleClose();
          setErrorSuccessMsg(
            t('Votre demande de révision a bien été envoyée.')
          );
          setErrorSuccessImg(animationUrl + '/08_Contenu_en_repasse.gif');
          setIsSuccess(true);
          setErrorSuccess(true);
        })
        .catch((e) => {
          switch (e.response.status) {
            case 400:
              setErrorSuccessMsg(
                t('Veuillez saisir un texte ou importer un fichier.')
              );
              break;

            default:
              setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));

              break;
          }

          handleClose();
          setIsSuccess(false);
          setErrorSuccess(true);
        });
    }
  }

  return (
    <div>
      <Modal style={classes.modalCustom} isOpen={showRevisionModal}>
        <div style={classes.modalHeader} className="modal-header">
          <h5 style={classes.title} className="modal-title">
            {t('Demande de révision')}
          </h5>
          <button
            type="button"
            style={classes.btnClose}
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          />
        </div>
        <div style={classes.modalPad} className="modal-body">
          <div className="row row-mb-lg">
            <h6>
              {t(
                "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses"
              )}
              :{' '}
              <CustomTooltip
                title={
                  <span>
                    {t(
                      'Dans le cadre d’une repasse sur des contenus, seuls les éléments suivants seront pris en charge'
                    )}{' '}
                    :
                    <ul>
                      <li>
                        {t(
                          'Si des phrases ne sont pas dans un français correct'
                        )}
                      </li>
                      <li>{t('le contenu présente des inexactitudes')}</li>
                      <li>
                        {t('Si le contenu ne respecte pas les règles du brief')}
                      </li>
                    </ul>
                    <p>
                      {' '}
                      {t(
                        'Les demandes suivantes ne seront pas prises en compte'
                      )}{' '}
                      :
                    </p>
                    <ul>
                      <li>{t('Coquilles (double espace, « s »…)')}</li>
                      <li>
                        {t(
                          'Expressions ou phrases françaises mais ne vous convenant pas'
                        )}
                      </li>
                      <li>
                        {t(
                          'Toute demande n’ayant pas été précisée lors du brief'
                        )}
                      </li>
                    </ul>
                  </span>
                }
                placement="top"
              >
                <button type="button" className="btn-circle"></button>
              </CustomTooltip>
            </h6>
          </div>

          <div className="row row-mb-lg">
            <textarea
              ref={textAreaRef}
              className=" col-12 box-wrapper box-list-text"
              placeholder={t('Vos demandes')}
              value={requestText || ''}
              onChange={(e) => {
                setRequestText(e.target.value);
                setWordCount(e.target.value.trim().split(' ').length);
              }}
            ></textarea>
            {errorMessage.length > 0 ? (
              <span style={{ color: 'red' }}>{errorMessage}</span>
            ) : (
              ''
            )}
            {wordCount >= 500 ? (
              <span style={{ color: 'red' }}>
                {t('Votre text ne de doit pas dépasser 500 mots')}
              </span>
            ) : null}
            <span>{wordCount}/500</span>
          </div>
          {!selectedFile ? (
            <div className="row row-mb-lg">
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={uploadFile}
              />
              <div className="d-flex flex-row">
                <div className="me-2">{t('Ou')}</div>

                <div className="me-2 mb-2">
                  <button style={{ color: 'red' }} onClick={showOpenFileDialog}>
                    {t('importer un document')}
                  </button>{' '}
                </div>
                <div className="me-2">
                  <svg
                    width="20"
                    height="12"
                    viewBox="0 0 20 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.5024 10.3755C14.1575 10.8069 14.2276 11.4362 14.659 11.781C15.0903 12.1259 15.7196 12.0558 16.0645 11.6244L19.5628 7.2489C20.1466 6.51864 20.1466 5.48132 19.5628 4.75106L16.0645 0.375516C15.7196 -0.0558493 15.0903 -0.125961 14.659 0.218919C14.2276 0.563799 14.1575 1.19307 14.5024 1.62444L17.2012 4.99998L0.999268 4.99998C0.446981 4.99998 -0.000732422 5.44769 -0.000732422 5.99998C-0.000732422 6.55226 0.446981 6.99998 0.999268 6.99998L17.2012 6.99998L14.5024 10.3755Z"
                      fill="#2D5BFF"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            <div className="row row-mb-lg">
              <div className="d-flex flex-row mb-2">
                <div className="me-2">{t('Importer un autre document')}</div>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: 'none' }}
                  onChange={uploadFile}
                />
                <button onClick={showOpenFileDialog}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.905925 7.12371C0.421964 7.12371 0.0296353 7.51604 0.0296353 8C0.0296353 8.48396 0.421964 8.87629 0.905925 8.87629L7.03996 8.87629L7.03995 15.0103C7.03995 15.4943 7.43228 15.8866 7.91624 15.8866C8.40021 15.8866 8.79253 15.4943 8.79253 15.0103L8.79253 8.87629L14.9266 8.87629C15.4105 8.87629 15.8029 8.48396 15.8029 8C15.8029 7.51604 15.4105 7.12371 14.9266 7.12371L8.79253 7.12371L8.79253 0.989682C8.79253 0.505722 8.40021 0.113392 7.91624 0.113393C7.43228 0.113393 7.03996 0.505721 7.03995 0.989682L7.03996 7.12371L0.905925 7.12371Z"
                      fill="#2D5BFF"
                    />
                  </svg>
                </button>
              </div>
              <div className="d-flex flex-row">
                <div className="me-3">
                  <svg
                    width="27"
                    height="36"
                    viewBox="0 0 27 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M26.96 8.48016C26.96 8.16012 26.84 7.84009 26.6001 7.60016L19.8802 0.400117C19.6402 0.119979 19.2803 0 18.9201 0H2.12001C0.960148 0 0 0.960077 0 2.12001V33.8402C0 35.0001 0.960076 35.9602 2.12001 35.9602H24.8801C26.04 35.9602 27.0002 35.0001 27.0002 33.8402L26.96 8.48016ZM25.12 8.88016H20.7198C19.56 8.88016 18.5998 7.92009 18.5998 6.76015V2.04027C18.5998 1.92029 18.7597 1.88039 18.8398 1.96019L25.24 8.64023C25.2799 8.72031 25.24 8.88016 25.12 8.88016ZM3.59602 14.3841H22.7748V15.5828H3.59602V14.3841ZM22.7748 17.9801H3.59602V19.1788H22.7748V17.9801ZM3.59602 25.1721H22.7748V26.3708H3.59602V25.1721ZM14.3841 21.5761H3.59602V22.7748H14.3841V21.5761Z"
                      fill="#3C3C3C"
                    />
                  </svg>
                </div>

                <div>
                  <span>{selectedFile.name} </span>
                  <br />
                  <span>{Math.floor(selectedFile.size / 1024) + 'KB'}</span>
                </div>

                <div className=" ms-auto">
                  <button onClick={() => setSelectedFile(null)}>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.35215 1.02032C1.9794 0.653385 1.38147 0.661111 1.01767 1.03678C0.654988 1.41131 0.661597 2.01063 1.0332 2.37644L4.71418 6L1.0332 9.62356C0.661597 9.98937 0.654988 10.5887 1.01767 10.9632C1.38147 11.3389 1.9794 11.3466 2.35215 10.9797L6.06246 7.32725L9.64788 10.8567C10.0206 11.2237 10.6186 11.2159 10.9824 10.8403C11.345 10.4657 11.3384 9.86642 10.9668 9.50062L7.41074 6L10.9668 2.49938C11.3384 2.13357 11.345 1.53425 10.9824 1.15972C10.6186 0.784052 10.0206 0.776326 9.64788 1.14326L6.06246 4.67275L2.35215 1.02032Z"
                        fill="#EE3900"
                        stroke="#EE3900"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="row row-mb-lg">
            <div style={classes.boxBtConfirmRight}>
              <button
                style={{ backgroundColor: '#ee3900' }}
                className="confirm me-2"
                onClick={handleClose}
              >
                {t('Annuler')}
              </button>
              <button
                style={{ backgroundColor: '#43b929' }}
                className="confirm"
                type="submit"
                onClick={handleSubmit}
              >
                {t('Envoyer')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
        imageUrl={errorSuccessImg}
      />
    </div>
  );
}

export default RevisionRequestModal;
