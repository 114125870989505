export const getTextColor = (text) => {
  if (!text.delivered) return 'text-gray';

  if (text.validated) return 'text-green';

  if ((text.revisionSent || text.globalRevisionSent) && !text.revisionDone)
    return 'text-orange';

  if ((text.revisionSent || text.globalRevisionSent) && text.revisionDone)
    return 'text-purple';

  return '';
};
