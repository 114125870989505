import { setOrderType } from 'store/Writer/Analyser';
import { setStatus } from 'store/Writer/OrderDisplay';
import {
  fetchWriterOrderDisplay,
  fetchWriterTextOrderDisplay,
} from 'store/Writer/OrderDisplay/features';
import { formatDate } from 'utils/DateUtils';
import BillActionCell from '../Cells/BillActionCell';

import {
  BILL_MODIFICATION,
  BILL_MODIFIED,
  BILL_STATUS_NOTGENERATED,
  BILL_STATUS_PAID,
  BILL_STATUS_PAYMENT,
  BILL_STATUS_VALIDATION,
} from 'utils/Constants';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function TableRow({ bill, index, dispatch, value }) {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <tr key={bill.id}>
      <td
        style={{ cursor: 'pointer', minWidth: '10vw' }}
        onClick={async () => {
          dispatch(setStatus('INPROGRESS'));
          dispatch(setOrderType('PREMIUM'));
          dispatch(fetchWriterOrderDisplay(bill.commandID));
          dispatch(fetchWriterTextOrderDisplay(bill.commandID));
          history.push('/Redacteur/DetailsDeLaCommande');
        }}
      >
        <span>{bill?.commandTitle}</span>
      </td>
      <td>
        {bill?.totalPrice !== null && (
          <span>{bill.totalPrice.toFixed(2)}€</span>
        )}{' '}
      </td>
      <td>
        <span>
          {bill?.status === BILL_STATUS_PAID
            ? t('Payée')
            : bill?.status === BILL_STATUS_PAYMENT
            ? t('En cours de paiement')
            : bill?.status === BILL_STATUS_VALIDATION ||
              bill?.status === BILL_MODIFIED
            ? t('En cours de validation')
            : bill?.status === BILL_STATUS_NOTGENERATED
            ? t('A envoyer')
            : bill?.status === BILL_MODIFICATION
            ? t('Modification demandée')
            : ''}{' '}
        </span>
      </td>
      <td>
        <span>{bill.validatedTime ? formatDate(bill.validatedTime) : '-'}</span>
      </td>

      <BillActionCell
        commandID={bill.commandID}
        value={value}
        bill={bill}
        index={index}
      />
    </tr>
  );
}

export default TableRow;
