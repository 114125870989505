import { Form } from '@unform/web';
import { Loader } from 'components/shared/Loader';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { classes } from 'components/shared/modal/modalClasses';
import axiosFacturation from 'config/axiosFacturation';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getBills } from 'store/Admin/BillDisplay/actions';
import DropzoneComponent from './BillFormComponents/DropZoneComponent';
import FormFields from './BillFormComponents/FormFields';
import ModalHeader from './BillFormComponents/ModalHeader';
import ModifcationDemandModal from './ModifcationDemandModal';

function ValidateBillFormModal({
  bill,
  writerCommandID,
  priceWord,
  handleClose,
  allPrice,
  file,
  billRef,
  visualize,
  actualBilling,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fieldValues] = useState({
    billRef: billRef ? billRef : '',
    price: priceWord,
    totalPrice: allPrice,
    file: file ? file : null,
  });
  const [openModifDemand, setOpenModifDemand] = useState(false);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const formRef = useRef(null);
  const [status, setStatus] = useState('idle');
  const [modifStatus, setModifStatus] = useState('idle');
  const handleOpenModif = () => {
    setOpenModifDemand(true);
  };
  const handleCloseModif = () => {
    setOpenModifDemand(false);
  };
  const validateAndSend = async () => {
    setStatus('loading');
    try {
      await axiosFacturation.post(
        `/admin/validate%20bill?writer command id=${writerCommandID}`
      );

      setErrorSuccess(true);

      setStatus('succeeded');
      setErrorSuccessMsg(t('La facture a été validé avec succès!'));
    } catch (error) {
      setStatus('error');

      setErrorSuccess(true);
    }
  };

  const handleFormSubmit = (event) => {
    validateAndSend();
  };

  const handleCloseErrorSuccess = () => {
    setErrorSuccess(false);
    handleClose();
    dispatch(getBills());
  };

  return (
    <div>
      <ModalHeader
        title={t('Récapitulatif de facturation')}
        handleClose={handleClose}
      />

      <div style={classes.modalPad} className="modal-body">
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          {status !== 'loading' ? (
            <>
              <FormFields
                bill={bill}
                fieldValues={fieldValues}
                actualBilling={actualBilling}
              />
              <div style={classes.line1}></div>

              <DropzoneComponent t={t} fieldValues={fieldValues} />
              {visualize !== true && (
                <div style={classes.boxBtConfirmRight}>
                  <button
                    style={classes.CTA_Button_modif}
                    className={'confirm'}
                    onClick={handleOpenModif}
                    type="button"
                  >
                    {t('Demander une modification')}
                  </button>
                  <button
                    style={classes.CTA_Button}
                    className={'confirm'}
                    type="submit"
                  >
                    {t('Valider la facture')}
                  </button>
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}

          <ModifcationDemandModal
            changeErrorSuccessMsg={setErrorSuccessMsg}
            open={openModifDemand}
            modifStatus={modifStatus}
            setModifStatus={setModifStatus}
            setErrorSuccess={setErrorSuccess}
            handleClose={handleCloseModif}
            writerCommandID={writerCommandID}
          />
        </Form>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={handleCloseErrorSuccess}
        success={status === 'error' || modifStatus === 'error' ? false : true}
        toggle={handleCloseErrorSuccess}
      />
    </div>
  );
}

export default ValidateBillFormModal;
