import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';

import { classes } from './modalClasses';

import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

export const ComparisonModal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      style={{ ...classes.largeModalCustom, maxWidth: '1000px' }}
      isOpen={props.open}
    >
      <div style={classes.modalHeader} className="modal-header">
        <h5 style={classes.title} className="modal-title">
          {t('Différence')}{' '}
        </h5>
        <button
          type="button"
          onClick={props.handleClose}
          style={classes.btnClose}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div style={classes.modalPad} className="modal-body">
        <div className="row row-mb-lg">
          <div className="col-12 col-md-12">
            <ReactDiffViewer
              oldValue={props.initialText || ''}
              newValue={props.text || ''}
              splitView={true}
              compareMethod={DiffMethod.WORDS}
              leftTitle="Version A"
              rightTitle="Version B"
            />
            <div style={classes.textConfirm}>{props.message}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ComparisonModal;
