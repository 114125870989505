import React from 'react';
import { useTranslation } from 'react-i18next';

import './shared.css';

import { PAGENUMBERSLARGELIMIT } from 'utils/Constants';

export const Paginator = (props) => {
  const { t } = useTranslation();

  return (
    <div className="row row-page">
      {props.hideTotalPageCount ? null : (
        <div className="col-12 col-md-6">
          {props.isLoading ? (
            <span>XXX {t('résultats sur')} XXX</span>
          ) : (
            <span>
              {Math.min(
                props.currentPage * props.nbElementsPerPage,
                props.listLength
              )}{' '}
              {t('résultats sur')} {props.listLength}
            </span>
          )}
        </div>
      )}

      <div className={props.hideTotalPageCount ? '' : 'col-12 col-md-6'}>
        <div className="box-pagination">
          {props.listLength === 0 || props.currentPage === 1 ? (
            <div style={{ cursor: 'not-allowed' }} className="text-page">
              {t('Précédent')}
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => props.goToPage(props.currentPage - 1)}
            >
              {t('Précédent')}
            </div>
          )}
          <div className="list-page-number">
            {props.pageNumbers
              .slice(
                Math.max(0, props.currentPage - PAGENUMBERSLARGELIMIT),
                Math.min(
                  props.currentPage + PAGENUMBERSLARGELIMIT,
                  props.pageNumbers.length
                )
              )
              .map((pageNum, index) => (
                <span
                  key={index}
                  className={pageNum === props.currentPage ? 'active' : ''}
                  onClick={() => props.goToPage(pageNum)}
                >
                  {pageNum}
                </span>
              ))}

            {props.listLength === 0 ||
            props.currentPage ===
              Math.ceil(props.listLength / props.nbElementsPerPage) ? (
              <span style={{ cursor: 'not-allowed' }} className="text-page">
                {t('Suivant')}
              </span>
            ) : (
              <span onClick={() => props.goToPage(props.currentPage + 1)}>
                {t('Suivant')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
