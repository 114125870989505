export const sortByField = (data, sortField, sortOrder) => {
  if (!sortField) return data.slice();

  return [...data].sort((a, b) => {
    if (!a[sortField]) return 1;
    if (!b[sortField]) return -1;

    if (
      sortField === 'deliveredTextsAmount' ||
      sortField === 'validatedTextsAmount'
    ) {
      const pourcentageA = (a[sortField] * 100) / a['textAmount'];
      const pourcentageB = (b[sortField] * 100) / b['textAmount'];

      return sortOrder === 'asc'
        ? pourcentageA - pourcentageB
        : pourcentageB - pourcentageA;
    }

    return (
      a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
        numeric: true,
      }) * (sortOrder === 'asc' ? 1 : -1)
    );
  });
};

export function handleSortingChange(
  field,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  handleSorting
) {
  const direction = field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
  setSortField(field);
  setSortOrder(direction);
  handleSorting(field, direction);
}
