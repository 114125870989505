import React, { useEffect, useState } from 'react';

import axios from 'axios';

import {
  Container,
  PopoverBody,
  Progress,
  UncontrolledPopover,
} from 'reactstrap';

import { updateCommande } from '../store/Admin/OrderList';
import { useDispatch } from 'react-redux';

// import {  createTwoFilesPatch } from "diff"
// import * as Diff2Html from "diff2html"
import { Editor } from '@tinymce/tinymce-react';
import {
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import {
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryFull,
  Timer,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

export const RED = '#FF595D';
export const ORANGE = '#FFBE64';
export const GREEN = '#3ED774';

export const TINY_API = 'fineit1r7ovsf0qscbsimav31w2jfn4mjo9xmh3iecfun0j4';

export const ISOlanguages = [
  { code: 'fr', name: 'Français' },
  { code: 'en', name: 'Anglais' },
  { code: 'eo', name: 'Espagnol' },
  { code: 'it', name: 'Italien' },
  { code: 'de', name: 'Allemand' },
  { code: 'aa', name: 'Afar' },
  { code: 'ab', name: 'Abkhazien' },
  { code: 'ae', name: 'Avestan' },
  { code: 'af', name: 'Afrikaans' },
  { code: 'ak', name: 'Akan' },
  { code: 'am', name: 'Amharique' },
  { code: 'an', name: 'Aragonais' },
  { code: 'ar', name: 'Arabe' },
  { code: 'as', name: 'Assamais' },
  { code: 'av', name: 'Avaric' },
  { code: 'ay', name: 'Aymara' },
  { code: 'az', name: 'Azerbaïdjanais' },
  { code: 'ba', name: 'Bashkir' },
  { code: 'be', name: 'Biélorusse' },
  { code: 'bg', name: 'Bulgare' },
  { code: 'bh', name: 'Langues bihari' },
  { code: 'bi', name: 'Bislama' },
  { code: 'bm', name: 'Bambara' },
  { code: 'bn', name: 'Bengali' },
  { code: 'bo', name: 'Tibétain' },
  { code: 'br', name: 'Breton' },
  { code: 'bs', name: 'Bosniaque' },
  { code: 'ca', name: 'Catalan ; Valencien' },
  { code: 'ce', name: 'Tchétchène' },
  { code: 'ch', name: 'Chamorro' },
  { code: 'co', name: 'Corse' },
  { code: 'cr', name: 'Cree' },
  { code: 'cs', name: 'Czech' },
  {
    code: 'cu',
    name: 'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique',
  },
  { code: 'cv', name: 'Tchouvache' },
  { code: 'cy', name: 'Welsh' },
  { code: 'da', name: 'Danois' },
  { code: 'dv', name: 'Divehi ; Dhivehi ; Maldivien' },
  { code: 'dz', name: 'Dzongkha' },
  { code: 'ee', name: 'Ewe' },
  { code: 'el', name: 'Grec moderne (1453-)' },
  { code: 'et', name: 'Estonien' },
  { code: 'eu', name: 'Basque' },
  { code: 'fa', name: 'Persan' },
  { code: 'ff', name: 'Fulah' },
  { code: 'fi', name: 'Finnois' },
  { code: 'fj', name: 'Fidjien' },
  { code: 'fo', name: 'Féroïen' },
  { code: 'fy', name: 'Frison occidental' },
  { code: 'ga', name: 'Irlandais' },
  { code: 'gd', name: 'Gaélique' },
  { code: 'gl', name: 'Galicien' },
  { code: 'gn', name: 'Guarani' },
  { code: 'gu', name: 'Gujarati' },
  { code: 'gv', name: 'Manx' },
  { code: 'ha', name: 'Hausa' },
  { code: 'he', name: 'Hébreu' },
  { code: 'hi', name: 'Hindi' },
  { code: 'ho', name: 'Hiri Motu' },
  { code: 'hr', name: 'Croate' },
  { code: 'ht', name: 'Haïtien ; créole haïtien' },
  { code: 'hu', name: 'Hongrois' },
  { code: 'hy', name: 'Arménien' },
  { code: 'hz', name: 'Herero' },
  {
    code: 'ia',
    name: 'Interlingua (Association internationale des langues auxiliaires)',
  },
  { code: 'id', name: 'Indonésien' },
  { code: 'ie', name: 'Interlingue ; Occidental' },
  { code: 'ig', name: 'Igbo' },
  { code: 'ii', name: 'Sichuan Yi ; Nuosu' },
  { code: 'ik', name: 'Inupiaq' },
  { code: 'io', name: 'Ido' },
  { code: 'is', name: 'Islandais' },
  { code: 'iu', name: 'Inuktitut' },
  { code: 'ja', name: 'Japonais' },
  { code: 'jv', name: 'Javanais' },
  { code: 'ka', name: 'Géorgien' },
  { code: 'kg', name: 'Kongo' },
  { code: 'ki', name: 'Kikuyu ; Gikuyu' },
  { code: 'kj', name: 'Kuanyama ; Kwanyama' },
  { code: 'kk', name: 'Kazakh' },
  { code: 'kl', name: 'Kalaallisut ; Groenlandais' },
  { code: 'km', name: 'Khmer central' },
  { code: 'kn', name: 'Kannada' },
  { code: 'ko', name: 'Coréen' },
  { code: 'kr', name: 'Kanuri' },
  { code: 'ks', name: 'Kashmiri' },
  { code: 'ku', name: 'Kurde' },
  { code: 'kv', name: 'Komi' },
  { code: 'kw', name: 'Cornouailles' },
  { code: 'ky', name: 'Kirghiz ; Kirghiz' },
  { code: 'la', name: 'Latin' },
  { code: 'lb', name: 'Luxembourgeois ; Letzeburgesch' },
  { code: 'lg', name: 'Ganda' },
  { code: 'li', name: 'Limburgan ; Limburger ; Limburgish' },
  { code: 'ln', name: 'Lingala' },
  { code: 'lo', name: 'Lao' },
  { code: 'lt', name: 'Lituanien' },
  { code: 'lu', name: 'Luba-Katanga' },
  { code: 'lv', name: 'Letton' },
  { code: 'mg', name: 'Malgache' },
  { code: 'mh', name: 'Marshallais' },
  { code: 'mi', name: 'Maori' },
  { code: 'mk', name: 'Macédonien' },
  { code: 'ml', name: 'Malayalam' },
  { code: 'mn', name: 'Mongolien' },
  { code: 'mr', name: 'Marathi' },
  { code: 'ms', name: 'Malay' },
  { code: 'mt', name: 'Maltais' },
  { code: 'my', name: 'Birmane' },
  { code: 'na', name: 'Nauru' },
  { code: 'nb', name: 'Bokmål, norvégien ; Bokmål norvégien' },
  { code: 'nd', name: 'Ndebele, Nord ; Ndebele Nord' },
  { code: 'ne', name: 'Népalais' },
  { code: 'ng', name: 'Ndonga' },
  { code: 'nl', name: 'Hollandais ; Flamand' },
  { code: 'nn', name: 'Nynorsk norvégien ; Nynorsk, norvégien' },
  { code: 'no', name: 'Norvégien' },
  { code: 'nr', name: 'Ndebele, South ; South Ndebele' },
  { code: 'nv', name: 'Navajo ; Navaho' },
  { code: 'ny', name: 'Chichewa ; Chewa ; Nyanja' },
  { code: 'oc', name: 'Occitan (post 1500)' },
  { code: 'oj', name: 'Ojibwa' },
  { code: 'om', name: 'Oromo' },
  { code: 'or', name: 'Oriya' },
  { code: 'os', name: 'Ossétien ; Ossétique' },
  { code: 'pa', name: 'Panjabi ; Punjabi' },
  { code: 'pi', name: 'Pali' },
  { code: 'pl', name: 'Polish' },
  { code: 'ps', name: 'Pushto ; Pashto' },
  { code: 'pt', name: 'Portugais' },
  { code: 'qu', name: 'Quechua' },
  { code: 'rm', name: 'Romanche' },
  { code: 'rn', name: 'Rundi' },
  { code: 'ro', name: 'Roumain ; Moldave ; Moldovan' },
  { code: 'ru', name: 'Russe' },
  { code: 'rw', name: 'Kinyarwanda' },
  { code: 'sa', name: 'Sanskrit' },
  { code: 'sc', name: 'Sarde' },
  { code: 'sd', name: 'Sindhi' },
  { code: 'se', name: 'Sami du Nord' },
  { code: 'sg', name: 'Sango' },
  { code: 'si', name: 'Sinhala ; Cingalais' },
  { code: 'sk', name: 'Slovaque' },
  { code: 'sl', name: 'Slovène' },
  { code: 'sm', name: 'Samoan' },
  { code: 'sn', name: 'Shona' },
  { code: 'so', name: 'Somali' },
  { code: 'sq', name: 'Albanais' },
  { code: 'sr', name: 'Serbe' },
  { code: 'ss', name: 'Swati' },
  { code: 'st', name: 'Sotho, Southern' },
  { code: 'su', name: 'Sundanese' },
  { code: 'sv', name: 'Suédois' },
  { code: 'sw', name: 'Swahili' },
  { code: 'ta', name: 'Tamil' },
  { code: 'te', name: 'Telugu' },
  { code: 'tg', name: 'Tadjik' },
  { code: 'th', name: 'Thai' },
  { code: 'ti', name: 'Tigrinya' },
  { code: 'tk', name: 'Turkmène' },
  { code: 'tl', name: 'Tagalog' },
  { code: 'tn', name: 'Tswana' },
  { code: 'to', name: 'Tonga (Îles Tonga)' },
  { code: 'tr', name: 'Turc' },
  { code: 'ts', name: 'Tsonga' },
  { code: 'tt', name: 'Tatar' },
  { code: 'tw', name: 'Twi' },
  { code: 'ty', name: 'Tahitien' },
  { code: 'ug', name: 'Uighur ; Uyghur' },
  { code: 'uk', name: 'Ukrainien' },
  { code: 'ur', name: 'Urdu' },
  { code: 'uz', name: 'Uzbek' },
  { code: 've', name: 'Venda' },
  { code: 'vi', name: 'Vietnamien' },
  { code: 'vo', name: 'Volapük' },
  { code: 'wa', name: 'Wallon' },
  { code: 'wo', name: 'Wolof' },
  { code: 'xh', name: 'Xhosa' },
  { code: 'yi', name: 'Yiddish' },
  { code: 'yo', name: 'Yoruba' },
  { code: 'za', name: 'Zhuang ; Chuang' },
  { code: 'zh', name: 'Chinois' },
  { code: 'zu', name: 'Zoulou' },
];

export const ReplaceTextInBold = (text, array) => {
  let content = text;
  array
    .filter((x) => !['styles', 'style'].includes(x))
    .map(
      (x) =>
        (content = content.replace(
          new RegExp('\\b' + x + '\\b', 'g'),
          '<strong>' + x + '</strong>'
        ))
    );
  while (
    content.includes('<strong><strong>') &&
    content.includes('</strong></strong>')
  ) {
    content = content.replaceAll('<strong><strong>', '<strong>');
    content = content.replaceAll('</strong></strong>', '</strong>');
  }
  return content;
};

export const CustomEditor = ({ content, disabled, onChange }) => (
  <Editor
    value={content}
    apiKey={TINY_API}
    disabled={disabled}
    onEditorChange={onChange}
    init={{
      height: 400,
      menubar: true,
      width: 580,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo | formatselect | fullscreen |' +
        'bold italic backcolor forecolor | table link | alignleft aligncenter  ' +
        'alignright alignjustify | bullist numlist outdent indent',
      content_style:
        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    }}
  />
);

// export const compareContent = (oldString, newString) => {
//   function hljs(html) {
//     return html.replace(
//       /<span class="d2h-code-line-ctn">(.+?)<\/span>/g,
//       '<span class="d2h-code-line-ctn"><code>$1</code></span>'
//     )
//   }
//   const diff = createTwoFilesPatch(
//     "Texte initial",
//     "Texte ajoutée",
//     oldString || "",
//     newString || ""
//   )
//   let html = Diff2Html.html(diff, {
//     showFiles: false,
//     matching: "words",
//     diffStyle: "word",
//     renderNothingWhenEmpty: true,
//     outputFormat: "side-by-side"
//   })
//   return hljs(html)
// }

export const getDateFormat = (input) => {
  let today = new Date(input);
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '-' + mm + '-' + yyyy;
};
export const getDateFormatInput = (input) => {
  let today = new Date(input);
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }
  return yyyy + '-' + mm + '-' + dd;
};

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export const BatteryDisplay = (props) => {
  const [loading, setLoading] = useState(true);
  const [level, setLevel] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  useEffect(() => {
    (async () => {
      let nbr = await axios
        .get(props.type + '/GetNbrText?id=' + props.idCommande)
        .then((res) => res.data)
        .catch(() => 0);
      const total = props.total;
      setLevel((nbr * 100) / total);
    })().then(() => setLoading(false));
  }, [props]);

  const classes = useStyles();
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const open = Boolean(anchorEl);
  if (level <= 20) {
    return (
      <React.Fragment>
        <Battery20
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{Math.round(level) + '%'}</Typography>
        </Popover>
      </React.Fragment>
    );
  }
  if (level > 20 && level < 30) {
    return (
      <React.Fragment>
        <Battery30
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{Math.round(level) + '%'}</Typography>
        </Popover>
      </React.Fragment>
    );
  }
  if (level >= 30 && level < 50) {
    return (
      <React.Fragment>
        <Battery50
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{Math.round(level) + '%'}</Typography>
        </Popover>
      </React.Fragment>
    );
  }
  if (level >= 50 && level < 60) {
    return (
      <React.Fragment>
        <Battery60
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{Math.round(level) + '%'}</Typography>
        </Popover>
      </React.Fragment>
    );
  }
  if (level >= 60 && level < 80) {
    return (
      <React.Fragment>
        <Battery80
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{Math.round(level) + '%'}</Typography>
        </Popover>
      </React.Fragment>
    );
  }
  if (level >= 80 && level < 90) {
    return (
      <React.Fragment>
        <Battery90
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{Math.round(level) + '%'}</Typography>
        </Popover>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <BatteryFull
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{Math.round(level) + '%'}</Typography>
      </Popover>
    </React.Fragment>
  );
};

export const NbrTextColor = (props) => {
  const [color, setColor] = useState('#FF595D');
  const [formResult, setResult] = useState(0);
  useEffect(() => {
    try {
      if (
        !props.formData ||
        !props.textWordCount ||
        props.textWordCount ||
        !props.nbrTextMin ||
        !props.nbrText
      )
        setColor('black');

      const textWordCount = props.textWordCount;
      const initialTextWordCount = props.initialTextWordCount;

      const result = props.initialText
        ? textWordCount - initialTextWordCount
        : textWordCount;
      setResult(result);

      const nbrText = props.nbrTextMin;
      const nbrTextMax = props.nbrTextMax;
      if (!result || !nbrText || !nbrTextMax) {
        setColor('black');
      }
      const calcul1 = (10 * nbrText) / 100;
      const calcul2 = nbrText - result;
      const calcul3 = -((10 * nbrTextMax) / 100);
      const calcul4 = nbrTextMax - result;

      const condition1 = calcul3 > calcul4;
      const condition2 = calcul2 >= calcul1;

      if (condition1) {
        setColor(ORANGE);
        props.setValid(true);
      }

      if (condition2) {
        setColor(RED);
        props.setValid(false);
      }

      if (!condition1 && !condition2) {
        props.setValid(true);
      }
    } catch (e) {
      props.setValid(true);
      setColor('black');
      setResult(0);
    }
  }, [props]);

  if (!color || formResult === null) return <h1> YOsr </h1>;

  return (
    <b>
      {' '}
      Nombre total de mots ajouté :{' '}
      <span
        style={{
          color: color,
        }}
      >
        {formResult}
      </span>
    </b>
  );
};

export const DeadlinePassed = (date) => {
  var userEntered = new Date(date); // valueAsNumber has no time or timezone!
  var now = new Date();
  var today = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
  );
  if (
    userEntered.getDate() === today.getDate() &&
    userEntered.getMonth() === today.getMonth() &&
    userEntered.getFullYear() === today.getFullYear()
  )
    return false;
  else if (userEntered.getTime() < today.getTime()) return true;
  else return false;
};

export const COULEUR_GRIS = '#e3e3e3';
export const COULEUR_BLEUE = '#87CEEB';
export const COULEUR_MENU_CORAIL = '#FFA07A';
export const COULEUR_MENU_CLICKED = '#f7c9b6';
export const BORDER_RADIUS = 20;

export const formatDate = (date) => {
  if (!date) {
    return null;
  }
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const ValidatedTexts = (props) => {
  const [loading, setLoading] = useState(true);
  const [level, setLevel] = useState(0);
  const [color, setColor] = React.useState(null);
  const [total, setTotal] = React.useState(null);
  const [nbrText, setNbrText] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      let nbr = props.validatedText;
      setTotal(props.total);
      setNbrText(props.validatedText);
      const totalInput = props.total;
      const pourcentage = (nbr * 100) / totalInput;
      setLevel(pourcentage);
      switch (true) {
        case pourcentage === 100:
          setColor('success');
          break;
        case pourcentage < 100 && pourcentage >= 10:
          setColor('warning');
          break;
        case pourcentage < 10 && pourcentage >= 0:
          setColor('danger');
          break;
        default:
          setColor('info');
      }
    })().then(() => setLoading(false));
  }, [props]);

  if (loading) {
    return <LinearProgress color="success" />;
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (props.text) {
    if (props.asValue) {
      return nbrText + '/' + total;
    }
    return (
      <Typography align="center">
        {'Text validé :' + nbrText + '/' + total}
      </Typography>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Progress color={color} value={level} />
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography id={'result'} className={'text-' + color}>
            {Math.round(level) + '%'}
          </Typography>
        </Popover>
      </Grid>
      <Grid item xs={12}>
        <p align={'center'} className={'text-' + color}>
          {nbrText + '/' + total + ' textes validés'}
        </p>
      </Grid>
    </Grid>
  );
};

export const DeliveredTexts = (props) => {
  const [loading, setLoading] = useState(true);
  const [level, setLevel] = useState(0);
  const [color, setColor] = React.useState(null);
  const [total, setTotal] = React.useState(null);
  const [nbrText, setNbrText] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      let nbr = props.deliveredText;
      setTotal(props.total);
      setNbrText(props.deliveredText);
      const totalInput = props.total;
      const pourcentage = (nbr * 100) / totalInput;
      setLevel(pourcentage);
      if (pourcentage === 100) {
        setColor('success');
      } else if (pourcentage < 100 && pourcentage >= 10) {
        setColor('warning');
      } else if (pourcentage < 10 && pourcentage >= 0) {
        setColor('danger');
      }
    })().then(() => setLoading(false));
  }, [props]);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return <LinearProgress color="success" />;
  }

  const open = Boolean(anchorEl);

  if (props.text) {
    if (props.asValue) {
      return nbrText + '/' + total;
    }
    return (
      <Typography align="center">
        {'Text livré : ' + nbrText + '/' + total}
      </Typography>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Progress color={color} value={level} />
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography className={'text-' + color}>
            {Math.round(level) + '%'}
          </Typography>
        </Popover>
      </Grid>
      <Grid item xs={12}>
        <p align={'center'} className={'text-' + color}>
          {nbrText + '/' + total + ' textes livrés'}
        </p>
      </Grid>
    </Grid>
  );
};

export const NumberOfWords = (str) => {
  try {
    if (!str || str.length === 0) {
      return 0;
    }
    // str = str.replace(/<br>/gi, "\n");
    //str = str.replace(/<p.*>/gi, "\n");
    //str = str.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 (Link->$1) ");
    //str = str.replace(/<(?:.|\s)*?>/g, "");
    let res = [];
    let nbr = str.replace(/[\t\n\r.?!]/gm, ' ').split(' ');
    nbr.foreach((s) => {
      let trimStr = s.trim();
      if (trimStr.length > 0) {
        res.push(trimStr);
      }
    });
    return res.length;
    // return str.trim().split(" ").length;
  } catch (e) {
    return 0;
  }
};
export const CheckValid = (props) => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [finished, setFinished] = useState(null);
  const [finishedAt, setFinishedAt] = useState(null);
  const [id, setId] = React.useState(null);
  const [validate, setValid] = React.useState(null);

  const LoadData = () => {
    axios.get(props.url + '/LoadCommande?id=' + id).then((res) =>
      dispatch(
        updateCommande({
          id: id,
          data: res.data,
        })
      )
    );
  };

  useEffect(() => {
    (async () => {
      setFinished(props.finished);
      if (!props.finished) {
        return;
      }
      setId(props.id);
      setFinishedAt(props.finishedAt);
      const response = await axios
        .get('/Admin/CheckTextValid?id=' + props.id)
        .then((res) => res.data)
        .catch(() => {
          return false;
        });
      setValid(response);
    })().then(() => setLoading(false));
  }, [props]);

  if (loading) {
    return <CircularProgress />;
  }
  if (props.allTextValidated) {
    return <Typography>0 jours</Typography>;
  }
  if (!finished || (validate && finished) || (finished && !finishedAt)) {
    return <Typography>-</Typography>;
  }

  return <HowManyDayLeft id={id} finishedAt={finishedAt} reload={LoadData} />;
};
export const HowManyDayLeft = (props) => {
  const dispatch = useDispatch();
  const [daysLeft, setDaysLeft] = useState(null);
  const [numberOfDays, setNumberOfDays] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    if (props.finishedAt) {
      const finishedAt = new Date(props.finishedAt);
      setId(props.id);
      const date2 = new Date();
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const utc2 = Date.UTC(
        finishedAt.getFullYear(),
        finishedAt.getMonth(),
        finishedAt.getDate()
      );
      const utc1 = Date.UTC(
        date2.getFullYear(),
        date2.getMonth(),
        date2.getDate()
      );
      setDaysLeft(Math.floor((utc2 - utc1) / _MS_PER_DAY));
    }
  }, [props]);

  const LoadData = () => {
    axios.get(props.url + '/LoadCommande?id=' + id).then((res) =>
      dispatch(
        updateCommande({
          id: id,
          data: res.data,
        })
      )
    );
  };

  const DelayDeadline = () => {
    axios
      .get(
        '/Admin/DelayDeadline?idCommande=' +
          id +
          '&numberOfDays=' +
          numberOfDays
      )
      .then(LoadData);
  };
  if (!daysLeft) return null;

  if (daysLeft > 0) {
    return (
      <Typography>{daysLeft + ' jour' + (daysLeft > 1 ? 's' : '')}</Typography>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography style={{ marginRight: 10 }}>Délai dépassé </Typography>
      {props.url === '/Admin' && (
        <React.Fragment>
          <button id={'PopoverFocusDelay'}>
            <Timer />
          </button>
          <UncontrolledPopover
            trigger="legacy"
            placement="bottom"
            target={'PopoverFocusDelay'}
          >
            <PopoverBody>
              <Container>
                <TextField
                  label={'Nombre de jours'}
                  type={'number'}
                  value={numberOfDays}
                  onChange={(e) => setNumberOfDays(e.target.value)}
                />
                <Button disabled={!numberOfDays} onClick={DelayDeadline}>
                  Modifier
                </Button>
              </Container>
            </PopoverBody>
          </UncontrolledPopover>
        </React.Fragment>
      )}
    </div>
  );
};
