export const verifyAllTextsDeletionCriteria = (items) => {
  return items.every(
    (item) =>
      item.delivered === false ||
      (item.delivered === true &&
        item.revisionSent === false &&
        item.revisionDone === false &&
        item.validated === false &&
        item.globalRevisionSent === false)
  );
};

export const deleteTextes = () => {};
