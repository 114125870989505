import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  fetchWriterOrderDisplay,
  fetchWriterTextOrderDisplay,
} from 'store/Writer/OrderDisplay/features';
import { setOrderType } from 'store/Writer/Analyser';

import { writerOrderDisplayByClientSelector } from 'store/Writer/OrderDisplayByClient';

import { Paginator } from 'components/shared/Paginator';
import { Loader } from 'components/shared/Loader';
import { ProgressBar } from 'components/shared/ProgressBar';
import SearchBar from 'components/shared/SearchBar';

import { formatDate } from 'utils/DateUtils';
import { lower } from 'utils/StringUtils';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { sortByField, handleSortingChange } from 'helpers/SortingHelper';

import 'pages/admin/order.css';

import { pageNbOptions } from 'utils/Constants';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

function OrderDisplayByClient() {
  const { urlSite, ordersList, isListLoading } = useSelector(
    writerOrderDisplayByClientSelector
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);

  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [allOrders, setAllOrders] = useState([]);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [pageNumbers, setPageNumbers] = useState([]);
  // const [anchorEl, setAnchorEl] = useState(null)

  const [titleSearch, setTitleSearch] = useHistoryState('titleSearch', '');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const columns = [
    {
      label: `${t('Titre de la commande')}`,
      field: 'title',
      sortable: true,
      sort: 'asc',
    },
    { label: 'Deadline', field: 'deadline', sortable: true, sort: 'asc' },
    {
      label: `${t('Textes livrés')}`,
      field: 'deliveredTextsAmount',
      sortable: true,
      sort: 'asc',
    },
    {
      label: `${t('Textes validés')}`,
      field: 'validatedTextsAmount',
      sortable: true,
      sort: 'asc',
    },
  ];

  const handleSearch = (event) => setTitleSearch(event.target.value);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  useEffect(() => {
    setAllOrders(ordersList);
  }, [ordersList]);

  useEffect(() => {
    if (titleSearch && titleSearch.length > 0) {
      setPage(1);
      setFilteredOrders(
        allOrders.filter((x) => {
          return lower(x.title).includes(lower(titleSearch));
        })
      );
    } else {
      setFilteredOrders([...allOrders]);
    }
  }, [titleSearch, allOrders]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  const setPage = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const handleSorting = (sortField, sortOrder) => {
    const sorted = sortByField(allOrders, sortField, sortOrder);
    setAllOrders(sorted);
  };

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{urlSite}</h1>
      </div>
      <div className="box-info-header" style={{ marginBottom: 10 }}>
        <div className="b-child-1">
          <h2>{t('Liste des commandes')}</h2>
        </div>
        <div className="b-child-2">
          <div className="d-flex  justify-content-end">
            <SearchBar
              urlSearch={titleSearch}
              handleChangeText={handleSearch}
            />
            <div className="d-flex align-items-center">
              <span className="me-2">{t('Afficher les résultats')}</span>
              <div className="dropdown" style={{ width: '70px' }}>
                <button
                  className="btn dropdown-toggle dropdown-custom w-100"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {ordersPerPage}
                  <span>
                    <ChevronDownIcon />
                  </span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {pageNbOptions
                    .filter((nb) => nb !== ordersPerPage)
                    .map((item, index) => (
                      <li key={index}>
                        <button
                          className="dropdown-item"
                          onClick={() => setOrdersPerPage(item)}
                        >
                          {item}
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box-wrapper">
        {isListLoading ? (
          <Loader />
        ) : (
          <div className="table-responsive">
            <div>
              <table className="table-lists table-commandes">
                <thead>
                  <tr>
                    {columns.map(({ label, field, sortable }) => (
                      <th
                        style={{
                          cursor: 'pointer',
                          width:
                            field === 'title'
                              ? '26%'
                              : field === 'deadline'
                              ? '20%'
                              : '',
                        }}
                        onClick={
                          sortable
                            ? () =>
                                handleSortingChange(
                                  field,
                                  sortField,
                                  sortOrder,
                                  setSortField,
                                  setSortOrder,
                                  handleSorting
                                )
                            : null
                        }
                        key={field}
                      >
                        {label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order, index) => (
                    <tr key={order.id}>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={async () => {
                          dispatch(setOrderType(order.type));
                          dispatch(fetchWriterOrderDisplay(order.id));
                          dispatch(fetchWriterTextOrderDisplay(order.id));
                          history.push('/Redacteur/DetailsDeLaCommande');
                        }}
                      >
                        <span>{order?.title}</span>
                      </td>
                      <td>
                        <span>
                          {order.deadline ? formatDate(order.deadline) : '-'}
                        </span>
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.deliveredTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                      <td>
                        <ProgressBar
                          amount={order.validatedTextsAmount}
                          total={order.textAmount}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <Paginator
        isLoading={isListLoading}
        listLength={filteredOrders.length}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        nbElementsPerPage={ordersPerPage}
        goToPage={(page) => setCurrentPage(page)}
      />
    </div>
  );
}

export default OrderDisplayByClient;
