import { createSlice } from '@reduxjs/toolkit';
import { fetchConsultantText } from './features';

export const consultantAnalyzer = createSlice({
  name: 'consultantAnalyzer',
  initialState: {
    orderId: null,
    textForm: null,
    orderType: null,
    isLoading: true,
    isError: true,
    textType: 'NON_TRANSLATED',
  },
  reducers: {
    updateTextForm: (state, { payload }) => {
      let object = { ...state.textForm };
      object[payload.key] = payload.value;
      state.textForm = { ...object };
    },
    setConsultantOrderId: (state, { payload }) => {
      state.orderId = payload;
    },
    setTextType: (state, { payload }) => {
      state.textType = payload;
    },
    setOrderType: (state, { payload }) => {
      state.orderType = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsultantText.fulfilled, (state, { payload }) => {
        state.textForm = payload;
        state.isLoading = false;
      })
      .addCase(fetchConsultantText.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchConsultantText.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default consultantAnalyzer.reducer;

export const {
  updateForm,
  updateTextForm,
  setConsultantOrderId,
  setTextType,
  setOrderType,
} = consultantAnalyzer.actions;
export const consultantAnalyzerSelector = (state) => state.consultantAnalyzer;
