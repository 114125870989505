import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { adminOrderListSelector } from 'store/Admin/OrderList';
import AdminOrderListTable from 'components/Admin/AdminOrderListTable';
import { ADMIN_REVIEW_ORDERS_COLUMNS } from 'utils/Constants';
import { ORDERSTATUS } from 'utils/Constants';

function SmartOrders() {
  const columns = ADMIN_REVIEW_ORDERS_COLUMNS;
  const { t } = useTranslation();

  const { smartOrders, isLoading, isError } = useSelector(
    adminOrderListSelector
  );

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  return (
    <>
      <AdminOrderListTable
        title={t("Commandes intelligentes")}
        columns={columns}
        orders={smartOrders}
        isLoading={isLoading}
        isError={isError}
        orderStatus={ORDERSTATUS.SMART}
      />
    </>
  );
}

export default SmartOrders;
