import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  adminWebsiteDetailsSelector,
  setWebsiteAccount,
  setWebsiteId,
} from 'store/Admin/WebsiteDisplay';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWebsiteOrdersList } from 'store/Admin/WebsiteDisplay/features';
import { setUrlSiteForDisplay } from 'store/Admin/WebsiteDisplay';

import axiosPrivate from 'config/axiosPrivate';

import { AssignClient } from 'components/Admin/AssignClient';

import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import {
  CustomAutocomplete,
  CustomAutocompleteFreeSolo,
} from 'components/shared/DropDown/CustomAutocomplete';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';
import { DeleteUser } from 'components/Admin/DeleteUser';

import { ROLES } from 'utils/Constants';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorComponent from 'components/shared/ErrorComponent';
import { Loader } from 'components/shared/Loader';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

import * as yup from 'yup';
import ClientList from 'store/Admin/ClientList';

const API = {
  pixopClients: '/user/pixOpsClient',
  getAllConsultants: '/user/all',
  getAllClients: '/user/clients',
  getAvailableThematics: '/thematic/all',
  updateWebsite: '/clientWebsite',
  deleteWebsite: '/clientWebsite/',
};

export default function WebsiteDisplay() {
  let { formData, isLoading, isError } = useSelector(
    adminWebsiteDetailsSelector
  );
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [pixOpData, setPixOpData] = useState([]);
  const [disabledForm, setDisabledForm] = useState(true);

  const [urlSitesList, setUrlSitesList] = useState([]);
  const [urlSite, setUrlSite] = useState(null);

  const [consultant, setConsultant] = useState(null);
  const [consultantsList, setConsultantsList] = useState([]);

  const [clientsList, setClientsList] = useState([]);
  const [accountClientsList, setAccountClientsList] = useState([]);

  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [websiteThematics, setWebsiteThematics] = useState([]);

  const [coconsTextNbr, setCoconsTextNbr] = useState(0);
  const [coconsNbr, setCoconsNbr] = useState(0);
  const [faqNbr, setFaqNbr] = useState(0);
  const [gmbNbr, setGmbNbr] = useState(0);
  const [serviceDetails, setServiceDetails] = useState('');
  const [textOptimizerNbr, setTextOptimizerNbr] = useState(0);

  const [index, setIndex] = useState(0);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  const [assignedClients, setAssignedClients] = useState([]);
  const [selectedClientForDelete, setSelectedClientForDelete] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    document.title = t('Typix - Fiche site web');
    window.dispatchEvent(new Event('locationchange'));

    axiosPrivate.get(API.pixopClients).then((res) => {
      return setPixOpData(res.data);
    });
    axiosPrivate
      .get(API.getAllConsultants, { params: { role: ROLES.CONSULTANT } })
      .then((res) => {
        setConsultantsList(res.data);
      });

    axiosPrivate
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));
  }, [t]);

  useEffect(() => {
    if (clientsList.length > 0) {
      setUrlSitesList(
        pixOpData.find((x) => x.clientName === clientsList[0].account)?.site
      );
    }
  }, [pixOpData, clientsList]);

  const validationSchema = yup.object().shape({
    coconsTextNbr: yup.number().min(0).required(),
    coconsNbr: yup.number().min(0).required(),
    faqNbr: yup.number().min(0).required(),
    gmbNbr: yup.number().min(0).required(),
    textOptimizerNbr: yup.number().min(0).required(),
  });

  useEffect(() => {
    const checkValidForm = async () => {
      const invalid = assignedClients.some(
        (x) =>
          !x.name ||
          !x.phoneNumber ||
          !x.mail ||
          !x.name.length ||
          !x.phoneNumber.length ||
          !x.mail.length
      );

      const positveNumbers = await validationSchema
        .validate({
          coconsTextNbr,
          coconsNbr,
          faqNbr,
          gmbNbr,
          textOptimizerNbr,
        })
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });

      if (
        !urlSite ||
        !consultant ||
        !positveNumbers ||
        websiteThematics.length === 0 ||
        serviceDetails.length === 0 ||
        invalid
      ) {
        setDisabledForm(true);
      } else {
        setDisabledForm(false);
      }
    };
    checkValidForm();
  }, [
    urlSite,
    consultant,
    websiteThematics,
    serviceDetails,
    assignedClients,
    validationSchema,
    coconsTextNbr,
    coconsNbr,
    faqNbr,
    gmbNbr,
    textOptimizerNbr,
  ]);

  useEffect(() => {
    if (!isLoading) {
      setUrlSite(formData.websiteDTO.urlSite);
      setClientsList(formData.clients);
      setTextOptimizerNbr(formData.websiteDTO.textOptimizerNbr);
      setCoconsTextNbr(formData.websiteDTO.coconsTextNbr);
      setCoconsNbr(formData.websiteDTO.coconsNbr);
      setFaqNbr(formData.websiteDTO.faqNbr);
      setGmbNbr(formData.websiteDTO.gmbNbr);
      setServiceDetails(formData.websiteDTO.serviceDetails);
      setConsultant(formData.websiteDTO.consultant);
      setWebsiteThematics(formData.websiteDTO.thematics);
    }
  }, [isLoading, formData]);

  useEffect(() => {
    if (clientsList.length > 0) {
      axiosPrivate
        .get(API.getAllClients, { params: { account: clientsList[0].account } })
        .then((res) => {
          setAccountClientsList(res.data);
        });
    }
  }, [clientsList]);

  const callbackFromMultiSelect = (newList) => {
    setWebsiteThematics(newList);
  };

  const handleDeleteClient = (clientEmail) => {
    setClientsList(clientsList.filter((client) => client.mail !== clientEmail));
    setShowDelete(false);
  };

  const updateWebsite = () => {
    let body = {
      websiteDTO: {
        id: formData.websiteDTO.id,
        coconsNbr,
        coconsTextNbr,
        consultant,
        faqNbr,
        gmbNbr,
        serviceDetails,
        textOptimizerNbr,
        urlSite,
        thematics: websiteThematics,
      },
      clients: assignedClients
        .filter((x) => x.name)
        .map((x) => ({
          mail: x.mail,
          name: x.name,
          phoneNumber: x.phoneNumber,
          account: clientsList[0].account,
          role: ROLES.CLIENT,
        })),
    };

    axiosPrivate
      .put(API.updateWebsite, body)
      .then(() => {
        setIsDeleted(false);
        setErrorSuccessMsg(
          `${t('Le contrat de')} ${urlSite} ${t(
            'a été mis à jour avec succès'
          )} !`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsDeleted(false);
        switch (e.response.status) {
          case 403:
            setErrorSuccessMsg(
              t('Contrat déjà existant, veuillez modifier les informations')
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  const deleteWebsite = () => {
    axiosPrivate
      .delete(API.deleteWebsite + formData.websiteDTO.id)
      .then(() => {
        setIsDeleted(true);
        setConfirmation(false);

        setErrorSuccessMsg(
          `${t('Le contrat de')} ${urlSite} ${'a été supprimé avec succès'} !`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsDeleted(true);
        setIsSuccess(false);
        setConfirmation(false);
        switch (e.response.status) {
          case 400:
            setErrorSuccessMsg(
              `${t("il y'a des commandes liés à au site web")} ${urlSite}`
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  if (isError) return <ErrorComponent />;
  if (isLoading) return <Loader />;

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{urlSite}</h1>
      </div>
      <div className="row">
        <div className="col-12 col-md-10 box-screen">
          <div className="box-img-bg bg-8" />
          <div className="box-img-bg bg-7-1" />
          <div className="box-img-bg bg-9" />
          <div className="header-title">
            {t('Fiche du site web')}{' '}
            <span
              onClick={() => {
                setConfirmationMsg(
                  `${t(
                    'Voulez-vous vraiment supprimer le contrat de'
                  )} ${urlSite} ? `
                );
                setConfirmation(true);
              }}
              className="bt-icon-trash"
            >
              <img src="/Images/icon-trash.png" alt="icon-trash" />{' '}
            </span>
          </div>

          <div className="box-wrapper">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="row row-mb">
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Compte client')}</div>
                    <div>
                      <input
                        type="text"
                        className="form-control form-ct"
                        style={{
                          backgroundColor: 'rgb(224,224,224)',
                          color: 'grey',
                        }}
                        disabled
                        value={
                          clientsList.length > 0 ? clientsList[0].account : ''
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Url du site')}</div>
                    <CustomAutocompleteFreeSolo
                      list={urlSitesList || []}
                      value={urlSite}
                      onInputChange={(value) => setUrlSite(value)}
                      setValue={(value) => {
                        const url = urlSitesList.find((x) => x === value);
                        if (url) setUrlSite(url);
                      }}
                      getOptionLabel={(option) => {
                        return option;
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Consultant')}</div>
                    <CustomAutocomplete
                      disabled={!consultantsList}
                      list={consultantsList}
                      value={consultant}
                      getOptionSelected={(option) =>
                        option.id === consultant.id
                      }
                      getOptionDisabled={(option) => false}
                      setValue={(option) => setConsultant(option)}
                      groupBy={(option) => false}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                    />
                  </div>
                </div>

                <div className="row row-mb" style={{ zIndex: 200 }}>
                  <div className="col-12 col-md-3">
                    <div className="label-title">
                      {t('Prénom / Nom du client')}
                    </div>
                    <div className="dropdown" style={{ zIndex: 99999 }}>
                      <button
                        className="btn dropdown-toggle dropdown-custom w-100"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ textTransform: 'none' }}
                      >
                        {clientsList.length > 0
                          ? clientsList[index].name
                          : null}
                        <span>
                          <ChevronDownIcon />
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {clientsList.map((client, key) => (
                          <li key={key}>
                            <button
                              className="dropdown-item"
                              onClick={() => setIndex(key)}
                            >
                              {client.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Mail client')}</div>
                    <div className="dropdown" style={{ zIndex: 999 }}>
                      <button
                        className="btn  dropdown-toggle dropdown-custom w-100"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ textTransform: 'none' }}
                      >
                        {clientsList.length > 0
                          ? clientsList[index].mail
                          : null}
                        <span>
                          <ChevronDownIcon />
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {clientsList.map((client, key1) => (
                          <li key={key1}>
                            <button
                              className="dropdown-item"
                              onClick={() => setIndex(key1)}
                            >
                              {client.mail}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="label-title">{t('Téléphone')}</div>
                    <div className="dropdown" style={{ zIndex: 999 }}>
                      <button
                        className="btn  dropdown-toggle dropdown-custom w-100"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ textTransform: 'none' }}
                      >
                        {clientsList.length > 0
                          ? clientsList[index].phoneNumber
                          : null}
                        <span>
                          <ChevronDownIcon />
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {clientsList.map((client, key3) => (
                          <li key={key3}>
                            <button
                              className="dropdown-item"
                              onClick={() => setIndex(key3)}
                            >
                              {client.phoneNumber}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-1">
                    <span
                      className="bt-minus-circle me-3 "
                      style={{ marginTop: 25 }}
                      onClick={() => {
                        setSelectedClientForDelete({
                          mail: clientsList[index]?.mail,
                          role: 'CLIENT',
                        });
                        setShowDelete(true);
                      }}
                    />
                  </div> */}
                  <div className="col-12 col-md-1">
                    <div
                      className="bt-plus-circle"
                      onClick={() =>
                        setAssignedClients([
                          {
                            name: '',
                            mail: '',
                            phoneNumber: '',
                          },
                          ...assignedClients,
                        ])
                      }
                      style={{ marginTop: 25 }}
                    />
                  </div>
                </div>
                {assignedClients.map((client, index) => (
                  <AssignClient
                    id={index}
                    list={accountClientsList.filter(
                      (item) =>
                        !clientsList.some((item2) => item2.id === item.id)
                    )}
                    new={false}
                    data={client}
                    account={
                      clientsList.length > 0 ? clientsList[0].account : ''
                    }
                    key={index}
                    updateClientsList={(type, object) => {
                      const newValue = client;
                      newValue[type] = object;
                      const newList = assignedClients;
                      newList[index] = newValue;
                      setAssignedClients([...newList]);
                    }}
                    deleteClient={(index) => {
                      let newList = assignedClients;
                      newList.splice(index, 1);
                      setAssignedClients([...newList]);
                    }}
                  />
                ))}
                <div className="row row-mb">
                  <div className="col-12 col-md-2" style={{ width: '18%' }}>
                    <div className="label-title">
                      {t('Nb de textes par Silo')}
                    </div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={coconsTextNbr}
                        onChange={(e) => setCoconsTextNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {coconsTextNbr < 0 && (
                      <p style={{ color: 'red' }}>
                        {t('les valeurs négatives ne sont pas autorisées')}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-2" style={{ width: '15.3%' }}>
                    <div className="label-title">{t('Nombre de FAQ')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={faqNbr}
                        onChange={(e) => setFaqNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {faqNbr < 0 && (
                      <p style={{ color: 'red' }}>
                        {t('les valeurs négatives ne sont pas autorisées')}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('Nombre de GMB')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={gmbNbr}
                        onChange={(e) => setGmbNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {gmbNbr < 0 && (
                      <p style={{ color: 'red' }}>
                        {t('les valeurs négatives ne sont pas autorisées')}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('Nombre de Silo')}</div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={coconsNbr}
                        onChange={(e) => setCoconsNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {coconsNbr < 0 && (
                      <p style={{ color: 'red' }}>
                        {t('les valeurs négatives ne sont pas autorisées')}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="label-title">
                      {t('Nb de textes à optimiser')}
                    </div>
                    <div className="number-wrapper">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-ct"
                        value={textOptimizerNbr}
                        onChange={(e) => setTextOptimizerNbr(e.target.value)}
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {textOptimizerNbr < 0 && (
                      <p style={{ color: 'red' }}>
                        {t('les valeurs négatives ne sont pas autorisées')}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="label-title">
                      {t('Thématiques d’expertise')}
                    </div>
                    <CustomChipsAutocomplete
                      callbackFromMultiSelect={callbackFromMultiSelect}
                      value={websiteThematics}
                      disabled={!availableThematicsList}
                      list={availableThematicsList}
                      setValue={(option) => setWebsiteThematics(option)}
                      getOptionLabel={(option) => {
                        return t(option.thematic);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="label-title">
                      {t('Détail de la prestation du contenu')}
                    </div>
                    <div>
                      <input
                        type="text"
                        className="form-control form-ct"
                        value={serviceDetails}
                        onChange={(e) => setServiceDetails(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-button-bt">
            <span>
              <button
                onClick={() => {
                  dispatch(fetchWebsiteOrdersList(formData.websiteDTO.id));
                  dispatch(setUrlSiteForDisplay(formData.websiteDTO.urlSite));
                  dispatch(setWebsiteId(formData.websiteDTO.id));
                  dispatch(setWebsiteAccount(clientsList[0].account));
                  history.push('/Admin/CommandesduSiteweb');
                }}
                className="bt-submit-y me-2"
              >
                {t('Voir les commandes')}
              </button>{' '}
            </span>
            <div>
              {disabledForm ? (
                <button className="bt-submit-disabled-y">
                  {' '}
                  {t('Sauvegarder')}{' '}
                </button>
              ) : (
                <button className="bt-submit-y" onClick={updateWebsite}>
                  {' '}
                  {t('Sauvegarder')}{' '}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() =>
          isDeleted ? history.goBack() : setErrorSuccess(false)
        }
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={deleteWebsite}
      />
      {showDelete && (
        <DeleteUser
          flag={true}
          onDelete={handleDeleteClient}
          selected={selectedClientForDelete}
          Close={() => {
            setShowDelete(false);
          }}
        />
      )}
    </div>
  );
}
