import * as React from 'react';
import { Route } from 'react-router-dom';
import Layout from './Layout';
import { HomePage } from 'pages/admin/Homepage';
import { Redirect } from 'react-router-dom';

import UsersManagement from 'pages/admin/UsersManagement';

import CreateOrder from 'pages/admin/CreateOrder';
import ValidatedOrders from 'pages/admin/ValidatedOrders';
import DelayedOrders from 'pages/admin/DelayedOrders';
import InProgressOrders from 'pages/admin/InProgressOrders';
import NonAttributedOrders from 'pages/admin/NonAttributedOrders';
import SmartOrders from 'pages/admin/SmartOrders';
import SmartWithReviewOrders from 'pages/admin/SmartWithReviewOrders';

import TextDisplay from 'pages/admin/TextDisplay';

import AdminProfile from 'pages/admin/AdminProfile';
import OrderDisplay from 'pages/admin/OrderDisplay';
import OrdersListOfWebsite from 'pages/admin/OrdersListOfWebsite';

import WritersManagement from 'pages/admin/WritersManagement';
import WriterDisplay from 'pages/admin/WriterDisplay';
import CreateWriter from 'pages/admin/CreateWriter';

import CreateWebsite from 'pages/admin/CreateWebsite';
import WebsiteDisplay from 'pages/admin/WebsiteDisplay';
import AdminBillsPage from 'pages/admin/BillsDisplay';
import WriterOrders from 'pages/admin/WriterOrders';
import Analyzer from 'pages/admin/Analyzer';

export default class AdminMainMenu extends React.Component {
  render() {
    return (
      <Layout>
        <Route
          path="/Admin/MainMenu"
          render={(props) => <HomePage {...props} reload={true} />}
        />
        <Route path="/Admin/MonProfil" component={AdminProfile} />
        <Route path="/Admin/CommandesValides" component={ValidatedOrders} />
        <Route path="/Admin/CommandesEnRetard" component={DelayedOrders} />
        <Route path="/Admin/CommandesEnCours" component={InProgressOrders} />
        <Route path="/Admin/DetailsDeLaCommande" component={OrderDisplay} />
        <Route path="/Admin/Redacteur" component={WritersManagement} />
        <Route path="/Admin/VoirRedacteur" component={WriterDisplay} />
        <Route path="/Admin/CreerRedacteur" component={CreateWriter} />
        <Route path="/Admin/GestionUtilisateur" component={UsersManagement} />
        <Route path="/Admin/NouvelleCommande" component={CreateOrder} />
        <Route
          path="/Admin/CommandesduSiteweb"
          component={OrdersListOfWebsite}
        />
        <Route path="/Admin/commandesDuRedacteur" component={WriterOrders} />
        <Route path="/Admin/AjouterClient" component={CreateWebsite} />
        <Route path="/Admin/FicheClient" component={WebsiteDisplay} />
        <Route path="/Admin/TextDetails" component={TextDisplay} />
        <Route
          path="/Admin/CommandesNonAttribuees"
          component={NonAttributedOrders}
        />

        <Route path="/Admin/CommandesIntelligentes" component={SmartOrders} />
        <Route
          path="/Admin/CommandesReview"
          component={SmartWithReviewOrders}
        />
        <Route path="/Admin/Factures" component={AdminBillsPage} />
        <Route path="/Admin/Analyzer" component={Analyzer} />
        <Route path="*">
          <Redirect to="/Admin/MainMenu" />
        </Route>
      </Layout>
    );
  }
}
