import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CopyIcon from 'icons/CopyIcon';

export const ClipboardCopy = ({ url }) => {
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  async function copyTextToClipboard(url) {
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(url)
      .then(() => {
        handleTooltipOpen();
        setTimeout(() => {
          handleTooltipClose();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="copied"
        >
          <span
            onClick={handleCopyClick}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
            }}
          >
            <CopyIcon />
          </span>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};
