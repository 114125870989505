import axiosNotifications from 'config/axiosNotifications';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useHistoryState } from 'utils/hooks/useHistoryState';
import { clientNotificationSelector } from 'store/Client/ClientNotification';
import { setNotificationsToSeen } from 'store/Client/ClientNotification';

import { NOTIFICATION_TYPE } from 'utils/Constants';

import NotificationDetails from 'components/shared/NotificationDetails';
import './Notification.css';

function ClientNotification() {
  const { notifications, isLoading, isError } = useSelector(
    clientNotificationSelector
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [active, setActive] = useHistoryState('active', 0);

  const revisionDoneNotif = notifications.filter(
    (notification) =>
      notification.notificationType === NOTIFICATION_TYPE.REVISION_DONE
  );
  const textIsDelivredNotif = notifications.filter(
    (notification) =>
      notification.notificationType === NOTIFICATION_TYPE.TEXT_IS_DELIVERED
  );
  const orderReadyNotif = notifications.filter(
    (notification) =>
      notification.notificationType === NOTIFICATION_TYPE.COMMAND_READY
  );

  function countUnaccessedNotifications(arr) {
    return arr.reduce(
      (count, notification) => (notification.accessed ? count : ++count),
      0
    );
  }

  useEffect(() => {
    axiosNotifications
      .post('/notification/all/seen')
      .then(() => dispatch(setNotificationsToSeen()))
      .catch((e) => {
        console.log(e);
      });
  }, [dispatch]);

  return (
    <div className="content-center">
      <div className="box-header-top">
        <span onClick={history.goBack} className="bt-circle me-3"></span>
        <h1>{t('Notifications')}</h1>
      </div>
      <div className="box-over" style={{ position: 'relative' }}>
        <div className="row">
          <div className="col-12 col-md-3">
            <div style={{ cursor: 'pointer' }} onClick={() => setActive(0)}>
              <div className=" box-screen h-100 ">
                <div className="box-wrapper ">
                  <div className="d-flex flex-row  align-items-center ">
                    <div className=" icon-notification pe-4">
                      <img
                        className="d-flex align-self-center"
                        src="/Images/icon-menu/Menu_Notif_Active.png"
                        alt="notification icon"
                      />
                      {/* <span className="icon-notification-badge bg-red">
                        {countUnaccessedNotifications(notifications)}
                      </span> */}
                    </div>

                    <div className="w-55">
                      {t('Vous avez')}{' '}
                      <strong>
                        {countUnaccessedNotifications(notifications)}{' '}
                        {t('nouvelles notifications')}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => setActive(1)}>
              <div className=" box-screen h-100 ">
                <div className="box-wrapper ">
                  <div className="d-flex flex-row  align-items-center ">
                    <div className=" icon-notification pe-4">
                      <img
                        className="d-flex align-self-center"
                        src="/Images/icon-menu/Menu_Notif_Active.png"
                        alt="notification icon"
                      />
                      <span className="icon-notification-badge bg-black">
                        {countUnaccessedNotifications(textIsDelivredNotif)}
                      </span>
                    </div>

                    <div className="w-55">
                      {t('Vous avez')}{' '}
                      <strong>
                        {countUnaccessedNotifications(textIsDelivredNotif)}{' '}
                        {t('textes livrés en attente de validation')}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => setActive(2)}>
              <div className=" box-screen">
                <div className="box-wrapper ">
                  <div className="d-flex flex-row  align-items-center ">
                    <div className="icon-notification  pe-4">
                      <img
                        className="d-flex align-self-center"
                        src="/Images/icon-menu/Menu_Notif_Active.png"
                        alt="notification icon"
                      />
                      <span className="icon-notification-badge bg-orange">
                        {countUnaccessedNotifications(revisionDoneNotif)}
                      </span>
                    </div>
                    <div className="w-55">
                      {t('Vous avez')}{' '}
                      <strong className="text-orange">
                        {countUnaccessedNotifications(revisionDoneNotif)}{' '}
                        {t('révisions effectuées en attentes de validation')}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => setActive(3)}>
              <div className="box-screen">
                <div className="box-wrapper ">
                  <div className="d-flex flex-row  align-items-center ">
                    <div className=" icon-notification pe-4">
                      <img
                        className="d-flex align-self-center"
                        src="/Images/icon-menu/Menu_Notif_Active.png"
                        alt="notification icon"
                      />
                      <span className="icon-notification-badge bg-green ">
                        {countUnaccessedNotifications(orderReadyNotif)}
                      </span>
                    </div>
                    <div className="w-55">
                      {t('Vous avez')}{' '}
                      <strong className="text-green">
                        {countUnaccessedNotifications(orderReadyNotif)}{' '}
                        {t('commandes prête')}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {active === 0 && (
            <NotificationDetails
              notifications={notifications}
              title={t('Toutes les notifications')}
              active={active}
              isLoading={isLoading}
              isError={isError}
            />
          )}
          {active === 1 && (
            <NotificationDetails
              notifications={textIsDelivredNotif}
              title={t('Textes livrés')}
              isLoading={isLoading}
              isError={isError}
            />
          )}
          {active === 2 && (
            <NotificationDetails
              notifications={revisionDoneNotif}
              title={t('Révisions effectués')}
              isLoading={isLoading}
              isError={isError}
            />
          )}
          {active === 3 && (
            <NotificationDetails
              notifications={orderReadyNotif}
              title={t('commandes prête')}
              isLoading={isLoading}
              isError={isError}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ClientNotification;
