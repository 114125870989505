import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

export const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f5 !important',
    opacity: '1 !important',
    textAlign: 'left',
    fontSize: '13px',
    letterSpacing: '-0.26px',
    color: '#3c3c3c',
    wordBreak: 'break-word',
    borderRadius: '12px',
    boxShadow:
      '2px -1px 8px 0 rgba(0, 0, 0, 0.15), -1px 2px 8px 0 rgba(0, 0, 0, 0.15)',
    width: '180px !important',
    fontWeight: '300',
  },
}))(Tooltip);

export const RevisionTextCustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f5 !important',
    opacity: '1 !important',
    textAlign: 'left',
    fontSize: '13px',
    letterSpacing: '-0.26px',
    color: '#3c3c3c',
    wordBreak: 'break-word',
    borderRadius: '12px',
    boxShadow:
      '2px -1px 8px 0 rgba(0, 0, 0, 0.15), -1px 2px 8px 0 rgba(0, 0, 0, 0.15)',
    fontWeight: '300',
  },
}))(Tooltip);

export const CustomWhiteTooltip = withStyles(() => ({
  tooltip: {
    verticalAlign: 'middle',
    border: 'solid 1px transparent',
    borderRadius: '30px',
    backgroundColor: 'white',
    color: '#3c3c3c',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: "'Poppins', sans-serif !important",
  },
}))(Tooltip);
