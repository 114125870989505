import React, { useState } from 'react';

import { saveAs } from 'file-saver';
import { renderToString } from 'react-dom/server';

import axiosPrivate from 'config/axiosPrivate';

import WordFileStructure from './WordFileStructure';
import { KeyWordListFormatter } from 'components/Admin/KeyWordListFormatter';
import { fileNameSanitizer } from 'helpers/FileNameSanitizer';
import DownloadModal from 'components/shared/modal/DownloadModal';

function getResultColor(result) {
  if (result) {
    if (result.semanticEnrichment <= 29) return '#FF595D';

    if (result.semanticEnrichment <= 69) return '#FFBE64';

    if (result.semanticEnrichment > 69) return '#3ED774';
  }

  return '#ffffff';
}

export const getWordFile = async (textId, user) => {
  const text = await axiosPrivate
    .get('/texts/' + textId + '/fullDetails')
    .then((res) => res.data);

  const keywords = text.semanticGap
    .map((x) => x.word + '(' + x.frequency + ')')
    .join(',\n');

  const convertedText = KeyWordListFormatter(keywords);

  let result = text.analyseResult && JSON.parse(text.analyseResult);
  let color = getResultColor(result);

  const htmlString = renderToString(
    <WordFileStructure
      user={user}
      title={text.textTitle}
      titleTag={text.titleTag}
      metaDescriptionTag={text.metaDescriptionTag}
      keywords={keywords}
      url={text.url}
      content={
        text.actualHtmlContent !== null
          ? text.actualHtmlContent
          : text.actualTextContent
      }
      result={result}
      numberOfWords={text.totalNumberOfTextAdded}
      color={color}
      convertedText={convertedText}
    />
  );
  const formData = new FormData();
  formData.append('fileName', text.textTitle + '.docx');
  formData.append('htmlString', htmlString);

  let file;
  let fileName = fileNameSanitizer(text.textTitle);

  const res = await axiosPrivate({
    url: '/user/download-file',
    responseType: 'arraybuffer',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
  });
  var blob = new Blob([res.data]);
  if (res.status === 200) {
    file = blob;
  } else {
    console.log('error');
  }

  return { file, fileName };
};

export const DownloadOneTextWordType = async (textId, user) => {
  const result = await getWordFile(textId, user);

  saveAs(result.file, result.fileName + '.docx');
};

export const DownloadTextsListWordType = async (
  data,
  user,
  setShowDownloadModel
) => {
  const timeout = setTimeout(() => setShowDownloadModel(true), 500);
  const zip = require('jszip')();

  let files = await data.map(async (text) => {
    const singleFile = await getWordFile(text.id, user);

    return {
      name: singleFile.fileName + '.docx',
      data: singleFile.file,
    };
  });

  const result = await Promise.all(files);
  result.map((x) => zip.file(x.name, x.data));
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, 'allFiles.zip');
    setShowDownloadModel(false);
  });
};
