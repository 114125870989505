import axios from 'axios';
import TokenService from './token.service';
import { fetchUser } from 'store/UserSession/features';

import { store } from 'store';
const endpoints = require('./endpoints.json')

const BASE_NOTIFICATIONS_URL = endpoints.axiosNotificationsAPI;

const axiosNotifications = axios.create({
  baseURL: BASE_NOTIFICATIONS_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosNotifications.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();

    if (token) {
      config.headers['authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosNotifications.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalReq = error?.config;
    if (error.response && error.response.status === 403) {
      // auto logout if 403 Forbidden response returned from api
      TokenService.removeUser();
      store.dispatch(fetchUser(null));

      originalReq._retry = true;
    }
    return Promise.reject(error);
  }
);

export default axiosNotifications;
