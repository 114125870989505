import Axios from 'axios';
const endpoints = require('./endpoints.json')
// const BASE_URL = process.env.REACT_APP_DEV_API_URL_PREFIX
const BASE_URL = endpoints.axiosPrivateAPI; //DEV
//const BASE_URL = "https://typix-prod-api.azuremicroservices.io" PROD
//const BASE_URL = "https://typix-staging-api.azuremicroservices.io"

const axios = Axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

export default axios;
