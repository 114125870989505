import Axios from 'axios';
const endpoints = require('./endpoints.json');
const BASE_URL = endpoints.keywordStuffingPrivateAPI;
//const BASE_URL = 'http://127.0.0.1:9000/';

const axiosStuffedDetector = Axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

export default axiosStuffedDetector;
