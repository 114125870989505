import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ClientMainLayout } from 'components/shared/Layout/MainLayout';
import DashboardPage from 'pages/client/DashboardPage';
import ClientProfile from 'pages/client/ClientProfile';
import ClientOrderDisplay from 'pages/client/ClientOrderDisplay';
import ClientTextDisplay from 'pages/client/ClientTextDisplay';
import ClientNotification from 'pages/client/ClientNotification';

function ClientMainMenu() {
  const routes = [
    <Route
      exact
      path="/Client/MainMenu"
      render={(props) => <DashboardPage {...props} reload={true} />}
    />,
    <Route exact path="/Client/MonProfile" component={ClientProfile} />,
    <Route
      exact
      path="/Client/DetailsDeLaCommande"
      component={ClientOrderDisplay}
    />,
    <Route exact path="/Client/TextDetails" component={ClientTextDisplay} />,
    <Route exact path="/Client/Notification" component={ClientNotification} />,

    <Route path="*">
      <Redirect to="/Client/MainMenu" />
    </Route>,
  ];

  return (
    <ClientMainLayout>
      {routes.map((route, index) => (
        <div key={index}>{route}</div>
      ))}
    </ClientMainLayout>
  );
}

export default ClientMainMenu;
