import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  handleSorting,
  removeNonAttributedOrder,
} from 'store/Writer/OrderList';
import {
  fetchWriterOrderDisplay,
  fetchWriterTextOrderDisplay,
} from 'store/Writer/OrderDisplay/features';
import { setStatus } from 'store/Writer/OrderDisplay';

import TableHeader from './TableHeader';
import { formatDate } from 'utils/DateUtils';
import {
  ORDERSTATUS,
  FILTER_WRITER_ORDER_TYPE_LIST,
  ORDER_TYPE,
} from 'utils/Constants';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { Loader } from 'components/shared/Loader';
import ErrorComponent from 'components/shared/ErrorComponent';

import { Paginator } from 'components/shared/Paginator';

import { ProgressBar } from 'components/shared/ProgressBar';
import OrderTypeFilter from 'components/shared/Filter/OrderTypeFilter';
import ApplicationModal from '../modal/ApplicationModal';
import ConfirmationModal from '../modal/ConfirmationModal';
import ErrorSuccesModal from '../modal/ErrorSuccesModal';

import axiosPrivate from 'config/axiosPrivate';
import { filterOrders } from 'helpers/FilterOrders';
import { setOrderType } from 'store/Writer/Analyser';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';

import './styles.css';

const fieldWidths = {
  title: '26%',
  deadline: '20%',
  type: '15%',
};

function OrdersStatusTable({
  title,
  columns,
  orders,
  isLoading,
  isError,
  isNonAttributed,
  orderStatus,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [titleSearch, setTitleSearch] = useHistoryState('titleSearch', '');

  const [currentPage, setCurrentPage] = useState(1);

  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [orderTitle, setOrderTitle] = useState('');
  const [totalText, setTotalText] = useState(null);

  const [pageNumbers, setPageNumbers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const [showApplication, setShowApplication] = useState(false);
  const [selectedType, setSelectedType] = useState(
    FILTER_WRITER_ORDER_TYPE_LIST[0]
  );

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  useEffect(() => {
    if (!orders) return;

    const filtered = filterOrders(orders, selectedType, titleSearch);
    if (titleSearch && titleSearch.length > 0) {
      setCurrentPage(1);
    }
    setFilteredOrders(filtered);
  }, [selectedType, titleSearch, orders]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  useEffect(() => {
    if (orderStatus !== ORDERSTATUS.NONATTRIBUTED)
      dispatch(
        handleSorting({
          table: orderStatus,
          sortField: 'deadline',
          sortOrder: 'asc',
        })
      );
  }, [dispatch, orderStatus]);

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortingChange = (field) => {
    const direction =
      field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
    dispatch(
      handleSorting({
        table: orderStatus,
        sortField: field,
        sortOrder: direction,
      })
    );
    setSortField(field);
    setSortOrder(direction);
  };

  function handleDeleteOrder() {
    axiosPrivate
      .delete(`/commands/${orderId}/refuse`)
      .then(() => {
        setConfirmation(false);
        dispatch(removeNonAttributedOrder(orderId));
        setErrorSuccessMsg(`${t('La commande a été supprimé avec succès')} !`);
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setConfirmation(false);
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  }
  return (
    <div className="content-center">
      <TableHeader
        title={title}
        titleSearch={titleSearch}
        setTitleSearch={setTitleSearch}
        ordersPerPage={ordersPerPage}
        setOrdersPerPage={setOrdersPerPage}
      />
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <ErrorComponent />
      ) : (
        <div className="box-wrapper">
          <div className="table-responsive">
            <div>
              <table className="table-lists table-commandes">
                <thead>
                  <tr>
                    {columns.map(({ label, field, sortable }) => (
                      <th
                        style={{
                          cursor: sortable ? 'pointer' : 'default',
                          width: fieldWidths[field] || '',
                        }}
                        onClick={
                          sortable ? () => handleSortingChange(field) : null
                        }
                        key={field}
                      >
                        {t(label)}
                        {!sortable ? null : field === sortField &&
                          sortOrder === 'asc' ? (
                          <span>
                            <ChevronUpIcon style={{ marginLeft: '3px' }} />
                          </span>
                        ) : (
                          <span>
                            <ChevronDownIcon style={{ marginLeft: '3px' }} />
                          </span>
                        )}{' '}
                        {field === 'type' ? (
                          <OrderTypeFilter
                            orderTypeList={FILTER_WRITER_ORDER_TYPE_LIST}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                            handleClick={handleClick}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                          />
                        ) : null}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!isNonAttributed
                    ? currentOrders.map((order, index) => (
                        <tr key={order.id}>
                          <td
                            style={{ cursor: 'pointer' }}
                            onClick={async () => {
                              dispatch(setStatus(orderStatus));
                              dispatch(setOrderType(order.type));
                              dispatch(fetchWriterOrderDisplay(order.id));
                              dispatch(fetchWriterTextOrderDisplay(order.id));
                              history.push('/Redacteur/DetailsDeLaCommande');
                            }}
                          >
                            <span>{order?.title}</span>
                          </td>
                          <td>
                            {
                              ORDER_TYPE.find(
                                (elem) => elem.type === order.type
                              ).name
                            }
                          </td>
                          <td>
                            <span>
                              {order.deadline
                                ? formatDate(order.deadline)
                                : '-'}
                            </span>
                          </td>
                          <td>
                            <ProgressBar
                              amount={order.deliveredTextsAmount}
                              total={order.textAmount}
                            />
                          </td>
                          <td>
                            <ProgressBar
                              amount={order.validatedTextsAmount}
                              total={order.textAmount}
                            />
                          </td>
                        </tr>
                      ))
                    : currentOrders.map((order, index) => (
                        <tr key={order.id}>
                          <td style={{ cursor: 'pointer' }}>
                            <div className="d-flex">
                              <div style={{ width: '270px' }}>
                                {order.title}
                              </div>
                              {order.type === 'SMART_WITH_REVIEW' ? (
                                <div className="smart_order_icon ">
                                  <img
                                    src="Images/icon-eye.jpg"
                                    alt="eye-icon"
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </td>
                          <td>
                            {order.thematics.map((item, index) => (
                              <span key={index}>
                                {(index ? ', ' : '') + t(item.thematic)}
                              </span>
                            ))}
                          </td>
                          <td>{order.remainingTextAmount}</td>
                          <td>
                            {order.writerApplied ? (
                              <span
                                style={{
                                  color: '#43B929',
                                }}
                              >
                                {t('Postulé')}
                              </span>
                            ) : (
                              <button
                                className="bt-submit-table"
                                style={{
                                  background: '#43B929',
                                  color: 'white',
                                }}
                                onClick={() => {
                                  setShowApplication(true);
                                  setOrderId(order.id);
                                  setOrderTitle(order.title);
                                  setTotalText(order.remainingTextAmount);
                                }}
                              >
                                {t('Postuler')}
                              </button>
                            )}
                          </td>
                          <td>
                            <div>
                              <button
                                type="button"
                                className="bt-delete-circle"
                                style={{
                                  display: 'inline-block',
                                  marginLeft: 3,
                                  verticalAlign: 'middle',
                                }}
                                onClick={() => {
                                  setOrderId(order.id);
                                  setConfirmationMsg(
                                    `${t(
                                      'Voulez-vous vraiment supprimer la commande de'
                                    )} ${order.title} ?`
                                  );
                                  setConfirmation(true);
                                }}
                              ></button>
                            </div>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <Paginator
        isLoading={isLoading}
        listLength={filteredOrders.length}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        nbElementsPerPage={ordersPerPage}
        goToPage={(page) => setCurrentPage(page)}
      />
      <ApplicationModal
        showApplication={showApplication}
        handleClose={() => setShowApplication(false)}
        orderId={orderId}
        orderTitle={orderTitle}
        totalText={totalText}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={handleDeleteOrder}
      />

      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
}

export default OrdersStatusTable;
